import { useCallback, useEffect } from "react";
import { atom, useRecoilState } from "recoil";

type AlreadySetState = {
  loading: boolean;
  reset: boolean;
  isOpen: boolean;
};

const alreadySetAtom = atom<AlreadySetState>({
  key: "alreadySetAtom",
  default: {
    loading: false,
    isOpen: false,
    reset: false,
  },
});

export function useAlreadySet() {
  const [{ loading, isOpen, reset }, setState] = useRecoilState(alreadySetAtom);

  const open = useCallback(() => {
    setState((prev) => ({
      ...prev,
      isOpen: true,
    }));
    document.body.style.overflow = "hidden";
  }, [setState]);

  const close = useCallback(() => {
      setState((prev) => ({
        ...prev,
        isOpen: false,
      }));
      document.body.style.overflow = "auto";
  }, [setState]);

  const resetToOriginalRoster = useCallback(() => {
    setState((prev) => ({
      ...prev,
      reset: true,
    }));
  }, [setState]);

  const resetAll = useCallback(() => {
    setState({
      loading: false,
      isOpen: false,
      reset: false,
    });
  }, [setState]);

  return {
    loading,
    open,
    close,
    isOpen,
    reset,
    resetToOriginalRoster,
    resetAll,
  };
}
