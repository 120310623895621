import styled from "styled-components"
import { Loading } from "@gamesheet/ui";

const StyledEntityCardLoader = styled.div.attrs({
    className: "small-loader"
})`
div {
    height: 50px;
    width: 50px;

    &::after {
        border-width: 4px;
        width: 36px;
        height: 36px;
    }
}
`

export function EntityCardLoader() {
    return (
        <StyledEntityCardLoader>
            <Loading />
        </StyledEntityCardLoader>
    )
}