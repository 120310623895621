import { useEffect } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { rawAppVersion, appVersion } from './atom.versions';
import { collection, query, where, doc, getDocs, onSnapshot } from "firebase/firestore";
import { firestore } from '@/libs/firebase';
import version from '../../version';
import { useAppState } from "@/state/app/useAppState";
import semver, { SemVer } from "semver";

export function useReadAppVersionState() {
    const setAppVersionState = useSetRecoilState(rawAppVersion);

    useEffect(() => {
        
      const versionDocRef = doc(firestore, 'versions', 'td-app-version');
        const q = query(collection(firestore, 'versions'), where('__name__', '==', versionDocRef));

        return onSnapshot(q, (querySnapshot) => {
            querySnapshot.forEach((doc) => {
                const data = doc.data();
                setAppVersionState({ remoteVersion: data.version, localVersion: version });
            });
        });

    }, [version]);

}

export function useUpdateService() {

    useReadAppVersionState();
    const app = useAppState();
    const appVersionState = useRecoilValue(appVersion);
    const local = semver.coerce(appVersionState.localVersion, { includePrerelease: true } as semver.CoerceOptions);
    const remote = semver.coerce(appVersionState.remoteVersion, { includePrerelease: true } as semver.CoerceOptions);

    useEffect(() => {
      
      // if semver checking fails, don't set update available
      if(!semver.valid(appVersionState.localVersion) || !semver.valid(appVersionState.remoteVersion)){
        return;
      }
            
      app.setUpdateAvailable(semver.lt(local!, remote!));

    }, [ appVersionState.remoteVersion, appVersionState.localVersion ]);
}
