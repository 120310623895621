import { useEffect, useState } from "react";
import { useRosterImport } from "../hooks/useRosterImport";
import { ProgressBar } from "@/components/pure/ProgressBar";

export function ImportingProgress(){

  const { players, coaches, playerImportProgress, coachImportProgress } = useRosterImport();
  const [requestedPlayers, setRequestedPlayers] = useState(0);
  const [requestedCoaches, setRequestedCoaches] = useState(0);

  useEffect(() => {
    const playersImporting = playerImportProgress.sent - (playerImportProgress.pending + playerImportProgress.resolved + playerImportProgress.rejected);
    const coachesImporting = coachImportProgress.sent - (coachImportProgress.pending + coachImportProgress.resolved + coachImportProgress.rejected);

    setRequestedPlayers( playersImporting );
    setRequestedCoaches( coachesImporting );

  }, [ JSON.stringify(playerImportProgress), JSON.stringify(coachImportProgress) ])

  return (
    <>
      <ProgressBar
        title={`Players ( ${playerImportProgress.resolved} Imported of ${players.length} )`}
        total={players.length}
        pending={playerImportProgress.pending}
        requested={requestedPlayers}
        success={ playerImportProgress.resolved}
        failed={ playerImportProgress.rejected}
      />

      <ProgressBar
        title={`Coaches ( ${coachImportProgress.resolved} Imported of ${coaches.length} )`}
        total={coaches.length}
        pending={coachImportProgress.pending}
        requested={requestedCoaches}
        success={ coachImportProgress.resolved}
        failed={ coachImportProgress.rejected}
      />
    </>
  )
}
