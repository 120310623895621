import { useEffect, useState, useCallback } from "react";
import { useAppState } from "@/state/app/useAppState";
import { useUserState } from "@/state/user/useUserState";
import { useSeasonsData } from "./useSeasonTeams";
import { useTeamRoster } from "./useTeamRoster/useTeamRoster";

export type CoachFormData = {
    id: string;
    firstName: string;
    lastName: string;
    position: string;
    rosterLocked: boolean;
    teamId: string;
};

export const useCoachData = (coachId?: string) => {

    const roster = useTeamRoster()
    const service = useCoachRosterEventService()
    const isNewCoach = coachId === "new";
    const seasons = useSeasonsData();
    
    const [formData, setFormData] = useState<CoachFormData>({
        id: "",
        firstName: "",
        lastName: "",
        position: "",
        rosterLocked: false,
        teamId: "",
    });

    const submit = useCallback( (onComplete?: () => void) => {
        
        // TODO: validate form data
        const { rosterLocked, teamId, ...coach } = formData;

        // This prevents the user from being able to send a request to create or update a coach when:
        // - there is no unlocked seasonteam in this prototeam OR
        // - the coach does not exist in any unlocked seasonteam in this prototeam
        if (!seasons.hasUnlockedRoster || rosterLocked || formData.firstName === "" || formData.lastName === "") return; // rosterLocked might be redundant 
        
        const coachData = {
            ...coach,
        }

        const func = isNewCoach ? "create" : "update";
        const team_id = isNewCoach ? seasons.firstUnlocked!.id : teamId
        return service[func](team_id, coachData).then(onComplete)
        
    }, [formData, seasons.hasUnlockedRoster, seasons.firstUnlocked]);

    const remove = useCallback( (onComplete?: () => void) => {

        const { rosterLocked } = formData;
        
        // This prevents the user from being able to send a request to create or update a coach when:
        // - there is no unlocked seasonteam in this prototeam OR
        // - the coach does not exist in any unlocked seasonteam in this prototeam
        if (!seasons.hasUnlockedRoster || rosterLocked) return;  // rosterLocked might be redundant

        // guard against bad data
        if (!coachId || isNewCoach){
            return;
        }

        return service.remove(seasons.firstUnlocked!.id, coachId).then(onComplete)
    }, [formData, seasons.hasUnlockedRoster, seasons.firstUnlocked])

    useEffect(() => {

        if(!coachId || coachId == "new") return;
        
        const coach = roster.coaches.find((coach) => coach.id == coachId);
        if(!coach) return;

        setFormData({
            id: coachId,
            firstName: coach.firstName,
            lastName: coach.lastName,
            position: coach.position,
            rosterLocked: coach.rosterLocked,
            teamId: coach.teamId,
        });

    }, [coachId]);

    return {
        formData,
        setFormData,
        submit,
        remove,
    }

}

type CoachRosterEvent = "prototeam-coach-create" | "prototeam-coach-update" | "prototeam-coach-remove" | "prototeam-coach-add" | "prototeam-coach-restore";
function useCoachRosterEventService() {
    
    const app = useAppState();
    const user = useUserState();

    async function send(event: CoachRosterEvent, team_id: string, coachData: Partial<CoachFormData>){

        const token = await user.GetToken();

        const coachSendData = {
            ...coachData,
            id: parseInt(coachData.id ? coachData.id : "0"),
        }

        const teamId = parseInt(team_id)

        const body = {
            event,
            attributes: {
                schema: "coach-event"
            },
            data: {
                teamId,
                coach: coachSendData,
            }
        }
    
        // TODO: handle errors
        return fetch(`${app.config.gateways.events}/roster`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        })
        .then((res) => res.json())
        .catch((err) => {
            
        });

    }

    return {
        update: (teamId:string, coachData: Partial<CoachFormData>) => send("prototeam-coach-update", teamId, coachData),
        create: (teamId:string, coachData: Partial<CoachFormData>) => send("prototeam-coach-create", teamId, coachData),
        remove: (teamId:string, coachId: string ) => send("prototeam-coach-remove", teamId, { id: coachId }),
        // add: () => send("prototeam-coach-add"),
        // restore: () => send("prototeam-coach-restore"),
    }

}