import { useCallback, useEffect } from "react";
import { atom, useRecoilState } from "recoil";

type ExitWarningState = {
  loading: boolean;
  isOpen: boolean;
};

const exitWarningAtom = atom<ExitWarningState>({
  key: "exitWarningAtom",
  default: {
    loading: false,
    isOpen: false,
  },
});

export function useExitWarning() {
  const [{ loading, isOpen }, setState] = useRecoilState(exitWarningAtom);

  const open = useCallback(() => {
    setState((prev) => ({
      ...prev,
      isOpen: true,
    }));

    document.body.style.overflow = "hidden";
  }, [setState]);

  const close = useCallback(() => {
    setState((prev) => ({
      ...prev,
      isOpen: false,
    }));

    document.body.style.overflow = "auto";
  }, [setState]);

  useEffect(() => {
    let mounted = true;

    if (!isOpen) {
      return;
    }

    return () => {
      mounted = false;
    };
  }, [isOpen]);

  return {
    loading,
    open,
    close,
    isOpen,
  };
}
