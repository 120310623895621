import { useEffect } from "react";
import config from ".";

let displayed = false

export function useConfig(){
    
    useEffect(() => {
        
        displayed && config.name != "Prod" || console.log(`Using ${config.name} config: `, config)
        displayed = true

    }, [])
    
    return config
}