import { useCallback, useEffect } from "react";
import { atom, useRecoilState } from "recoil";

type DeletePlayerOrCoachState = {
  loading: boolean;
  isOpen: boolean;
};

const deletePlayerOrCoachStateAtom = atom<DeletePlayerOrCoachState>({
  key: "deletePlayerOrCoachStateAtom",
  default: {
    loading: false,
    isOpen: false,
  },
});

export function useDeletePlayerOrCoach() {
  const [{ loading, isOpen }, setState] = useRecoilState(deletePlayerOrCoachStateAtom);

  const open = useCallback(() => {
    setState((prev) => ({
      ...prev,
      isOpen: true,
    }));

    document.body.style.overflow = "hidden";
  }, [setState]);

  const close = useCallback(() => {
    setState((prev) => ({
      ...prev,
      isOpen: false,
    }));

    document.body.style.overflow = "auto";
  }, [setState]);

  useEffect(() => {
    let mounted = true;

    if (!isOpen) {
      return;
    }

    return () => {
      mounted = false;
    };
  }, [isOpen]);

  return {
    loading,
    open,
    close,
    isOpen,
  };
}
