import React from "react";
import { PopupBackground, PopupBox, TeamCard, CircleButton, LockedTeamCard } from "./styles";
import { Title } from "@gamesheet/ui";
import LockImg from "@/assets/images/padlock.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCheck } from "@fortawesome/free-solid-svg-icons";
import { TeamSelectorProps } from "./types";
import { LockedRoster } from "@/state/data/useTeamRoster/types";
import { fontSize } from "pdfkit";
import { useSeasonsData } from "@/state/data/useSeasonTeams";

export function TeamSelector({ activeSeasons, lockedRosters, toggleRosterSelectMenu, selectedRoster, setSelectedRoster }: TeamSelectorProps) {
  return (
    <>
      <PopupBackground onClick={toggleRosterSelectMenu} />

      <PopupBox>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontWeight: 'bold', fontSize: '18px', textAlign: 'left', backgroundColor: '#2e2e2e', color: '#FFF', borderTopLeftRadius: '6px', borderTopRightRadius: '6px', height: '45px', paddingLeft: '20px', paddingRight: '10px' }}>
          TEAM ROSTERS <CircleButton className="close-button" onClick={toggleRosterSelectMenu}> <FontAwesomeIcon icon={faTimes} /> </CircleButton>
        </div>

          <TeamCard onClick={() => { setSelectedRoster("0"); toggleRosterSelectMenu() }}>
            <div style={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <span>Team Roster for:</span> { selectedRoster.teamId === '0' && <span style={{ fontWeight: 'normal' }}><FontAwesomeIcon icon={faCheck} style={{ color: '#FEC307' }} /> Active </span> }
            </div>
            <div style={{ fontStyle: 'italic', width: '80%', overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical' }}>
              { activeSeasons.withUnlockedRosters.map((team: any, index: any) => (
                <span key={index}>
                  {team.season.title}
                  {index < activeSeasons.withUnlockedRosters.length - 1 ? ", " : ""}
                </span> 
              ))} 
            </div>
          </TeamCard>

        <Title bartype="divider" text="Locked Rosters" size="h5" />
        {lockedRosters.map((team: LockedRoster) => (
          <LockedTeamCard key={team.id} onClick={() => { setSelectedRoster(team.id); toggleRosterSelectMenu() }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <span style={{ width: '80%' }}>{team.seasonTitle}</span>
              <div style={{ textAlign: 'right' }}>
                { (selectedRoster.teamId == team.id) && <span style={{ fontWeight: 'normal' }}><FontAwesomeIcon icon={faCheck} style={{ color: '#FEC307' }} /> Active</span> }
                <img src={LockImg} alt="Padlock" style={{ width: '25px', height: '25px' }} />
              </div>
            </div>
          </LockedTeamCard>
        ))}
     
      <div style={{ fontSize: '10px', textAlign: 'left', paddingLeft: '10px', paddingBottom: '6px'}}>Select the roster you would like to view</div>
      </PopupBox>
    </>
  )
}