import styled from "styled-components";
import { Modal, Button } from "@gamesheet/ui";

import { useLineupSuccess } from "./useLineupSuccess";
import woohoo from "@/assets/images/woohoo.png"


export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgb(0, 0, 0, 0.5);
  z-index: 5200;
`;

const Text = styled.div`
  color: #36383d;
  text-align: center;
  font-family: Rubik;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 35px;
`;

const ConfirmButtonContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  padding-top: 0.5rem;
  justify-content: space-between;
`;

const Divider = styled.div`
  border: 1px solid #d7d7d8;
  margin: 0 -1rem;
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 2rem;
`;

const Emoji = styled.img`
  width: 160px; 
  height: auto; 
`;


const BoldedText = styled.div`
  color: #36383d;
  text-align: center;
  font-family: Rubik;
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
`;

export function LineupSuccess() {
  const { isOpen, close, opponent } = useLineupSuccess();

  if (!isOpen) {
    return <></>;
  }

  return (
    <ModalContainer>
      <Modal title="Lineup Set" show={isOpen} onClose={close}>
        <ContentContainer>
          <Emoji src={woohoo} alt="success-img"></Emoji>
          <BoldedText>WooHoo!</BoldedText>
          <Text>The lineup for your upcoming game against {opponent} has been set.</Text>
          <Text>See you at the game!</Text>
        </ContentContainer>
        <Divider />
        <ConfirmButtonContainer>
          <Button size="lg" label="OK" width="block" onClick={close} />
        </ConfirmButtonContainer>
      </Modal>
    </ModalContainer>
  );
}
