import styled from "styled-components";
import { useUserState } from "@/state/user/useUserState";
import { Modal, Button } from "@gamesheet/ui";
import { NewFormField, useCreateForm } from "@/forms/hooks/useCreateForm";
import { TextInput } from "@/forms/components/TextInput";
import { useAppState } from "@/state/app/useAppState";
import { useSelectedTeamState } from "@/state/team/useSelectedTeamState";
import { useState } from "react";
import { emailRegex } from "@/forms/SignupForm";
import { PageLoading } from "./PageLoading";

export type ExportAllScoreheetsModalProps = {
  numGames: number;
  onClose: () => void;
};

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgb(0, 0, 0, 0.5);
  z-index: 5200;
  font-family: "Rubik", Arial, sans-serif;

  background-size: cover;
  background-position: right;
  background-attachment: fixed;
`;

export const TextBefore = styled.p`
  margin-bottom: 0.5rem;
`;

export const TextAfter = styled.p`
  text-align: center;
  margin-top: 0.4rem;
  margin-bottom: 0.5rem;
`;

export const ButtonGroup = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ExportAllScoreheetsModal = ({
  numGames,
  onClose,
}: ExportAllScoreheetsModalProps) => {
  const app = useAppState();
  const selectedTeam = useSelectedTeamState();
  const user = useUserState();
  const [callSent, setCallSent] = useState(false);
  const { state: formState, onChange } = useCreateForm(
    "export-all-scoresheets",
    {
      email: NewFormField(
        user.email || "",
        emailRegex,
        "Email must be valid"
      ),
    }
  );

  const handleExport = async () => {
    const url = `${app.config.gateways.eventLog}/v5/teams/${
      selectedTeam!.id
    }/export-all-team-scoresheets`;
    const headers = new Headers();
    user.isLoggedIn &&
      headers.set("Authorization", `Bearer ${user.tokens.access}`);

    const requestData = {
      attributes: {
        event: "export-all-team-scoresheets",
        schema: "export-scoresheet",
        version: "v1",
      },
      data: {
        id: selectedTeam!.id,
        title: selectedTeam!.title,
        email: formState.email.value,
      },
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers,
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        setCallSent(true);
      } else {
        console.error("Error while updating team data:", response);
      }
    } catch (error) {
      console.error("Error while updating team data:", error);
    }
  };

  if(!formState){
    return <PageLoading />
  }

  return (
    <ModalContainer>
      <Modal title="Confirm Score Sheet Export" show={true} onClose={onClose}>
        {(!callSent && (
          <>
          {numGames >= 1 ? 
              <TextBefore style={{ marginBottom: "0.5rem" }}>
                Score sheets for {numGames} {numGames == 1 ? "game" : "games"}{" "}
                involving {selectedTeam?.title} will be exported and a link to
                download the score sheets will be emailed to:
              </TextBefore> 
            : 
              <TextBefore style={{ marginBottom: "0.5rem" }}>
                No games found for {selectedTeam?.title}
              </TextBefore>
          }

          {numGames >= 1 &&  
            <TextInput
              state={formState.email}
              name="email"
              label="Email"
              onChange={onChange}
              autocomplete={false}
            /> }
           
            <ButtonGroup>
              <Button variant="inverted" label="Cancel" size="lg" onClick={onClose}>
                Cancel
              </Button>
              {numGames >= 1 ?   
                <Button label="Export Score Sheets" size="lg" onClick={handleExport}/>
                  : 
                <Button state="disabled" variant="inverted" size="lg" label="Export Score Sheets" />
              }
            
            </ButtonGroup>
          </>
        )) || (
          <>
            <p>
              Your score sheets are being exported.
            </p>
            <p>
              Once finished, you’ll receive an email at {formState.email.value} from{" "}
              <strong>no-reply@gamesheet.app</strong> with the link to download
              the requested score sheets.
            </p>
            <p>It may take a few minutes.</p>
            <Button label="confirm" size="lg" onClick={onClose}>
              OK
            </Button>
          </>
        )}
      </Modal>
    </ModalContainer>
  );
};
