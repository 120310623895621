import styled from "styled-components";
import { Title, Alert, Toggle, Loading } from "@gamesheet/ui";
import { TeamEvent } from "@/state/data/useTeamEvents";
import { TeamEvents } from "./useScheduleTableColumns";
import { GameCard } from "@/components/GameCard";
import { useAppState } from "@/state/app/useAppState";
import { useState, useEffect, useCallback } from "react";
import { useRecoilState } from "recoil";
import { showPastEventsState } from "./atom.showPastEvents";
import dayjs from "@/libs/dayjs";
import { LineupTeam } from "@/flows/LineupFlow/state/useLatestLineup";
import { useFilteredEvents } from "./useFilteredEvents";
import { useDebounce } from "@/hooks/useDebounce";

const StyledSmallEventsGrid = styled.div`
  display: flex;
  flex-direction: column;
  

  .title-bar1{
    display: flex !important;
    align-items: center;
    justify-content: space-between;
  }

  .divider {
    margin-top: 2px;
    background-color: #306CF6;
    border-radius: 100px;
    height: 2px;
    width: 100%;
  }
`;

const StyledPageContainer = styled.div`
  height: calc(100vh - 120px); 
  display: flex;
  flex-direction: column;
`

const StyledPastEventsGrid = styled(StyledSmallEventsGrid)`
row-gap: 0;
margin-bottom: 12px;

.game-card-list-final:nth-child(odd) {
    background-color: #EEE;
}
`

const StyledFutureEventsGrid = styled(StyledSmallEventsGrid)`
padding-top: 1rem;
row-gap: 1rem;
`

const StyledTitle = styled(Title)`
display: flex;
flex-direction: row;
`

const StyledToggle = styled(Toggle)`
flex: 1;
margin-bottom: 0rem !important;
`

const StyledHeader = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #fff;


  .title-bar1{
    display: flex !important;
    align-items: center;
    justify-content: space-between;
  }
`;

const StyledScrollableContent = styled.div`
  flex: 1;
  overflow-y: scroll;
  padding-bottom: 140px;

  .divider {
    margin-top: 2px;
    background-color: #306CF6;
    border-radius: 100px;
    height: 2px;
    width: 100%;
  }
`;

const StyledLoadingContainer = styled.div`
width:100%;
padding: 50px;

> div {
    margin: 0 auto;
    display: block;
}
`


type Props = {
    todayRef: React.RefObject<HTMLDivElement>;
    next: TeamEvent | undefined;
    scheduledGames: TeamEvents[];
    completedGames: TeamEvents[];
    events: TeamEvents[];
    eventsChanged: number;
    isRemoteLineupsEnabled: boolean;
    latestLineup: LineupTeam | null;
}

export function MobileScheduleView({ todayRef, next, scheduledGames, completedGames, events: unsortedEvents, eventsChanged, isRemoteLineupsEnabled, latestLineup }:Props){

    const app = useAppState();
    const [ showPastEvents, setShowPastEvents ] = useRecoilState(showPastEventsState);
    const handleToggle = () => {
      setShowPastEvents(!showPastEvents);
    };

  const {
    events,
    calendarEvents,
    futureEvents,
    pastEvents
  } = useFilteredEvents(unsortedEvents, { showPastEvents });

  const handleGamesLoaded = () => {
    setIsGamesLoaded(true);
  }

  const [isGamesLoaded, setIsGamesLoaded] = useState(false);
  const debouncedHandleInitialLoadScroll = useDebounce(handleGamesLoaded, 500);
  useEffect(() => {
    debouncedHandleInitialLoadScroll()
  }, [JSON.stringify(pastEvents), pastEvents.length])

  useEffect(() => {
    if (!isGamesLoaded) return;

    let scrollDistance = pastEvents.length * 46.78 - (46.78 * 2.5)
    const divElement = document.getElementsByClassName('eventsWrapper')
    if (divElement) {
      if (divElement.length > 0) {
        const element = divElement[0] as HTMLElement;
        element.scrollTop -= 999999;
        element.scrollTop += scrollDistance;
      }
    }
  }, [isGamesLoaded])

  function getUserTimeZone() {
      // Use the Intl.DateTimeFormat object to get the user's timezone
      const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      return userTimeZone;
    }
  const userTimeZone = getUserTimeZone();
  const utcOffsetSeconds = dayjs().tz(userTimeZone).utcOffset() * 60;

  const handleInitialLoadScroll = () => {
      let scrollDistance = pastEvents.length * 46.78 - (46.78 * 2.5)

      const divElement = document.getElementsByClassName('eventsWrapper')
      if (divElement){
          if (divElement.length > 0) {
              const element = divElement[0] as HTMLElement;
              element.scrollTop -= 999999;
              element.scrollTop += scrollDistance;
          }
      }
  }

  scheduledGames.sort((a, b) => new Date(a.scheduledStartTime).getTime() - new Date(b.scheduledStartTime).getTime())
  // pastEvents.sort((a, b) => new Date(a.scheduledStartTime).getTime() - new Date(b.scheduledStartTime).getTime())

  const Events = useCallback(() => {

    if (!isGamesLoaded) return <StyledLoadingContainer>
         <Loading />
     </StyledLoadingContainer>;

    if (!scheduledGames.length && !completedGames.length) {
      return <Alert message="There are no events scheduled for this team" style="warning" />
    }

    return (<>
      <StyledHeader>
          { !!next && <GameCard game={next} teamId={app.selectedTeam} template="featured" isRemoteLineupsEnabled={isRemoteLineupsEnabled} latestLineup={latestLineup} /> }
          <div style={{paddingTop: "1rem"}}></div>
          <StyledTitle text="Schedule" bartype="bar" variant="inverted"/>
      </StyledHeader>
      <StyledScrollableContent className="eventsWrapper">
          <StyledPastEventsGrid>
              { pastEvents.map((game, index) => <GameCard key={index} game={game} teamId={app.selectedTeam} template="list-final" /> )}
          </StyledPastEventsGrid>
          <div className="divider"/>
          <div ref={todayRef} />
          <StyledFutureEventsGrid>
              { futureEvents.map((game, index) => <GameCard key={index} game={game} teamId={app.selectedTeam} template="list-schedule" /> )}
          </StyledFutureEventsGrid>
      </StyledScrollableContent>
    </>)
    
  }, [ isGamesLoaded, eventsChanged, latestLineup ])

  return (
    <StyledPageContainer>
      <Events />
    </StyledPageContainer>)
}