import { useCallback, useEffect } from "react";
import { atom, useRecoilState } from "recoil";
import { useAppState } from "@/state/app/useAppState";
import { useUserState } from "@/state/user/useUserState";

type EditPrototeamModal = {
  prototeam: ProtoTeam;
  loading: boolean;
  isOpen: boolean;
};

type ProtoTeam = {
  id: string;
  title: string;
  logo: string;
  club: string;
};

type SubmitProps = {
  id: string;
  teamName: string;
  teamLogo: string;
};

type RemoveProps = {
  id: string;
};

type UpdateTeamData = {
  attributes: {
    event: "edit-team",
    schema: "basic-team-info",
    version: "v1",
  },
  data: {
    id: string,
    title?: string,
    logo?: string
  },
}

export const protoTeamModalState = atom<EditPrototeamModal>({
  key: "protoTeamModalState",
  default: {
    prototeam: {
      id: "",
      title: "",
      logo: "",
      club: "",
    },
    loading: false,
    isOpen: false,
  },
});

export function useEditPrototeam() {
  const app = useAppState();
  const user = useUserState();
  const [{ prototeam, loading, isOpen }, setState] = useRecoilState(protoTeamModalState);

  const setEditPrototeamData = (prototeam: ProtoTeam) => {
    setState((prev) => ({
      ...prev,
      prototeam,
    }));
  };

  const open = useCallback(() => {
    setState((prev) => ({
      ...prev,
      isOpen: true,
    }));

    document.body.style.overflow = "hidden";
  }, [setState]);

  const close = useCallback(() => {
    setState((prev) => ({
      ...prev,
      isOpen: false,
    }));

    document.body.style.overflow = "auto";
  }, [setState]);

  const onRemoveTeam = async ({ id }: RemoveProps) => {
    const url = `${app.config.gateways.eventLog}/v5/users/${user.id}/remove-prototeam`;
    const headers = new Headers();
    user.isLoggedIn && headers.set("Authorization", `Bearer ${user.tokens.access}`);

    const requestData = {
      attributes: {
        event: "remove-prototeam",
        schema: "user-prototeam",
        version: "v1",
      },
      data: {
        userId: user.id,
        prototeamId: id,
      },
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers,
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        setState((prev) => ({
          ...prev,
          isOpen: false,
        }));
      } else {
        console.error("Error while updating team data:", response);
      }
    } catch (error) {
      console.error("Error while updating team data:", error);
    }
  };

  const onSubmit = async ({ id, teamName, teamLogo }: SubmitProps) => {
    const trimmedTeamName = teamName?.trim() || "";

    if (!trimmedTeamName) {
      //guard to prevent empty strings from being sent to the server for team name
      return;
    }

    const requestData: UpdateTeamData = {
      attributes: {
        event: "edit-team",
        schema: "basic-team-info",
        version: "v1",
      },
      data: {
        id: id,
      },
    };

    if (trimmedTeamName !== prototeam.title) {
      //Only add team name to payload if it has changed
      requestData.data.title = trimmedTeamName;
    }

    if (teamLogo !== prototeam.logo) {
      //Only add team logo to payload if it has changed
      requestData.data.logo = teamLogo;
    }


    const url = `${app.config.gateways.eventLog}/v5/teams/${id}/edit-team`;
    const headers = new Headers();
    user.isLoggedIn && headers.set("Authorization", `Bearer ${user.tokens.access}`);

    try {
      const response = await fetch(url, {
        method: "POST",
        headers,
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        setState((prev) => ({
          ...prev,
          isOpen: false,
        }));
      } else {
        console.error("Error while updating team data:", response);
      }
    } catch (error) {
      console.error("Error while updating team data:", error);
    }
  };

  return {
    loading,
    prototeam,
    open,
    close,
    isOpen,
    setEditPrototeamData,
    onSubmit,
    onRemoveTeam
  };
}
