import { NavLink } from "react-router-dom";
import styled from "styled-components";

export type Size = "lg" | "sm";

const Button = styled.a`
text-align: center;

&#btn-profile {
     min-width: 60px;
     max-width: 60px;
}
`

const NavIcon = styled.div<{ $size: Size }>`
display: flex;
align-items: center;
justify-content: center;

svg {
    height: ${p => p.$size == "sm" ? "24px" : "36px"};
    width: auto;
}
`

const NavText = styled.div<{ $size: Size }>`
display: flex;
align-items: center;
justify-content: center;
font-size: ${p => p.$size == "sm" ? "0.8rem" : "1rem"};
`

export type NavigationItem = {
    to?: string;
    action?: React.MouseEventHandler<HTMLAnchorElement>;
    label?: string;
    icon?: JSX.Element;
    desktop?: boolean;
    mobile?: 'left' | 'right' | 'icon';
    combined?: boolean;
}

export function NavButton({ button, size="sm" }: {button : NavigationItem, size?: Size }) {

    const content = <>
        <NavIcon $size={size}>{button.icon}</NavIcon>
        <NavText $size={size}>{button.label}</NavText>
    </>

    return <>
        {button.to && !button.combined && (
            <NavLink to={button.to}>
                {content}
            </NavLink>
        )}
        {button.action && !button.combined && (
            <Button onClick={button.action}>
                {content}
            </Button>
        )}
        {button.action && button.to && button.combined && (
            <Button onClick={button.action}>
                <NavLink to={button.to}>
                    {content}
                </NavLink>
            </Button>
        )}
    </>

}