import { PropsWithChildren } from "react";
import styled from "styled-components";

const MobileActionBar = styled.div`
position: fixed;
width: 100%;
background: ${(props)=>props.theme.primaryColor}CC;
box-shadow: 0 0 10px 0 rgba(0,0,0,0.5);
top: 60px;
left: 0;
padding: 4px;
`

const DesktopActionBar = styled.div`
position: fixed;
background: ${(props)=>props.theme.primaryColor}CC;
box-shadow: 0 0 10px 0 rgba(0,0,0,0.5);
top: 111px;
left: calc((100vw - min(1280px, 100vw)) / 2);
right: calc((100vw - min(1280px, 100vw)) / 2);
padding: 4px;
`

type ActionBarProps = PropsWithChildren<{
    isMobile?: boolean
}>

export function ActionBar(props:ActionBarProps){
    
    const StyledActionBar = props.isMobile ? MobileActionBar : DesktopActionBar
    
    return <StyledActionBar>
        {props.children}
    </StyledActionBar>
}