import React from "react";
import styled from "styled-components";

const StyledModalBackground = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: #36383d;
  opacity: 0.5;
  z-index: 100;
  filter: blur(3px);
`;

const StyledModalContent = styled.div`
  height: 95vh;
  width: 90vw;
  margin: 0 1rem;
  font-size: 0.9rem;
  z-index: 101;
  background-color: #f5f5f4;
  border-radius: 0.3125rem;
  box-shadow: 0px 4px 4px 0px rgba(54, 56, 61, 0.4);
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const StyledModalTitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 0.3125rem 0.3125rem 0rem 0rem;
  background: #36383d;
  color: #fff;
  padding: 0.75rem 1.25rem;
`;

const StyledModalTitle = styled.div`
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
`;

const StyledCloseIcon = styled.svg`
  position: absolute;
  right: 1.25rem;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
`;

const StyledModalFooter = styled.div`
  color: #000;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background-color: #fff;
`;

export interface FlowModalProps {
  title: string;
  children: React.ReactNode;
  onExit: () => void;
}

const FlowModal: React.FC<FlowModalProps> = ({ title, children, onExit }) => {
  return (
    <>
      <StyledModalBackground />
      <StyledModalContent>
        <StyledModalTitleContainer>
          <StyledModalTitle>{title}</StyledModalTitle>
          <StyledCloseIcon onClick={onExit} xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
            <path d="M5.41406 4.58594L22.4141 21.5859" stroke="#FEC307" stroke-width="4" />
            <path d="M22.4141 4.58594L5.41406 21.5859" stroke="#FEC307" stroke-width="4" />
          </StyledCloseIcon>
        </StyledModalTitleContainer>

        {children}
        <StyledModalFooter></StyledModalFooter>
      </StyledModalContent>
    </>
  );
};

export { FlowModal, StyledModalBackground };
