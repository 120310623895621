import { ActionType, ReducerAction, TeamRosterLocalState } from "./types";

export const TeamRosterReducer = (state: TeamRosterLocalState, action: ReducerAction): TeamRosterLocalState => {

    switch (action.type) {
        case ActionType.TOGGLE_ROSTER_SELECT:
            return {
                ...state,
                rosterSelectOpen: !state.rosterSelectOpen
            };

    }

    return state;

}