import { Button, FormWrapper, Loading, Title } from "@gamesheet/ui";
import * as Grid from "@/layouts/GridItems";
import { TextInput } from "./components/TextInput";
import { FormEvent, FormEventHandler, forwardRef, useCallback, useEffect, useImperativeHandle, useState, MouseEvent } from "react";
import { FormFieldType, NewFormField, useCreateForm } from "@/forms/hooks/useCreateForm";
import { PasswordInput } from "./components/PasswordInput";
import { styled } from "styled-components";
import { PageLoading } from "@/components/pure/PageLoading";
import { useUserState } from "@/state/user/useUserState";
import { AppStateInterface } from "@/state/app/app.interface";
import { UserUpdate } from "@/services/useUserService";
import { emailRegex } from "./SignupForm";

const StyledEditUserForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: 16px;
`

type EditUserFormProps = {
    onSubmit: (userForm:EditUserFormState, revalidateUser: boolean) => void;
    userDetails: UserUpdate;
    app: AppStateInterface;
}

export type EditUserFormState = {
    firstName: FormFieldType<string>;
    lastName: FormFieldType<string>;
    email: FormFieldType<string>;
    confirmPassword: FormFieldType<string>;
}

export const EditUserForm = forwardRef(({ onSubmit, userDetails, app }:EditUserFormProps, ref ) => {
    
    const user = useUserState()

    const { state: formState, onChange } = useCreateForm('editUser', {
        firstName: NewFormField((userDetails?.firstName || ""), /.{2,}/, 'First Name must be at least 2 characters'),
        lastName: NewFormField((userDetails?.lastName || ""), /.{2,}/, 'Last Name must be at least 2 characters'),
        email: NewFormField((user.email || ""), emailRegex, 'Email must be valid'),
        confirmPassword: NewFormField((""), /.{0,}/, 'Please enter your password'),
    })

    const [ emailChanged, setEmailChanged ] = useState(false);
    const [ showSave, setShowSave ] = useState(false);

    useEffect(() => {
        if(formState){
            (setEmailChanged(formState.email?.value !== userDetails.email))
        }
    }, [ userDetails.email, formState?.email?.value ])

    useEffect(() => {
        if(formState){
            setShowSave((formState.email?.value !== userDetails.email) || (formState.lastName?.value !== userDetails.lastName) || (formState.firstName?.value !== userDetails.firstName))
        }
    },[ formState, userDetails ])

    const handleSubmit = useCallback((e:MouseEvent<HTMLButtonElement> | FormEvent<HTMLFormElement>) => {
        e && e.preventDefault();
        
        for(const field of Object.values(formState)){
            if(!field.value.match(field.test)){
                return;
            }
        }
        
        onSubmit(formState, emailChanged);
    }, [ onSubmit, formState, emailChanged ])

    useEffect(() => {
        if (showSave){
            app.ui('rightTray').set(<Button gs-style="solid" size="lg" onClick={handleSubmit}>Save</Button>)
        } else {
            app.ui('rightTray').set(<Button gs-style="solid" size="lg" style={{ backgroundColor:'#b5b5b3' }}>Save</Button>)
        }
    }, [ onSubmit, formState, showSave ])

    if(!formState){
        return <PageLoading />
    }

    return <FormWrapper style={{ marginTop:'15px' }}>
                <Title bartype="divider" text="Edit Profile" size="h2"/>
                <StyledEditUserForm onSubmit={handleSubmit} style={{ marginTop:'15px' }}>
                    <TextInput state={formState.firstName} name="firstName" label="First Name" onChange={onChange} />
                    <TextInput state={formState.lastName} name="lastName" label="Last Name" onChange={onChange} />
                    <TextInput state={formState.email} name="email" label="Email" onChange={onChange} autocomplete={false} />
                    { emailChanged && <PasswordInput state={formState.confirmPassword} name="confirmPassword" label="Verify Password" onChange={onChange} autocomplete={false} /> }
                </StyledEditUserForm>
            </FormWrapper>
})