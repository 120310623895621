import styled from "styled-components";
import { Modal, Button } from "@gamesheet/ui";
import { useAppState } from "@/state/app/useAppState";

import { useFrozenLineup } from "./useFrozenLineup";

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgb(0, 0, 0, 0.5);
  z-index: 5200;
`;

const Text = styled.div`
  font-family: Rubik;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  padding-bottom: 0.75rem;
`;

const ConfirmButtonContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  padding-top: 0.5rem;
  justify-content: space-between;
`;

const Divider = styled.div`
  border: 1px solid #d7d7d8;
  margin: 0 -1rem;
`;

export function FrozenLineup() {
  const app = useAppState();
  const { isOpen, close } = useFrozenLineup();

  const handleClose = () => {
    close();
    app.navigate("/");
  };

  if (!isOpen) {
    return <></>;
  }

  return (
    <ModalContainer>
      <Modal title="Lineup is read-only" show={isOpen} onClose={handleClose}>
        <Text>Remote lineups have been frozen for this game and can only be edited by the scoring official.</Text>
        <Divider />
        <ConfirmButtonContainer>
          <Button size="lg" width="block" label="OK" onClick={handleClose} />
        </ConfirmButtonContainer>
      </Modal>
    </ModalContainer>
  );
}
