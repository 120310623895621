import { useEffect } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { locationQueryState, locationInputFocusState } from './state/atoms';

interface UseQueryInputProps {
  value: string;
  setValue: (value: string) => void;
  refine: (value: string) => void;
  clear: number;
}

export default function useQueryInput({ value, setValue, refine, clear }: UseQueryInputProps) {
  const [query, setQuery] = useRecoilState(locationQueryState);
  const setFocus = useSetRecoilState(locationInputFocusState);

  useEffect(() => {
    setQuery(value);
  }, [value]);

  useEffect(() => {
    if (clear > 0) {
      setQuery("");
    }
  }, [clear]);

  useEffect(() => {
    refine(query);
    setValue(query);
  }, [query, refine, setValue]);

  return {
    query,
    setQuery,
    gotFocus: () => setFocus(true),
  };
}
