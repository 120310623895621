import styled from "styled-components";
import { Outlet } from "react-router-dom";
import { SavePlayer } from "@/windows/lineups/savePlayer";

const StyledFlowLayout = styled.div`
    /* Add your styling for the FlowLayout */
`;

export function FlowLayout(props: any) {
    return (
        <StyledFlowLayout>
            <Outlet />
        </StyledFlowLayout>
    );
}
