import React from 'react'
import styled from 'styled-components'

const StyledErrorBar = styled.div`
  display: flex;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;

  border-radius: 0.375rem;
  border: 1px solid #EA868F;
  background: #F8D7DA;
`

type ErrorBarType = {
    message: string
}


export default function ErrorBar({message}: ErrorBarType) {
  return (
    <StyledErrorBar><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g clip-path="url(#clip0_308_5)">
      <path d="M8.98195 1.56595C8.88296 1.39352 8.74022 1.25025 8.56815 1.15062C8.39608 1.051 8.20078 0.998535 8.00195 0.998535C7.80312 0.998535 7.60781 1.051 7.43574 1.15062C7.26367 1.25025 7.12094 1.39352 7.02195 1.56595L0.164946 13.233C-0.292054 14.011 0.255946 15 1.14495 15H14.8579C15.7469 15 16.2959 14.01 15.8379 13.233L8.98195 1.56595ZM7.99995 4.99995C8.53495 4.99995 8.95395 5.46195 8.89995 5.99495L8.54995 9.50195C8.53819 9.63972 8.47515 9.76806 8.3733 9.86159C8.27145 9.95511 8.13822 10.007 7.99995 10.007C7.86167 10.007 7.72844 9.95511 7.62659 9.86159C7.52474 9.76806 7.46171 9.63972 7.44995 9.50195L7.09995 5.99495C7.08738 5.86919 7.10129 5.74218 7.14079 5.62212C7.18029 5.50206 7.2445 5.3916 7.32929 5.29786C7.41408 5.20413 7.51756 5.12919 7.63307 5.07788C7.74858 5.02657 7.87355 5.00002 7.99995 4.99995ZM8.00195 11C8.26716 11 8.52152 11.1053 8.70905 11.2928C8.89659 11.4804 9.00195 11.7347 9.00195 12C9.00195 12.2652 8.89659 12.5195 8.70905 12.7071C8.52152 12.8946 8.26716 13 8.00195 13C7.73673 13 7.48238 12.8946 7.29484 12.7071C7.1073 12.5195 7.00195 12.2652 7.00195 12C7.00195 11.7347 7.1073 11.4804 7.29484 11.2928C7.48238 11.1053 7.73673 11 8.00195 11Z" fill="#58151C"/>
    </g>
    <defs>
      <clipPath id="clip0_308_5">
        <rect width="16" height="16" fill="white"/>
      </clipPath>
    </defs>
  </svg> {message}</StyledErrorBar>
  )
}
