import React, { ChangeEvent, FC } from "react";
import styled from "styled-components";
import { send } from "@/libs/cloudflare";

interface ImageUploaderProps {
  onUploadSuccess: (imageUrl: string) => void;
  getToken: () => string;
  setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
  setError: React.Dispatch<React.SetStateAction<string | null>>;
  isSubmitting: boolean;
  label: string;
  disabled?: boolean;
}

const StyledImageUploader = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;
  overflow: hidden;
`;

const StyledInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
`;

const StyledLabel = styled.label`
  display: inline-block;
  padding: 0.5rem 1.25rem;
  color: #36383d;
  text-align: center;
  border-radius: 5px;
  font-size: 0.6875rem;
  font-weight: 500;
  border-radius: 5px;
  border: 2px solid #36383d;
`;

export const ImageUploader: FC<ImageUploaderProps> = ({
  onUploadSuccess,
  getToken,
  setIsSubmitting,
  setError,
  isSubmitting,
  label,
  disabled = true,
}) => {
  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    setError("");
    const token = await getToken();
    setIsSubmitting(true);

    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];

      if (
        !file.type.match(/image\/jpeg/) &&
        !file.type.match(/image\/png/) &&
        !file.type.match(/image\/webp/) &&
        !file.type.match(/image\/svg/)
      ) {
        setIsSubmitting(false);
        setError(
          "Unsupported file type. Please upload a PNG, JPEG, WebP, or SVG."
        );
        return;
      }

      if (file.size > 10 * 1024 * 1024) {
        setIsSubmitting(false);
        setError("File size must be less than 10MB");
        return;
      }

      try {
        const imageUrl = await send(file, token);
        onUploadSuccess(imageUrl);
        setIsSubmitting(false);
      } catch (error) {
        setIsSubmitting(false);
        setError("Error uploading image");
        console.error("Error uploading image:", error);
      }
    }
  };

  return (
    <StyledImageUploader>
      <StyledLabel htmlFor="image-uploader-input">
        {isSubmitting ? "Uploading..." : label}
      </StyledLabel>
      <StyledInput
        type="file"
        accept="image/*"
        id="image-uploader-input"
        onChange={handleFileChange}
        disabled={disabled}
      />
    </StyledImageUploader>
  );
};
