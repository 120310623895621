import { Button, Title } from "@gamesheet/ui";
import * as Grid from "@/layouts/GridItems";
import { useAppState } from "@/state/app/useAppState";
import { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router";
import { WarningCard } from "@/components/pure/EntityCard/MobileEntityCard";
import { useCoachData } from "@/state/data/useCoachData";
import { useSeasonsData } from "@/state/data/useSeasonTeams";
import { useTeamRoster } from "@/state/data/useTeamRoster/useTeamRoster";


export function TeamRosterCoachDelete(){

    const app = useAppState()
    const params = useParams()
    const coachId = params.id
    const roster = useTeamRoster()
    const { formData, remove } = useCoachData(coachId)
    const seasons = useSeasonsData()
    const showActions = seasons.hasUnlockedRoster

    const onCoachDelete = useCallback( () => {
        remove()
        coachId && roster.removeCoach(coachId)
        app.navigate(`/roster/team`)
    }, [remove])
    
    useEffect(() => {
        
        app.ui('leftTray').set(<Button gs-style="solid" size="lg" onClick={() => app.navigate(`/roster/coach/${coachId}`)}>Cancel</Button>)
        if (showActions) {
            app.ui('rightTray').set(<Button gs-style="solid" size="lg" onClick={onCoachDelete} variant="danger">Confirm Remove</Button>)
        }

        return () => {
            app.ui('leftTray').clear()
            app.ui('rightTray').clear()
        }
    }, [onCoachDelete])

    return (<>
        <Grid.Blank $column={1} $row={1}>
            <WarningCard title={`Remove ${formData.firstName} ${formData.lastName}`}>
                This will remove the coach {formData.firstName} {formData.lastName} from your roster. Are you sure?
            </WarningCard>  
        </Grid.Blank>
    </>)
}