import React, { useEffect } from "react";
import styled from "styled-components";
import { useLineupData } from "./state/useLineup";
import CoachForm from "@/forms/CoachForm";
import { coachFormDataState } from "./state/atom.coachFormDataState";
import { useRecoilState } from "recoil";
import { Title } from "@gamesheet/ui";
import { BreakPoints } from "@/libs/breakpoints";

const StyledForm = styled.div`
  width: 100%;
  padding-top: 1rem;

  @media screen and (min-width: ${BreakPoints.lg}) {
    padding-top: 0;
  }
`;

export const AddCoachStep: React.FC = () => {
  const { LocalLineupData } = useLineupData();
  const [formData, setFormData] = useRecoilState(coachFormDataState);


  useEffect(() => {
    // Assuming you want to set coach data when a coach is selected

    const lineupData = LocalLineupData.get();
    if (!lineupData || !lineupData.lineup) return; // Add null check

    const selectedCoach = lineupData.lineup.coaches.find((coach) => coach.isSelected);

    if (selectedCoach) {
      setFormData(selectedCoach);
    } else {
      // If no coach is selected, reset the form data
      setFormData({
        id: "",
        firstName: "",
        lastName: "",
        position: "",
        rosterLocked: false,
        teamId: "",
      });
    }
  }, [LocalLineupData.get(), setFormData]);

  return (
    <StyledForm>
      <Title bartype="divider" size="h2" text={`Add Coach`} />
      <CoachForm formData={formData} setFormData={setFormData} useLineupConfig={LocalLineupData.getSelectedPlayerGoalieOrCoach()?.data.new !== undefined ? !LocalLineupData.getSelectedPlayerGoalieOrCoach()?.data.new : false }  />
    </StyledForm>
  );
};
