import React, { useEffect, useState } from "react";
import { Button, Loading } from "@gamesheet/ui";
import { styled } from "styled-components";
import { useAppState } from "@/state/app/useAppState";
import { LineupFlowPath, LineupRecord } from "./types";
import { useLineupFlow } from "./state/useLineupFlow";
import { PlayersStep } from "./step.players";
import { StartingGoalieStep } from "./step.startingGoalie";
import { CoachesStep } from "./step.coaches";
import { PreviewStep } from "./step.preview";
import { FlowModal } from "../components/FlowModal";
import { BreakPoints } from "@/libs/breakpoints";
import { useLineupData } from "./state/useLineup";
import { AddPlayerStep } from "./step.addPlayer";
import { AddCoachStep } from "./step.addCoach";
import { useTeamsData } from "@/state/data/useTeams";
import { useSavePlayer } from "@/windows/lineups/savePlayer/useSavePlayer";
import { useUserState } from "@/state/user/useUserState";
import { SavePlayer } from "@/windows/lineups/savePlayer";
import { useTeamRoster } from "@/state/data/useTeamRoster";
import { RosterCoach, RosterPlayer } from "@/state/data/useTeamRoster/types";
import { useLocation, useParams } from "react-router-dom";
import { SaveCoach } from "@/windows/lineups/saveCoach";
import { useSaveCoach } from "@/windows/lineups/saveCoach/useSaveCoach";
import { DeletePlayerOrCoach } from "@/windows/lineups/deletePlayerOrCoach";
import { useDeletePlayerOrCoach } from "@/windows/lineups/deletePlayerOrCoach/useDeletePlayerOrCoach";
import { PlayerNotParticipating } from "@/windows/lineups/playerNotParticipating";
import {
  goalieHasStartingValueTrueSelector,
  playerFormIsDifferentState,
  playerFormValidityState,
  playersAndGoaliesWithEmptyJerseySelector,
  playingGoalieIdsSelector,
} from "./state/atom.playerFormDataState";
import { useRecoilValue } from "recoil";
import { coachFormIsDifferentState, coachFormValidityState, hasHeadCoachSelector } from "./state/atom.coachFormDataState";
import { useMatchingJerseyPlayers } from "./state/atom.matchingJerseyPlayerState";
import { useValidationErrors } from "@/windows/lineups/validationErrors/useValidationErrors";
import { ValidationErrors } from "@/windows/lineups/validationErrors";
import { useWarningDisplay } from "@/windows/lineups/warningDisplay/useValidationErrors";
import { WarningDisplay } from "@/windows/lineups/warningDisplay";
import { useLatestLineup } from "./state/useLatestLineup";
import { useSignatureState } from "./state/useSignatureState";
import { useExitWarning } from "@/windows/lineups/exitWarning/useExitWarning";
import { ExitWarning } from "@/windows/lineups/exitWarning";
import { useAlreadySet } from "@/windows/lineups/alreadySet/useAlreadySet";
import { AlreadySet } from "@/windows/lineups/alreadySet";
import { SeasonTeamData, useSeasonsData } from "@/state/data/useSeasonTeams";
import { useFrozenLineup } from "@/windows/lineups/frozenLineup/useFrozenLineup";
import { FrozenLineup } from "@/windows/lineups/frozenLineup";
import { getOpponent } from "@/state/data/useTeamEvents";
import { useLineupSuccess } from "@/windows/lineups/lineupSuccess/useLineupSuccess";
import { useScheduledGameService } from "@/services/useScheduledGameService/useScheduledGameService";
import { usePlayerNotParticipating } from "@/windows/lineups/playerNotParticipating/usePlayerNotParticipating";
import { useLockedRosters } from "@/state/data/useTeamRoster/useLockedRosters";
import { useReadLockedRosterData } from "@/state/data/useTeamRoster/useReadLockedRosters";

const MobileLineupFlowContainer = styled.div`
  padding: 0 0.5rem;
  background-color: #f5f5f4;
  display: flex;
  flex-direction: column;
  height: 100dvh;
`;

const DesktopLineupFlowContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const DesktopLineupFlowContentContainer = styled.div`
  padding: 0.5rem;
  height: calc(100vh - 96px);
  overflow-y: auto;
  position: relative;
`;

const MobileLineupFlowContentContainer = styled.div`
  padding: 0rem 0.5rem;
  overflow-y: auto;
  position: relative;
  flex-grow: 1;
`;

const LineupFlowButtons = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: auto;
  background-color: #f5f5f4;
  padding-bottom: 2rem;

  @media screen and (min-width: ${BreakPoints.lg}) {
    padding-bottom: 0;
  }
`;

const LineupFlowButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
`;

const CancelNextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
`;

const MobileCancelNextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 0rem;
`;

const VerticalButtonContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: 1rem;
`;

const DesktopYellowBar = styled.div`
  height: 32px;
  background-color: ${(props) => props.theme.primaryColor};
  padding: 4px;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 999;
`;

const LineupFlowButtonsContainer = styled.div`
  position: sticky;
  bottom: 0;
  z-index: 100;
  background-color: #fff;
  @media screen and (min-width: ${BreakPoints.lg}) {
    border-top: 1px solid #d7d7d8;
  }
`;

const FakeUserBar = styled.div`
  width: 100vw;
  margin-top: 32px;
  height: 78px;
  background-color: ${(props) => props.theme.secondaryColor};
  color: ${(props) => props.theme.textOnSecondaryColor};
  position: fixed;
  top: 0;
  left: 0;
`;

type LineupFlowButtonProps = {
  onClick: () => void;
  label: string;
};

function LineupFlowButton({ onClick, label }: LineupFlowButtonProps) {
  return (
    <Button onClick={onClick} type="button" gs-style="solid" variant="inverted" size="lg">
      {label}
    </Button>
  );
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function LineupFlow() {
  const app = useAppState();
  const user = useUserState();
  const flow = useLineupFlow(LineupFlowPath.Player);
  const { LocalLineupData } = useLineupData();
  const teams = useTeamsData();
  const { teamId, gameId } = useParams<{ teamId: string; gameId: string }>();
  const { matchingPlayers } = useMatchingJerseyPlayers();
  const gameService = useScheduledGameService();
  const query = useQuery();
  const email = query.get('email'); 

  const opponent = useRecoilValue(getOpponent({ gameId, teamId }));

  const { latestLineup, loading: latestLineupLoading } = useLatestLineup(gameId, teamId);
  const [publishLoading, setPublishLoading] = useState(false);
  const { svgData } = useSignatureState();
  const seasons = useSeasonsData();
  const [isUnlocked, setIsUnlocked] = useState(false);
  const [lockedRosterInfo, setLockedRosterInfo] = useState<SeasonTeamData>();
  const [lockedRosterLoading, setLockedRosterLoading] = useState(true);

  const { open: openSavePlayerModal, close: closeSavePlayerModal } = useSavePlayer();
  const { open: openSaveCoachModal, close: closeSaveCoachModal } = useSaveCoach();
  const { open: openDeletePlayerOrCoachModal, close: closeDeletePlayerOrCoachModal } = useDeletePlayerOrCoach();
  const { open: openValidationErrors, close: closeValidationErrors } = useValidationErrors();
  const { open: openWarnings, close: closeWarnings } = useWarningDisplay();
  const { open: openExitWarning, close: closeExitWarning } = useExitWarning();
  const { open: openAlreadySet, reset: resetToRoster, resetAll: resetAllAlreadySetValues, close: closeAlreadySet } = useAlreadySet();
  const { open: openFrozenLineup, close: closeFrozenLineup } = useFrozenLineup();
  const { open: openSuccessModal, setOpponentTeam: setOpponentTitleForSuccessMsg } = useLineupSuccess();
  const { close: closePlayerNotParticipatingModal } = usePlayerNotParticipating();

  useEffect(() => {
    if (!gameId) return;
    if (!teamId) return;
    if (!seasons) return;

    let mounted = true;
    const seasonIdFromGameId = async () => {
      const response = await gameService.GetGameDetails(gameId);
      return response?.data?.season_id || "";
    };

    seasonIdFromGameId().then((seasonId) => {
      if (mounted) {
        const seasonTeam = seasons.teams.find((team) => team.season.id === seasonId);
        setLockedRosterInfo(seasonTeam);
        setIsUnlocked(!seasonTeam?.rosterLocked);
        setLockedRosterLoading(false);
      }
    });

    return () => {
      mounted = false;
    };
  }, [gameId, teamId, JSON.stringify(seasons)]);

  const isPlayerFormValid = useRecoilValue(playerFormValidityState);
  const isPlayerFormDifferent = useRecoilValue(playerFormIsDifferentState);
  const isCoachFormValid = useRecoilValue(coachFormValidityState);
  const isCoachFormDifferent = useRecoilValue(coachFormIsDifferentState);
  const playersWithoutJersey = useRecoilValue(playersAndGoaliesWithEmptyJerseySelector);
  const playingGoalieIds = useRecoilValue(playingGoalieIdsSelector);
  const hasStartingGoalie = useRecoilValue(goalieHasStartingValueTrueSelector);
  const hasActiveHeadCoach = useRecoilValue(hasHeadCoachSelector);

  useReadLockedRosterData();

  const roster = useTeamRoster();

  const lockedRoster = useLockedRosters();

  useEffect(() => {
    const container = document.querySelector(".lineup-flow-content");
    if (container) {
      container.scrollTop = 0;
    }
  }, [flow.path]);

  useEffect(() => {
    if (!teamId) return;
    if (!teams.all()) return;
    if (app?.selectedTeam === teamId) return;

    let teamFound = false;

    teams.all().forEach((team) => {
      if (team.id === teamId) {
        app.setSelectedTeam(team.id);
        teamFound = true;
      }
    });

    if (!teamFound) {
      app.navigate("/");
    }
  }, []);

  useEffect(() => {
    // cleanup to clear any modal that might be open and the user navigates back via the browser
    closeExitWarning();
    closeFrozenLineup();
    closeSavePlayerModal();
    closeSaveCoachModal();
    closeDeletePlayerOrCoachModal();
    closeValidationErrors();
    closeWarnings();
    closeAlreadySet();
    closePlayerNotParticipatingModal();
  }, []);

  useEffect(() => {
    let mounted = true;

    if (!roster) return;
    if (!teamId) return;
    if (!gameId) return;
    if (!teams) return;
    if (!teams.getSeasonTeams) return;
    if (latestLineupLoading) return;
    if (lockedRosterLoading) return;

    if (latestLineup && !resetToRoster) {
      const playersObject = latestLineup.players;
      const coachesObject = latestLineup.coaches;

      const playersArray = Object.values(playersObject).filter((player) => player.position !== "goalie");
      const goaliesArray = Object.values(playersObject).filter((player) => player.position === "goalie");

      const mappedPlayers = playersArray.map((player) => ({
        ...player,
        status:
          player.affiliated === true ? "not_playing" : player.status === "Regular" ? "playing" : player.status === "affiliated" ? "not_playing" : player.status === "" ? "playing" : player.status,
        teamId: teamId,
        externalId: player.externalId,
        errorMessage: "",
        playerDataChanged: false,
        bioDataChanged: false,
        saveToRoster: false,
        isSelected: false,
        new: false,
        rosterLocked: !isUnlocked,
      }));

      const mappedGoalies = goaliesArray.map((player) => ({
        ...player,
        status:
          player.affiliated === true ? "not_playing" : player.status === "Regular" ? "playing" : player.status === "affiliated" ? "not_playing" : player.status === "" ? "playing" : player.status,
        teamId: teamId,
        externalId: player.externalId,
        errorMessage: "",
        playerDataChanged: false,
        bioDataChanged: false,
        saveToRoster: false,
        isSelected: false,
        new: false,
        rosterLocked: !isUnlocked,
      }));

      const mappedCoaches = Object.values(coachesObject).map((coach) => ({
        ...coach,
        new: false,
        externalId: coach.externalId,
        teamId: teamId,
        rosterLocked: !isUnlocked,
      }));

      mounted &&
        LocalLineupData.set({
          lineup: { players: mappedPlayers, goalies: mappedGoalies, coaches: mappedCoaches },
          locked: false,
          protoTeamId: teamId,
          gameId: gameId,
          id: "lineup1",
          createdAt: 123123,
          rosterLocked: !isUnlocked,
        });

      if (latestLineup.locked) {
        mounted && openFrozenLineup();
      } else {
        mounted && openAlreadySet();
      }
    } else if (!lockedRosterInfo?.rosterLocked) {
      // Filter players and goalies based on position
      const goalies: RosterPlayer[] = roster.goalies.filter((player) => player.position === "goalie");
      const players: RosterPlayer[] = roster.players.filter((player) => player.position !== "goalie");
      // Similarly, set coaches data from the roster object
      const coaches: RosterCoach[] = roster.coaches;
      // // Update your local state with the filtered players and goalies

      const mappedPlayers = players.map((player) => ({
        ...player,
        status:
          player.affiliated === true ? "not_playing" : player.status === "Regular" ? "playing" : player.status === "affiliated" ? "not_playing" : player.status === "" ? "playing" : player.status,
        teamId: teamId,
        photoURL: player.photoUrl,
        externalId: player.externalId,
        errorMessage: "",
        playerDataChanged: false,
        bioDataChanged: false,
        saveToRoster: false,
        isSelected: false,
        new: false,
        rosterLocked: !isUnlocked,
      }));

      const mappedGoalies = goalies.map((player) => ({
        ...player,
        status:
          player.affiliated === true ? "not_playing" : player.status === "Regular" ? "playing" : player.status === "affiliated" ? "not_playing" : player.status === "" ? "playing" : player.status,
        teamId: teamId,
        photoURL: player.photoUrl,
        externalId: player.externalId,
        errorMessage: "",
        playerDataChanged: false,
        bioDataChanged: false,
        saveToRoster: false,
        isSelected: false,
        new: false,
        rosterLocked: !isUnlocked,
      }));

      const mappedCoaches = coaches.map((coach) => ({
        ...coach,
        externalId: coach.externalId,
        teamId: coach.teamId,
        rosterLocked: !isUnlocked,
        new: false,
        status: coach?.status || "coaching",
      }));

      mounted &&
        LocalLineupData.set({
          lineup: { players: mappedPlayers, goalies: mappedGoalies, coaches: mappedCoaches },
          locked: false,
          protoTeamId: teamId,
          gameId: gameId,
          id: "lineup1",
          createdAt: 123123,
          rosterLocked: !isUnlocked,
        });
    } else {
      const handleLockedRosterSetting = () => {
        // Determine if the roster is locked based on the season ID
        const isRosterLocked = lockedRoster.lockedRosters.some((roster) => roster.seasonId === lockedRosterInfo.season.id.toString());



        if (isRosterLocked) {
          const foundRoster = lockedRoster.lockedRosters.find((roster) => roster.seasonId === lockedRosterInfo.season.id.toString());
          if (foundRoster) {
            const playersObject = foundRoster.players;
            const coachesObject = foundRoster.coaches;

            const playersArray = Object.values(playersObject).filter((player) => player.position !== "goalie");
            const goaliesArray = Object.values(playersObject).filter((player) => player.position === "goalie");

            const mappedPlayers = playersArray.map((player) => ({
              ...player,
              status:
                player.affiliated === true
                  ? "not_playing"
                  : player.status === "Regular"
                  ? "playing"
                  : player.status === "affiliated"
                  ? "not_playing"
                  : player.status === ""
                  ? "playing"
                  : player.status,
              teamId: teamId,
              photoURL: player.photoUrl,
              externalId: player.externalId,
              errorMessage: "",
              playerDataChanged: false,
              bioDataChanged: false,
              saveToRoster: false,
              isSelected: false,
              new: false,
              rosterLocked: !isUnlocked,
            }));

            const mappedGoalies = goaliesArray.map((player) => ({
              ...player,
              status:
                player.affiliated === true
                  ? "not_playing"
                  : player.status === "Regular"
                  ? "playing"
                  : player.status === "affiliated"
                  ? "not_playing"
                  : player.status === ""
                  ? "playing"
                  : player.status,
              teamId: teamId,
              photoURL: player.photoUrl,
              externalId: player.externalId,
              errorMessage: "",
              playerDataChanged: false,
              bioDataChanged: false,
              saveToRoster: false,
              isSelected: false,
              new: false,
              rosterLocked: !isUnlocked,
            }));

            const mappedCoaches = Object.values(coachesObject).map((coach) => ({
              ...coach,
              externalId: coach.externalId,
              teamId: teamId,
              rosterLocked: !isUnlocked,
              status: coach?.status || "coaching",
              new: false,
            }));


            LocalLineupData.set({
              lineup: { players: mappedPlayers, goalies: mappedGoalies, coaches: mappedCoaches },
              locked: false,
              protoTeamId: teamId,
              gameId: gameId,
              id: "lineup1",
              createdAt: 123123,
              rosterLocked: !isUnlocked,
            });
          }
        }
      };
      handleLockedRosterSetting();
    }

    return () => {
      mounted = false;
    };
  }, [JSON.stringify(roster), latestLineup, latestLineupLoading, resetToRoster, teamId, gameId, lockedRosterLoading, lockedRosterInfo]);

  useEffect(() => {
    if (!opponent) return;
    setOpponentTitleForSuccessMsg(opponent.title);
  }, [opponent]);

  const path = React.useMemo(() => {
    switch (flow.path) {
      default:
      case LineupFlowPath.Player:
        return <PlayersStep flow={flow} />;
      case LineupFlowPath.StartingGoalie:
        return <StartingGoalieStep />;
      case LineupFlowPath.Coach:
        return <CoachesStep flow={flow} />;
      case LineupFlowPath.Preview:
        return <PreviewStep />;
      case LineupFlowPath.AddPlayer:
        return <AddPlayerStep />;
      case LineupFlowPath.AddCoach:
        return <AddCoachStep />;
    }
  }, [flow.path]);

  const handleBack = () => {
    switch (flow.path) {
      case LineupFlowPath.StartingGoalie:
        LocalLineupData.deselectAll();
        flow.usePlayerPath();
        break;
      case LineupFlowPath.Coach:
        LocalLineupData.deselectAll();
        if (playingGoalieIds.length <= 1) {
          flow.usePlayerPath();
        } else {
          flow.useStartingGoaliePath();
        }
        break;
      case LineupFlowPath.Preview:
        LocalLineupData.deselectAll();
        flow.useCoachPath();
        break;
      case LineupFlowPath.AddPlayer:
        LocalLineupData.deselectAll();
        flow.usePlayerPath();
        break;
      case LineupFlowPath.AddCoach:
        LocalLineupData.deselectAll();
        flow.useCoachPath();
        break;
    }
  };

  const handlePublish = async () => {
    setPublishLoading(true);
    const response = await LocalLineupData.submitLineup(flow);
    if (response === 200) {
      setPublishLoading(false);
      flow.usePlayerPath();
      resetAllAlreadySetValues();
      if(email){
        app.navigate("/")
      } else {
        app.goBack();
      }
      openSuccessModal();
    }
    setPublishLoading(false);
  };

  const handlePlayerPageNext = () => {
    if (playingGoalieIds.length === 1) {
      LocalLineupData.setGoalieStartingById(playingGoalieIds[0]);
      flow.useCoachPath();
    } else {
      flow.useStartingGoaliePath();
    }
  };

  const buttons = React.useMemo(() => {
    switch (flow.path) {
      case LineupFlowPath.Player:
        return (
          <LineupFlowButtons>
            {app.layout.showDesktop ? (
              <CancelNextContainer>
                <Button size="lg" width="auto" onClick={openExitWarning} variant="inverted" label="Cancel" />
                <div style={{ display: "flex", justifyContent: "space-between", gap: "1rem" }}>
                  {isUnlocked && <Button size="lg" width="auto" onClick={flow.useAddPlayerPath} label="+ New Player" />}
                  {matchingPlayers.length > 0 || playersWithoutJersey.length > 0 ? (
                    <Button size="lg" variant="muted" width="auto" onClick={openValidationErrors} label="Next" />
                  ) : playingGoalieIds.length === 0 ? (
                    <Button size="lg" width="auto" onClick={openWarnings} label="Next" />
                  ) : (
                    <Button size="lg" width="auto" onClick={handlePlayerPageNext} label="Next" />
                  )}
                </div>
              </CancelNextContainer>
            ) : (
              <LineupFlowButtonContainer>
                {isUnlocked && <Button size="lg" width="block" onClick={flow.useAddPlayerPath} label="+ New Player" />}
                <MobileCancelNextContainer>
                  <LineupFlowButton onClick={openExitWarning} label="Cancel" />
                  {matchingPlayers.length > 0 || playersWithoutJersey.length > 0 ? (
                    <Button size="lg" variant="muted" width="auto" onClick={openValidationErrors} label="Next" />
                  ) : playingGoalieIds.length === 0 ? (
                    <Button size="lg" width="auto" onClick={openWarnings} label="Next" />
                  ) : (
                    <Button size="lg" width="auto" onClick={handlePlayerPageNext} label="Next" />
                  )}
                </MobileCancelNextContainer>
              </LineupFlowButtonContainer>
            )}
          </LineupFlowButtons>
        );
      case LineupFlowPath.StartingGoalie:
        return (
          <LineupFlowButtons>
            {app.layout.showDesktop ? (
              <CancelNextContainer>
                <div style={{ display: "flex", justifyContent: "space-between", gap: "1rem" }}>
                  <Button size="lg" width="auto" onClick={openExitWarning} variant="inverted" label="Cancel" />
                  <Button size="lg" width="auto" onClick={handleBack} variant="inverted" label="Back" />
                </div>

                <div style={{ display: "flex", justifyContent: "space-between", gap: "1rem" }}>
                  {hasStartingGoalie ? <Button size="lg" width="auto" onClick={flow.useCoachPath} label="Next" /> : <Button size="lg" width="auto" variant="muted" label="Next" disabled />}
                </div>
              </CancelNextContainer>
            ) : (
              <LineupFlowButtonContainer>
                <MobileCancelNextContainer>
                  <div style={{ display: "flex", justifyContent: "space-between", gap: "1rem" }}>
                    <Button size="lg" width="auto" onClick={openExitWarning} variant="inverted" label="Cancel" />
                    <Button size="lg" width="auto" onClick={handleBack} variant="inverted" label="Back" />
                  </div>
                  {hasStartingGoalie ? <Button size="lg" width="auto" onClick={flow.useCoachPath} label="Next" /> : <Button size="lg" width="auto" variant="muted" label="Next" disabled />}
                </MobileCancelNextContainer>
              </LineupFlowButtonContainer>
            )}
          </LineupFlowButtons>
        );
      case LineupFlowPath.Coach:
        return (
          <LineupFlowButtons>
            {app.layout.showDesktop ? (
              <CancelNextContainer>
                <div style={{ display: "flex", justifyContent: "space-between", gap: "1rem" }}>
                  <Button size="lg" width="auto" onClick={openExitWarning} variant="inverted" label="Cancel" />
                  <Button size="lg" width="auto" onClick={handleBack} variant="inverted" label="Back" />
                </div>

                <div style={{ display: "flex", justifyContent: "space-between", gap: "1rem" }}>
                  {isUnlocked && <Button size="lg" width="auto" onClick={flow.useAddCoachPath} label="+ New Coach" />}
                  {hasActiveHeadCoach ? <Button size="lg" width="auto" onClick={flow.usePreviewPath} label="Next" /> : <Button size="lg" width="auto" onClick={openWarnings} label="Next" />}
                </div>
              </CancelNextContainer>
            ) : (
              <LineupFlowButtonContainer>
                {isUnlocked && <Button size="lg" width="block" onClick={flow.useAddCoachPath} label="+ New Coach" />}
                <MobileCancelNextContainer>
                  <div style={{ display: "flex", justifyContent: "space-between", gap: "1rem" }}>
                    <LineupFlowButton onClick={openExitWarning} label="Cancel" />
                    <Button size="lg" width="auto" onClick={handleBack} variant="inverted" label="Back" />
                  </div>
                  {hasActiveHeadCoach ? <Button size="lg" width="auto" onClick={flow.usePreviewPath} label="Next" /> : <Button size="lg" width="auto" onClick={openWarnings} label="Next" />}
                </MobileCancelNextContainer>
              </LineupFlowButtonContainer>
            )}
          </LineupFlowButtons>
        );

      case LineupFlowPath.AddPlayer:
        return (
          <LineupFlowButtons>
            {app.layout.showDesktop ? (
              <CancelNextContainer>
                <div style={{ display: "flex", justifyContent: "space-between", gap: "1rem" }}>
                  <Button size="lg" width="auto" onClick={handleBack} variant="inverted" label="Cancel" />
                </div>

                <div style={{ display: "flex", justifyContent: "space-between", gap: "1rem" }}>
                  {LocalLineupData.getSelectedPlayerGoalieOrCoach()?.data?.new && (LocalLineupData.getSelectedPlayerGoalieOrCoach()?.type === "player" || "goalie") ? (
                    <Button variant="danger" size="lg" onClick={openDeletePlayerOrCoachModal} label="Delete" />
                  ) : null}
                  {isPlayerFormValid && isPlayerFormDifferent ? (
                    <Button size="lg" width="auto" onClick={openSavePlayerModal} label={LocalLineupData.getSelectedPlayerGoalieOrCoach()?.data ? "Update Player" : "Create Player"} />
                  ) : (
                    <Button size="lg" width="auto" variant="muted" label={LocalLineupData.getSelectedPlayerGoalieOrCoach()?.data ? "Update Player" : "Create Player"} disabled />
                  )}
                </div>
              </CancelNextContainer>
            ) : (
              <LineupFlowButtonContainer>
                <VerticalButtonContainer>
                  <div style={{ display: "flex", justifyContent: "space-between", gap: ".5rem" }}>
                    <Button variant="inverted" size="lg" width="block" onClick={handleBack} label="Cancel" />
                  </div>
                  <div style={{ display: "flex", gap: ".5rem", flexDirection: "column-reverse" }}>
                    {LocalLineupData.getSelectedPlayerGoalieOrCoach()?.data?.new && (LocalLineupData.getSelectedPlayerGoalieOrCoach()?.type === "player" || "goalie") ? (
                      <Button variant="danger" size="lg" width="block" onClick={openDeletePlayerOrCoachModal} label="Delete" />
                    ) : null}
                    {isPlayerFormValid && isPlayerFormDifferent ? (
                      <Button size="lg" width="block" onClick={openSavePlayerModal} label={LocalLineupData.getSelectedPlayerGoalieOrCoach()?.data ? "Update Player" : "Create Player"} />
                    ) : (
                      <Button size="lg" width="block" variant="muted" label={LocalLineupData.getSelectedPlayerGoalieOrCoach()?.data ? "Update Player" : "Create Player"} disabled />
                    )}
                  </div>
                </VerticalButtonContainer>
              </LineupFlowButtonContainer>
            )}
          </LineupFlowButtons>
        );

      case LineupFlowPath.AddCoach:
        return (
          <LineupFlowButtons>
            {app.layout.showDesktop ? (
              <CancelNextContainer>
                <div style={{ display: "flex", justifyContent: "space-between", gap: "1rem" }}>
                  <Button size="lg" width="auto" onClick={handleBack} variant="inverted" label="Cancel" />
                </div>

                <div style={{ display: "flex", justifyContent: "space-between", gap: "1rem" }}>
                  {LocalLineupData.getSelectedPlayerGoalieOrCoach()?.data?.new && LocalLineupData.getSelectedPlayerGoalieOrCoach()?.type === "coach" ? (
                    <Button variant="danger" size="lg" onClick={openDeletePlayerOrCoachModal} label="Delete" />
                  ) : null}

                  {isCoachFormValid && isCoachFormDifferent ? (
                    <Button size="lg" width="auto" onClick={openSaveCoachModal} label={LocalLineupData.getSelectedPlayerGoalieOrCoach()?.data ? "Update Coach" : "Create Coach"} />
                  ) : (
                    <Button size="lg" width="auto" variant="muted" label={LocalLineupData.getSelectedPlayerGoalieOrCoach()?.data ? "Update Coach" : "Create Coach"} disabled />
                  )}
                </div>
              </CancelNextContainer>
            ) : (
              <LineupFlowButtonContainer>
                <VerticalButtonContainer>
                  <div style={{ display: "flex", justifyContent: "space-between", gap: ".5rem" }}>
                    <Button variant="inverted" size="lg" width="block" onClick={handleBack} label="Cancel" />
                  </div>
                  <div style={{ display: "flex", gap: ".5rem", flexDirection: "column-reverse" }}>
                    {LocalLineupData.getSelectedPlayerGoalieOrCoach()?.data?.new && LocalLineupData.getSelectedPlayerGoalieOrCoach()?.type === "coach" ? (
                      <Button variant="danger" width="block" size="lg" onClick={openDeletePlayerOrCoachModal} label="Delete" />
                    ) : null}
                    {isCoachFormValid && isCoachFormDifferent ? (
                      <Button size="lg" width="block" onClick={openSaveCoachModal} label={LocalLineupData.getSelectedPlayerGoalieOrCoach()?.data ? "Update Coach" : "Create Coach"} />
                    ) : (
                      <Button size="lg" width="block" variant="muted" label={LocalLineupData.getSelectedPlayerGoalieOrCoach()?.data ? "Update Coach" : "Create Coach"} disabled />
                    )}
                  </div>
                </VerticalButtonContainer>
              </LineupFlowButtonContainer>
            )}
          </LineupFlowButtons>
        );
      case LineupFlowPath.Preview:
        return (
          <LineupFlowButtons>
            {app.layout.showDesktop ? (
              <CancelNextContainer>
                <div style={{ display: "flex", justifyContent: "space-between", gap: "1rem" }}>
                  <Button size="lg" width="auto" onClick={openExitWarning} variant="inverted" label="Cancel" />
                  <Button size="lg" width="auto" onClick={handleBack} variant="inverted" label="Back" />
                </div>

                <div style={{ display: "flex", justifyContent: "space-between", gap: "1rem" }}>
                  {svgData?.length > 0 ? (
                    <Button size="lg" width="auto" onClick={handlePublish} label="Publish" />
                  ) : (
                    <Button size="lg" variant="muted" width="auto" onClick={() => {}} label="Publish" />
                  )}
                </div>
              </CancelNextContainer>
            ) : (
              <LineupFlowButtonContainer>
                <MobileCancelNextContainer>
                  <div style={{ display: "flex", justifyContent: "space-between", gap: "1rem" }}>
                    <LineupFlowButton onClick={openExitWarning} label="Cancel" />
                    <Button size="lg" width="auto" onClick={handleBack} variant="inverted" label="Back" />
                  </div>
                  {svgData?.length > 0 ? (
                    <Button size="lg" width="auto" onClick={handlePublish} label="Publish" />
                  ) : (
                    <Button size="lg" variant="muted" width="auto" onClick={() => {}} label="Publish" />
                  )}
                </MobileCancelNextContainer>
              </LineupFlowButtonContainer>
            )}
          </LineupFlowButtons>
        );
    }
  }, [flow.path, app.layout, LocalLineupData.getSelectedPlayerGoalieOrCoach()?.data]);

  let view: JSX.Element = <></>;

  if (latestLineupLoading || publishLoading) {
    return (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
        <Loading />
      </div>
    );
  }

  const modals = (
    <>
      <SavePlayer flow={flow} />
      <SaveCoach flow={flow} />
      <DeletePlayerOrCoach flow={flow} />
      <PlayerNotParticipating flow={flow} />
      <ValidationErrors flow={flow} />
      <WarningDisplay flow={flow} />
      <ExitWarning flow={flow} />
      <AlreadySet />
      <FrozenLineup />
    </>
  );

  if (app.layout.showDesktop) {
    view = (
      <>
        <DesktopYellowBar />
        <FakeUserBar />
        {modals}
        <DesktopLineupFlowContainer>
          <FlowModal title={latestLineupLoading || lockedRosterLoading ? "" : `Set Lineup For Upcoming Game vs ${opponent?.title || ""}`} onExit={openExitWarning}>
            {latestLineupLoading || lockedRosterLoading ? (
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
                <Loading />
              </div>
            ) : (
              <>
                <DesktopLineupFlowContentContainer className="lineup-flow-content">{path}</DesktopLineupFlowContentContainer>
                <LineupFlowButtonsContainer>{buttons}</LineupFlowButtonsContainer>
              </>
            )}
          </FlowModal>
        </DesktopLineupFlowContainer>
      </>
    );
  } else {
    view = (
      <MobileLineupFlowContainer>
        {modals}
        {latestLineupLoading || lockedRosterLoading ? (
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
            <Loading />
          </div>
        ) : (
          <MobileLineupFlowContentContainer className="lineup-flow-content">{path}</MobileLineupFlowContentContainer>
        )}

        <LineupFlowButtonsContainer>{buttons}</LineupFlowButtonsContainer>
      </MobileLineupFlowContainer>
    );
  }
  return <>{view}</>;
}
