import { useEffect } from "react";
import { atom, useRecoilState, useRecoilValue } from "recoil";

const messageListenersState = atom<Array<(event:MessageEvent) => void>>({
    key: 'messageListenersState',
    default: []
})

export function useWindowState(){

    const messageListeners = useRecoilValue(messageListenersState)

    const onWindowMessageRecieved = (event:MessageEvent) => {
        messageListeners.forEach(listener => {
            listener(event)
        })
    }

    useEffect(() =>{
        window.addEventListener('message', onWindowMessageRecieved, false)
        return () => window.removeEventListener('message', onWindowMessageRecieved, false)
    }, [ messageListeners.length ])

}

export function useMessageListener(){

    const [ messageListeners, setMessageListeners ] = useRecoilState(messageListenersState)

    return {
        add: (listener:(event:MessageEvent) => void) => {
            setMessageListeners([...messageListeners, listener])
        },
        remove: (listener:(event:MessageEvent) => void) => {
            setMessageListeners(messageListeners.filter(l => l !== listener))
        }
    }

}