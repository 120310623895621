// useDeleteRepeatingEvent.js
import { useCallback } from "react";
import { atom, useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import { useAppState } from "@/state/app/useAppState";
import { useUserState } from "@/state/user/useUserState";
import { useSelectedTeamState } from "@/state/team/useSelectedTeamState";
import { EventFormState, recurrenceRRuleState } from "@/forms/EventForm";

type DeleteRepeatingEventModal = {
  isOpen: boolean;
  eventData: EventFormState | null;
};

export const deleteRepeatingEventModalState = atom<DeleteRepeatingEventModal>({
  key: "deleteRepeatingEventModalState",
  default: {
    isOpen: false,
    eventData: null,
  },
});

export function useDeleteRepeatingEvent(resetAllForm?: () => void) {
  const app = useAppState();
  const user = useUserState();
  const selectedTeam = useSelectedTeamState();

  const [{ isOpen }, setState] = useRecoilState(deleteRepeatingEventModalState);

  const open = useCallback((eventFormData: EventFormState) => {
    setState((prev) => ({
      ...prev,
      isOpen: true,
      eventData: eventFormData,
    }));

    document.body.style.overflow = "hidden";
  }, [setState]);

  const close = useCallback(() => {
    setState((prev) => ({
      ...prev,
      isOpen: false,
      eventData: null,
    }));

    document.body.style.overflow = "auto";
  }, [setState]);

  const eventData = useRecoilValue(deleteRepeatingEventModalState).eventData;
  const resetReccurenceRRule = useResetRecoilState(recurrenceRRuleState);

  const DeleteEvent = async (occurrenceType: "all" | "future" | "single", formData?:EventFormState ) => {

    const url = `${app.config.gateways.eventLog}/v5/teams/${selectedTeam?.id}/delete-event`;
    const headers = new Headers();
    user.isLoggedIn && headers.set("Authorization", `Bearer ${user.tokens.access}`);

    let requestData = {};

    if (formData) {
      requestData = {
        attributes: {
          event: "delete-event",
          schema: "delete-event",
          version: "v1",
        },
        data: {
          multiple: occurrenceType,
          id: formData.id.value,
        },
      };
    } else if (eventData && !formData) {
      requestData = {
        attributes: {
          event: "delete-event",
          schema: "delete-event",
          version: "v1",
        },
        data: {
          multiple: occurrenceType,
          id: eventData.id.value,
        },
      };
    } else {
        console.error("Missing event data."); 
        return
    }



    try {
      app.loading.unload('events');
      const response = await fetch(url, {
        method: "POST",
        headers,
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        close();
        app.loading.complete('events');
        app.navigate("/games")
        resetAllForm && resetAllForm();
        resetReccurenceRRule();
        return 200;
      } else {
        app.loading.complete('events');
        console.error("Error while updating event data:", response);
        return 500;
      }
    } catch (error) {
      app.loading.complete('events');
      console.error("Error while updating event data:", error);
      return 500;
    }
  };

  const onDeleteOccurrence = useCallback(
    async (occurrenceType: "all" | "future" | "single") => {
      try {
        // Move the network request logic here
        const response = await DeleteEvent(occurrenceType);
        // Check the response and handle accordingly
        if (response === 200) {
          resetAllForm && resetAllForm();
          resetReccurenceRRule();
          app.navigate("/games");
          return 200;
        }
      } catch (error) {
        
      }

      close();
    },
    [close, DeleteEvent, eventData]
  );

  return {
    isOpen,
    open,
    close,
    onDeleteOccurrence,
    DeleteEvent
  };
}
