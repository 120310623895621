import dayjs from "@/libs/dayjs";
import styled from "styled-components";
import { useRef, useEffect, useState, useCallback } from "react";
import { TeamEvent } from "@/state/data/useTeamEvents";
import { TeamEvents } from "./useScheduleTableColumns";
import { Title, Calendar, CalendarLegendIcon, Toggle, Alert, Loading } from "@gamesheet/ui";
import { GameCard } from "@/components/GameCard";
import { useAppState } from "@/state/app/useAppState";
import { useRecoilState } from "recoil";
import { showPastEventsState } from "./atom.showPastEvents";
import { LineupTeam } from "@/flows/LineupFlow/state/useLatestLineup";
import { useFilteredEvents } from "./useFilteredEvents";
import { useDebounce } from "@/hooks/useDebounce";
import { useUserState } from "@/state/user/useUserState";

export const StyledTeamSchedule = styled.div.attrs({
    className: "team-schedule-grid"
})`
display: flex;
width: 100%;
margin-bottom: -45px;
height: 100%;

.divider {
    margin-top: 2px;
    background-color: #306CF6;
    border-radius: 100px;
    height: 2px;
    width: 100%;
  }
`;

const StyledCalendarWrapper = styled.div`
display: flex;
flex-direction: column;
margin-left: 1rem;
`;

const StyledCalendar = styled(Calendar)`
flex: 0;
`

const StyledEventsWrapper = styled.div.attrs({
    className: 'eventsWrapper'
})`
flex: 1;
overflow: auto;
flex-direction: column;
min-height: 425px;
max-height: 55vh;
padding-bottom: 2rem;
`;


const StyledEventsGrid = styled.div`
display: flex;
flex-direction: column;
`

const StyledPastEventsGrid = styled(StyledEventsGrid)`
row-gap: 0;
margin-bottom: 1rem !important;

.game-card-list-final:nth-child(odd) {
    background-color: #EEE;
}
`

const StyledFutureEventsGrid = styled(StyledEventsGrid)`
padding-top: 1rem;
row-gap: 1rem;
`

const StyledLoadingContainer = styled.div`
width:100%;
padding: 50px;

> div {
    margin: 0 auto;
    display: block;
}
`

type Props = {
    todayRef: React.RefObject<HTMLDivElement>;
    teamId: string;
    next: TeamEvent | undefined;
    events: TeamEvents[];
    eventsChanged: number;
    isRemoteLineupsEnabled: boolean;
    latestLineup: LineupTeam | null;
}

export function DesktopScheduleView({ todayRef, teamId, next, events: unsortedEvents, eventsChanged, isRemoteLineupsEnabled, latestLineup }: Props) {
    
    const app = useAppState();
    const user = useUserState();
    const currentDate = require('dayjs');
    const pastEventsContainerRef = useRef<HTMLDivElement>(null);
    const futureEventsContainerRef = useRef<HTMLDivElement>(null);

    // show past events
    const [showPastEvents, setShowPastEvents] = useRecoilState(showPastEventsState);
    const toggleShowPastEvents = () => setShowPastEvents(!showPastEvents);

    const {
        events,
        calendarEvents,
        futureEvents,
        pastEvents
    } = useFilteredEvents(unsortedEvents, { showPastEvents });

    const handleGamesLoaded = () => {
        setIsGamesLoaded(true);
    }

    const [isGamesLoaded, setIsGamesLoaded] = useState(false);
    const debouncedHandleInitialLoadScroll = useDebounce(handleGamesLoaded, 500);
    useEffect(() => {
        debouncedHandleInitialLoadScroll()
    }, [JSON.stringify(pastEvents), pastEvents.length])

    useEffect(() => {
        if(!isGamesLoaded) return;
        
        let scrollDistance = pastEvents.length * 46.78 - (46.78 * 2.5)
        const divElement = document.getElementsByClassName('eventsWrapper')
        if (divElement) {
            if (divElement.length > 0) {
                const element = divElement[0] as HTMLElement;
                element.scrollTop -= 999999;
                element.scrollTop += scrollDistance;
            }
        }
    }, [isGamesLoaded])

    const scrollToEvent = (index: number, list: string) => {

        if (list === 'future' && futureEventsContainerRef.current) {
            const element = futureEventsContainerRef.current.children[index] as HTMLElement;
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: "nearest" });
            }
        }

        if (list === 'past' && pastEventsContainerRef.current) {
            const element = pastEventsContainerRef.current.children[index] as HTMLElement;
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: "nearest" });
            }
        }
    };

    const getScrollIndex = (day: dayjs.Dayjs) => {
        const targetTimestamp = day.valueOf();

        let closestIndex = -1;
        let closestDifference = Infinity;
        let list = 'past'

        pastEvents.forEach((event, index) => {
            const currentTimestamp = dayjs(event.scheduledStartTime).valueOf();
            const currentDifference = Math.abs(currentTimestamp - targetTimestamp);

            if (currentDifference < closestDifference) {
                closestIndex = index;
                closestDifference = currentDifference;
                list = 'past'
            }
        });

        futureEvents.forEach((event, index) => {
            const currentTimestamp = dayjs(event.scheduledStartTime).valueOf();
            const currentDifference = Math.abs(currentTimestamp - targetTimestamp);

            if (currentDifference < closestDifference) {
                closestIndex = index;
                closestDifference = currentDifference;
                list = 'future'
            }
        });

        return { closestIndex, list };
    }

    const handleDayClick = (day: dayjs.Dayjs) => {
        const { closestIndex, list } = getScrollIndex(day);

        if (closestIndex !== -1) {
            scrollToEvent(closestIndex, list);
        }
    };

    const Events = useCallback(() => {

       if (!isGamesLoaded) return <StyledLoadingContainer>
            <Loading />
        </StyledLoadingContainer>;

        if (events.length === 0) return <Alert message="No Events Found" style="warning" />

        return <>
            <StyledPastEventsGrid ref={pastEventsContainerRef}>
                {pastEvents.map((game, index) => <GameCard key={index} game={game} teamId={app.selectedTeam} template="list-final" />)}
            </StyledPastEventsGrid>
            <div className="divider" />
            <div ref={todayRef} />
            <StyledFutureEventsGrid ref={futureEventsContainerRef}>
                {futureEvents.map((game, index) => <GameCard key={index} game={game} teamId={app.selectedTeam} template="list-schedule" />)}
            </StyledFutureEventsGrid>
        </>;

    }, [showPastEvents, isGamesLoaded, eventsChanged])

    return (
        <StyledTeamSchedule>
            <div style={{ display: 'flex', flexDirection: 'column', flex: "1" }}>
                { !!next && <GameCard game={next} teamId={app.selectedTeam} template="featured" isRemoteLineupsEnabled={isRemoteLineupsEnabled} latestLineup={latestLineup}/> }
                { !!next && <div style={{paddingTop: "1rem"}}></div>}

                <Title text="Schedule" bartype="divider" size="h2"/>
                <StyledEventsWrapper>
                    <StyledEventsGrid>
                        <Events />
                    </StyledEventsGrid>
                </StyledEventsWrapper>
            </div>
            <StyledCalendarWrapper>
                <StyledCalendar defaultMonth={currentDate().startOf('month')} events={calendarEvents} handleDayClick={(day: dayjs.Dayjs) => handleDayClick(day)} />
                <div style={{ display: 'flex' }}>
                    <Toggle checked={showPastEvents} onChange={toggleShowPastEvents}/><div style={{ fontSize: '12px', color: '#86888B', paddingTop: '3px' }}>Show past practices and events</div>
                    {/* <Toggle checked={showPastEvents} onChange={toggleShowPastEvents}/><div style={{ fontSize: '12px', color: '#86888B', paddingTop: '3px' }}>Show past games</div> */}
                    <div style={{ display: "flex", justifyContent: "flex-end", flex: '1'}}>
                        <CalendarLegendIcon label={"Game"} color="36383D" />
                        <CalendarLegendIcon label="Practice" color="FEDB6A" />
                        <CalendarLegendIcon label="Event" color="D7D7D8" />
                    </div>
                </div>
            </StyledCalendarWrapper>
        </StyledTeamSchedule>
    );

}
