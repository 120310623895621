import { useResetRecoilState } from "recoil"
import { AcceptInvitationFlowState } from "@/flows/AcceptInvitationFlow/state/atom.AcceptInvitationFlowState"
import { appState } from "./app.state"
import { firebaseUserState } from "@/state/user/useSetFirebaseAuth"
import { userState } from "@/state/user/useUserState"
import { useEventsState } from "../events/useEventsStats"

export function useResetState(){

    const events = useEventsState()
    const resetFunctions = [
        useResetRecoilState(appState),
        useResetRecoilState(AcceptInvitationFlowState),
        useResetRecoilState(firebaseUserState),
        useResetRecoilState(userState),
    ]

    return () => {
        resetFunctions.forEach(reset => reset())
        events.trigger("app:reset")
    }

}