import { router as teamRouter } from './apps/teams/router';
import { router as leagueRouter } from './apps/leagues/router';
import config from "./config";
import { RouterProvider } from 'react-router-dom';
import { useDeviceState, useSetDeviceState } from './state/device/useDeviceState';
import { useEventsState } from './state/events/useEventsStats';
import { useEffect, useState } from 'react';

let router: typeof teamRouter;
switch (config.app) {
  case 'team': router = teamRouter; break;
  case 'league': router = leagueRouter; break;
  default: throw new Error(`Invalid app type: ${config.app}`);
}

export function GameSheetRouter() {

  useSetDeviceState()
  const [lastUpdate, setLastUpdate] = useState(Date.now())
  const events = useEventsState()
  const device = useDeviceState()

  useEffect(() => events.add("app:reset", () => setLastUpdate(Date.now())), [])

  return <>
    <RouterProvider key={lastUpdate} router={router(device)} />
  </>

}