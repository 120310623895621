import { useState } from "react";
import { useRecoilCallback } from "recoil";

export function DebugButton() {
    return <></>
    // const [isHover, setIsHover] = useState(false);
    // const handleMouseEnter = () => setIsHover(true);
    // const handleMouseLeave = () => setIsHover(false);
  
    // const onClick = useRecoilCallback(({ snapshot }) => async () => {
    //   console.debug('Atom values:');
    //   for (const node of snapshot.getNodes_UNSTABLE()) {
    //     const value = await snapshot.getPromise(node);
    //     console.debug(node.key, value);
    //   }
    // }, []);
  
    // return <>
    //   <button id="dumpState" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={onClick} style={{ position: 'absolute', left: 0, bottom: 0, zIndex: 100000000, backgroundColor: '#000', color: '#FFF', width: 150, height: 50, opacity: isHover ? 1 : 0 }}>Dump State</button>
    // </>
  }