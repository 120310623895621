import { useRecoilState, useResetRecoilState } from "recoil";
import { LineupFlowState } from "./atom.lineupFlowState";
import { LineupFlowPath, LineupRecord } from "../types";
import { useAppState } from "../../../state/app/useAppState";
import { useCallback, useEffect } from "react";
import { useLineupService } from "../services/useLineupService";
import { useLineupEvents } from "./useLineupEvents";
import { useLineupData } from "./useLineup";

export type LineupFlowHook = {
  path: LineupFlowPath;
  reset: () => void;
  usePlayerPath: () => void;
  useStartingGoaliePath: () => void;
  useCoachPath: () => void;
  usePreviewPath: () => void;
  useAddPlayerPath: () => void;
  useAddCoachPath: () => void;
};

export function useLineupFlow(path: LineupFlowPath): LineupFlowHook {
  const app = useAppState();
  const LineupEvents = useLineupEvents();
  const LineupService = useLineupService();
  const LineupData = useLineupData();
  const resetLineupFlow = useResetRecoilState(LineupFlowState);
  const [state, setState] = useRecoilState(LineupFlowState);

  const reset = useCallback(() => {
    resetLineupFlow();
    setState((s) => ({ ...s, path }));
  }, [resetLineupFlow, setState, path]);

  // events
  useEffect(() => {
    return app.events.add("LineupFlow.Reset", reset);
  }, [reset]);

  const usePlayerPath = useCallback(() => {
    setState((s) => ({ ...s, path: LineupFlowPath.Player }));
  }, [setState]);

  const useStartingGoaliePath = useCallback(() => {
    setState((s) => ({ ...s, path: LineupFlowPath.StartingGoalie }));
  }, [setState]);

  const useCoachPath = useCallback(() => {
    setState((s) => ({ ...s, path: LineupFlowPath.Coach }));
  }, [setState]);

  const usePreviewPath = useCallback(() => {
    setState((s) => ({ ...s, path: LineupFlowPath.Preview }));
  }, [setState]);

  const useAddPlayerPath = useCallback(() => {
    setState((s) => ({ ...s, path: LineupFlowPath.AddPlayer }));
  }, [setState]);

  const useAddCoachPath = useCallback(() => {
    setState((s) => ({ ...s, path: LineupFlowPath.AddCoach }));
  }, [setState]);

  return {
    ...state,
    reset,
    usePlayerPath,
    useStartingGoaliePath,
    useCoachPath,
    usePreviewPath,
    useAddPlayerPath,
    useAddCoachPath,
  };
}
