import { useCallback, useEffect } from "react";
import { atom, useRecoilState } from "recoil";
import { useAppState } from "@/state/app/useAppState";
import { useSeasonsData } from "@/state/data/useSeasonTeams";
import { useUserState } from "@/state/user/useUserState";

type IpadKeysModal = {
    keys: ApiKey[];
    loading: boolean;
    isOpen: boolean;
}

type ApiKey = {
    id: number;
    value: string;
    description: string;
    roles: [];
}

const apiKeysModalState = atom<IpadKeysModal>({
    key: 'apiKeysModalState',
    default: {
        keys: [],
        loading: false,
        isOpen: false,
    },
});

export function useIpadApiKeys() {

    const app = useAppState()
    const user = useUserState()
    const { seasons } = useSeasonsData()
    const [{ keys, loading, isOpen }, setState] = useRecoilState(apiKeysModalState);

    const open = useCallback(() => {
        setState(prev => ({
            ...prev,
            isOpen: true,
        }));

        document.body.style.overflow = "hidden";
    }, [setState]);

    const close = useCallback(() => {
        setState(prev => ({
            ...prev,
            isOpen: false,
        }));

        document.body.style.overflow = "auto";
    }, [setState]);

    useEffect(() => {

        let mounted = true;

        if (!isOpen) {
            return
        }

        if (seasons.length <= 0) {
            setState(prev => ({
                ...prev,
                keys: [],
            }));
            return
        }

        const headers = new Headers()
        user.isLoggedIn && headers.set("Authorization", `Bearer ${user.tokens.access}`)

        const seasonIds = seasons.map(season => season.id).join(",")
        const url = `${app.config.gateways.data}/ipad-keys/v4?filter[seasons]=${seasonIds}`;

        setState(prev => ({
            ...prev,
            loading: true,
        }));

        (async () => {

            const response = await fetch(url, { method: "GET", headers })
            const data = await response.json()

            const keys = Array.isArray(data.data) ? data.data : [];

            mounted && setState(prev => ({
                ...prev,
                keys,
                loading: false
            }));

        })()
        
        return () => {
            mounted = false;
        }

    }, [user.isLoggedIn, user.tokens.access, seasons, isOpen])

    return {
        loading,
        apiKeys: keys,
        open,
        close,
        isOpen,
    }
}