import dayjs from "@/libs/dayjs";
import { TeamEvents } from "./useScheduleTableColumns";
import { CalendarEventResponse } from "@gamesheet/ui/build/Components/Calendar/Calendar";
import { getUserOffsetNow, getUserOffsetForDate, getUserTimeZone } from "@/libs/timezones";

type Options = {
    showPastEvents: boolean;
}

export function useFilteredEvents ( unsortedEvents:TeamEvents[], options:Options ) {

    const today = new Date();
    today.setHours(0)
    today.setMinutes(0)
    today.setSeconds(0)
    today.setMilliseconds(0)
    const todayTimestamp = today.getTime();

    // constants
    const USER_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const UTC_OFFSET_SECONDS = dayjs().tz(USER_TIMEZONE).utcOffset() * 60;
    const TIMESTAMP = Math.floor(todayTimestamp / 1000) + UTC_OFFSET_SECONDS;

    const events = [...unsortedEvents].sort((eventA, eventB) => {
        return eventA.normalizedStartDateTime.getTime() - eventB.normalizedStartDateTime.getTime();
    });

    const filteredEvents = events.filter((event) => {
        const future = event?.ScheduledStartTime?.Time?.seconds >= TIMESTAMP;
        const past = event?.ScheduledStartTime?.Time?.seconds < TIMESTAMP && event?.type === 'game'
        const pastPE = event?.ScheduledStartTime?.Time?.seconds < TIMESTAMP && event?.type !== 'game' && options.showPastEvents;

        return future || past || pastPE;
    });
    
    const calendarEvents: CalendarEventResponse = {
        loading: false,
        data: filteredEvents.map((event) => {
            const timeZoneOffset = getUserOffsetForDate(USER_TIMEZONE, new Date(event?.ScheduledStartTime?.Time?.seconds * 1000));
            return {
                type: event?.type || "",
                start: dayjs(new Date((event?.ScheduledStartTime?.Time?.seconds - timeZoneOffset * 60) * 1000)),
                color: event?.type === 'game' ? "#36383D" : (event?.type === 'practice' ? "#FEDB6A" : "#D7D7D8"),
            };
        }),
    };
    
    const futureEvents = events.filter((event) => {
        const _futureGames = event?.ScheduledStartTime?.Time?.seconds >= TIMESTAMP && event?.type === 'game';
        const _futureEvents = event?.ScheduledStartTime?.Time?.seconds >= TIMESTAMP && event?.type !== 'game';

        return _futureGames || _futureEvents
    });

    const pastEvents = events.filter((event) => {
        const past = event?.ScheduledStartTime?.Time?.seconds < TIMESTAMP && event?.type === 'game';
        const pastPE = event?.ScheduledStartTime?.Time?.seconds < TIMESTAMP && event?.type !== 'game' && options.showPastEvents;

        return past || pastPE;
    });

    return {
        events,
        filteredEvents,
        calendarEvents,
        futureEvents,
        pastEvents,
    }

}