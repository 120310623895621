// useRepeatingEvent.js
import { useCallback } from "react";
import { atom, useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import { useAppState } from "@/state/app/useAppState";
import { EventFormState, recurrenceRRuleState } from "@/forms/EventForm";
import { useUserState } from "@/state/user/useUserState";
import { useSelectedTeamState } from "@/state/team/useSelectedTeamState";
import { getOffset } from "@/libs/timezones";

type RepeatingEventModal = {
  isOpen: boolean;
  eventData: EventFormState | null;
  setLoadingEvent: (loading: boolean) => void;
};


export const repeatingEventModalState = atom<RepeatingEventModal>({
  key: "repeatingEventModalState",
  default: {
    isOpen: false,
    eventData: null,
    setLoadingEvent: (loading: boolean) => {},
  },
});

export function useRepeatingEvent(resetAllForm?: () => void) {
  const app = useAppState();
  const user = useUserState();
  const selectedTeam = useSelectedTeamState();
  const [{ isOpen }, setState] = useRecoilState(repeatingEventModalState);
  // const eventData = useRecoilValue(eventFormState);

  const formatDate = (date: Date | null | undefined) => {
    if (!date) {
      return;
    }
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hour = date.getHours().toString().padStart(2, "0");
    const minute = date.getMinutes().toString().padStart(2, "0");

    const formattedString = `${year}-${month}-${day}T${hour}:${minute}`;


    return formattedString;
  };
  const recurrenceRRuleValue = useRecoilValue(recurrenceRRuleState);
  const resetReccurenceRRule = useResetRecoilState(recurrenceRRuleState);


  const open = useCallback((eventFormData:EventFormState, setLoadingEvent: (loading: boolean) => void) => {
    setState((prev) => ({
      ...prev,
      isOpen: true,
      eventData: eventFormData,
      setLoadingEvent: setLoadingEvent,
    }));

    document.body.style.overflow = "hidden";
  }, [setState]);

  const close = useCallback(() => {
    setState((prev) => ({
      ...prev,
      isOpen: false,
      eventData: null,

    }));

    document.body.style.overflow = "auto";
  }, [setState]);

  const eventData = useRecoilValue(repeatingEventModalState).eventData;

  const EditEvent = async ( occurrenceType: "all" | "future" | "single", formData?:EventFormState, setLoadingEvent?: (loading: boolean) => void) => {

    const url = `${app.config.gateways.eventLog}/v5/teams/${selectedTeam?.id}/edit-event`;
    const headers = new Headers();
    user.isLoggedIn && headers.set("Authorization", `Bearer ${user.tokens.access}`);

    let requestData = {};

    if (formData) {
      if (formData?.startDateTime?.value === null || formData?.endDateTime?.value === null) {
        return;
      }
      requestData = {
        attributes: {
          event: "edit-event",
          schema: "edit-event",
          version: "v1",
        },
        data: {
          multiple: occurrenceType,
          event: {
              id: formData.id.value,
              type: formData.type.value,
              title: formData.type.value === "practice" ? "practice" : formData.title.value,
              recurrence: recurrenceRRuleValue,
              timezone_offset: getOffset(formData.timezone.value, formData.startDateTime.value) || 0,
              start_date: formatDate(formData.startDateTime.value),
              end_date: formatDate(formData.endDateTime.value),
              timezone: formData.timezone.value,
              location: formData.location.value,
              notes: formData.notes.value,
              prototeam_id: selectedTeam?.id,
              event_group: formData.eventGroup.value, 
            
          },
        },
      };
    } else if (eventData && !formData) {
      if (eventData?.startDateTime?.value === null || eventData?.endDateTime?.value === null) {
        return;
      }
      requestData = {
        attributes: {
          event: "edit-event",
          schema: "edit-event",
          version: "v1",
        },
        data: {
          multiple: occurrenceType,
          event: {
              id: eventData.id.value,
              type: eventData.type.value,
              title: eventData.type.value === "practice" ? "practice" : eventData.title.value,
              recurrence: recurrenceRRuleValue,
              timezone_offset: getOffset(eventData.timezone.value, eventData.startDateTime.value) || 0,
              start_date: formatDate(eventData.startDateTime.value),
              end_date: formatDate(eventData.endDateTime.value),
              timezone: eventData.timezone.value,
              location: eventData.location.value,
              notes: eventData.notes.value,
              prototeam_id: selectedTeam?.id,
              event_group: eventData.eventGroup.value, 
            
          },
        },
      };
    } else {
      return;
    }

    try {
      app.loading.unload('events');
      const response = await fetch(url, {
        method: "POST",
        headers,
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        close();
        resetAllForm && resetAllForm();
        resetReccurenceRRule();
        app.loading.complete('events');
        app.navigate("/games")
        return 200;
      } else {
        app.loading.complete('events');
        console.error("Error while updating event data:", response);
        return 500;
      }
    } catch (error) {
      app.loading.complete('events');
      console.error("Error while updating event data:", error);
      return 500;
    }
  };

  const onChangeOccurrence = useCallback(
    async (occurrenceType: "all" | "future" | "single") => {
      try {

        // Move the network request logic here
        const response = await EditEvent(occurrenceType);

        // Check the response and handle accordingly
        if (response === 200) {
          resetAllForm && resetAllForm();
          resetReccurenceRRule();
          app.navigate("/games");
          return 200;
        }
      } catch (error) {
        console.error("Error in EditEvent:", error);
      }

      // Close the modal after handling the logic
      close();
    },
    [EditEvent, close, eventData]
  );

  return {
    isOpen,
    open,
    close,
    onChangeOccurrence,
    EditEvent,
  };
}
