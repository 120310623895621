import { selector } from "recoil";
import { rawRosterState, rosterState } from "./atom.rosterstate";
import { Roster, RosterCoach, RosterPerson, RosterPlayer } from "./types";
import { FirestoreRosterPerson, StoredRoster } from "@/services/useRosterService/types";

export const ActiveRosterState = selector({
    key: 'ActiveRosterState',
    get: ({ get }) => {

        const roster = { ...get(rawRosterState) }
        
        // filter players
        roster.players = roster.players.filter((player) => {
            return !player.season?.archived && !player.rosterLocked
        }).sort((a, b) => a.firstName.localeCompare(b.firstName))

        // filter coaches
        roster.coaches = roster.coaches.filter((coach) => {
            return !coach.season?.archived && !coach.rosterLocked
        }).sort((a, b) => a.firstName.localeCompare(b.firstName))

        // return
        return {
            ...roster,
            skaters: roster.players.filter((player) => player.position !== 'goalie'),
            goalies: roster.players.filter((player) => player.position === 'goalie')
        }

    }
});
