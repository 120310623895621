import { useCallback } from "react"
import { listenToActiveRoster } from "./func.listenToActiveRoster"
import { listenToSeasonTeamRoster } from "./func.listenToSeasonTeamRoster"

export function useRosterService(){
    
    return {
        listenToActiveRoster: useCallback(listenToActiveRoster, []),
        listenToSeasonTeamRoster: useCallback(listenToSeasonTeamRoster, [])
    }

}