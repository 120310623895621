import styled from "styled-components";
import { Modal, Button, UserNotice } from "@gamesheet/ui";
import { LineupFlowHook } from "@/flows/LineupFlow/state/useLineupFlow";
import { useRecoilValue } from "recoil";
import { playersAndGoaliesWithEmptyJerseySelector } from "@/flows/LineupFlow/state/atom.playerFormDataState";
import { useValidationErrors } from "./useValidationErrors";
import { useMatchingJerseyPlayers } from "@/flows/LineupFlow/state/atom.matchingJerseyPlayerState";

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgb(0, 0, 0, 0.5);
  z-index: 5200;
`;

const Text = styled.div`
  font-family: Rubik;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  padding-bottom: 0.75rem;
`;

const ConfirmButtonContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  padding-top: 0.5rem;
`;

const Divider = styled.div`
  border: 1px solid #d7d7d8;
  margin: 0 -1rem;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 1rem;
`;

type SavePlayerProps = {
  flow: LineupFlowHook;
};

export function ValidationErrors({ flow }: SavePlayerProps) {
  const { isOpen, close } = useValidationErrors();

  const { matchingPlayers } = useMatchingJerseyPlayers();
  const playersWithoutJersey = useRecoilValue(playersAndGoaliesWithEmptyJerseySelector);

  if (!isOpen) {
    return <></>;
  }

  return (
    <ModalContainer>
      <Modal title="Validation Errors" show={isOpen} onClose={close}>
        <Text>You must correct the following issues in order to proceed to the next step:</Text>
        <Content>
          {matchingPlayers.length > 0 && <UserNotice display={true} type="error" msg="More than one player has the same jersey Number" />}
          {playersWithoutJersey.length > 0 && <UserNotice display={true} type="error" msg={`Missing jersey numbers on: ${playersWithoutJersey.join(", ")}`} />}
        </Content>
        <Divider />
        <ConfirmButtonContainer>
          <Button size="lg" label="OK" onClick={close} />
        </ConfirmButtonContainer>
      </Modal>
    </ModalContainer>
  );
}
