import { useEffect, useState } from "react";
import { useRosterImport } from "../hooks/useRosterImport";
import styled from "styled-components";

const StyledImportComplete = styled.div`
  text-align: center;
  font-size: 18px;
`;

export function ImportComplete() {
  const { team, playerImportProgress, coachImportProgress } = useRosterImport();
  const [failedMessage, setFailedMesssage] = useState("");

  useEffect(() => {
    const failedPlayerImports =
      playerImportProgress.rejected &&
      `${playerImportProgress.rejected} Players`;
    const failedCoachImports =
      coachImportProgress.rejected && `${coachImportProgress.rejected} Coaches`;
    setFailedMesssage(
      [failedPlayerImports, failedCoachImports]
        .filter((x) => !!x)
        .join(" and ") || ""
    );
  }, [playerImportProgress.rejected, coachImportProgress.rejected]);

  return (
    <StyledImportComplete>
      <div>
        <p>
          {playerImportProgress.resolved} players and{" "}
          {coachImportProgress.resolved} coaches were imported into the{" "}
          <span className="season">{team && team.title}</span> team.
        </p>
      </div>

      {failedMessage && (
        <div className="csv-import__result__warning">
          <p>Failed to import {failedMessage}</p>
        </div>
      )}
    </StyledImportComplete>
  );
}
