import { useEffect, useState } from 'react';
import { collectionGroup, getDocs, where, query } from 'firebase/firestore';
import { firestore } from '@/libs/firebase';
import { useRecoilValue } from 'recoil';
import { appState } from '@/state/app/app.state';
import { SeasonTeamData } from "./useSeasonTeams"

export function useSeasonTeamsInSeasonData(seasonId: number) {
  const [seasonTeams, setSeasonTeams] = useState<any>();

  useEffect(() => {
    let mounted = true;

    const fetchSeasonTeamsFromFirestore = async () => {
      try {

        // Reference to the seasonteams collection group
        const seasonteamsCollection = collectionGroup(firestore, 'seasonteams');

        // Query documents where season.id is equal to the specified seasonId
        const q = query(seasonteamsCollection, where('season.id', '==', seasonId));
        const querySnapshot = await getDocs(q);

        // Extract data from the query snapshot
        const seasonTeamsData = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

       mounted && setSeasonTeams(seasonTeamsData);
      } catch (error) {
        console.error('Error fetching season teams from Firestore:', error);
      }
    };

    // Call the function to fetch data
    fetchSeasonTeamsFromFirestore();

    return () => {
      mounted = false;
    };

  }, [seasonId]);

  return {
    seasonTeams,
  };
}
