import Papa from "papaparse";

import _camelCase from "lodash/camelCase";
import _trim from "lodash/trim";
import _startCase from "lodash/startCase";
import * as default_abbr from "./abbreviations";
import { makePositionTranslator } from "./abbreviations/position";

import ReadingError from "./ReadingError";

const REQUIRED_COLUMNS = [
  "type",
  "firstName",
  "lastName",
  "externalId",
  "jerseyNumber",
  "position",
  "designation",
  "affiliated",
];

const OPTIONAL_COLUMNS = [
  "birthdate",
  "weight",
  "height",
  "shotHand",
  "country",
  "state",
  "hometown",
  "draftedBy",
  "committedTo",
  "bio",
];

const headerTransformations = {
  birthCountry: "country",
  birthState: "state",
  birthCity: "hometown",
} as { [key: string]: string };

const COLUMNS = [...REQUIRED_COLUMNS, ...OPTIONAL_COLUMNS];

function makeHumanReadableColumns(cols: string[]) {
  return cols.map((col) => `"${_startCase(col)}"`);
}

function reader(
  file: File,
  playerPositions: { abbr: string, title: string, key: string }[],
  coachPositions: { abbr: string, title: string, key: string }[]
): Promise<{ data: { [col: string]: any }[]; errors: any[] }> {
  const abbr = { ...default_abbr };
  abbr['position'] = makePositionTranslator(playerPositions, coachPositions);

  return new Promise((resolve) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: "greedy",
      transformHeader: (hd) => {
        const camel = _camelCase(hd);
        if (camel in headerTransformations) {
          return headerTransformations[camel];
        } else {
          return camel;
        }
      },
      transform: (value, col) => {
        let trimmedValue = _trim(value);
        trimmedValue =
          (abbr as any)[col] && !!trimmedValue
            ? (abbr as any)[col](trimmedValue)
            : trimmedValue;
        return trimmedValue;
      },
      complete: (res: any) => resolve(res),
    });
  });
}

export default async function read(file: File, { teamId }: { teamId: string }, playerPositions: { abbr: string, title: string, key: string }[], coachPositions: { abbr: string, title: string, key: string }[]) {
  const { data: records, errors } = await reader(file, playerPositions, coachPositions);

  if (errors.length > 0) {
    throw new ReadingError("CSV file is invalid");
  } else if (records.length === 0) {
    throw new ReadingError("CSV file is empty");
  }

  const columns = Object.keys(records[0]);
  const validColumns = COLUMNS;
  const missingColumns = validColumns.filter(
    (validCol) =>
      !OPTIONAL_COLUMNS.includes(validCol) && !columns.includes(validCol)
  );

  if (!!missingColumns.length) {
    const humanReadableColumns = makeHumanReadableColumns(missingColumns);
    const message = `CSV file is missing ${
      missingColumns.length > 1 ? "the following columns:" : "the column:"
    } ${humanReadableColumns.join(", ")}`;
    throw new ReadingError(message);
  }

  const unknownColumns = columns.filter(
    (col) => !OPTIONAL_COLUMNS.includes(col) && !validColumns.includes(col)
  );

  if (unknownColumns.length > 0) {
    const humanReadableColumns = makeHumanReadableColumns(unknownColumns);

    let message;

    if (unknownColumns.length === 1) {
      message = `CSV file includes unknown column ${humanReadableColumns[0]}`;
    } else {
      message = `CSV file includes the following unknown columns: ${humanReadableColumns.join(
        ", "
      )}`;
    }

    throw new ReadingError(message);
  }

  for (var index in records) {
    records[index].teamId = teamId;
  }

  return { records };
}
