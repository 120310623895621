import { atom, useRecoilState } from "recoil";
import React from "react";

const signatureState = atom({
  key: "signatureState",
  default: { hasContent: false, svgData: "" },
});

export function useSignatureState() {
  const [state, setState] = useRecoilState(signatureState);

  const addPath = (path: string) => {
    setState((prevState) => ({
      ...prevState,
      hasContent: true,
      svgData: prevState.svgData + path,
    }));
  };

  const clearCanvas = () => {
    setState({ hasContent: false, svgData: "" });
  };

  return {
    ...state,
    addPath,
    clearCanvas,
  };
}
