import { useRecoilState } from "recoil";
import { AcceptInvitationFlowState } from "./atom.AcceptInvitationFlowState";
import { useCallback } from "react";
import { PrototeamOptions, Strategy } from "../types";
import { TeamData } from "@/state/data/useTeams";

export function usePrototeamOptionsState(): {
    options: PrototeamOptions,
    setStrategy: (strat: Strategy) => void,
    setLinkedTeam: (id: TeamData) => void,
} {

    const [state, setState] = useRecoilState(AcceptInvitationFlowState)
    const setStrategy = useCallback((strat: Strategy) => setState(s => ({ ...s, prototeamOptions: { ...s.prototeamOptions, strategy: strat } })), [setState])
    const setLinkedTeam = useCallback((team: TeamData) => setState(s => ({ ...s, prototeamOptions: { ...s.prototeamOptions, linkedTeam: team } })), [setState])

    return {
        options: state.prototeamOptions,
        setStrategy,
        setLinkedTeam,
    }

}