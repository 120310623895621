import styled from "styled-components"
import InfoIcon from '@mui/icons-material/Info';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import SuccessIcon from '@mui/icons-material/CheckCircle';
import React, { PropsWithChildren } from "react";
import { EntityCardLoader } from "./EntityCardLoader";

const StyledEntityCard = styled.div.attrs({
    className: "entityCard"
})`
display: flex;
flex-direction: row;
background-color: #fff;
border-radius: 5px;
padding: 5px;
font-family: 'Rubik';
min-height: 60px;

img {
    height: 50px;
    width: 50px;
    object-fit: contain;
    border-radius: 5px;
    margin-right: 5px;
}
`

// below styles are for cards which don't have a substat

const TextCols = styled.div<{reverse?: boolean}>`
flex-grow: ${props => props.reverse ? 0 : 1};
display: flex;
flex-direction: ${props => props.reverse ? 'row-reverse' : 'row'};
justify-content: space-between;
margin-left: ${props => props.reverse ? '0' : '10px'};
gap: ${props => props.reverse ? '.75rem' : '0'};
`

const Titles = styled.div.attrs({
    className: "description"
})`
display: flex;
flex-direction: column;
justify-content: space-evenly;
`

const Stats = styled.div`
flex: 1 0 auto;
display: flex;
text-align: right;
flex-direction: column;
justify-content: space-evenly;
margin-right: 5px;
`

// below styles are for cards which do have a substat

const TextRows = styled.div`
flex-grow: 1;
display: flex;
flex-direction: column;
justify-content: space-evenly;
margin-left: 10px;
`

const Main = styled.div.attrs({
    className: "description"
})`
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
margin-right: 5px;
`

const Details = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
margin-right: 5px;
`

// below styles are common to all entity cards

const Title = styled.div`
font-size: 1.2rem;
line-height: 89%;
`

const Subtitle = styled.div`
font-size: 0.75rem;
line-height: normal;
`

const Stat = styled.div`
font-size: 1.2rem;
line-height: 89%;
margin-left: 10px;
font-weight: 500;
min-width: 3ch;
`

const SubStat = styled.div`
font-size: 0.75rem;
line-height: normal;
`

type EntityCardProps = {
    isLoading?: boolean
    title: string
    subtitle?: string | React.ReactNode
    stat?: string
    substat?: string
    image?: string
    Icon?: JSX.Element
    reverse?: boolean
}

export function MobileEntityCard({ title, subtitle, stat, substat, image, Icon = <></>, isLoading, reverse }: EntityCardProps) {

    // to conform to the text-alignment design spec:
    // if substat exists, arrange card text in two rows
    // if substat doesn't exist, arrange card text in two columns so stat can be vertically centered

    let textContent = (
        <TextRows>
            <Main>
                <Title style={{ textTransform: 'uppercase' }}>{title}</Title>
                <Stat>{stat}</Stat>
            </Main>
            {(subtitle || substat) && (<Details>
                <Subtitle>{subtitle}</Subtitle>
                <SubStat>{substat}</SubStat>
            </Details>)}
        </TextRows>
    );

    if (!substat) {
        textContent = (
            <TextCols reverse={reverse}>
                <Titles>
                    <Title style={{ textTransform: 'uppercase' }}>{title}</Title>
                    <Subtitle>{subtitle}</Subtitle>
                </Titles>
               {stat ? <Stats>
                    <Stat>{stat}</Stat>
                </Stats> : <div style={{paddingLeft: ".5rem"}}/>}
            </TextCols>
        );
    }

    return (
        <StyledEntityCard>
            {isLoading && <EntityCardLoader />}
            {(!isLoading && image) && <img src={image} />}
            {Icon}
            {textContent}
        </StyledEntityCard>
    );

}

const StyledMessageCard = styled(StyledEntityCard) <{ color: any }>`
display: block;
border-left: 8px solid ${(props) => props.theme[props.color]};
padding-left: 5px;

svg {
    color: ${(props) => props.theme[props.color]};
    font-size: 2.5rem;
    margin-left: 5px;
}

.entityCard {
    background-color: ${(props) => props.theme[props.color]}10; 
    .description {
        padding: 8px 0;
    }
}
`

const StyledInfoCard = styled(StyledMessageCard).attrs({ className: "infoCard" })``
const StyledAnnouncementCard = styled(StyledMessageCard).attrs({ className: "announcementCard" })``
const StyledErrorCard = styled(StyledMessageCard).attrs({ className: "errorCard" })``
const StyledWarningCard = styled(StyledMessageCard).attrs({ className: "warningCard" })``

type MessageCardProps = PropsWithChildren<{
    title: string
    message?: string
}>
export function InfoCard({ title, message, children }: MessageCardProps) {
    return (<StyledInfoCard color="info">
        <MobileEntityCard title={title} subtitle={message || children} Icon={<InfoIcon />} />
    </StyledInfoCard>)
}

export function AnnouncementCard({ title, message, children }: MessageCardProps) {
    return (<StyledAnnouncementCard color="info">
        <MobileEntityCard title={title} subtitle={message || children} Icon={<AnnouncementIcon />} />
    </StyledAnnouncementCard>)
}

export function ErrorCard({ title, message, children }: MessageCardProps) {
    return (<StyledErrorCard color="danger">
        <MobileEntityCard title={title} subtitle={message || children} Icon={<ErrorIcon />} />
    </StyledErrorCard>)
}

export function WarningCard({ title, message, children }: MessageCardProps) {
    return (<StyledWarningCard color="primaryColor">
        <MobileEntityCard title={title} subtitle={message || children} Icon={<WarningIcon />} />
    </StyledWarningCard>)
}

export function SuccessCard({ title, message, children }: MessageCardProps) {
    return (<StyledMessageCard color="success">
        <MobileEntityCard title={title} subtitle={message || children} Icon={<SuccessIcon />} />
    </StyledMessageCard>)
}