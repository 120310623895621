import { Button, FormWrapper } from "@gamesheet/ui";
import { useEffect, useRef } from "react";
import styled from "styled-components";
import { ActionBar } from "@/components/pure/ActionBar";
import IframeWithOtp from "@/components/utils/IframeWithOtp";
import { useAppState } from "@/state/app/useAppState";
import { useStatsWidget } from "@/state/app/useStatsWidget";
import { Iframe } from "@/state/app/useIframeLoading";
import { usePageState } from "@/state/page/usePageState";

const StyledButtonGroup = styled.div`
display:flex;
column-gap: 5px;

button {
    display:block;
    flex: 1;

    &.active {
        color: rgb(254, 195, 7);
    }
}
`

const StyledBootstrapSelect = styled.select.attrs({
    className: 'form-select form-select-sm'
})`
border: 1px solid ${({ theme }) => theme.secondaryColor};
`

const FloatingStatsNav = styled.div<{ $width: number | string, $open?: boolean }>`
display: ${p => { return p.$open ? 'flex' : 'none' }};
visibility: ${p => p.$open ? 'visible' : 'hidden'};
flex-direction: column;
row-gap: 5px;
position: fixed;
top: 136px;
right: 5px;

button {
    width: ${p => p.$width}px;
}
`



type StatsButtonProps = {
    onClick: () => void;
    label: string;
    active: boolean;
}
function StatsButton({ onClick, label, active }: StatsButtonProps) {

    return <Button className={active ? "active" : ""} onClick={onClick} variant='inverted'>{label}</Button>

}

export function TeamStatsPage() {

    const app = useAppState()
    const statsRef = useRef<HTMLButtonElement>(null)
    const StatsWidget = useStatsWidget()
    const Page = usePageState('stats')

    const handleClick = (route:string) => () => {
        Page.showBackButton()
        StatsWidget.setPage(route)
    }

    useEffect(() => {

        // don't process empty seasons
        if (StatsWidget.seasons.length == 0) return;

        const firstSeason = StatsWidget.seasons[0]
        StatsWidget.setLocation(firstSeason.id, `standings`)

    }, [StatsWidget.seasons.length])

    return (<>
        <ActionBar isMobile={app.layout.showMobile}>
            <div style={{ display: 'flex', flexDirection: 'column', rowGap: 5, alignItems: 'stretch' }}>
                <div>
                    <FormWrapper>
                        <StyledBootstrapSelect onChange={(e) => StatsWidget.setSeason(e.target.value)}>
                            {StatsWidget.seasons.map((season, index) => <option key={index} value={season.id}>{season.title}</option>)}
                        </StyledBootstrapSelect>
                    </FormWrapper>
                </div>
                <StyledButtonGroup>
                    <StatsButton onClick={handleClick('scores')} label="Scores" active={StatsWidget.page=="scores"} />
                    <StatsButton onClick={handleClick('schedule')} label="Schedule" active={StatsWidget.page=="schedule"} />
                    <StatsButton onClick={handleClick('standings')} label="Standings" active={StatsWidget.page=="standings"} />
                    {app.layout.showMobile && <>
                        <FloatingStatsNav $width={statsRef.current?.clientWidth || 0} $open={StatsWidget.isStatsMenuOpen}>
                            <StatsButton onClick={handleClick('players')} label="Players" active={StatsWidget.page=="players"} />
                            <StatsButton onClick={handleClick('goalies')} label="Goalies" active={StatsWidget.page=="goalies"} />
                        </FloatingStatsNav>

                        <Button innerRef={statsRef} onClick={StatsWidget.toggleStatsMenu} variant='inverted' gs-style={StatsWidget.isStatsMenuOpen ? "outline" : "solid"}>Stats</Button>
                    </>}
                    {(app.layout.showTablet || app.layout.showDesktop) && <>
                        <StatsButton onClick={handleClick('players')} label="Players" active={StatsWidget.page=="players"} />
                        <StatsButton onClick={handleClick('goalies')} label="Goalies" active={StatsWidget.page=="goalies"} />
                    </>}
                </StyledButtonGroup>
            </div>
        </ActionBar>

        <Iframe id="stats-widget" $isLoading={false} src={StatsWidget.src} />
    </>)
}