import React, { useEffect } from "react";
import styled from "styled-components";
import { useLineupData } from "./state/useLineup";
import PlayerForm from "@/forms/PlayerForm";
import { playerFormDataState } from "./state/atom.playerFormDataState";
import { useRecoilState } from "recoil";
import { BreakPoints } from "@/libs/breakpoints";

const StyledForm = styled.div`
  width: 100%;
  padding-top: 1rem;

  @media screen and (min-width: ${BreakPoints.lg}) {
    padding-top: 0;
  }
`;

export const AddPlayerStep: React.FC = () => {
  const { LocalLineupData } = useLineupData();
  const [formData, setFormData] = useRecoilState(playerFormDataState);

  useEffect(() => {
    const lineupData = LocalLineupData.get();
    if (!lineupData || !lineupData.lineup) return;

    const selectedPlayer = lineupData.lineup.players.find((player) => player.isSelected);
    const selectedGoalie = lineupData.lineup.goalies.find((goalie) => goalie.isSelected);

    const selectedPlayerData = selectedPlayer || selectedGoalie;
    if (selectedPlayerData) {
      setFormData(selectedPlayerData);
    } else {
      setFormData({
        id: "",
        firstName: "",
        lastName: "",
        photoURL: "",
        affiliated: false,
        status: "playing",
        jersey: "",
        duty: "",
        position: "",
        birthMonth: "",
        birthDay: "",
        birthYear: "",
        weight: "",
        height: "",
        shotHand: "",
        country: "",
        province: "",
        hometown: "",
        draftedBy: "",
        committedTo: "",
        biography: "",
        rosterLocked: false,
        teamId: "",
      });
    }
  }, [LocalLineupData.get().lineup, setFormData]);

  return (
    <StyledForm>
      <PlayerForm formData={formData} isLocked={false} setFormData={setFormData} useLineupConfig={LocalLineupData.getSelectedPlayerGoalieOrCoach()?.data.new !== undefined ? !LocalLineupData.getSelectedPlayerGoalieOrCoach()?.data.new : false } isLineupRosterLocked={LocalLineupData.getSelectedPlayerGoalieOrCoach()?.data.rosterLocked} />
    </StyledForm>
  );
};
