import React from "react";
import styled from "styled-components";

const CardActionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const CardActionButton = styled.div`
  display: flex;
  height: 100%;
  width: 5.625rem;
  padding: 1rem 1.25rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 0.3125rem;
  background: #fff;
  color: #36383d;
  text-align: center;
  font-family: Rubik;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  cursor: pointer;
`;

const ActiveIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="20" viewBox="0 0 24 20" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.2424 0.639054C20.6539 0.231165 21.2094 0.00160693 21.7888 8.40439e-06C22.3682 -0.00159012 22.9249 0.224899 23.3386 0.630512C23.7523 1.03612 23.9898 1.58827 23.9997 2.16757C24.0096 2.74687 23.791 3.30679 23.3914 3.72626L11.6541 18.3979C11.4524 18.6151 11.2089 18.7895 10.9383 18.9105C10.6676 19.0316 10.3753 19.0968 10.0789 19.1023C9.78246 19.1078 9.48796 19.0534 9.21302 18.9425C8.93807 18.8316 8.68831 18.6663 8.47868 18.4567L0.70185 10.6769C0.485197 10.475 0.311424 10.2316 0.1909 9.96107C0.0703752 9.69058 0.00556728 9.39857 0.00034318 9.10249C-0.00488092 8.8064 0.0495859 8.51229 0.160493 8.23771C0.271401 7.96314 0.436477 7.71371 0.645874 7.50431C0.855272 7.29491 1.1047 7.12984 1.37928 7.01893C1.65386 6.90802 1.94797 6.85355 2.24405 6.85878C2.54014 6.864 2.83214 6.92881 3.10264 7.04934C3.37313 7.16986 3.61658 7.34363 3.81846 7.56029L9.97524 13.7141L20.1866 0.703739C20.2048 0.680963 20.2244 0.659358 20.2454 0.639054H20.2424Z"
      fill="#36383D"
    />
  </svg>
);

type CardActionProps = {
  active: boolean;
  onClick?: () => void;
  text?: string;
};

const CardAction: React.FC<CardActionProps> = ({ active, onClick, text = "" }) => {
  return <CardActionWrapper>{active ? <CardActionButton onClick={onClick}>{ActiveIcon}</CardActionButton> : <CardActionButton onClick={onClick}>{text}</CardActionButton>}</CardActionWrapper>;
};

export default CardAction;
