import { selector, useRecoilValue } from "recoil";

import { rawSeasonTeamsDataState, teamsDataState } from "./useTeams";
import { appState, selectedPrototeamIdState } from "@/state/app/app.state";

export type SeasonTeamData = {
  id: string;
  title: string;
  rosterLocked: boolean;
  deletedAt: {
    Time: string;
    Valid: boolean;
  };
  division: {
    id: number;
    title: string;
  };
  season: {
    id: number;
    title: string;
    archived: boolean;
    manager_scheduling: boolean;
  };
  association: {
    id: number;
    title: string;
  };
  league: {
    id: number;
    title: string;
  };
};

export type SeasonTeamsData = SeasonTeamData[];

export type SportPositionOption = {
  abbr: string;
  key: string;
  sport: string[];
  title: string;
  type: string;
};

const seasonTeamsDataState = selector({
  key: "seasonTeamsDataState",
  get: async ({ get }) => {
    const prototeams = get(teamsDataState);
    const rawSeasonTeams = get(rawSeasonTeamsDataState);

    const seasonTeams = prototeams.data.map(async (team) => {
      const seasonTeams = rawSeasonTeams[team.id];
      return {
        ...team,
        seasonTeams,
      };
    });

    return Promise.all(seasonTeams);
  },
});

export const selectedTeamSportPositionsOptionsData = selector({
  key: "selectedTeamSportPositionsOptionsDataState",
  get: async ({ get }) => {
    const selectedteamId = get(selectedPrototeamIdState);
    const data = get(teamsDataState);

    const team = data.data.find((team) => team.id === selectedteamId);

    if (!team) return [] as SportPositionOption[];

    // abort the fetch after 2 seconds to prevent hanging the app if the endpoint is down
    const controller = new AbortController();
    setTimeout(() => controller.abort(), 2000);

    const sport = team?.sport || "hockey";
    return fetch(`https://lookups.gamesheet.io/api/positions?sport=${sport}`, {
      method: "GET",
      signal: controller.signal,
    })
      .then((response) => {
        if (!response.ok) throw new Error("Failed to fetch sport options");
        return response.json();
      })
      .catch(() => []) as Promise<SportPositionOption[]>;
  },
});

export const selectedTeamSportCoachPositionsOptionsData = selector({
  key: "selectedTeamSportCoachPositionsOptionsDataState",
  get: async ({ get }) => {
    const selectedteamId = get(selectedPrototeamIdState);
    const data = get(teamsDataState);

    const team = data.data.find((team) => team.id === selectedteamId);

        if (!team) return [] as SportPositionOption[];

        // abort the fetch after 2 seconds to prevent hanging the app if the endpoint is down
        const controller = new AbortController()
        setTimeout(() => controller.abort(), 2000)

        const sport = team?.sport || "hockey";
        return fetch(`https://lookups.gamesheet.io/api/duties?sport=${sport}&for=coach`, { 
            method: "GET",
            signal: controller.signal
        }).then(response => {
            if (!response.ok) throw new Error('Failed to fetch sport options')
            return response.json()
        }).catch(() => ([])) as Promise<SportPositionOption[]>
    }
})

export const seasonsDataState = selector({
  key: "seasonsDataState",
  get: ({ get }) => {
    const app = get(appState);
    const data = get(seasonTeamsDataState);
    const prototeam = data.find((team) => team.id === app.selectedTeam);

    if (!prototeam)
      return {
        seasons: [],
        divisions: [],
        teams: [],
      };

    return {
      teams: prototeam?.seasonTeams,
      divisions: prototeam?.seasonTeams?.map((data) => {
        const { season, division, ...team } = data;

        return {
          season,
          ...division,
          team,
        };
      }),
      // invert the team to be a property of the season
      seasons: prototeam?.seasonTeams?.map((data) => {
        const { season, division, ...team } = data;

        return {
          ...season,
          division,
          team,
        };
      }),
    };
  },
});

export function useSeasonsData() {
  const prototeamData = useRecoilValue(seasonTeamsDataState);
  const data = useRecoilValue(seasonsDataState);

  const firstUnlocked = data.teams.find((team) => team.rosterLocked !== true);
  const firstUnarchived = data.teams.find((team) => team.season?.archived !== true);
  const firstMaliable = data.teams.find((team) => team.season?.archived !== true && team.rosterLocked !== true);

  const withUnlockedRosters = data.teams.filter((team) => team.rosterLocked !== true);
  const withUnarchivedSeasons = data.teams.filter((team) => team.season?.archived !== true);
  const withMaliableRosters = data.teams.filter((team) => !team.rosterLocked && !team.season?.archived);

  return {
    teams: data.teams,
    teamIds: data.teams.map((team) => team.id),
    seasons: data.seasons,
    lastUpdated: Date.now(),

    hasUnlockedRoster: !!firstUnlocked,
    firstUnlocked,
    withUnlockedRosters,

    hasUnarchivedSeason: !!firstUnarchived,
    firstUnarchived,
    withUnarchivedSeasons,

    hasMaliableRoster: !!firstMaliable,
    firstMaliable,
    withMaliableRosters,

    hasLockedRoster: withUnlockedRosters.length !== data.teams.length,
    hasArchivedSeason: !firstUnarchived,

    allSeasonsRostersUnlocked: withUnlockedRosters.length === data.teams.length,

    prototeams: prototeamData,
    dashboard: firstUnlocked || data.teams[0],
  };
}
