import styled from "styled-components";
import { Modal, Button } from "@gamesheet/ui";
import { useSavePlayer } from "./useSavePlayer";
import { LineupFlowHook } from "@/flows/LineupFlow/state/useLineupFlow";
import { useLineupData } from "@/flows/LineupFlow/state/useLineup";
import { useRecoilValue } from "recoil";
import { playerFormDataState } from "@/flows/LineupFlow/state/atom.playerFormDataState";
import { v4 as uuidv4 } from "uuid";

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgb(0, 0, 0, 0.5);
  z-index: 5200;
`;

const Text = styled.div`
  font-family: Rubik;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  padding-bottom: 0.75rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
`;

const ConfirmButtonContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  padding-top: 0.5rem;
`;

const Divider = styled.div`
  border: 1px solid #d7d7d8;
  margin: 0 -1rem;
`;

type SavePlayerProps = {
  flow: LineupFlowHook;
};

export function SavePlayer({ flow }: SavePlayerProps) {
  const { isOpen, close } = useSavePlayer();
  const { LocalLineupData } = useLineupData();
  const formData = useRecoilValue(playerFormDataState);

  if (!isOpen) {
    return <></>;
  }

  const handleSavePlayerToRoster = (saveToRoster: "yes" | "no") => {
    const saveToRosterBool = saveToRoster === "yes" ? true : false;

    const lineup = LocalLineupData.get().lineup;
    const { players, goalies } = lineup;

    const allParticipants = [...players, ...goalies];
    let selectedParticipant = null;

    // Loop through all participants to find the one with the matching ID
    for (const participant of allParticipants) {
      if (participant.id === formData.id) {
        selectedParticipant = participant;
        break; // Exit the loop once the correct participant is found
      }
    }

    if (selectedParticipant) {
      // Update the selected participant's data
      const rosterDataChanged =
        !selectedParticipant.rosterLocked &&
        (selectedParticipant.duty !== formData.duty ||
          selectedParticipant.jersey !== formData.jersey ||
          selectedParticipant.position !== formData.position ||
          selectedParticipant.rosterDataChanged ||
          selectedParticipant.affiliated !== formData.affiliated);

      const bioDataChanged =
        !selectedParticipant.rosterLocked &&
        (selectedParticipant.biography !== formData.biography ||
          selectedParticipant.photoURL !== formData.photoURL ||
          selectedParticipant.weight !== formData.weight ||
          selectedParticipant.height !== formData.height ||
          selectedParticipant.shotHand !== formData.shotHand ||
          selectedParticipant.country !== formData.country ||
          selectedParticipant.province !== formData.province ||
          selectedParticipant.hometown !== formData.hometown ||
          selectedParticipant.draftedBy !== formData.draftedBy ||
          selectedParticipant.committedTo !== formData.committedTo ||
          selectedParticipant.bioDataChanged ||
          selectedParticipant.firstName !== formData.firstName ||
          selectedParticipant.lastName !== formData.lastName ||
          selectedParticipant.birthDay !== formData.birthDay ||
          selectedParticipant.birthMonth !== formData.birthMonth ||
          selectedParticipant.birthYear !== formData.birthYear);

      const updatedParticipant = { ...selectedParticipant, ...formData, rosterDataChanged, bioDataChanged, isSelected: false, saveToRoster: saveToRosterBool };

      // Update the lineup record with the updated participant
      const updatedLineupRecord = {
        ...LocalLineupData.get(),
        lineup: {
          ...lineup,
          players: players.filter((player) => player.id !== formData.id),
          goalies: goalies.filter((goalie) => goalie.id !== formData.id),
        },
      };

      if (formData.position === "goalie") {
        updatedLineupRecord.lineup.goalies.push(updatedParticipant);
      } else {
        updatedLineupRecord.lineup.players.push(updatedParticipant);
      }
      LocalLineupData.set(updatedLineupRecord);
    } else {
      // If no participant is found, add the new participant to the lineup
      const newParticipant = {
        ...formData,
        id: parseInt(uuidv4().replace(/-/g, ""), 16).toString(),
        isSelected: false,
        rosterDataChanged: true,
        bioDataChanged: true,
        new: true,
        saveToRoster: saveToRosterBool,
      };

      // Determine whether the new participant is a player or goalie
      if (formData.position === "goalie") {
        // Add the new participant to the goalies array
        const updatedLineupRecord = {
          ...LocalLineupData.get(),
          lineup: {
            ...lineup,
            goalies: [...goalies, newParticipant],
            players,
          },
        };
        LocalLineupData.set(updatedLineupRecord);
      } else {
        // Add the new participant to the players array
        const updatedLineupRecord = {
          ...LocalLineupData.get(),
          lineup: {
            ...lineup,
            players: [...players, newParticipant],
            goalies,
          },
        };
        LocalLineupData.set(updatedLineupRecord);
      }
    }

    close();
    flow.usePlayerPath();
  };

  return (
    <ModalContainer>
      <Modal title="Save Changes" show={isOpen} onClose={close}>
        <Text>
          How would you like to save your changes to {formData.firstName} {formData.lastName}
        </Text>
        <Divider />
        <ButtonContainer>
          <ConfirmButtonContainer>
            <Button size="lg" label="Save for this game only" onClick={() => handleSavePlayerToRoster("no")} />
            <Button
              size="lg"
              variant={LocalLineupData.getSelectedPlayerGoalieOrCoach()?.data.rosterLocked ? "muted" : ""}
              label="Save for this and future games"
              onClick={LocalLineupData.getSelectedPlayerGoalieOrCoach()?.data.rosterLocked ? () => {} : () => handleSavePlayerToRoster("yes")}
            />
          </ConfirmButtonContainer>

          <Button size="lg" variant="inverted" label="Cancel" onClick={close} />
        </ButtonContainer>
      </Modal>
    </ModalContainer>
  );
}
