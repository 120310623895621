const VALID_POSITIONS = {
  player: ["forward", "defence", "goalie", "left_wing", "right_wing", "centre", "transition"],
  coach: [
    "head_coach",
    "assistant_coach",
    "trainer",
    "assistant_trainer",
    "manager",
    "head_coach_at_large",
    "assistant_coach_at_large",
    "trainer_at_large"
  ]
};

const ALL_VALID_POSITIONS = [
  ...VALID_POSITIONS.player,
  ...VALID_POSITIONS.coach
];

export type PositionValidator = ({ type, position }: {
  type: string;
  position: string;
}) => {
  field: string;
  error: string;
}[];

export function makePositionValidator(playerPositions: { key: string }[], coachPositions: { key: string }[]) {
  if (!playerPositions || !playerPositions.length) {
    return validatePosition;
  } else if (!coachPositions || !coachPositions.length) {
    return validatePosition;
  }

  const validPlayerPositions = playerPositions.map(p => p.key.toLowerCase());
  const validCoachPositions = coachPositions.map(p => p.key.toLowerCase());
  const validPositions = {player: validPlayerPositions, coach: validCoachPositions };
  const allValidPositions = [
    ...validPlayerPositions,
    ...validCoachPositions
  ];

  return function validatePosition({ type, position }: { type: string, position: string }) {
    if (position === "") {
      return [];
    }

    const isValidPosition =
      type === "player" || type === "coach"
        ? (validPositions[type] || []).includes(position.toLowerCase())
        : allValidPositions.includes(position);

    if (!isValidPosition) {
      return [{ field: "position", error: "invalid" }];
    }

    return [];
  };
}

export default function validatePosition({ type, position }: { type: string, position: string }) {
  if (position === "") {
    return [];
  }

  const isValidPosition =
    type === "player" || type === "coach"
      ? (VALID_POSITIONS[type] || []).includes(position.toLowerCase())
      : ALL_VALID_POSITIONS.includes(position);

  if (!isValidPosition) {
    return [{ field: "position", error: "invalid" }];
  }

  return [];
}
