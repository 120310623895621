import { useAppState } from "@/state/app/useAppState";
import { useEffect, useState, useCallback } from "react";
import { PageLoading } from "@/components/pure/PageLoading";
import { usePageState } from "@/state/page/usePageState";
import { GamesheetPage } from "@/state/page/GamesheetPage";
import { useParams } from "react-router-dom";
import { ErrorCard } from "@/components/pure/EntityCard/MobileEntityCard";
import { useRecoilValue, useRecoilState, useResetRecoilState } from "recoil";
import EventForm, { EventFormState, recurrenceRRuleState } from "@/forms/EventForm";
import { eventData,rawEventData } from "@/services/useEventService/atom.eventData";
import { useEventService } from "@/services/useEventService/useEventService";
import { useReadEventData } from "@/services/useEventService/useReadEventData";

export function EventCreatePage() {
  const Page = usePageState("event-create", {
    rememberScroll: false
  });
  const app = useAppState();

  const { eventId } = useParams<{ eventId: string }>();

  const [loadingEvent, setLoadingEvent] = useState(true);
  const showLoading = !app.hasSelectedTeam || !app.loading.isComplete('events')
  const [error, setError] = useState("");

  const eventService = useEventService();


  const event = useRecoilValue(eventData);

  const resetEventData = useResetRecoilState(rawEventData);

  const { ReadEventData } = useReadEventData();

  const recurrenceRRuleValue = useRecoilValue(recurrenceRRuleState);
  
  useEffect(() => {
  if (!eventId) return; // eventId can be undefined
  const fetchData = async () => {
          try {
        const response = await ReadEventData(eventId);
                  setLoadingEvent(false);
              } catch (error) {
        console.error('Error fetching event data:', error);
      }
    };
fetchData();
}, [eventId]);

  
  function onSuccessfulSubmit(createAnother?: boolean, resetForm?: () => void) {
    resetForm && resetForm();
    app.loading.complete('events')
    createAnother ? app.navigate("/event/new") : app.navigate("/games");
    setTimeout(() => {
        resetEventData();
    }, 1000);
  }



  const onSubmit = useCallback(async (eventForm: EventFormState, method: string, another?: boolean, resetForm?: () => void) => {
    switch (method) {
      case "create":
        app.loading.unload('events')
        const createResponse = await eventService.CreateEvent(eventForm);
        if (createResponse == 200) {
          onSuccessfulSubmit(another, resetForm);
          app.loading.complete('events')
        } else {
          setError("Error creating scheduled game.");
          app.loading.complete('events')
        }
        return;
    }
  }, [recurrenceRRuleValue]);

  // wait for events to load
  if (loadingEvent || showLoading) {
    {
      return <PageLoading />;
    }
  }

  let view: JSX.Element = <></>;

  if (app.layout.showMobile) {
    view = <EventForm eventOrPractice="event" eventDetails={event.data} onSubmit={onSubmit} resetEventData={resetEventData} setLoadingEvent={setLoadingEvent}/>;
  }

  if (app.layout.showTablet || app.layout.showDesktop) {
    view = <EventForm eventOrPractice="event" eventDetails={event.data} onSubmit={onSubmit} resetEventData={resetEventData} setLoadingEvent={setLoadingEvent}/>;
  }

  return (
    <GamesheetPage $loading={Page.loading}>
      <>
        {view}
        {error && (
                <div style={{ marginTop: '20px' }}>
                    <ErrorCard title="" message={error} />
                </div>
            )}
      </>
    </GamesheetPage>
  );
}
