import { useAppState } from "@/state/app/useAppState";
import { useEffect, useRef, useState, useCallback } from "react";
import { PageLoading } from "@/components/pure/PageLoading";
import { useTeamEventsData } from "@/state/data/useTeamEvents";
import { usePageState } from "@/state/page/usePageState";
import { GamesheetPage } from "@/state/page/GamesheetPage";
import { ScheduleGameForm, ScheduleGameFormState} from "@/forms/ScheduleGameForm";
import { useParams } from "react-router-dom";
import { useSeasonsData } from "@/state/data/useSeasonTeams";
import { ErrorCard } from "@/components/pure/EntityCard/MobileEntityCard";
import { useReadScheduledGameData } from "@/services/useScheduledGameService/useReadScheduledGameData";
import { scheduledGameData, rawScheduledGameData } from "@/services/useScheduledGameService/atom.scheduledGameData";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import { useScheduledGameService } from "@/services/useScheduledGameService/useScheduledGameService";
import { teamEventsDataState } from "@/state/data/useTeamEvents";
import { getUserOffsetNow } from "@/libs/timezones";

function getUTCOffset(timeZone: string): string {
    // Get the utc offset for the specified timezone
    const now = new Date();
  
    const formatter = new Intl.DateTimeFormat('en-US', {
      timeZone,
      timeZoneName: 'short',
    });
  
    const parts = formatter.formatToParts(now);
    const timeZonePart = parts.find(part => part.type === 'timeZoneName');
  
    return timeZonePart?.value || 'UTC';
  }

export function GameCreatePage() {

    const Page = usePageState('game-create', {
        rememberScroll: false
    })
    const app = useAppState();
    const seasons = useSeasonsData();
    const todayRef = useRef<HTMLDivElement | null>(null)
    const events = useTeamEventsData()
    const next = events.nextGame
    const showLoading = !app.hasSelectedTeam || !app.loading.isComplete('events')

    // var scheduledGameId = "0"
    const { gameId: scheduledGameId } = useParams<{gameId: string}>()
    // if (gameId) {scheduledGameId = gameId}


    const [ loadingGame, setLoadingGame ] = useState(true)
    // const [ waitForGame, setWaitForGame ] = useState(true)
    const [ loadingBC, setLoadingBC ] = useState(true)
    const [ gameActionLoading, setGameActionLoading ] = useState(false)
    const [ error, setError ] = useState("")

    const [ broadcastProviders, setBroadcastProviders ] = useState([])
    const scheduledGame = useRecoilValue(scheduledGameData);
    const scheduledGameService = useScheduledGameService();
    const { ReadScheduledGameData } = useReadScheduledGameData();
    const resetScheduledGameData = useResetRecoilState(rawScheduledGameData);
    const setTeamEventsData = useSetRecoilState(teamEventsDataState)
    
    useEffect(() => {
        if (!scheduledGameId) return; // scheduledGameId can be undefined
        const fetchData = async () => {
          try {
            const response = await ReadScheduledGameData(scheduledGameId);
            setLoadingGame(false)
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
        fetchData();
    }, [scheduledGameId]);

    useEffect(() => {
        async function fetchBroadcasters() {
            try {
                const broadcasters = await scheduledGameService.GetBroadcastProviders();

                setBroadcastProviders(broadcasters)
                setLoadingBC(false)
            } catch (error) {
                setLoadingBC(false);
            }
        }

        fetchBroadcasters();
    }, [])

    function onSuccessfulSubmit(createAnother?:boolean, resetForm?: () => void){
        resetForm && resetForm();
        createAnother ? app.navigate('/games/new') : app.navigate('/games');
        setTimeout(() => { resetScheduledGameData(); }, 1000);
    }

    const onSubmit = useCallback(async (gameForm:ScheduleGameFormState, method: string, another?: boolean, resetForm?: () => void, opposingTitle?: string) => {
        app.setGameCreated();

        switch (method) {
            case 'create':
                setGameActionLoading(true)
                const createResponse = await scheduledGameService.CreateScheduledGame(gameForm);
                if (createResponse == 200) {
                    onSuccessfulSubmit(another, resetForm);
                    
                    const sstime = new Date(gameForm.dateTime.value)
                    const userOffset = getUserOffsetNow()
                    sstime.setMinutes(sstime.getMinutes() + (userOffset));

                    setTeamEventsData((state) => ({
                        data: {
                            ...state.data,
                            [0]: {
                                id: 0,
                                visitor: {
                                    id: gameForm.homeFlag.value ? gameForm.opposingTeamId.value : gameForm.teamId.value,
                                    title: gameForm.homeFlag ? opposingTitle : ""
                                },
                                home: {
                                    id: !gameForm.homeFlag.value ? gameForm.opposingTeamId.value : gameForm.teamId.value,
                                    title: gameForm.homeFlag.value ? "" : opposingTitle
                                },
                                ScheduledStartTime: {Time: {
                                    seconds: Math.floor(sstime.getTime() / 1000),
                                    nanoseconds: 0,
                                }},
                                scheduledStartTime: sstime,
                                normalizedStartDateTime: sstime,
                                timeZoneName: gameForm.timezone.value,
                                location: gameForm.location.value,
                                type: "game",
                                tempGame: true
                            } as any
                        },
                        lastUpdated: Date.now(),
                    }));
                    setGameActionLoading(false)
                } else {
                    setError("Error creating scheduled game.")
                    setGameActionLoading(false)
                }
                return;
            case 'edit':
                setGameActionLoading(true)
                const editResponse = await scheduledGameService.EditScheduledGame(gameForm);
                if (editResponse == 200) {
                    onSuccessfulSubmit(false, resetForm);
                    setGameActionLoading(false)
                } else {
                    setError("Error editing scheduled game.")
                    setGameActionLoading(false)
                }
                return;
            case 'delete':
                setGameActionLoading(true)
                const deleteResponse = await scheduledGameService.DeleteScheduledGame(gameForm);
                if (deleteResponse == 200) {
                    setGameActionLoading(false)
                    app.navigate('/games');
                } else {
                    setError("Error deleting scheduled game.")
                    setGameActionLoading(false)
                }
                return;
          }
    }, [])

    // wait for events to load
    if (showLoading || loadingGame || loadingBC || gameActionLoading) {
        return <PageLoading />
    }

    let view: JSX.Element = <></>

    if (app.layout.showMobile) {
        view = <ScheduleGameForm onSubmit={onSubmit} gameDetails={scheduledGame.data} broadcastProviders={broadcastProviders} resetScheduledGameData={resetScheduledGameData}/>
    }

    if (app.layout.showTablet || app.layout.showDesktop) {
        view = <ScheduleGameForm onSubmit={onSubmit} gameDetails={scheduledGame.data} broadcastProviders={broadcastProviders} resetScheduledGameData={resetScheduledGameData}/>
    }


    return <GamesheetPage $loading={Page.loading}>
        <>
            {view}
            {error && (
                <div style={{ marginTop: '20px' }}>
                    <ErrorCard title="" message={error} />
                </div>
            )}
        </>
    </GamesheetPage>
}