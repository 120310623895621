import { useCallback, useEffect } from "react";
import { atom, useRecoilState } from "recoil";

type LineupSuccessState = {
  loading: boolean;
  isOpen: boolean;
  opponent: string;
};

const lineupSuccessAtom = atom<LineupSuccessState>({
  key: "lineupSuccessAtom",
  default: {
    loading: false,
    isOpen: false,
    opponent: "",
  },
});

export function useLineupSuccess() {
  const [{ loading, isOpen, opponent }, setState] = useRecoilState(lineupSuccessAtom);

  const open = useCallback(() => {
    setState((prev) => ({
      ...prev,
      isOpen: true,
    }));

    document.body.style.overflow = "hidden";
  }, [setState]);

  const close = useCallback(() => {
    setState((prev) => ({
      ...prev,
      isOpen: false,
      opponent: "",
    }));

    document.body.style.overflow = "auto";
  }, [setState]);

  const setOpponentTeam = useCallback(
    (opponent: string) => {
      setState((prev) => ({
        ...prev,
        opponent,
      }));
    },
    [setState]
  );

  return {
    loading,
    open,
    close,
    isOpen,
    setOpponentTeam,
    opponent,
  };
}
