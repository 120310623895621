import React from 'react';
import { ToastWrapper } from "@gamesheet/ui";
import { useAppState } from '@/state/app/useAppState';
import { useUserState } from '@/state/user/useUserState';

interface UpdateAvailableButtonProps {
  show: boolean;
}

export const UpdateAvailableButton: React.FC<UpdateAvailableButtonProps> = ({ show }) => {

  const app = useAppState();
  const desktop = app.layout.showDesktop;
  const user = useUserState();

  const handleUpdateClick = () => {
    user.Expire();
    window.location.reload();
  };

  return (
    <ToastWrapper
      onClick={handleUpdateClick}
      message="A new update is available"
      clickableText="Update Now"
      show={show}
      style={{ position: "fixed", bottom: desktop ? '20px' : '130px', left: desktop ? '20px' : '2px', width: "60%", maxWidth: "400px", zIndex: 9999, borderRadius: "5px", fontSize: "0.6rem" }}
    />
  );
};
