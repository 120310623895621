export const MakePrototeamSeasons = (prototeam_id: string, seasons: any, inviteRoles: any): string[] => {

  const roles = inviteRoles?.invitation?.roles
  const schedulerRoles = roles?.filter((role: any) => role.title == "scheduler")
  const prototeam = seasons?.prototeams?.find((prototeam: any) => prototeam.id === prototeam_id);

  const seasonTeamsFiltered = prototeam?.seasonTeams?.filter((seasonTeam: any) => {
    const hasMatchingRole = schedulerRoles?.some((role: any) => {
      const teamCheck = seasonTeam.id == role.level.id && role.level.type == "teams";
      const divisionCheck = seasonTeam.division.id == role.level.id && role.level.type == "divisions";
      const seasonCheck = seasonTeam.id == role.level.id && role.level.type === "seasons";
      const leagueCheck = seasonTeam.league.id == role.level.id && role.level.type == "leagues";
      const associationCheck = seasonTeam.association.id == role.level.id && role.level.type == "associations";

      return teamCheck || divisionCheck || seasonCheck || leagueCheck || associationCheck;
    });

    return hasMatchingRole;
  });

  const prototeamSeasons = seasonTeamsFiltered?.map((team: any) => team.season.title);
  return prototeamSeasons || [];

}

