export const defaultAwayJersey = "/away_jersey_placeholder.svg";
export const defaultHomeJersey = "/home_jersey_placeholder.svg";

export const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export function getDaysInMonth(month: string | number): string[] {
  const year = new Date().getFullYear();
  let monthIndex: number;
  if (typeof month === "string") {
    const monthNames = months;
    monthIndex = monthNames.findIndex((name) => name.toLowerCase() === month.toLowerCase());
    if (monthIndex === -1) {
      throw new Error(`Invalid month name: ${month}`);
    }
  } else if (typeof month === "number") {
    monthIndex = month;
  } else {
    throw new Error(`Invalid month: ${month}`);
  }
  const daysInMonth = new Date(year, monthIndex + 1, 0).getDate();
  const daysArray = Array.from({ length: daysInMonth }, (_, i) => (i + 1).toString());
  return daysArray;
}

export function createYearRange(): string[] {
  const currentYear = new Date().getFullYear();
  const yearRange = Array.from({ length: 100 }, (_, i) => (currentYear - i).toString());
  return yearRange;
}
