import styled from "styled-components";
import { Modal, Button } from "@gamesheet/ui";
import { useAppState } from "@/state/app/useAppState";

import { useExitWarning } from "./useExitWarning";
import { LineupFlowHook } from "@/flows/LineupFlow/state/useLineupFlow";
import { useAlreadySet } from "../alreadySet/useAlreadySet";
import { useLocation } from "react-router-dom";

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgb(0, 0, 0, 0.5);
  z-index: 5200;
`;

const Text = styled.div`
  font-family: Rubik;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  padding-bottom: 0.75rem;
`;

const ConfirmButtonContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  padding-top: 0.5rem;
  justify-content: space-between;
`;

const Divider = styled.div`
  border: 1px solid #d7d7d8;
  margin: 0 -1rem;
`;

type ExitWarningProps = {
  flow: LineupFlowHook;
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}


export function ExitWarning({ flow }: ExitWarningProps) {
  const app = useAppState();
  const { isOpen, close } = useExitWarning();
  const { resetAll } = useAlreadySet();

  const query = useQuery();
  const email = query.get('email'); 

  if (!isOpen) {
    return <></>;
  }

  return (
    <ModalContainer>
      <Modal title="Are you sure you want to exit?" show={isOpen} onClose={close}>
        <Text>Your game lineup has not been saved. If you leave now, you’ll lose any modifications you’ve made.</Text>

        <Divider />
        <ConfirmButtonContainer>
          <Button size={app.layout.showDesktop ? "lg" : "md"} label="Cancel" variant="inverted" onClick={close} />
          <Button
            size={app.layout.showDesktop ? "lg" : "md"}
            label="Leave without saving"
            onClick={() => {
              close();
              flow.reset();
              resetAll();
              if(email){
                app.navigate("/")
              } else {
                app.goBack();
              }
            }}
          />
        </ConfirmButtonContainer>
      </Modal>
    </ModalContainer>
  );
}
