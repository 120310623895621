import { useEffect, useReducer, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useRosterImport } from "../hooks/useRosterImport";
import { capitalizeFirstLetter } from "@/libs/capitalizeFirstLetter";

const StyledValidationFailed = styled.div`
  .csv-import__error__text {
    background-color: #ffafaf;
    border-radius: 5px;
    padding-top: 10px;
    padding-bottom: 9px;
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
    p {
      margin-bottom: 0;
    }
  }

  .err-row {
    margin-top: 1.5rem;
    border-radius: 0.3125rem;

    .err-contianer{
      display:flex;
      flex-wrap: wrap;
      gap: .5rem;
    }

    .err {
        background-color: white;
        border-radius: 5px;
        border: 3px solid #FFAFAF; 
        padding: 0 5px 5px 5px;

        .field {
          font-size: 14px;
          margin-bottom: 0.2rem;
        }
        .problem {
          margin-bottom: 0;
          font-size: 18px;
          padding-left: 4px;
        }
      }
    
    .row-ident {
      display: flex;

      .num {
        background-color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 9px;
        padding-top: .75rem;
        line-height: 0em;
        .always-row {
          font-size: 14px;
        }
        .line {
          padding-left: 4px;
          font-size: 18px;
        }
      }
      .name {
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        font-size: 18px;
        line-height: normal; 
        border-left: 1px solid #86888B;
        padding: 0 1rem;
        p {
          margin-bottom: 0;
        }
      }
    }

    .row-errs {
      display: flex;
      gap: 0.5rem;
      flex-wrap: wrap;
      .err {
        background-color: white;
        border-radius: 5px;
        border: 3px solid #FFAFAF; 
        padding: 0 5px 5px 5px;

        .field {
          font-size: 14px;
          margin-bottom: 0.2rem;
        }
        .problem {
          margin-bottom: 0;
          font-size: 18px;
          padding-left: 4px;
        }
      }
    }
  }
`;

const makeShapeFromFields = (fieldType: any) => {
  return PropTypes.shape(
    Object.keys(FIELDS).reduce((result, field) => {
      return { ...result, [field]: fieldType };
    }, {})
  );
};

const FIELDS = {
  type: "Type",
  firstName: "First Name",
  lastName: "Last Name",
  externalId: "External ID",
  teamId: "Team Id",
  jerseyNumber: "Jersey Number",
  position: "Position",
  designation: "Designation",
  affiliated: "Affiliated",
  birthdate: "Birthdate",
  weight: "Weight",
  height: "Height",
  shotHand: "Shot Hand",
  country: "Birth Country",
  state: "Birth State",
  hometown: "Birth City",
  draftedBy: "Drafted By",
  committedTo: "Committed To",
  bio: "Bio",
};

const fieldsReducer = (state: any, action: any): any => {
  switch (action.type) {
    case "changeSkippedRecords": {
      const skipped = action.payload.skipped;
      const fields = Object.keys(FIELDS).filter(
        (_field) => !skipped.includes(_field)
      );
      const columns = Math.min(6, Math.ceil(fields.length / 2));
      const matrix = [];
      while (fields.length) matrix.push(fields.splice(0, columns));
      return {
        lineNumberSpan: matrix.length + 1,
        columns,
        fieldMatrix: matrix,
        fields,
      };
    }
    default: {
      // do nothing
    }
  }
};

export function ValidationFailed() {
  const { records } = useRosterImport();
  const [recordsWithErrors, setRecordsWithErrors] = useState<any[]>([]);

  const [state, dispatch] = useReducer(fieldsReducer, {
    lineNumberSpan: 0,
    columns: 0,
    fieldMatrix: [],
    fields: [],
  });

  useEffect(() => {
    dispatch({ type: "changeSkippedRecords", payload: { skipped: ["bio"] } });
  }, []);

  useEffect(() => {
    setRecordsWithErrors(
      records
        .map((record, recordIndex) => ({ ...record, line: recordIndex + 2 }))
        .filter((record) => {
          return !!record.validationErrors.length;
        })
    );
  }, [records.length]);

  return (
    <StyledValidationFailed>
      <div className="csv-import__error__text">
        <p>
          There {recordsWithErrors.length == 1 ? "is one row" : `are ${recordsWithErrors.length} rows`} with validation errors in the selected file.
        </p>
      </div>
      {recordsWithErrors.map((record) => (
        <div className="err-row" key={record.line}>
          <div className="err-contianer">
          <div className="row-ident">
            <div className="num">
              <p className="always-row">Row</p>
              <p className="line">{record.line}</p>
            </div>
            <div className="name">
              <p>{record.firstName}</p>
              <p>{record.lastName}</p>
            </div>
            </div>
            {record.validationErrors.map((error: any, index: number) => (
              <div className="err" key={index}>
                <p className="field">{capitalizeFirstLetter(error.field)}</p>
                <p className="problem">{record[error.field]}</p>
              </div>
            ))}

          </div>
          {/* <div className="row-errs">
            {record.validationErrors.map((error: any, index: number) => (
              <div className="err" key={index}>
                <p className="field">{capitalizeFirstLetter(error.field)}</p>
                <p className="problem">{record[error.field]}</p>
              </div>
            ))}
          </div> */}
        </div>
      ))}
    </StyledValidationFailed>
  );
}
