import { useAppState } from "@/state/app/useAppState";
import { useTeamsData } from "@/state/data/useTeams";

export function useSelectedTeamState(){

    const teams = useTeamsData();
    const app = useAppState();

    const team = teams.all().find(team => team.id === app.selectedTeam);

    if (!!team) {
        return {
            ...team,
            seasonTeams: teams.getSeasonTeams(team.id) || [],
        };
    } else {
        return undefined;
    }
}