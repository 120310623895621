import { useRecoilState } from "recoil";
import { AcceptInvitationFlowState } from "./atom.AcceptInvitationFlowState";
import { useCallback } from "react";

export function useInvitationCodeState():[string, (code:string) => void]{

    const [state, setState] = useRecoilState(AcceptInvitationFlowState)
    const setter = useCallback((code:string) => setState(s => ({ ...s, invitationCode: code })), [ setState ])
    
    return [
        state.invitationCode,
        setter
    ]

}