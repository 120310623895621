export type PageOptions = {
    rememberScroll: boolean,
    showActionsMenu: boolean,
    showBackButton: boolean,
    backButtonText?: string,
    backButtonMethod: () => void
}

export const defaultOptions:PageOptions = {
    rememberScroll: true,
    showActionsMenu: false,
    showBackButton: false,
    backButtonText: 'Back',
    backButtonMethod: () => window.history.back()
}