import config from "@/config";
import { useCallback, useEffect } from "react";
import { useSeasonsData } from "@/state/data/useSeasonTeams";
import { atom, selector, useRecoilState, useRecoilValue } from "recoil";
import { getOtp } from "@/state/user/getOtp";
import { userState } from "@/state/user/useUserState";

type StatsWidgetSeason = ReturnType<typeof useSeasonsData>['seasons'][0]
const StatsWidgetSelectedState = atom({
    key: 'statsWidgetSelectedState',
    default: {
        season: null,
        page: ""
    } as {
        season: null|StatsWidgetSeason,
        page: string
    }
})

const StatsWidgetState = selector({
    key: 'statsWidgetState',
    get: async ({ get }) => {
        
        const selected = get(StatsWidgetSelectedState)
        const user = get(userState)

        if(!selected.season){
            return {
                src: "",
                season: null,
                page: "",
                team: null,
                division: null
            }
        }

        const src = new URL(`/seasons/${selected?.season.id}/${selected.page}`, config.apps.statsWidget)
        src.searchParams.set('filter[division]', selected.season.division.id.toString())
        src.searchParams.set('configuration[logo]', 'false')
        src.searchParams.set('configuration[navigation]', 'false')
        src.searchParams.set('otp', await getOtp(user.tokens.access))

        return {
            src: src.href,
            season: selected.season,
            page: selected.page,
            team: selected.season.team,
            division: selected.season.division,
        }

    }
})

const statsMenuOpenState = atom({ key: 'statsMenuOpenState', default: false })

export function useStatsWidget(){

    const data = useSeasonsData()
    const [ selected, setSelected ] = useRecoilState(StatsWidgetSelectedState)
    const StatsWidget = useRecoilValue(StatsWidgetState)

    // dropdown menu
    const [ statsMenuOpen, setStatsMenuState ] = useRecoilState(statsMenuOpenState)
    function toggleStatsMenu(){
        setStatsMenuState(!statsMenuOpen)
    }

    useEffect(() => {
        
    }, [statsMenuOpen])

    // handle page change event from button group
    const setSelectedPage = useCallback((page:string) => {
        
        if (selected.page != page){
            setSelected({ ...selected, page })
            setStatsMenuState(false)
        }

    }, [selected, data])

    // handle season change event from select
    const setSelectedSeason = useCallback((id:number) => {
        const season = data.seasons.find(season => season.id == id)
        if ( season ){
            setSelected({ 
                ...selected, 
                season: season,
                page: StatsWidget.page?.replace(/teams\/\d+/, `teams/${season.team.id}`)
            })
        }
    }, [selected, data])

    const setLocation = useCallback((seasonId:number, page:string) => {
        const season = data.seasons.find(season => season.id == seasonId)
        if ( season ){
            setSelected({ 
                ...selected,
                season: season,
                page
            })
        }
    }, [selected, data])

    return {
        
        ...StatsWidget,
        teams: data.teams,
        seasons: data.seasons,

        toggleStatsMenu,
        isStatsMenuOpen: statsMenuOpen,

        setSeason: (id:number|string) => setSelectedSeason(Number(id)),
        setPage: (page:string) => setSelectedPage(page),
        onClickSetPage: (page:string) => () => setSelectedPage(page),
        setLocation,

    }

}