import { Button, Title } from "@gamesheet/ui";
import { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import CoachForm from "@/forms/CoachForm";
import { PageLoading } from "@/components/pure/PageLoading";
import * as Grid from "@/layouts/GridItems";
import { useAppState } from "@/state/app/useAppState";
import { useCoachData } from "@/state/data/useCoachData";
import { Navigate } from "react-router-dom";
import { useAppNavigation } from "@/hooks/useAppNavigation";
import { useSeasonsData } from "@/state/data/useSeasonTeams";
import { useTeamRoster } from "@/state/data/useTeamRoster/useTeamRoster";
import { usePageState } from "@/state/page/usePageState";

export function TeamRosterCoachEdit(){
    
    const app = useAppState()
    const Page = usePageState('team-coach-edit', {
        rememberScroll: false,
        showBackButton: true,
        backButtonMethod: () => app.navigate(`/roster/team`)
    })

    const params = useParams()
    const coachId = params.id
    const isNew = coachId == "new"
    const roster = useTeamRoster()
    const { formData, setFormData, submit } = useCoachData(coachId)
    const [ loading, setLoading ] = useState(false)
    const { navigate, destination, hasNavigation, handleNavigation } = useAppNavigation();
    const seasons = useSeasonsData()
    const showActions = seasons.hasUnlockedRoster
    const formValid = formData.firstName.length > 1 && formData.lastName.length > 1;

    const onSaveCoach = useCallback( () => {
        if (formValid) {
            setLoading(true)
            submit(onCoachSaved)
        }
    }, [submit])

    const onCoachSaved = () => {
        setLoading(false)
        
        const updateFunc = coachId == "new" ? roster.addCoach : roster.updateCoach
        updateFunc({
            firstName: formData.firstName,
            lastName: formData.lastName,
            id: formData.id,
            position: formData.position,
            rosterLocked: formData.rosterLocked,
            teamId: formData.teamId,
        })
        navigate(`/roster/team`)
    }

    useEffect(() => {
        
        const btnLabel = isNew ? "Create" : "Save"
        Page.setPrimaryButton(btnLabel, onSaveCoach, !formValid)

    }, [ isNew, formValid, onSaveCoach ])

    if (hasNavigation) {
        return <Navigate to={destination} />
    }
    
    if(loading){
        return <PageLoading />
    }

    return (<>
        <Grid.Blank $column={1} $row={1}>
            
            <CoachForm formData={formData} setFormData={setFormData} />
            { showActions && !isNew && <Button variant="danger" width="block" size="lg" onClick={app.navigateOnClick(`/roster/coach/${coachId}/delete`)}>Remove {formData.firstName} {formData.lastName}</Button> }
        
        </Grid.Blank>
    </>)
}