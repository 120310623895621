import { addDoc, collection, onSnapshot, orderBy, query, serverTimestamp } from "firebase/firestore";
import { useEffect } from "react";
import { atom, useRecoilValue, useSetRecoilState } from "recoil";
import { useAppState } from "@/state/app/useAppState";
import WordFilter from 'bad-words'

type ChatMessage = {
    from: {
        name: string;
        email: string;
        profile_image: string;
    },
    message: string;
    timestamp: number;
}

type ChatData = {
    messages: ChatMessage[],
}

const chatState = atom<ChatData>({
    key: 'chatState',
    default: {
        messages: [],
    }
})

export function useReadMessages(){

    const app = useAppState()
    const setChatData = useSetRecoilState(chatState)
    const filter = new WordFilter()

    useEffect(() => {

        // we need a team selected to read messages
        if(!app.selectedTeam) return;

        const q = query(collection(app.connections.firestore, `teams/${app.selectedTeam}/chats/coaches/messages`), orderBy('timestamp', 'asc'))
        return onSnapshot(q, (querySnapshot) => {
            
            const messages = querySnapshot.docs.map((doc) => {
                
                let message = doc.data().message
                try {
                    message = filter.clean(message)
                } catch(e){
                    console.error(e)
                }
                
                return {
                    ...doc.data(),
                    message,
                }

            }) as ChatMessage[];
            setChatData({ messages })

        })

    }, [])
}

export function useChat(){

    const app = useAppState()
    const data = useRecoilValue(chatState)
    const room = collection(app.connections.firestore, `teams/${app.selectedTeam}/chats/coaches/messages`)

    return {
        messages: () => data.messages,
        send: async (message: string) => {
            
            if(!app.activeUser) return;
            
            addDoc(room, {
                from: {
                    name: app.activeUser.displayName,
                    email: app.activeUser.email,
                    profile_image: app.activeUser.photoURL,
                },
                message,
                timestamp: serverTimestamp(),
            })
        }
    }

}