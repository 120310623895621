import { Loading } from "@gamesheet/ui";
import styled from "styled-components";

const StyledLoader = styled.div`
position: fixed;
display: flex;
align-items: center;
justify-content: center;
top: 0;
left: 0;
width: 100vw;
height: 100vh;
`

export function PageLoading(){

    return <StyledLoader>
        <Loading />
    </StyledLoader>

}