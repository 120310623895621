import { useEffect, useState } from "react";
import styled from "styled-components";

export const Iframe = styled.iframe<{ $isLoading?: boolean, id:string }>`
opacity: ${({ $isLoading }) => $isLoading ? 0 : 1}; 
transition: opacity 0.4s;
width:100%;
height:100%;
margin:0;
border:0;
`

export function useIframeLoadingState( id:string ){

    const [ loading, setLoading ] = useState(false)
    // const loaded = () => {
    //     setLoading(false)
    // }

    // // add/remove event listener on iframe load
    // useEffect(() => {
        
    //     const iframe = document.querySelector(`iframe#${id}`);
    //     if(!iframe) return;

    //     iframe.addEventListener('load', loaded)
    //     return () => {
    //         iframe.removeEventListener('load', loaded)
    //     }

    // }, [])

    

    return {
        loading,
        setLoading
    }

}