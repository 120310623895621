import { selector } from "recoil";
import { AcceptInvitationFlowState } from "./atom.AcceptInvitationFlowState";
import { ConvertInvitationPlayerToRosterPlayer } from "../lib/ConvertInvitationPlayerToRosterPlayer";

export const SampleTeamState = selector({
    key: 'SampleTeamState',
    get: ({ get }) => {
        const state = get(AcceptInvitationFlowState);

        const team = !!state.invitation?.teams?.length ? state.invitation.teams[0] : null;
        if (!team) {
            return null;
        }

        return {
            ...team,
            roster: team.players.map((player) => ConvertInvitationPlayerToRosterPlayer(team.id, player))
        }
    }
})