import { useEffect } from "react"
import { PageOptions } from "./type.options."
import { Action, ActionType, PageSessionState } from "./reducer.pagesession"
import { SetterOrUpdater, useRecoilState } from "recoil";
import { GlobalPageOptions, PageStates } from "./atom.pagestates";

export function usePageScrollState(
    options:PageOptions, 
    sessionState:[PageSessionState, (action:ActionType, state:Partial<PageSessionState>) => void],
    pageState:[GlobalPageOptions, SetterOrUpdater<GlobalPageOptions>]
){
    // page global state (persisted between page loads)
    const [ page, setPageState ] = pageState
    const [ session, setPageSession ] = sessionState

    // on page load, scroll to the last known scroll position
    useEffect(() => {
        window.scrollTo({
            top: page.scrollY,
            behavior: "instant" as ScrollBehavior
        })
        setPageSession(ActionType.SET_LOADING, { loading: false })
    }, [])

    // on page unload, save the current scroll position
    useEffect(() => {
        
        const handleScroll = () => {
            setPageState({
                scrollY: window.scrollY
            })
        }
        
        options.rememberScroll && window.addEventListener('scroll', handleScroll)
        return () => {
            options.rememberScroll && window.removeEventListener('scroll', handleScroll)
        }

    }, [])

    return
}