import { useTeamRoster } from "@/state/data/useTeamRoster/useTeamRoster";
import { RosterOption } from "./types";
import { useMemo, useState, useEffect } from "react";
import { useSeasonsData } from "@/state/data/useSeasonTeams";
import { useLockedRosters } from "@/state/data/useTeamRoster/useLockedRosters";
import { atom, useRecoilState } from "recoil";

export const SelectedRosterState = atom<RosterOption | null>({
    key: 'SelectedRosterState',
    default: null
});

export const SelectedTeamIdState = atom<string>({
    key: 'SelectedTeamIdState',
    default: "0"
});

export function useRosterSelect() {

    const defaultRosterOption = useDefaultRosterOption();
    const { tick: lockedRostersChanged, lockedRosters } = useLockedRosters()
    
    // selected roster
    const [_selectedRoster, _setSelectedRoster] = useRecoilState(SelectedRosterState)
    const [selectedTeamId, _setSelectedTeamId] = useRecoilState(SelectedTeamIdState)

    const selectedRoster = _selectedRoster || defaultRosterOption;

    useEffect(() => {
        
        // if selected roster is locked, set to default
        if (!selectedRoster.isLocked) {
            _setSelectedRoster(defaultRosterOption)
        }   

    }, [ JSON.stringify(defaultRosterOption) ])

    // create list of roster options
    const rosterOptions = useMemo(() => [
        defaultRosterOption,
        ...lockedRosters.map((team): RosterOption => ({
            ...team,
            teamId: team.id,
            title: "(LOCKED) " + team.title,
            isLocked: true
        }))
    ], [ lockedRostersChanged, JSON.stringify(defaultRosterOption) ])

    useEffect(() => {
        const selectedRoster = rosterOptions.find((team) => team.teamId == selectedTeamId)
        _setSelectedRoster(selectedRoster || defaultRosterOption)
    }, [selectedTeamId, JSON.stringify(rosterOptions), JSON.stringify(defaultRosterOption)])

    return {
        selected: selectedRoster,
        options: rosterOptions,
        isLocked: selectedRoster.isLocked,
        lockedRosters,
        setSelectedRoster: _setSelectedTeamId
    }

}

function useDefaultRosterOption(): RosterOption {

    const seasons = useSeasonsData()
    const activeRoster = useTeamRoster()

    return useMemo(() => {
        
        const firstMaliableSeason = seasons.firstMaliable
        const seasonId = firstMaliableSeason?.season.id.toString() || "";
        const teamId = firstMaliableSeason?.id || "";

        const baseOption = {
            teamId: "0",
            title: "Active Roster",
            seasonTitle: "",
            seasonId,
            divisionTitle: "",
            divisionId: "",
            displayTeamId: teamId,
            isLocked: false
        };

        // If hasUnlockedRoster is true, use activeRoster values
        if (seasons.hasUnlockedRoster) {
            return {
                ...baseOption,
                ...activeRoster,
            };
        }

        if( seasons.seasons.length === 1 && seasons.seasons[0].team.rosterLocked ){
            return {
                ...baseOption,
                ...activeRoster,
                seasonTitle: seasons.seasons[0].title,
                seasonId: seasons.seasons[0].id.toString(),
                teamId: "0",
                isLocked: true
            }
        }
        
        // Otherwise, set empty values
        return {
            ...baseOption,
            players: [],
            coaches: [],
            skaters: [],
            goalies: [],
        };

    }, [
        JSON.stringify(activeRoster), 
        JSON.stringify(seasons),
        seasons.hasMaliableRoster
    ]);

}