import { FormWrapper, Title, DateAndTimePicker, Button, TimePicker } from "@gamesheet/ui";
import { forwardRef, useCallback, useEffect, useState, ChangeEvent, useRef } from "react";
import { FormFieldType, NewFormField, useCreateForm } from "@/forms/hooks/useCreateForm";
import styled from "styled-components";
import { TextInput } from "./components/TextInput";
import "@gamesheet/ui/build/index.css";
import { SelectInput } from "./components/SelectInput";
import { useAppState } from "@/state/app/useAppState";
import { Resetter } from "recoil";
import { formatOffset, getOffset, getTimezoneOptions } from "@/libs/timezones";
import { EventFormData } from "@/services/useEventService/types";
import RepeatWidget, { RRuleWeekDayType, RepeatDay } from "./components/RepeatWidget";
import { RRule } from "rrule";
import { useRepeatingEvent } from "@/windows/repeatingEvent/useRepeatingEvent";
import { useDeleteRepeatingEvent } from "@/windows/deleteRepeatingEvent/useDeleteRepeatingEvent";
import ScheduledGameLocationInput from "@/components/utils/GameLocation/ScheduledGameLocationInput"
import { atom, useRecoilState } from "recoil";
import { PageLoading } from "@/components/pure/PageLoading";
import ButtonSpace from "@/components/pure/ButtonSpace";
import { locationInputFocusState } from '@/components/utils/GameLocation/components/QueryInput/state/atoms';

const mobileMediaQuery = `@media (max-width: 768px)`;

const RowWrapper = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;

  .full-width {
    width: 100%;
  }

  ${mobileMediaQuery} {
    flex-direction: column;
    gap: 0;
  }
`;

const DualRowWrapper = styled.div`
  display: flex;
  gap: 0rem 1rem;
  align-items: top;
  width: 50%;

  .form-control:disabled {
    background-color: #d7d7d8;
  }

  ${mobileMediaQuery} {
    gap: 0 0.5rem;
    width: 100%;
  }
`;

const RowWrapper2 = styled.div`
  display: flex;
  gap: 1rem;

  .full-width {
    width: 100%;
  }

  .form-control:disabled {
    background-color: #d7d7d8;
  }

  ${mobileMediaQuery} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 2rem;

  .button {
    font-size: 1rem !important;
    font-weight: 500 !important;
  }

  ${mobileMediaQuery} {
    flex-direction: column-reverse;
  }
`;

const CreateButtonContainer = styled.div`
  display: flex;
  gap: 0rem;
  justify-content: space-between;
  width: 100%;

  ${mobileMediaQuery} {
    flex-direction: column-reverse;
    width: 100%;
    padding-bottom: 1rem;
  }

`;

const CancelButtonsContainer = styled.div`
  display: flex; 
  gap-right: 1rem;
  align-items: flex-start;

  ${mobileMediaQuery} {
    flex-direction: column-reverse;
    width: 100%;
  }
`

const EditButtonsContainer = styled.div`
  display: flex; 
  gap-right: 1rem;
  align-items: flex-end;

  ${mobileMediaQuery} {
    flex-direction: column-reverse;
    width: 100%;
  }
`

const ConfirmDeleteContainer = styled.div`
  display: flex;
  gap: 2rem;
  justify-content: space-around;

  ${mobileMediaQuery} {
   flex-direction: column-reverse;
   gap: 1rem;
  }
`;

const VS = styled.div`
  color: #000;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 1rem;

  ${mobileMediaQuery} {
    text-align: center;
    padding: 0.5rem 0;
    width: 100%;
  }
`;

const NotesAndLocationContianer = styled.div`
  display: flex;
  column-gap: 0rem;
  ${mobileMediaQuery} {
    flex-direction: column-reverse;
  }
`;

export type EventFormState = {
  id: FormFieldType<number>;
  title: FormFieldType<string>;
  timezone: FormFieldType<string>;
  startDateTime: FormFieldType<Date | null>;
  endDateTime: FormFieldType<Date | null>;
  location: FormFieldType<string>;
  notes: FormFieldType<string>;
  type: FormFieldType<string>;
  recurrence: FormFieldType<string>;
  eventGroup: FormFieldType<string>;
};

type EventFormProps = {
  onSubmit: (eventForm: EventFormState, method: string, another?: boolean, resetForm?: () => void) => void;
  eventDetails: EventFormData;
  eventOrPractice: "event" | "practice";
  resetEventData: Resetter;
  setLoadingEvent: (loading: boolean) => void;
};

export const recurrenceRRuleState = atom({
  key: 'recurrenceRRuleState',
  default: '',
});

interface RRULEValues {
  interval: number;
  until: string;
  byday: string[];
}

export type TimeZoneOptions = {
  label: string;
  value: string;
  offset: number | null;
}

export const EventForm = forwardRef(({ eventDetails, eventOrPractice, onSubmit, resetEventData, setLoadingEvent }: EventFormProps, ref) => {
  const app = useAppState();
  const events = app.events;
  const showLoading = !app.hasSelectedTeam || !app.loading.isComplete('events')
  const [locationVal, setLocationVal] = useState(eventDetails?.location || "")
  const [locationFocusState, setLocationFocusState] = useRecoilState(locationInputFocusState);
  const locationInputBox = useRef<HTMLDivElement>(null);
  const [timezoneOptions, setTimeZoneOptions] = useState<TimeZoneOptions[]>(getTimezoneOptions().map((tz) => {
    if (!!eventDetails?.startDateTime && tz === eventDetails?.timezone) {
      return {
        label: `${tz} (GMT${formatOffset(getOffset(tz, eventDetails?.startDateTime))})`,
        value: tz,
        offset: getOffset(tz, eventDetails?.startDateTime),
      }
    } else {
      return {
        label: tz,
        value: tz,
        offset: null,
      }
    }
  }));

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (locationInputBox.current && !locationInputBox.current.contains(event.target as Node)) {
        setLocationFocusState(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const parseRRULE = (rruleString: string): RRULEValues | any => {
    const result: RRULEValues = {
      interval: 1,
      until: '',
      byday: [],
    };
    const regex = /(?:;|^)([^=;]+)=([^;]+)/g;
    let match;
    while ((match = regex.exec(rruleString)) !== null) {
      const [, key, value] = match;
      switch (key) {
        case 'INTERVAL':
          result.interval = parseInt(value, 10);
          break;
        case 'UNTIL':
          result.until = value;
          break;
        case 'BYDAY':
          result.byday = value.split(',');
          break;
        // Add more cases for other RRULE properties if needed
      }
    }
    return result;
  };

  const parsedRRULE = parseRRULE(eventDetails.recurrence);

  const adjustedDateString = parsedRRULE.until.replace(/(\d{4})(\d{2})(\d{2})T(\d{2})(\d{2})(\d{2})Z/, '$1-$2-$3T$4:$5:$6Z');
  const timestamp = adjustedDateString ? Date.parse(adjustedDateString) : new Date().setMonth(new Date().getMonth() + 3);;
  const [endTime, setEndTime] = useState<string | null>(eventDetails.endDateTime?.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false }) || null);
  const [selectedRepeatOption, setSelectedRepeatOption] = useState<string>(eventDetails.recurrence ? "Weekly" : "Never");
  // potentially logic for repeating logic should go in it's own hook
  const [resetLoaded, setResetLoaded] = useState<boolean>(false);
  const [repeatEventActive, setRepeatEventActive] = useState(parsedRRULE.byday.length > 0);
  const [repeatEventEndDate, setRepeatEventDate] = useState<Date>(new Date(timestamp));
  const [repeatEventInterval, setRepeatEventInterval] = useState<number>(parsedRRULE.interval || 1);
  const [repeatDays, setRepeatDays] = useState<RepeatDay[]>([
    { active: parsedRRULE.byday.includes("SU"), value: "SU", label: "S" },
    { active: parsedRRULE.byday.includes("MO"), value: "MO", label: "M" },
    { active: parsedRRULE.byday.includes("TU"), value: "TU", label: "T" },
    { active: parsedRRULE.byday.includes("WE"), value: "WE", label: "W" },
    { active: parsedRRULE.byday.includes("TH"), value: "TH", label: "T" },
    { active: parsedRRULE.byday.includes("FR"), value: "FR", label: "F" },
    { active: parsedRRULE.byday.includes("SA"), value: "SA", label: "S" },
  ]);
  const [readableRRule, setReadableRRule] = useState<string>(eventDetails.recurrence);
  const [recurrence, setRecurrence] = useRecoilState(recurrenceRRuleState);

  const resetRepeatEvent = () => {
    setRepeatEventActive(false)
    setRepeatEventDate(new Date(timestamp));
    setRepeatEventInterval(1)
    setRepeatDays([
      { active: false, value: "SU", label: "S" },
      { active: false, value: "MO", label: "M" },
      { active: false, value: "TU", label: "T" },
      { active: false, value: "WE", label: "W" },
      { active: false, value: "TH", label: "T" },
      { active: false, value: "FR", label: "F" },
      { active: false, value: "SA", label: "S" },
    ])
    setReadableRRule("");
    setRecurrence("");
  }

  useEffect(() => {
    if (eventDetails.id === 0) {
      resetRepeatEvent();
      setResetLoaded(true);
    }
  }, [JSON.stringify(eventDetails)])

  useEffect(() => {
    if (repeatEventActive) {
      const activeDays = repeatDays.filter((day) => day.active);

      if (activeDays.length > 0) {
        const daysOfWeek: RRuleWeekDayType[] = activeDays.map((day) => day.value);

        const adjustedRepeatEventEndDate = new Date(repeatEventEndDate);
        const day = adjustedRepeatEventEndDate.getDate();
        const month = adjustedRepeatEventEndDate.getMonth();
        const year = adjustedRepeatEventEndDate.getFullYear();

        const ruleDate = new Date(Date.UTC(year, month, day, 23, 59, 0, 0));


        const rruleOptions = {
          freq: RRule.WEEKLY,
          interval: repeatEventInterval,
          wkst: RRule.MO,
          until: ruleDate,
          byweekday: daysOfWeek.map((day) => RRule[day]),
        };

        const rule = new RRule(rruleOptions);

        // Get the generated RRULE string
        const rruleString = rule.toString();

        let ruletext = rule.toText();
        ruletext = ruletext.charAt(0).toUpperCase() + ruletext.slice(1);

        setRecurrence(rruleString);
        setReadableRRule(ruletext);
      } else {
        // No active days, set readableRRule to an empty string
        setReadableRRule("");
        setRecurrence("");
      }
    }
  }, [repeatEventActive, repeatEventEndDate, repeatEventInterval, repeatDays]);

  const [formValid, setFormValid] = useState<boolean>(false);

  const handleRepeatSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value;
    setSelectedRepeatOption(selectedValue);

    if (selectedValue === 'Never') {
      resetRepeatEvent();
    } else {

      setRepeatEventActive(true);
      setRepeatEventDate(new Date(timestamp));
    }
  };

  const editEvent = eventDetails.id != 0;
  const widthStyle = app.layout.showMobile ? "block" : "auto";

  function getUserTimeZone() {
    // Use the Intl.DateTimeFormat object to get the user's timezone
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return userTimeZone;
  }
  const userTimeZone = getUserTimeZone();


  const {
    state: formData,
    onChange,
    setFormsState,
    resetForm,
  } = useCreateForm("event", {
    id: NewFormField(eventDetails.id || 0, /.{0,}/, ""),
    title: NewFormField(eventOrPractice === "event" ? eventDetails.title || "" : "practice", /.{1,}/, "Title must be entered", eventOrPractice === "practice" ? true : false),
    startDateTime: NewFormField(eventDetails?.startDateTime || null, /.{1,}/, "Start date and time must be chosen", true),
    endDateTime: NewFormField(eventDetails?.endDateTime || null, /.{0,}/, "", true),
    timezone: NewFormField(eventDetails.timezone || userTimeZone, /.{1,}/, "Time zone must be selected", eventDetails?.timezone || userTimeZone ? true : false),
    location: NewFormField(eventDetails.location, eventOrPractice === "practice" ? /.{1,}/ : /.{0,}/, eventOrPractice === "practice" ? "Location must be entered" : ""),
    notes: NewFormField(eventDetails.notes, /.{0,}/, ""),
    type: NewFormField(eventOrPractice, /.{0,}/, ""),
    recurrence: NewFormField(eventDetails.recurrence, /.{0,}/, ""),
    eventGroup: NewFormField(eventDetails.eventGroup, /.{0,}/, ""),
  });


  useEffect(() => {
    // Update the form data when eventDetails change
    if (setFormsState) {
      setFormsState((formData: any) => ({
        ...formData,
        event: {
          initialState: { ...formData.event.initialState },
          currentState: {
            ...formData.event.currentState,
            // Update each field with the corresponding value from eventDetails
            id: { ...formData.event.currentState.id, value: eventDetails.id || 0 },
            title: { ...formData.event.currentState.title, value: eventDetails.title || "" },
            startDateTime: { ...formData.event.currentState.startDateTime, value: eventDetails.startDateTime || null },
            endDateTime: { ...formData.event.currentState.endDateTime, value: eventDetails.endDateTime || null },
            timezone: { ...formData.event.currentState.timezone, value: eventDetails.timezone || userTimeZone },
            location: { ...formData.event.currentState.location, value: eventDetails.location || "" },
            notes: { ...formData.event.currentState.notes, value: eventDetails.notes || "" },
            type: { ...formData.event.currentState.type, value: eventOrPractice },
            recurrence: { ...formData.event.currentState.recurrence, value: eventDetails.recurrence || "" },
            eventGroup: { ...formData.event.currentState.eventGroup, value: eventDetails.eventGroup || "" },
          },
        },
      }));

      if (eventOrPractice === "practice") {
        setFormsState((formData: any) => ({
          ...formData,
          event: {
            ...formData.event,
            currentState: {
              ...formData.event.currentState,
              type: { ...formData.event.currentState.type, valid: true, error: "", value: "practice" },
            },
          },
        }));
      }

    }
  }, [JSON.stringify(eventDetails), eventOrPractice, setFormsState, userTimeZone]);

  // Set Location
  useEffect(() => {
    if (setFormsState && (eventOrPractice === "practice")) {
      setFormsState((formData: any) => ({
        ...formData, event: {
          initialState: { ...formData.event.initialState }, currentState: {
            ...formData.event.currentState,
            location: { ...formData.event.currentState.location, value: locationVal, valid: locationVal.length > 0 },
          },
        },
      }));
    }
  }, [locationVal])

  const handleStartDateTimeChange = (newDate: Date) => {
    updateTimeZoneOptions(formData.timezone.value, newDate);

    if (setFormsState) {
      // Check if the start date is before the end date
      // const isStartDateBeforeEndDate = newDate <= formData.endDateTime.value;
      const newEndDate = new Date(newDate.getTime() + 60 * 60 * 1000);

      setFormsState((formData: any) => ({
        ...formData,
        event: {
          initialState: { ...formData.event.initialState },
          currentState: {
            ...formData.event.currentState,
            startDateTime: {
              ...formData.event.currentState.startDateTime,
              value: newDate,
              valid: true,
            },
            endDateTime: {
              ...formData.event.currentState.endDateTime,
              value: newEndDate,
              valid: true,
              error: "",
            },
          },
        },
      }));
    }
  };

  const handleEndDateTimeChange = (newDate: Date) => {
    if (setFormsState) {
      // Check if the end date is before the start date
      const isEndDateValid = formData.startDateTime.value && newDate >= formData.startDateTime.value;

      setFormsState((formData: any) => ({
        ...formData,
        event: {
          initialState: { ...formData.event.initialState },
          currentState: {
            ...formData.event.currentState,
            endDateTime: {
              ...formData.event.currentState.endDateTime,
              value: newDate,
              valid: isEndDateValid,
              error: isEndDateValid ? "" : "End date must be after the start date",
            },
          },
        },
      }));

      // Update endTime when endDateTime changes
      setEndTime(newDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }));
    }
  };


  useEffect(() => {
    // Update endTime whenever endDateTime changes
    const endDateTimeValue = formData?.endDateTime?.value;

    if (endDateTimeValue) {
      setEndTime(endDateTimeValue.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false }));
    } else {
      setEndTime(null);
    }
  }, [formData?.endDateTime?.value]);

  const setEndTimeFromTimePicker = (newTime: string) => {
    if (!formData.startDateTime.value) return;
    if (newTime > formData.startDateTime.value.toTimeString()) {
      handleEndDateTimeChange(new Date(`${formData.startDateTime.value.toDateString()} ${newTime}`));
    } else {
      handleEndDateTimeChange(new Date(`${new Date(formData.startDateTime.value.getTime() + 60 * 24 * 60 * 1000).toDateString()} ${newTime}`));
    }
    setEndTime(newTime);
  }



  const validateForm = useCallback(() => {
    // Validating form data
    let isValid = false;


    if (!editEvent && formData) {
      isValid = formData?.title?.valid && formData?.startDateTime?.valid && formData?.endDateTime?.valid && formData?.timezone?.valid && !!formData?.startDateTime?.value && !!formData?.endDateTime?.value
    }


    if (eventOrPractice === "practice") {
      isValid = isValid && formData?.location?.valid;
    }


    if (editEvent && eventDetails && formData) {
      isValid =
        eventDetails.location !== formData.location?.value ||
        eventDetails.notes !== formData.notes?.value ||
        eventDetails.title !== formData.title?.value ||
        eventDetails.timezone !== formData.timezone?.value ||
        eventDetails?.startDateTime?.getTime() !== formData?.startDateTime?.value?.getTime() ||
        eventDetails?.endDateTime?.getTime() !== formData?.endDateTime?.value?.getTime() ||
        eventDetails.recurrence !== recurrence;

    }

    return isValid;
  }, [formData, eventOrPractice, editEvent, eventDetails, recurrence]);

  useEffect(() => {
    // Validate the form whenever the form data changes
    const isValid = validateForm();
    setFormValid(isValid);
  }, [formData, validateForm]);

  const updateTimeZoneOptions = (currentValue: string, startDateTime: Date | null) => {
    if (!startDateTime) return;

    const offset = getOffset(currentValue, startDateTime);
    if (!offset) return;

    setTimeZoneOptions((prev) => {
      return prev.map((option) => {
        if (option.value === currentValue) {
          if (option.offset !== offset) {
            return {
              ...option,
              label: `${currentValue} (GMT${formatOffset(offset)})`,
              offset: offset,
            };
          }
        }
        return option;
      });
    });
  }

  const updateTimeZone = (e: ChangeEvent<HTMLSelectElement>) => {
    updateTimeZoneOptions(e.target.value, formData.startDateTime.value);
    return onChange(e);
  };

  const commonOnClickParams = { value: editEvent ? eventDetails.id : 0, valid: true, test: /.{0,}/, error: "" };

  const resetAllForm = () => {
    resetForm && resetForm();
    resetRepeatEvent();
  }

  const handleClick = (action: string, reset: boolean) => {
    onSubmit({ ...formData, id: commonOnClickParams }, action, reset, resetAllForm);
    resetEventData();
  };

  const { open: openRepeatingEventModal, EditEvent } = useRepeatingEvent(resetAllForm);
  const { open: openDeleteRepeatingEventModal, DeleteEvent } = useDeleteRepeatingEvent(resetAllForm);
  useEffect(() => events.add('show-repeating-event', openRepeatingEventModal), [openRepeatingEventModal])
  useEffect(() => events.add('show-delete-repeating-event', openDeleteRepeatingEventModal), [openDeleteRepeatingEventModal])

  const dateFormat = app.layout.showDesktop ?
    {
      weekday: "long" as const,
      month: "short" as const,
      day: "numeric" as const,
      year: "numeric" as const,
      hour: "numeric" as const,
      minute: "numeric" as const,
      hour12: true,
    } as Intl.DateTimeFormatOptions : {
      weekday: "short",
      month: "short",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    } as Intl.DateTimeFormatOptions;

  if (showLoading || !formData) {
    return <PageLoading />
  }

  return (
    <FormWrapper>
      <fieldset>
        {eventOrPractice === "event" ? (
          <div style={{ paddingBottom: '15px' }}>
            <Title bartype="divider" text={editEvent ? "Edit Event" : "Create New Event"} size="h2" />
          </div>
        ) : (
          <div style={{ paddingBottom: '15px' }}>
            <Title bartype="divider" text={editEvent ? "Edit Practice" : "Create New Practice"} size="h2" />
          </div>
        )}
        {eventOrPractice === "event" && (
          <div className="">
            <div className="form-floating mb-2 mb-md-3">
              <TextInput state={formData.title} name="title" label="Event Title*" onChange={onChange} />
            </div>
          </div>
        )}
        <RowWrapper>
          <DualRowWrapper>
            <div className={app.layout.showDesktop ? "col" : ""} style={{ width: '100%', minWidth: '150px' }}>
              <div className="form-floating mb-2 mb-md-3" style={{ minWidth: '150px' }}>
                <DateAndTimePicker value={formData.startDateTime?.value || null} setValue={handleStartDateTimeChange} label="Start Date & Time*" dateFormatOptions={dateFormat} hideValidation />
              </div>
            </div>
            <div className={app.layout.showDesktop ? "" : "col"} style={{ width: '100%', minWidth: '100px', maxWidth: '180px' }} >
              <div className="form-floating mb-2 mb-md-3" style={{ minWidth: '100px', maxWidth: '180px' }}>
                <TimePicker
                  value={endTime}
                  setValue={setEndTimeFromTimePicker}
                  label="End Time"
                  error={{ valid: formData?.endDateTime?.valid, message: formData?.endDateTime?.error }}
                  hideValidation
                />
              </div>
            </div>
          </DualRowWrapper>

          <div className="col full-width">
            <div className="form-floating mb-2 mb-md-3">
              <SelectInput state={formData.timezone} name="timezone" label="Time Zone*" onChange={updateTimeZone} options={timezoneOptions} />
            </div>
          </div>
        </RowWrapper>
        <NotesAndLocationContianer>
          <div style={{ flexDirection: "column", flex: "1" }}>
            <div className={`form-floating ${["col", "form-floating", "mb-2", "mb-md-3"].join(" ")}`}>
              <select
                name={"repeat"}
                id={"repeat"}
                className={`form-control ${[]} border`}
                onChange={handleRepeatSelect}
                style={{ fontFamily: "sans-serif" }}
                value={selectedRepeatOption}
              >
                <option value="Never">Never</option>
                <option value="Weekly">{readableRRule ? readableRRule : "Weekly"}</option>
              </select>
              <label htmlFor={"repeat"} className={`floating`}>
                Repeat
              </label>
            </div>
            <div style={{ justifyContent: app.layout.showMobile ? "center" : undefined, display: app.layout.showMobile ? "flex" : undefined }}>
              {repeatEventActive && (
                <RepeatWidget date={repeatEventEndDate} setDate={setRepeatEventDate} interval={repeatEventInterval} setInterval={setRepeatEventInterval} days={repeatDays} setDays={setRepeatDays} />
              )}
            </div>
          </div>
          <DualRowWrapper style={{ alignItems: "top" }}>
            <div className="col">
              <div style={{ display: "flex", flexDirection: "column", marginLeft: !app.layout.showMobile ? "16px" : undefined }}>
                <div className="form-floating mb-2 mb-md-3" ref={locationInputBox}>
                  {
                    eventOrPractice === "event" ?
                      <TextInput state={formData.location} name="location" label="Location" onChange={onChange} /> :
                      <ScheduledGameLocationInput
                        location={locationVal}
                        setLocation={setLocationVal}
                        clear={0}
                        isDisabled={false}
                      />
                  }

                </div>
              </div>
              <div className="col">
                <div className="form-floating mb-2 mb-md-3" style={{ marginLeft: !app.layout.showMobile ? "16px" : undefined }}>
                  <textarea className="form-control" value={formData.notes?.value || ""} name="notes" onChange={onChange} placeholder="Notes" style={{ height: "100px" }} />
                  <label htmlFor="notes">Notes</label>
                </div>
              </div>
            </div>
          </DualRowWrapper>
        </NotesAndLocationContianer>
        <FooterContainer>
          <CreateButtonContainer>
            <CancelButtonsContainer>
              <Button
                variant="inverted"
                label="Cancel"
                onClick={() => {
                  app.navigate("/games");
                  resetAllForm();
                  resetEventData();
                }}
                width={`${app.layout.showMobile ? "block" : "auto"}`}
              >
                Cancel
              </Button>
              {!app.layout.showDesktop && <ButtonSpace width="0px" />}
              {!app.layout.showDesktop && <ButtonSpace width="0px" />}
              {editEvent && <Button
                label={eventOrPractice === "event" ? "Delete Event" : "Delete Practice"}
                size="lg"
                width="auto"
                variant="danger"
                style={{ marginLeft: app.layout.showMobile ? "0" : "1rem", width: app.layout.showMobile ? "100%" : "" }}
                onClick={() => openDeleteRepeatingEventModal(formData)}
              />}
            </CancelButtonsContainer>
            {editEvent ? (
              <EditButtonsContainer>
                <ButtonSpace />
                <Button
                  label={eventOrPractice === "event" ? "Edit Event" : "Edit Practice"}
                  size="lg"
                  style={{ backgroundColor: formValid ? "" : "#b5b5b3", width: app.layout.showMobile ? "100%" : "" }}
                  onClick={formValid ? () => (formData.eventGroup.value.length > 0 ? openRepeatingEventModal(formData, setLoadingEvent) : EditEvent("single", formData, setLoadingEvent)) : () => { }}
                />
              </EditButtonsContainer>
            ) : (
              <EditButtonsContainer>
                <Button label="Create And Add Another" size="lg" style={{ backgroundColor: formValid ? "" : "#b5b5b3", width: app.layout.showMobile ? "100%" : "" }} onClick={formValid ? () => handleClick("create", true) : () => { }} />
                <ButtonSpace />
                <Button
                  label={eventOrPractice === "event" ? "Create Event" : "Create Practice"}
                  size="lg"
                  style={{ backgroundColor: formValid ? "" : "#b5b5b3", width: app.layout.showMobile ? "100%" : "" }}
                  onClick={formValid ? () => handleClick("create", false) : () => { }}
                />
              </EditButtonsContainer>
            )}
          </CreateButtonContainer>
        </FooterContainer>
      </fieldset>
    </FormWrapper>
  );
});

export default EventForm;
