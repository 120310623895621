import { useRecoilValue } from "recoil";
import PropTypes from "prop-types";
import styled from "styled-components";
import _startCase from "lodash/startCase";
import { selectedTeamSportPositionsOptionsData, selectedTeamSportCoachPositionsOptionsData } from "@/state/data/useSeasonTeams";

const StyledParser = styled.div`
  .csv-import__error__text {
    background-color: #ffafaf;
    border-radius: 5px;
    padding-top: 10px;
    padding-bottom: 9px;
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
    p {
      margin-bottom: 0;
    }
  }

  p.section-title {
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 22px;
  }

  .color-red {
    color: red;
  }
`;

const StyledSupportedOptions = styled.div`
  p.list-title {
    margin-bottom: 0;
    font-weight: bold;
  }

  .format-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }

  code {
    padding-left: 4px;
    color: black;
  }
`;

export function Parser({ error }: { error: string }) {
  const playerPositions = useRecoilValue(selectedTeamSportPositionsOptionsData);
  const coachPositions = useRecoilValue(selectedTeamSportCoachPositionsOptionsData);

  return (
    <StyledParser>
      <p className="section-title">Prepare Your Roster File for GameSheet</p>
      <ol>
        <li>Download our Template.</li>
        <li>Fill in all required (<span className="color-red">*</span>) fields.</li>
        <li>Ensure dates are in the format YYYY-MM-DD</li>
        <li>
          Ensure Type, Shot Hand, Position, and Designation values match the
          options listed below.
        </li>
      </ol>
      <StyledSupportedOptions>
        <p className="section-title">Supported Options</p>
        <div className="format-container">
          <div className="list-container">
            <p className="list-title">Valid types:</p>
            <ol>
              <li>
                <code>P, Player</code>
              </li>
              <li>
                <code>C, Coach</code>
              </li>
            </ol>
          </div>
          <div className="list-container">
            <p className="list-title">Valid Shot hands:</p>
            <ol>
              <li>
                <code>L, Left</code>
              </li>
              <li>
                <code>R, Right</code>
              </li>
            </ol>
          </div>
          <div className="list-container">
            <p className="list-title">Valid player designations:</p>
            <ol>
              <li>
                <code>C, Captain</code>
              </li>
              <li>
                <code>A, Alternate_captain</code>
              </li>
            </ol>
          </div>
          <div className="list-container">
            <p className="list-title">Valid player positions:</p>
            <ol>
              {playerPositions.map((pos) => (
                <li key={pos.abbr}>
                  <code>{pos.abbr}, {pos.title}</code>
                </li>
              ))}
            </ol>
          </div>
          <div className="list-container">
            <p className="list-title">Valid coach positions:</p>
            <ol>
              {coachPositions.map((pos) => (
                <li key={pos.abbr}>
                  <code>{pos.abbr}, {pos.title}</code>
                </li>
              ))}
            </ol>
          </div>
        </div>
      </StyledSupportedOptions>
      <p className="section-title">Tips</p>
      <ul>
        <li>
          The External ID allows GameSheet to link player stats from season to
          season and is usually set to their registration ID.
        </li>
        <li>
          All of the columns included in the CSV template must be present in
          order for the import to function. You may not, for example, remove the
          BIRTHDATE column because you are not including birth dates.
        </li>
        <li>
          Column order in the CSV file does not matter, as long as the headings
          match the template.
        </li>
        <li>
          Player/Coach first and last name will be converted automatically to
          all-caps so formatting of these fields is not necessary.
        </li>
        <li>
          To export a CSV, use the Save As option in Excel and under Save as
          type box, choose CSV (Comma delimited).
        </li>
      </ul>
      {error && (
        <div className="csv-import__error__text">
          <p>{error}</p>
        </div>
      )}
    </StyledParser>
  );
}

Parser.propTypes = {
  error: PropTypes.string,
  teamId: PropTypes.string,
};

Parser.defaultProps = {
  error: null,
  teamId: null,
};
