import { selector } from "recoil";
import { recordsState } from "./atoms";
import { mergeByExternalId } from "../functions/mergeByExternalId";

export const playerRecordsState = selector({
  key: "playerRecords",
  get: ({ get }) => {
    const players = get(recordsState)
      .filter((record) => record.type === "player")
      .map((player) => ({ ...player, team_ids: [player.teamId] }));
    return mergeByExternalId(players);
  },
});
