import config from "@/config";
import { useUserState } from "@/state/user/useUserState";
import { useEffect } from "react";
import { useRecoilState, useResetRecoilState } from 'recoil';
import { rawScheduledGameData } from './atom.scheduledGameData';
import { useScheduledGameService } from "./useScheduledGameService";

export const useReadScheduledGameData = () => {
    const user = useUserState();
    const [gameData, setGameData] = useRecoilState(rawScheduledGameData);
    const resetScheduledGameData = useResetRecoilState(rawScheduledGameData);
    const gameService = useScheduledGameService();

    return {
        ReadScheduledGameData: async (gameId: string) => {
            resetScheduledGameData();
            try {
                if (gameId != 'new') {
                    const data = await gameService.GetGameDetails(gameId);
                    if (data.status == 'success') {
                        setGameData({status: data.status, data:{...data.data, game_id: parseInt(gameId)}});
                    }
                    return data.status;
                }
            } catch (error) {
                console.error("Error while updating team data:", error);
                return error;
            }

        }
    }
}