import React from "react";
import styled from "styled-components";
import { Modal, Button } from "@gamesheet/ui";
import { useDeletePlayerOrCoach } from "./useDeletePlayerOrCoach";
import { useAppState } from "@/state/app/useAppState";
import { LineupFlowHook } from "@/flows/LineupFlow/state/useLineupFlow";
import { useLineupData } from "@/flows/LineupFlow/state/useLineup";

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgb(0, 0, 0, 0.5);
  z-index: 5200;
`;

const Text = styled.div`
  font-family: Rubik;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  padding-bottom: 0.75rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
`;

const ConfirmButtonContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  padding-top: 0.5rem;
`;

const Divider = styled.div`
  border: 1px solid #d7d7d8;
  margin: 0 -1rem;
`;

type DeleteLineupPerson = {
  flow: LineupFlowHook;
};

export function DeletePlayerOrCoach({ flow }: DeleteLineupPerson) {
  const app = useAppState();
  const { isOpen, close } = useDeletePlayerOrCoach();
  const { LocalLineupData } = useLineupData();

  if (!isOpen) {
    return <></>;
  }

  const handleDeleteFromLineup = () => {
    const selectedPerson = LocalLineupData.getSelectedPlayerGoalieOrCoach();

    if (!selectedPerson) return;
    if (selectedPerson) {
      const lineup = LocalLineupData.get()?.lineup; // Add a null check here
      if (!lineup) return; // Return early if lineup is undefined

      let updatedLineup = { ...lineup };

      if (selectedPerson.type === "player") {
        updatedLineup = {
          ...lineup,
          players: lineup.players.filter((player) => player.id !== selectedPerson.data.id),
        };
      } else if (selectedPerson.type === "goalie") {
        updatedLineup = {
          ...lineup,
          goalies: lineup.goalies.filter((goalie) => goalie.id !== selectedPerson.data.id),
        };
      } else if (selectedPerson.type === "coach") {
        updatedLineup = {
          ...lineup,
          coaches: lineup.coaches.filter((coach) => coach.id !== selectedPerson.data.id),
        };
      }

      // Update the local lineup data with the updated lineup
      LocalLineupData.set({
        ...LocalLineupData.get(),
        lineup: updatedLineup,
      });

      // Close the modal and navigate to the appropriate path
      close();
      if (selectedPerson.type === "player" || selectedPerson.type === "goalie") {
        flow.usePlayerPath();
      } else {
        flow.useCoachPath();
      }
    }
  };

  return (
    <ModalContainer>
      <Modal title="Delete Player" show={isOpen} onClose={close}>
        <Text>
          Delete {`${LocalLineupData.getSelectedPlayerGoalieOrCoach()?.data?.firstName || ""} ${LocalLineupData.getSelectedPlayerGoalieOrCoach()?.data?.lastName || ""}`} from the lineup? This action
          cannot be undone.
        </Text>
        <Divider />
        <ButtonContainer>
          <ConfirmButtonContainer>
            <Button size="lg" variant="danger" label="Delete" onClick={handleDeleteFromLineup} />
          </ConfirmButtonContainer>

          <Button size="lg" variant="inverted" label="Cancel" onClick={close} />
        </ButtonContainer>
      </Modal>
    </ModalContainer>
  );
}
