import { Config } from "./type";
import { config as defaultConfig } from "./config.default";

const prod:Config = {
    ...defaultConfig,
    name: "Production",
    apps: {
        statsWidget: "https://gamesheetstats.com",
        leagueDashboard: "https://gamesheet.app",
    },
    firebase: {
        apiKey: "AIzaSyCk5pKBFxvCMuwPchzXgvvz4XmmscJTvs8",
        authDomain: "gamesheet-production.firebaseapp.com",
        databaseURL: "https://gamesheet-production.firebaseio.com",
        projectId: "gamesheet-production",
        storageBucket: "gamesheet-production.appspot.com",
        messagingSenderId: "978308121196",
        appId: "1:978308121196:web:e42b3202090baa0c65ce49",
        measurementId: "G-JNZ87Y74PG"
    },
    unleash: {
        environment: "production",
        clientKey: "default:production.ad173ffc498dfc85ed689e30c23e2ffdcf6af4118ed295f2d6f2695f",
    }
}

const prodLocal:Config = {
    ...prod,
    name: "Prod Local",
    gateways: {
        data: "https://gateway-data-awy26srzoa-nn.a.run.app",
        events: "https://gateway-events-awy26srzoa-nn.a.run.app",
        auth: "https://gateway-authserver-awy26srzoa-nn.a.run.app",
        eventLog: "https://gateway-eventlog-awy26srzoa-nn.a.run.app",
        lookups: "https://lookups.gamesheet.io",
    },
    services: {
        bffApi: "/api", 
        assetsApi: "https://assetsapi-awy26srzoa-nn.a.run.app",
        registryApi: "https://registrysync-v4-awy26srzoa-nn.a.run.app",
        scoresheetExportApi: "https://scoresheet-service-awy26srzoa-nn.a.run.app",
        unleashApi: "https://features.gamesheet.io/api/frontend",
    },
    apps: {
        ...prod.apps
    },
}

export const config = (process.env.REACT_APP_LOCAL === "true") ? prodLocal : prod;