import styled from "styled-components";
import { Modal, Button } from "@gamesheet/ui";
import { useResetPassword } from "./useResetPassword";
import {
  FormFieldType,
  NewFormField,
  useCreateForm,
} from "@/forms/hooks/useCreateForm";
import { PasswordInput } from "@/forms/components/PasswordInput";
import { BreakPoints } from "@/libs/breakpoints";
import { ErrorCard } from "@/components/pure/EntityCard/MobileEntityCard";

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgb(0, 0, 0, 0.5);
  z-index: 5200;

  .gs-modal {
    width: 30rem;
  }

  @media only screen and (max-width: ${BreakPoints.md}) {
    .gs-modal .gs-modal-content {
      padding-bottom: 0;
    }
  }
`;

const Divider = styled.div`
  border: 1px solid #d7d7d8;
  margin-bottom: 1rem;
  margin-left: -1rem;
  margin-right: -1rem;
`;

const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .button {
    font-size: 1rem !important;
    font-weight: 500 !important;
  }

  @media only screen and (max-width: ${BreakPoints.md}) {
    .button {
      margin-bottom: 1rem;
    }
  }


  
`;

const ContentContainer = styled.div`
  > div {
    padding-bottom: 1rem;
    width: 100%;
  }
`;

export type ResetPasswordFormState = {
  password: FormFieldType<string>;
};

export function ResetPassword() {
  const { isOpen, close, loading, onSubmit, error, clearError } = useResetPassword();

  const { state: formState, onChange: _onChange } = useCreateForm("resetPassword", {
    password: NewFormField(
      "",
      /.{8,}/,
      "Password must be at least 8 characters"
    ),
  });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    _onChange(e);
    clearError();
  }

  if (!isOpen) {
    return <></>;
  }

  return (
    <ModalContainer>
      <Modal title="Update your password" show={isOpen} onClose={close}>
        <ContentContainer>
          <p>Please enter your new password below.</p>
          <PasswordInput
            state={formState.password}
            name="password"
            label="Password"
            onChange={onChange}
            autocomplete={false}
          />
        </ContentContainer>

        {error && (<>
          <ErrorCard title="" message={error} />
          <div style={{ "marginBottom": "1rem" }} />
        </>)}

        <Divider />

        <FooterContainer>
          <Button variant="inverted" label="Cancel" onClick={close}>
            Cancel
          </Button>
          {formState.password.valid ? (
            <Button onClick={() => onSubmit(formState)}>Update Password</Button>
          ) : (
            <Button
              state="disabled"
              variant="inverted"
              label="Update Password"
            />
          )}
        </FooterContainer>
      </Modal>
    </ModalContainer>
  );
}
