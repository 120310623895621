import { useEffect, useRef } from 'react';

// TypeScript type for the callback function
type CallbackFunction = (...args: any[]) => void;

// useDebounce hook typed with TypeScript
export function useDebounce(callback: CallbackFunction, delay: number): CallbackFunction {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  function debouncedFunction(...args: any[]) {
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      callback(...args);
    }, delay);
  }

  useEffect(() => {
    return () => {
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return debouncedFunction;
}