import React, { useRef } from 'react';
import { InstantSearch } from 'react-instantsearch-dom';
import { SearchBoxProvided } from 'react-instantsearch-core';
import algoliasearch from 'algoliasearch/lite';
import { RecoilRoot } from 'recoil';

import QueryInput from './components/QueryInput/QueryInput';
import SearchResults from './components/SearchResults/SearchResults';

const searchClient = algoliasearch('13DJIXLJVR', '1fcb9fdc5f04ff7825b431fa559e3066');

const ScheduledGameLocationInput: React.FC<{
  location: string;
  setLocation: (location: string) => void;
  clear: number;
  isDisabled?: boolean;
}> = ({ location, setLocation, clear, isDisabled }) => {

  return (
      <InstantSearch searchClient={searchClient} indexName="LIVEBARN_SURFACES" >
        <QueryInput value={location} setValue={setLocation} clear={clear} isDisabled={isDisabled} />
        <SearchResults />
      </InstantSearch>
  );
};

export default ScheduledGameLocationInput;
