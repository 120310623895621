import { useCallback, useEffect } from "react";
import { atom, useRecoilState } from "recoil";
import { useAppState } from "@/state/app/useAppState";
import { useUserState } from "@/state/user/useUserState";
import { useTeamsData } from "@/state/data/useTeams";

type EditEmailNotificationsModal = {
  loading: boolean;
  isOpen: boolean;
};

export const emailNotificationsModalState = atom<EditEmailNotificationsModal>({
  key: "emailNotificationsModalState",
  default: {
    loading: false,
    isOpen: false,
  },
});

export function useEditEmailNotifications() {
  const app = useAppState();
  const user = useUserState();
  const teams = useTeamsData().all();

  const [{ loading, isOpen }, setState] = useRecoilState(emailNotificationsModalState);

  const open = useCallback(() => {
    setState((prev) => ({
      ...prev,
      isOpen: true,
    }));

    document.body.style.overflow = "hidden";
  }, [setState]);

  const close = useCallback(() => {
    setState((prev) => ({
      ...prev,
      isOpen: false,
    }));

    document.body.style.overflow = "auto";
  }, [setState]);

  const handlePostGameEmailUnsubscribe = async (prototeamId: string) => {
    const url = `${app.config.gateways.eventLog}/v5/teams/${prototeamId}/post-game-email/unsubscribe`;
    const headers = new Headers();
    user.isLoggedIn && headers.set("Authorization", `Bearer ${user.tokens.access}`);

    const requestData = {
      attributes: {
        event: "remove-subscription",
        schema: "user-prototeam",
        version: "v1",
      },
      data: {
        userId: user.id,
        prototeamId,
      },
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers,
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        // state is handled locally in the component
      } else {
        console.error("Error while updating team data:", response);
      }
    } catch (error) {
      console.error("Error while updating team data:", error);
    }
  };

  const handlePostGameEmailSubscribe = async (prototeamId: string) => {
    const url = `${app.config.gateways.eventLog}/v5/teams/${prototeamId}/post-game-email/subscribe`;
    const headers = new Headers();
    user.isLoggedIn && headers.set("Authorization", `Bearer ${user.tokens.access}`);

    const requestData = {
      attributes: {
        event: "add-subscription",
        schema: "user-prototeam",
        version: "v1",
      },
      data: {
        userId: user.id,
        prototeamId,
      },
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers,
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        // state is handled locally in the component
      } else {
        console.error("Error while updating team data:", response);
      }
    } catch (error) {
      console.error("Error while updating team data:", error);
    }
  };

  const handleGameLineupSubscribe = async (prototeamId: string) => {
    const url = `${app.config.gateways.eventLog}/v5/teams/${prototeamId}/lineup-notifications/subscribe`;
    const headers = new Headers();
    user.isLoggedIn && headers.set("Authorization", `Bearer ${user.tokens.access}`);

    const requestData = {
      attributes: {
        event: "subscribe-lineup-notifications",
        schema: "user-prototeam",
        version: "v1",
      },
      data: {
        userId: user.id,
        prototeamId,
      },
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers,
        body: JSON.stringify(requestData),
      });

      if (!response.ok) {
        console.error("Error while subscribing to game lineup notifications:", response);
      }
      // Additional logic for handling a successful subscription can go here
    } catch (error) {
      console.error("Error while subscribing to game lineup notifications:", error);
    }
  };

  const handleGameLineupUnsubscribe = async (prototeamId: string) => {
    const url = `${app.config.gateways.eventLog}/v5/teams/${prototeamId}/lineup-notifications/unsubscribe`;
    const headers = new Headers();
    user.isLoggedIn && headers.set("Authorization", `Bearer ${user.tokens.access}`);

    const requestData = {
      attributes: {
        event: "unsubscribe-lineup-notifications",
        schema: "user-prototeam",
        version: "v1",
      },
      data: {
        userId: user.id,
        prototeamId,
      },
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers,
        body: JSON.stringify(requestData),
      });

      if (!response.ok) {
        console.error("Error while unsubscribing from game lineup notifications:", response);
      }
      // Additional logic for handling a successful unsubscription can go here
    } catch (error) {
      console.error("Error while unsubscribing from game lineup notifications:", error);
    }
  };

  const handleToggleAllTeams = async (checked: boolean, emailTeamIds: string[], entitledTeamIds: string[]) => {
    const emailPromises = emailTeamIds.map((teamId) => (checked ? handlePostGameEmailSubscribe(teamId) : handlePostGameEmailUnsubscribe(teamId)));

    const lineupPromises = entitledTeamIds.map((teamId) => (checked ? handleGameLineupSubscribe(teamId) : handleGameLineupUnsubscribe(teamId)));

    await Promise.all([...emailPromises, ...lineupPromises]);
  };

  return {
    loading,
    open,
    close,
    isOpen,
    handlePostGameEmailSubscribe,
    handlePostGameEmailUnsubscribe,
    handleToggleAllTeams,
    handleGameLineupSubscribe,
    handleGameLineupUnsubscribe,
  };
}
