import { RosterProps } from "."
import { Title } from "@gamesheet/ui";
import { TabletEntityCard, TitleOnlyTabletEntityCard } from "@/components/pure/EntityCard/TabletEntityCard";
import * as Grid from "@/layouts/GridItems";
import Avatar from "@/assets/images/blank_avatar.svg"
import { useAppState } from "@/state/app/useAppState";
import { addImgSizeToUrl } from "@/libs/addImgSizeToUrl";
import styled from "styled-components"

const StyledTabletRoster = styled.div`
background-color: #fafafa;

.cursor-pointer {
    cursor: pointer;
}
`

type TabletRosterProps = RosterProps;

export function TabletRoster({ roster, onClickCoach = () => { }, onClickPlayer = () => { }, showTitles = true }: TabletRosterProps) {

    const app = useAppState()

    return <StyledTabletRoster>
        <Grid.LayoutGridContainer>

            {showTitles && !!roster.skaters.length && <Grid.Blank $column={1} $row={1}>
                <Title bartype="divider" text={`Players (${roster.skaters.length})`} size="h2" />
            </Grid.Blank>}

            {roster.skaters.map((player, index) => {

                const jersey = player.jersey ? `#${player.jersey}` : "";
                return <Grid.Blank key={index} $column={1} $row={index + 2} onClick={() => onClickPlayer(player)} className="cursor-pointer">
                    <TabletEntityCard
                        isLoading={!player.id}
                        image={player.photoUrl ? addImgSizeToUrl(player.photoUrl, 96) : Avatar}
                        stat={jersey}
                        title={`${player.firstName} ${player.lastName}`}
                        subtitle={player.duty && "(" + app.helpers.translateDuty(player.duty) + ")" || ""}
                        substatOne={app.helpers.translatePosition(player.position, true)}
                        substatTwo={player.affiliated && "Affiliated" || "Regular"}
                    />
                </Grid.Blank>
            })
            }

            {showTitles && !!roster.goalies.length && <Grid.Blank $column={1} $row={roster.skaters.length + 2}>
                <Title bartype="divider" text={`Goalies (${roster.goalies.length})`} size="h2" />
            </Grid.Blank>}

            {roster.goalies.map((player, index) => {

                const jersey = player.jersey ? `#${player.jersey}` : "";
                return <Grid.Blank key={index} $column={1} $row={index + 3 + roster.skaters.length} onClick={() => onClickPlayer(player)} className="cursor-pointer">
                    <TabletEntityCard
                        isLoading={!player.id}
                        image={player.photoUrl ? addImgSizeToUrl(player.photoUrl, 96) : Avatar}
                        stat={jersey}
                        title={`${player.firstName} ${player.lastName}`}
                        subtitle={player.duty && "(" + app.helpers.translateDuty(player.duty) + ")" || ""}
                        substatOne={app.helpers.translatePosition(player.position, true)}
                        substatTwo={player.affiliated && "Affiliated" || "Regular"}
                    />
                </Grid.Blank>
            })
            }

            {showTitles && !!roster?.coaches?.length && <Grid.Blank $column={1} $row={roster.skaters.length + roster.goalies.length + 3}>
                <Title bartype="divider" text={`Coaches (${roster.coaches.length})`} size="h2" />
            </Grid.Blank>}

            {roster?.coaches?.map((coach, index) => {
                return <Grid.Blank key={index} $column={1} $row={index + roster.skaters.length + roster.goalies.length + 4} onClick={() => onClickCoach(coach)} className="cursor-pointer">
                    <TitleOnlyTabletEntityCard
                        isLoading={!coach.id}
                        title={`${coach.firstName} ${coach.lastName}`}
                        subtitle={app.helpers.translatePosition(coach.position, true)}
                    />
                </Grid.Blank>
            })
            }
        </Grid.LayoutGridContainer>
    </StyledTabletRoster>
}