import { Button } from "@gamesheet/ui";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useSignatureState } from "../state/useSignatureState";
import { useAppState } from "@/state/app/useAppState";

const StyledCanvas = styled.canvas`
  touch-action: none;
  height: 150px;
  width: 355px;
  background-color: #f5f5f4;
  border-radius: 0 0 5px 5px;
  box-shadow: 0px 4px 4px 0px rgba(54, 56, 61, 0.4);
`;

const StyledCanvasContainer = styled.div`
  margin-bottom: 0.75rem;
`;

const SignatureHeader = styled.div`
  height: 50px;
  border-radius: 5px 5px 0px 0px;
  background: #36383d;
  color: #f5f5f4;
  font-family: Rubik;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SignHere = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #86888b;
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  pointer-events: none;
`;

const SignaturePad: React.FC = () => {
  const app = useAppState();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const { addPath, clearCanvas, svgData } = useSignatureState();
  const [currentPath, setCurrentPath] = useState("");
  const [hasSignature, setHasSignature] = useState(false);

  useEffect(() => {
    //mobile view and desktop view have different refs. So in order to keep the state the same, when show desktop changes we clear the canvas.
    clearCanvas();
  }, [app.layout.showDesktop]);

  useEffect(() => {
    // Check if there's a signature
    if (svgData) {
      setHasSignature(true);
    } else {
      setHasSignature(false);
    }
  }, [svgData]);

  const getCoordinates = (e: React.MouseEvent<HTMLCanvasElement> | React.TouchEvent<HTMLCanvasElement>) => {
    if (!canvasRef.current) return { offsetX: 0, offsetY: 0 };
    const rect = canvasRef.current.getBoundingClientRect();
    const offsetX = e.nativeEvent instanceof MouseEvent ? e.nativeEvent.clientX : e.nativeEvent.touches[0].clientX;
    const offsetY = e.nativeEvent instanceof MouseEvent ? e.nativeEvent.clientY : e.nativeEvent.touches[0].clientY;
    return { offsetX: offsetX - rect.left, offsetY: offsetY - rect.top };
  };

  const startDrawing = (e: React.MouseEvent<HTMLCanvasElement> | React.TouchEvent<HTMLCanvasElement>) => {
    const { offsetX, offsetY } = getCoordinates(e);
    setCurrentPath(`M ${offsetX} ${offsetY} `);
    setIsDrawing(true);
    setHasSignature(true);

    if (canvasRef.current) {
      const ctx = canvasRef.current.getContext("2d");
      if (ctx) {
        ctx.beginPath();
        ctx.moveTo(offsetX, offsetY);
      }
    }
  };

  const draw = (e: React.MouseEvent<HTMLCanvasElement> | React.TouchEvent<HTMLCanvasElement>) => {
    if (!isDrawing) return;
    const { offsetX, offsetY } = getCoordinates(e);
    setCurrentPath((prev) => `${prev}L ${offsetX} ${offsetY} `);
    if (canvasRef.current) {
      const ctx = canvasRef.current.getContext("2d");
      if (ctx) {
        ctx.lineTo(offsetX, offsetY);
        ctx.stroke();
      }
    }
  };

  const stopDrawing = () => {
    setIsDrawing(false);
    addPath(currentPath);
    setCurrentPath("");
  };

  const clearSignaturePad = () => {
    clearCanvas();
    if (canvasRef.current) {
      const ctx = canvasRef.current.getContext("2d");
      if (ctx) {
        ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
      }
    }
  };

  return (
    <StyledCanvasContainer>
      <SignatureHeader>
        Signature <Button size="lg" label="Clear" onClick={clearSignaturePad} />
      </SignatureHeader>
      <div style={{ position: "relative", width: "355px", height: "150px" }}>
        {!hasSignature && <SignHere>Sign Here</SignHere>}
        <StyledCanvas
          ref={canvasRef}
          width={355}
          height={150}
          onMouseDown={startDrawing}
          onMouseMove={draw}
          onMouseUp={stopDrawing}
          onTouchStart={startDrawing}
          onTouchMove={draw}
          onTouchEnd={stopDrawing}
        />
      </div>
    </StyledCanvasContainer>
  );
};

export default SignaturePad;
