import { useEffect, useState } from "react";
import { collection, query, where, orderBy, limit, getDocs, onSnapshot } from "firebase/firestore";
import { firestore } from "@/libs/firebase";
import { LineupRecord } from "../types";

type Coach = {
  signature: string;
  id: string;
  lastName: string;
  status: string;
  position: string;
  externalId: string;
  firstName: string;
};

type Player = {
  height: string;
  id: string;
  draftedBy: string;
  country: string;
  jersey: string;
  lastName: string;
  position: string;
  weight: string;
  shotHand: string;
  status: string;
  committedTo: string;
  hometown: string;
  affiliated: boolean;
  province: string;
  externalId: string;
  birthDay: string;
  birthYear: string;
  duty: string;
  birthMonth: string;
  photoURL: string;
  firstName: string;
  biography: string;
  starting: boolean;
};

type CreatedAt = {
  seconds: number;
  nanoseconds: number;
};

export type LineupTeam = {
  coaches: { [key: string]: Coach };
  prototeamId: string;
  locked: boolean;
  vs: string;
  players: { [key: string]: Player };
  createdAt: CreatedAt;
};

export function useLatestLineup(gameId?: string, protoTeamId?: string) {
  const [latestLineup, setLatestLineup] = useState<LineupTeam | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!gameId || !protoTeamId) {
      return;
    }
    const q = query(collection(firestore, `games/${gameId}/lineups`), where("prototeamId", "==", protoTeamId), orderBy("createdAt", "desc"), limit(1));

    const unSub = onSnapshot(q, (querySnapshot) => {
      if (querySnapshot.empty) {
        // Query returned no data
        setLatestLineup(null);
        setLoading(false); // Not loading anymore
      } else {
        querySnapshot.forEach(
          (doc) => {
            const data = doc.data();
            setLatestLineup(data as LineupTeam);
            setLoading(false);
          },
          (error: any) => {
            console.error("Error fetching latest lineup:", error);
            setError("Error fetching data");
            setLoading(false); // Ensure loading is set to false even if there's an error
          }
        );
      }
    });

    setError(null);

    return () => {
      unSub();
    };
  }, [gameId, protoTeamId]);

  return { latestLineup, loading, error };
}
