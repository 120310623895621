import styled from "styled-components";
import { Modal, Button } from "@gamesheet/ui";
import { useSaveCoach } from "./useSaveCoach";
import { LineupFlowHook } from "@/flows/LineupFlow/state/useLineupFlow";
import { useLineupData } from "@/flows/LineupFlow/state/useLineup";
import { useRecoilValue } from "recoil";
import { v4 as uuidv4 } from "uuid";
import { coachFormDataState } from "@/flows/LineupFlow/state/atom.coachFormDataState";

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgb(0, 0, 0, 0.5);
  z-index: 5200;
`;

const Text = styled.div`
  font-family: Rubik;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  padding-bottom: 0.75rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
`;

const ConfirmButtonContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  padding-top: 0.5rem;
`;

const Divider = styled.div`
  border: 1px solid #d7d7d8;
  margin: 0 -1rem;
`;

type SavePlayerProps = {
  flow: LineupFlowHook;
};

export function SaveCoach({ flow }: SavePlayerProps) {
  const { isOpen, close } = useSaveCoach();
  const { LocalLineupData } = useLineupData();
  const formData = useRecoilValue(coachFormDataState);

  if (!isOpen) {
    return <></>;
  }

  const handleSaveCoachToRoster = (saveToRoster: "yes" | "no") => {
    const saveToRosterBool = saveToRoster === "yes";

    const lineup = LocalLineupData.get().lineup;
    const { coaches } = lineup;

    let selectedParticipant = null;

    // Loop through all coaches to find the one with the matching ID
    for (const coach of coaches) {
      if (coach.id === formData.id) {
        selectedParticipant = coach;
        break;
      }
    }

    if (selectedParticipant) {
      // Update the selected coach's data
      const isRosterLocked = selectedParticipant.rosterLocked;

      const updatedParticipant = {
        ...selectedParticipant,
        ...formData,
        rosterDataChanged: !isRosterLocked && (selectedParticipant.position !== formData.position || selectedParticipant.rosterDataChanged),
        bioDataChanged: !isRosterLocked && (selectedParticipant.bioDataChanged || selectedParticipant.firstName !== formData.firstName || selectedParticipant.lastName !== formData.lastName),
        isSelected: false,
        saveToRoster: saveToRosterBool,
      };
      // Update the lineup record with the updated coach
      const updatedLineupRecord = {
        ...LocalLineupData.get(),
        lineup: {
          ...lineup,
          coaches: coaches.map((coach) => (coach.id === updatedParticipant.id ? updatedParticipant : coach)),
        },
      };

      LocalLineupData.set(updatedLineupRecord);
    } else {
      // If no coach is found, add the new coach to the lineup
      const newCoach = {
        ...formData,
        id: (parseInt(uuidv4().replace(/-/g, ""), 16) * -1).toString(),
        isSelected: false,
        rosterDataChanged: true,
        bioDataChanged: true,
        new: true,
        saveToRoster: saveToRosterBool,
        status: "coaching",
      };

      // Update the lineup record with the new coach
      const updatedLineupRecord = {
        ...LocalLineupData.get(),
        lineup: {
          ...lineup,
          coaches: [...coaches, newCoach],
        },
      };

      LocalLineupData.set(updatedLineupRecord);
    }

    close();
    flow.useCoachPath();
  };

  return (
    <ModalContainer>
      <Modal title="Save Changes" show={isOpen} onClose={close}>
        <Text>
          How would you like to save your changes to {formData.firstName} {formData.lastName}
        </Text>
        <Divider />
        <ButtonContainer>
          <ConfirmButtonContainer>
            <Button size="lg" label="Save for this game only" onClick={() => handleSaveCoachToRoster("no")} />
            <Button
              variant={LocalLineupData.getSelectedPlayerGoalieOrCoach()?.data.rosterLocked ? "muted" : ""}
              size="lg"
              label="Save for this and future games"
              onClick={LocalLineupData.getSelectedPlayerGoalieOrCoach()?.data.rosterLocked ? () => {} : () => handleSaveCoachToRoster("yes")}
            />
          </ConfirmButtonContainer>

          <Button size="lg" variant="inverted" label="Cancel" onClick={close} />
        </ButtonContainer>
      </Modal>
    </ModalContainer>
  );
}
