const VALID_TYPES = ["player", "coach"];

export default function validateType({ type }: { type: string }) {
  switch (true) {
    case type === "": {
      return [{ field: "type", error: "blank" }];
    }
    case !VALID_TYPES.includes(type): {
      return [{ field: "type", error: "invalid" }];
    }
    default: {
      return [];
    }
  }
}
