import PropTypes from "prop-types";

export type ShowIfProps = {
  cond: any;
  value?: any;
  component?: any;
  children?: any;
};

export function ShowIf({ cond, value, children, component }: ShowIfProps) {
  return <>{cond === value ? component || children : <></>}</>;
}

ShowIf.propTypes = {
  cond: PropTypes.any.isRequired,
  value: PropTypes.any,
  component: PropTypes.node,
};

ShowIf.defaultProps = {
  value: true,
};
