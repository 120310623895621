import React from "react";
import styled from "styled-components";
import { MobileEntityCard } from "@/components/pure/EntityCard/MobileEntityCard";
import CardAction from "@/components/pure/EntityCard/CardAction";
import { Title, UserNotice } from "@gamesheet/ui";
import { useLineupData } from "./state/useLineup";
import { LineupFlowHook } from "./state/useLineupFlow";
import { useAppState } from "@/state/app/useAppState";
import { usePlayerNotParticipating } from "@/windows/lineups/playerNotParticipating/usePlayerNotParticipating";
import { ExtendedCoachFormData } from "./types";
import { BreakPoints } from "@/libs/breakpoints";

const StyledCoachesStep = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: 100%;
  padding-top: 1rem;

  @media screen and (min-width: ${BreakPoints.lg}) {
    padding-top: 0;
  }
`;

const MobileEntityCardWrapper = styled.div`
  cursor: pointer;
  width: 100%;
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  max-width: 35rem;
`;

type CoachStepProps = {
  flow: LineupFlowHook;
};

export const CoachesStep: React.FC<CoachStepProps> = ({ flow }) => {
  const app = useAppState();
  const { LocalLineupData } = useLineupData();
  const { open: openPlayerNotParticipatingModal } = usePlayerNotParticipating();

  const coachesData = LocalLineupData.get().lineup.coaches || [];

  const handleCoachCardClick = (coachId: string) => {
    // Update the selected coach's isSelected property to true
    const updatedCoaches = LocalLineupData.get().lineup.coaches.map((coach) => {
      if (coach.id === coachId) {
        return { ...coach, isSelected: true };
      }
      return coach;
    });

    // Update the local lineup data with the updated coaches list
    LocalLineupData.set({
      ...LocalLineupData.get(),
      lineup: {
        ...LocalLineupData.get().lineup,
        coaches: updatedCoaches,
      },
    });

    flow.useAddCoachPath();
  };

  const handleCoachActionClick = (coachId: string, setToCoaching: boolean) => {
    if (setToCoaching) {
      LocalLineupData.setPlayingStatusById(coachId);
    } else {
      LocalLineupData.selectPlayerGoalieOrCoachById(coachId, "coaches");
      openPlayerNotParticipatingModal();
    }
  };

  const sanitizeStatus = (coach: ExtendedCoachFormData) => {
    if (!coach) return "";
    const { status, suspension } = coach;
    switch (status) {
      case "coaching":
        return "Coaching";
      case "suspended":
        return `Suspended ${suspension?.number || 0} of ${suspension?.length || 0}`;
      default:
        return status;
    }
  };

  return (
    <StyledCoachesStep>
      <Column>
        <Title bartype="divider" size="h2" text={`Coaches (${coachesData.length})`} />
        {coachesData.map((coach, index) => (
          <UserNotice display={!!coach?.errorMessage} type="error" msg={coach?.errorMessage || ""} key={index}>
            <div style={{ display: "flex", gap: "1rem" }}>
              <MobileEntityCardWrapper onClick={() => handleCoachCardClick(coach.id)}>
                <MobileEntityCard title={`${coach.firstName} ${coach.lastName}`} subtitle={app.helpers.translatePosition(coach.position, true)} reverse />
              </MobileEntityCardWrapper>
              <CardAction
                active={coach.status == "coaching"}
                onClick={() => handleCoachActionClick(coach.id, coach.status !== "coaching")}
                text={coach.status !== "coaching" ? sanitizeStatus(coach) : "coaching"}
              />
            </div>
          </UserNotice>
        ))}
      </Column>
    </StyledCoachesStep>
  );
};
