// SearchResults.tsx

import React from 'react';
import { connectInfiniteHits } from 'react-instantsearch-dom';
import { InfiniteHitsProvided } from 'react-instantsearch-core';
import styled from 'styled-components';

import useSearchResults from './useSearchResults';
import { Button } from '@gamesheet/ui';

type Surface = {
  name: string;
  id: string;
  compositeKey: string;
};

type Venue = {
  name: string;
  city: string;
  province: string;
  postalCode: string;
  surfaces: Surface[];
};

type SearchResultsProps = InfiniteHitsProvided & {
  hits: Venue[];
};

const StyledSearchResults = styled.div`
  position: relative;

  .location-query-results-box {
    position: absolute;
    padding: 10px;
    max-height: 400px;
    overflow-y: scroll;
    left: 0;
    right: 0;
    background-color: #FFFFFF;
    border: 1px solid #000000;
    border-top: none;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    z-index: 3;
  }

  .surface {
    cursor: pointer;
    color: #0000FF;
  }
`;

const SearchResults: React.FC<SearchResultsProps> = ({ hits, hasPrevious, refinePrevious, hasMore, refineNext }) => {
  const { venues, setLocation } = useSearchResults({ hits });

  return (
    <StyledSearchResults>
      {venues.length > 0 && (
        <div className="location-query-results-box">
          {hasPrevious && (
            <Button disabled={!hasPrevious} onClick={refinePrevious} label='Show previous'/>
          )}
          {venues.map((venue) => {
            const surfaces = (
              <ul>
                {venue.surfaces.map((surface) => (
                  <li
                    className="surface"
                    onClick={() => setLocation(surface.compositeKey)}
                    key={surface.id}
                  >
                    {surface.name}
                  </li>
                ))}
              </ul>
            );

            return (
              <div
                className="venue"
                key={`${venue.name}-${venue.postalCode}`}
              >
                <span style={{ fontWeight: 500 }}>
                  {venue.name}, {venue.city}, {venue.province}, {venue.postalCode}
                </span>
                {surfaces}
              </div>
            );
          })}
          {hasMore && (
            <Button disabled={!hasMore} onClick={refineNext} label='Show more'/>
          )}
        </div>
      )}
    </StyledSearchResults>
  );
};

export default connectInfiniteHits(SearchResults);
