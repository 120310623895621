import { atom } from 'recoil';

export const locationQueryState = atom({
  key: 'locationQuery',
  default: '',
});

export const locationInputFocusState = atom({
  key: 'locationInputFocus',
  default: false,
});
