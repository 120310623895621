import { selectedTeamSportPositionsOptionsData, selectedTeamSportCoachPositionsOptionsData } from "@/state/data/useSeasonTeams";
import { useRecoilValue } from "recoil";

export function useTranslatePosition() {
    const positions = useRecoilValue(selectedTeamSportPositionsOptionsData);
    const coachPositions = useRecoilValue(selectedTeamSportCoachPositionsOptionsData);

    return (position: string, returnTitle = false, defaultReturn = "-"): string => {

        const foundPosition = positions.find(p => p.key === position);
        if (foundPosition) {
            return returnTitle ? foundPosition.title : foundPosition.abbr.toUpperCase();
        }

        const foundCoachPosition = coachPositions.find(p => p.key === position);
        if (foundCoachPosition) {
            return returnTitle ? foundCoachPosition.title : foundCoachPosition.abbr.toUpperCase();
        }
        
        // keep this as fallback in case positions can't be loaded dynamically
        switch (position?.toLowerCase()) {
            case "goalie":
                return returnTitle ? "Goalie" : "G";
            case "forward":
                return returnTitle ? "Forward" : "F";
            case "centre":
            case "center":
                return returnTitle ? "Center" : "C";
            case "defence":
            case "defense":
                return returnTitle ? "Defense" : "D";
            case "left_wing":
                return returnTitle ? "Left Wing" : "LW";
            case "transition":
                return returnTitle ? "Transition" : "T";
            case "right_wing":
                return returnTitle ? "Right Wing" : "RW";
            case "midfield":
                return returnTitle ? "Midfield" : "M";
            case "attack":
                return returnTitle ? "Attack" : "A";
            case "head_coach":
                return returnTitle ? "Head Coach" : "HC";
            case "assistant_coach":
                return returnTitle ? "Assistant Coach" : "AC";
            case "manager":
                return returnTitle ? "Manager" : "MGR";
            case "trainer":
                return returnTitle ? "Trainer" : "TR";
            case "assistant_trainer":
                return returnTitle ? "Assistant Trainer" : "ATR";
            case "head_coach_at_large":
                return returnTitle ? "Head Coach at Large" : "HCL";
            case "assistant_coach_at_large":
                return returnTitle ? "Assistant Coach at Large" : "ACL";
            case "trainer_at_large":
                return returnTitle ? "Trainer at Large" : "TRL";
            default:
                return defaultReturn;
        }
    };
};

export function translateDuty(position: string, returnTitle = false): string {
    switch (position?.toLowerCase()) {
        case "captain":
            return returnTitle ? "Captain" : "C";
        case "alternate_captain":
            return returnTitle ? "Alternate Captain" : "A";
        default:
            return "";
    }
};

export function translateShotHand(shotHand: string, returnTitle = false, defaultReturn = "-"): string {
    switch (shotHand?.toLowerCase()) {
        case "left":
            return returnTitle ? "Left" : "L";
        case "right":
            return returnTitle ? "Right" : "R";
        default:
            return defaultReturn;
    }
}