import { SetterOrUpdater } from "recoil";
import { GlobalPageOptions } from "./atom.pagestates";
import { ActionType, PageSessionState } from "./reducer.pagesession";
import { PageOptions } from "./type.options.";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppState } from "../app/useAppState";
import { StyledFloatingMenu, Menu } from "./styles";
import { Button } from "@gamesheet/ui";
import { Overlay } from "@/pages/team.roster/styles";
import { useAppNavigation } from "@/hooks/useAppNavigation";

const Back = ({ handler, text }:{ handler: () => void, text?: string }, ) => {
    return <Button backgroundColor="#343a40" textColor="white" size="lg" key="back" onClick={handler}>{text || "Back"}</Button>
}

export function usePageButtons(
    options:PageOptions
){
    const app = useAppState()
    const { navigate, destination, handleNavigation, hasNavigation } = useAppNavigation();
    
    // open / close state of actions menu
    const [ showActions, setShowActions ] = useState(false)
    const open = useCallback(() => { setShowActions(true); app.menu.open() }, [setShowActions, app.menu.open])
    const close = useCallback(() => { setShowActions(false); app.menu.close() }, [setShowActions, app.menu.close])

    useEffect(() => {
        const currentUrl = window.location.pathname;
        if (currentUrl !== "/roster/team") {
            close()
        }
    }, [window.location.pathname]);

    // close the actions menu when the mobile layout is shown
    useEffect(() => { app.layout.showMobile && close(); }, [])

    const makeNavHandler = useCallback((destination:string) => {
        const navigate = handleNavigation(destination);

        return () => {
            app.menu.close();
            navigate();
        };
    }, [app.menu.close, handleNavigation]);

    // controls to add buttons to the actions menu
    const [ actions, setActions ] = useState<React.ReactElement[]>([])
    const clearButtons = useCallback(() => setActions([]), [setActions])
    const addNavButton = useCallback((destination:string, label:string) => setActions(a => [...a, <Button size="lg" key={label} onClick={makeNavHandler(destination)} variant="inverted">{label}</Button>]), [setActions, makeNavHandler])
    const addButton = useCallback((handler: () => void, label: string) => setActions(a => [...a, <Button size="lg" key={label} onClick={handler} variant="inverted">{label}</Button>]), [setActions])

    // actions menu
    // removing this useMemo creates a "maximum update depth exceeded" error
    const buttons = useMemo(() => <div style={{position:'relative'}}>
        <StyledFloatingMenu>
            { showActions || <Button size="lg" onClick={open}>Actions</Button> }
            { showActions && <>
                <Menu $dropup={app.layout.showMobile ? 1 : 0} >
                    { actions }
                </Menu>
                <Button size="lg" onClick={close}>Close</Button>
            </>}
        </StyledFloatingMenu>
        <Overlay $open={showActions} onClick={close} />
    </div>, [ showActions, app.layout.showMobile ])

    // primary action button
    // cannot be used with the actions menu
    const [ PrimaryButton, _setPrimaryButton ] = useState<React.ReactElement>()
    const setPrimaryButton = useCallback((label:string, handler:() => void, disabled=false) => {
        _setPrimaryButton(<Button 
            size="lg"
            disabled={disabled}
            variant={disabled ? "muted" : ""} 
            onClick={() => !disabled && handler()}>
                {label}
            </Button>
        )
    }, [_setPrimaryButton]);

    //back button
    const [ showBackButton, setShowBackButton ] = useState<boolean>(options.showBackButton)
    const [ backButtonText, setBackButtonText ] = useState<string>(options.backButtonText || "Back")

    // add buttons to tray
    useEffect(() => showBackButton ? app.ui('leftTray').set(<Back handler={options.backButtonMethod} text={backButtonText} />) : ()=>{}, [ showBackButton, backButtonText ])
    useEffect(() => options.showActionsMenu ? app.ui('actionTray').set(buttons) : ()=>{}, [ buttons, options.showActionsMenu ])
    useEffect(() => !options.showActionsMenu && PrimaryButton ? app.ui('rightTray').set(PrimaryButton) : ()=>{}, [ PrimaryButton, options.showActionsMenu ])

    const out = useMemo(() => ({
        clearButtons,
        addNavButton,
        addButton,
        setPrimaryButton,
        setBackButtonText,
        showBackButton: () => setShowBackButton(true),
        navigation: {
            hasNavigation,
            destination,
            navigate
        },
        closeActionMenu: close,
    }), [ clearButtons, addNavButton, addButton, setPrimaryButton, setBackButtonText, showBackButton, hasNavigation, destination, navigate, close ])

    return out;
}