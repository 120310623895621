import styled from "styled-components";
import { Modal, Button } from "@gamesheet/ui";
import { BreakPoints } from "@/libs/breakpoints";
import { useDeleteRepeatingEvent } from "./useDeleteRepeatingEvent";
import { useAppState } from "@/state/app/useAppState";
import { PageLoading } from "@/components/pure/PageLoading";
import { useRecoilValue } from "recoil";
import { deleteRepeatingEventModalState } from "./useDeleteRepeatingEvent";

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgb(0, 0, 0, 0.5);
  z-index: 5200;

  .gs-modal {
    margin: 0 1rem;
    width: 30rem;
  }

  .gs-modal-content {
    padding: 0 !important;
  }

  @media only screen and (max-width: ${BreakPoints.md}) {
    .gs-modal .gs-modal-content {
      padding-bottom: 0;
    }
  }
`;

const Divider = styled.div`
  border: 1px solid #d7d7d8;
  margin-bottom: 1rem;
`;
const InfoText = styled.div`
 color:  #404247;
font-family: Rubik;
font-size: 1rem;
font-style: italic;
font-weight: 400;
line-height: normal;
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem 1rem 1rem;
  gap: 0.5rem;

  .button {
    font-size: 1rem !important;
    font-weight: 500 !important;
    width: 100%;
  }

  @media only screen and (max-width: ${BreakPoints.md}) {
    flex-wrap: wrap-reverse;
  }
`;

const Header = styled.div`
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const ContentContainer = styled.div`
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export function DeleteRepeatingEventModal() {
  const { isOpen, close, onDeleteOccurrence } = useDeleteRepeatingEvent();
  const app = useAppState();
  const showLoading = !app.hasSelectedTeam || !app.loading.isComplete('events')
  const eventData = useRecoilValue(deleteRepeatingEventModalState).eventData;
  const recurring = (eventData?.eventGroup?.value?.length || 0) > 0

  const modalTitle = recurring ? "Confirm Recurring Event Deletion" : "Confirm Event Deletion";

  if (showLoading) {
    return <PageLoading />
  }

  if (!isOpen) {
    return <></>;
  }

  return (
    <ModalContainer>
      <Modal title={modalTitle} show={isOpen} onClose={close}>
        <ContentContainer>
          { recurring && <Header>You are deleting a repeating event.</Header> }
          { recurring && <InfoText>Do you want to delete only this occurrence of the event, this and all future occurrences, or all occurrences?</InfoText>}
          { !recurring && <InfoText>Are you sure you want to delete this event? This is a permanent action.</InfoText> }
        </ContentContainer>

        <Divider />
        <FooterContainer>
          { !recurring && <Button variant="danger" label="Confirm delete" onClick={() => onDeleteOccurrence("single")} />}
          { recurring && <Button variant="danger" label="Delete only this event" onClick={() => onDeleteOccurrence("single")} />}
          { recurring && <Button variant="danger" label="Delete all future events" onClick={() => onDeleteOccurrence("future")} />}
          { recurring && <Button variant="danger" label="Delete all" onClick={() => onDeleteOccurrence("all")} />}
          <Button style={{ marginTop: "10px" }} variant="inverted" label="Cancel" onClick={close} />
        </FooterContainer>
      </Modal>
    </ModalContainer>
  );
}
