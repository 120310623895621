import { atom, selector } from "recoil";
import { ExtendedPlayerFormData } from "../types";
import { PlayerFormData } from "@/state/data/usePlayerData";
import { selectedPersonState } from "./atom.lineupFlowState";
import { LineupDataState } from "./useLineup";

export const playerFormDataState = atom({
  key: "playerFormDataState",
  default: {
    id: "",
    firstName: "",
    lastName: "",
    photoURL: "",
    affiliated: false,
    status: "playing",
    jersey: "",
    duty: "",
    position: "",
    birthMonth: "",
    birthDay: "",
    birthYear: "",
    weight: "",
    height: "",
    shotHand: "",
    country: "",
    province: "",
    hometown: "",
    draftedBy: "",
    committedTo: "",
    biography: "",
    rosterLocked: false,
    teamId: "",
  } as PlayerFormData,
});

export const playerFormValidityState = selector<boolean>({
  key: "playerFormValidityState",
  get: ({ get }) => {
    const playerFormData: PlayerFormData = get(playerFormDataState);
    const { firstName, lastName, jersey } = playerFormData;
    return firstName.length > 0 && lastName.length > 0;
  },
});

export const playerFormIsDifferentState = selector<boolean>({
  key: "playerFormIsDifferentState",
  get: ({ get }) => {
    const playerFormData = get(playerFormDataState);
    const selectedPerson = get(selectedPersonState) as ExtendedPlayerFormData | undefined;

    if (!selectedPerson) {
      return true;
    }

    // Iterate over the properties of playerFormData and compare them with selectedPerson
    for (const key in playerFormData) {
      if (playerFormData.hasOwnProperty(key)) {
        if (playerFormData[key as keyof PlayerFormData] !== selectedPerson[key as keyof PlayerFormData]) {
          return true;
        }
      }
    }

    return false;
  },
});

export const playersAndGoaliesWithEmptyJerseySelector = selector({
  key: "playersAndGoaliesWithEmptyJerseySelector",
  get: ({ get }) => {
    const lineupData = get(LineupDataState);
    const { players, goalies } = lineupData.localLineupRecord.lineup;

    const playersWithEmptyJerseyAndActive = players.filter((player) => player.jersey === "" && (player.status === "playing" || player.status === "starting"));

    const goaliesWithEmptyJerseyAndActive = goalies.filter((goalie) => goalie.jersey === "" && (goalie.status === "playing" || goalie.status === "starting"));

    const names = [
      ...playersWithEmptyJerseyAndActive.map((player) => `${player.firstName} ${player.lastName}`),
      ...goaliesWithEmptyJerseyAndActive.map((goalie) => `${goalie.firstName} ${goalie.lastName}`),
    ];

    return names;
  },
});

export const playingGoalieIdsSelector = selector<string[]>({
  key: "playingGoalieIdsSelector",
  get: ({ get }) => {
    const lineupData = get(LineupDataState);
    const { goalies } = lineupData.localLineupRecord.lineup;

    // Filter goalies with status "playing" and extract their IDs
    const playingGoalieIds = goalies.filter((goalie) => goalie.status === "playing").map((goalie) => goalie.id);

    return playingGoalieIds;
  },
});

export const goalieHasStartingValueTrueSelector = selector<boolean>({
  key: "goalieHasStartingValueTrueSelector",
  get: ({ get }) => {
    const lineupData = get(LineupDataState);
    const { goalies } = lineupData.localLineupRecord.lineup;

    // Check if any goalie has starting value true
    const hasStartingValueTrue = goalies.some((goalie) => goalie.starting);

    return hasStartingValueTrue;
  },
});
