import { PageLoading } from "@/components/pure/PageLoading";
import { SignupForm, SignupFormState } from "@/forms/SignupForm";
import { useAppState } from "@/state/app/useAppState";
import { Button, Title } from "@gamesheet/ui";
import { useCallback, useEffect, useMemo, useRef } from "react";

type RegisterNewAccountProps = {
    onAccept: (data:SignupFormState) => void;
    onBack: () => void;
    error?: string;
}

export function RegisterNewAccount({ onAccept, onBack, error }:RegisterNewAccountProps){

    const app = useAppState()
    const formRef = useRef<{ submitForm: () => void | null, loading: boolean }>(null)
    const submitForm = useCallback(() => {
        formRef.current?.submitForm()
    }, [ formRef.current ])
    
    const BtnBack = useMemo(() => <Button type="button" size="lg" onClick={onBack}>Back</Button>, [ onBack ])
    useEffect(() => app.ui('leftTray').set(BtnBack), [ BtnBack ])

    const BtnAccept = useMemo(() => <Button type="button" size="lg" onClick={submitForm}>Create Account</Button>, [ submitForm ])
    useEffect(() => app.ui('rightTray').set(BtnAccept), [ BtnAccept ])

    if(formRef.current?.loading){
        return <PageLoading />
    }

    return <>
        <div style={{ marginTop:'15px'}}>
            <SignupForm ref={formRef} onSubmit={onAccept} error={error} />
        </div>
    </>

}