import { useCallback, useState, useEffect } from "react";
import { atom, useRecoilState } from "recoil";
import { useAppState } from "@/state/app/useAppState";
import { ResetPasswordFormState } from ".";
import { auth } from "@/libs/firebase"
import { setPersistence, browserLocalPersistence, signInWithCustomToken } from "firebase/auth"


type ResetPasswordModal = {
  loading: boolean;
  isOpen: boolean;
};

export const resetPasswordModalState = atom<ResetPasswordModal>({
  key: "resetPasswordModalState",
  default: {
    loading: false,
    isOpen: false,
  },
});

export function useResetPassword() {

  const app = useAppState();

  const [{ loading, isOpen }, setState] = useRecoilState(resetPasswordModalState);

  const [code, setCode] = useState<string>("");
  const [userId, setUserId] = useState<string>("");
  const [error, setError] = useState<string>("");

  useEffect(() => {
    let mounted = true;
    const getUrlParams = () => {
      const params = new URLSearchParams(window.location.search);
      const code = decodeURIComponent(params.get("code") || "");

      const [codeParam, userIdParam] = atob(code).split("|");

      if (codeParam) {
        mounted && setCode(codeParam);
      }

      if (userIdParam) {
        mounted && setUserId(userIdParam);
      }

      // Update hasValues state
      mounted &&
        !!codeParam &&
        !!userIdParam &&
        setState((prev) => ({
          ...prev,
          isOpen: true,
        }));
      mounted && params.delete("code");
      mounted && params.delete("userId");

      // clear the query params and update the URL
      mounted && window.history.replaceState({}, "", `${window.location.pathname}?${params.toString()}`);
    };

    // Call the function when the component mounts
    mounted && getUrlParams();

    // Cleanup the event listener on component unmount
    return () => {
      mounted = false;
    };
  }, []);

  const open = useCallback(() => {
    setState((prev) => ({
      ...prev,
      isOpen: true,
    }));

    document.body.style.overflow = "hidden";
  }, [setState]);

  const close = useCallback(() => {
    setState((prev) => ({
      ...prev,
      isOpen: false,
    }));

    document.body.style.overflow = "auto";
  }, [setState]);

  const onSubmit = useCallback(
    async ({ password }: ResetPasswordFormState) => {
      if (!password) {
        return;
      }

      const url = `${app.config.gateways.auth}/auth/v4/reset-password`;
      const body = {
        userId: parseInt(userId),
        code,
        plainPassword: password.value,
      };

      const headers = new Headers();
      headers.append("Content-Type", "application/json");

      fetch(url, {
        method: "POST",
        headers,
        body: JSON.stringify(body),
      }).then(response => {
        if (!response.ok) {
          console.error("Error while reseting password");
          setError("Error while reseting password");
        }

        response.json().then(data => {
          setPersistence(auth, browserLocalPersistence);
          signInWithCustomToken(auth, data.firebase);
          close();
        })
      }).catch(error => {
        console.error("Error while reseting password", error);
        setError("Error while reseting password");
      });
    },
    [code, userId]
  );

  return {
    loading,
    open,
    close,
    isOpen,
    onSubmit,
    error,
    clearError: () => setError(""),
  };
}

