import { atom, selector } from "recoil";
import { LineupFlowPath, LineupFlowType } from "../types";
import { LineupDataState } from "./useLineup";

export const LineupFlowState = atom<LineupFlowType>({
    key: 'LineupFlowState',
    default: {
        path: LineupFlowPath.Player,
    }
});


export const selectedPersonState = selector({
    key: "selectedPersonState",
    get: ({ get }) => {
      const lineupData = get(LineupDataState);
      const { players, goalies, coaches } = lineupData.localLineupRecord.lineup;
  
      const selectedPlayer = players.find((player) => player.isSelected);
      const selectedGoalie = goalies.find((goalie) => goalie.isSelected);
      const selectedCoach = coaches.find((coach) => coach.isSelected);
  
      return selectedPlayer || selectedGoalie || selectedCoach || null;
    },
  });