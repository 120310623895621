import { useAppState } from "@/state/app/useAppState";
import { Firestore, collection, onSnapshot, query } from "firebase/firestore";
import { useEffect, useReducer } from "react";
import { handleRosterState } from "./reducer.handleRostserState";
import { listenToRoster } from "./func.listenToRoster";
import { StoredRoster } from "./types";

export function listenToSeasonTeamRoster( 
    firestore: Firestore,
    onRosterChange: (roster:Partial<StoredRoster>) => void,
    prototeamId: string,
    seasonTeamId: string
){
    
    // guard
    if(!prototeamId || !seasonTeamId) return () => {}

    // declarations
    const playersQuery = query(collection(firestore, `teams/${prototeamId}/seasonteams/${seasonTeamId}/players`));
    const coachesQuery = query(collection(firestore, `teams/${prototeamId}/seasonteams/${seasonTeamId}/coaches`))
    return listenToRoster(playersQuery, coachesQuery, onRosterChange)

}