import SendIcon from '@mui/icons-material/Send';
import { Button, Title } from "@gamesheet/ui";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import * as Grid from "@/layouts/GridItems";
import { useChat } from "@/state/data/useChat";
import { useUserState } from "@/state/user/useUserState";

const StyledChat= styled.div`
display: flex;
flex-direction: column;
justify-content: flex-end;
padding-bottom: 30px;
min-height: 100vh;
`

const Message = styled.div`
padding-top: 15px;

.sender {
    color: #FFF;
    font-size: 0.8rem;
    padding-top: 0.1rem;
}

.message {
    border-radius: 8px;
    display: inline-block;
    padding: 0.5rem 0.75rem;
}
`

const SentMessage = styled(Message)`
text-align: right;
margin-left: 10%;

.message {
    background-color: #eee;    
}
`

const ReceivedMessage = styled(Message)`
margin-right: 10%;

.message {
    background-color: ${(props) => props.theme.primaryColor};
}
`

const MessageInput = styled.div`
background-color: ${(props) => props.theme.primaryColor}20;
position: fixed;
bottom: 73px;
left: 0;
right: 0;
height: 35px;
padding: 10px;
display: flex;
gap: 10px;
padding-bottom: 45px;

.message {
    display: block;
    flex: 2;
    display: flex;
    
    input {
        display: block;
        font-size: 16px;
        background-color: #fff;
        padding: 5px;
        width: 100%;
        border: 1px solid ${(props) => props.theme.secondaryColor};
        border-radius: 4px;
    }
}
`

export function Chat(){

    const user = useUserState();
    const chat = useChat();
    const [message, setMessage] = useState<string>("");
    const scrollRef = useRef<HTMLDivElement>(null);
    const messages = chat.messages();

    function send(){
        chat.send(message)
        setMessage("")
    }

    function scrollToBottom(){
        if(scrollRef.current) scrollRef.current.scrollIntoView({behavior: "smooth"})
    }

    useEffect(() => {
        scrollToBottom()
    }, [ messages.length ])

    return (<>
        <StyledChat>
        { messages.map((message, index) => {
            
            const details = <>
                <div className="message">{message.message}</div>
                <div className="sender">{message.from.name}</div>
            </>
            
            return (<>
                    { message.from.email == user.email && <SentMessage>{details}</SentMessage> }
                    { message.from.email != user.email && <ReceivedMessage>{details}</ReceivedMessage> }
            </>)

        })}
        <div className="scrollRef" ref={scrollRef}></div>
        </StyledChat>
        
        <MessageInput>
            <div className="message"><input type="text" value={message} onChange={(e)=>setMessage(e.target.value)} /></div>
            <Button inverted='primaryInverted' onClick={send}><SendIcon /></Button>
        </MessageInput>
    </>)


}