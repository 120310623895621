import React, { Fragment, ChangeEvent } from "react";
import { connectSearchBox } from "react-instantsearch-dom";
import { SearchBoxProvided } from "react-instantsearch-core";
import useQueryInput from "./useQueryInput";

interface QueryInputProps extends SearchBoxProvided {
  value: string;
  setValue: (value: string) => void;
  clear: number;
  isDisabled?: boolean;
}

const QueryInput: React.FC<QueryInputProps> = ({ value: startingValue, currentRefinement, setValue, isSearchStalled, refine, clear, isDisabled }) => {
  const queryInputRef = (!!startingValue) ? startingValue : currentRefinement

  const { query, setQuery, gotFocus } = useQueryInput({ value: queryInputRef, setValue, refine, clear });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setQuery(e.currentTarget.value);
  };

  //TODO: isDisabled logic needs to be incorporated.
  return (
    <Fragment>
      <div className={`form-floating`}>
        <input
          required
          type="search"
          name={"location"}
          id={"location"}
          className={`form-control`}
          placeholder={"Location*"}
          onChange={handleChange}
          onFocus={gotFocus}
          value={query}
          disabled={isDisabled}
        />

        <label htmlFor={"location"} className={`floating`}>
          Location*
        </label>
      </div>
    </Fragment>
  );
};

export default connectSearchBox(QueryInput);
