import styled from "styled-components";
import { Modal, Button } from "@gamesheet/ui";
import { useForgotPassword } from "./useForgotPassword";
import { useState } from "react";
import {
  ForgotPasswordForm,
  ForgotPasswordFormState,
} from "@/forms/ForgotPasswordForm";
import { BreakPoints } from "@/libs/breakpoints";

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgb(0, 0, 0, 0.5);
  z-index: 5200;

  .gs-modal {
    margin: 0 1rem;
    width: 30rem;
  }

  .gs-modal-content{
    padding: 0 !important;
  }

  @media only screen and (max-width: ${BreakPoints.md}) {
    .gs-modal .gs-modal-content {
      padding-bottom: 0;
    }
  }
`;

const Divider = styled.div`
  border: 1px solid #d7d7d8;
  margin-bottom: 1rem;
`;
const InfoText = styled.div`
  color: #000;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 1rem 0;
  padding: 0 1rem;
`;

const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem 1rem 1rem;

  .button {
    font-size: 1rem !important;
    font-weight: 500 !important;
  }

  @media only screen and (max-width: ${BreakPoints.md}) {
   flex-wrap: wrap-reverse;
   gap: .5rem;
   .button{
    width: 100%;
   }
  }
`;

const ContentContainer = styled.div`
  padding:  0.5rem 1rem;
`;

export function ForgotPassword() {
  const { isOpen, close, loading, onSubmit } = useForgotPassword();

  const [isValid, setIsValid] = useState(false);

  const [formData, setFormData] = useState<ForgotPasswordFormState>();

  if (!isOpen) {
    return <></>;
  }

  return (
    <ModalContainer>
      <Modal title="Forgot Password" show={isOpen} onClose={close}>
        <ContentContainer>
          <ForgotPasswordForm
            onSubmit={onSubmit}
            setIsValid={setIsValid}
            setFormData={setFormData}
          />
          <InfoText>
            Enter your email above and we'll send you a link to create a new
            password.
          </InfoText>
        </ContentContainer>

        <Divider />

        <FooterContainer>
          <Button variant="inverted" label="Cancel" onClick={close}>
            Cancel
          </Button>
          {isValid && formData ? (
            <Button onClick={() => onSubmit(formData)}>
              Request Password Reset
            </Button>
          ) : (
            <Button
              state="disabled"
              variant="inverted"
              label="Request Password Reset"
            />
          )}
        </FooterContainer>
      </Modal>
    </ModalContainer>
  );
}
