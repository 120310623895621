// PlayerForm.tsx
import { FormWrapper, Title, DateAndTimePicker, TimePicker, Button } from "@gamesheet/ui";
import { forwardRef, useCallback, useEffect, useState, ChangeEvent } from "react";
import { FormFieldType, NewFormField, useCreateForm } from "@/forms/hooks/useCreateForm";
import styled from "styled-components";
import { TextInput } from "./components/TextInput";
import "@gamesheet/ui/build/index.css";
import { useSeasonsData } from "@/state/data/useSeasonTeams";
import { useSeasonTeamsInSeasonData } from "@/state/data/useSeasonTeamsInSeason";
import { ScheduleGameFormData } from "@/services/useScheduledGameService/types";
import { SelectInput } from "./components/SelectInput";
import ScheduledGameLocationInput from "@/components/utils/GameLocation/ScheduledGameLocationInput"
import { useAppState } from "@/state/app/useAppState";
import { Resetter } from "recoil"
import { useUserState } from "@/state/user/useUserState";
import { PageLoading } from "@/components/pure/PageLoading";
import { formatOffset, getOffset, getTimezoneOptions, getUserOffsetNow, getUserTimeZone } from "@/libs/timezones";
import { TimeZoneOptions } from "./EventForm";
import dayjs from "dayjs";

const mobileMediaQuery = `@media (max-width: 768px)`;

const RowWrapper = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;

  .full-width{
    width: 100%;
  }

  .form-control:disabled{
    background-color: #D7D7D8;
  }

  ${mobileMediaQuery} {
    flex-direction: column;
    align-items: flex-start;
    gap: 0 0.5rem;
  }
`;

const WrappingRow = styled.div`
  display: flex;
  gap: 0 1rem;
  align-items: center;
  flex-wrap: wrap;

  .full-width{
    width: 100%;
  }

  .form-control:disabled{
    background-color: #D7D7D8;
  }

  ${mobileMediaQuery} {
    /* flex-direction: column;
    align-items: flex-start; */
    gap: 0 0.5rem;
  }

  .w100 {
    flex-basis: 100%;
    flex-shrink: 0;
    flex-grow: 0;
  }

  .w75 {
    flex-basis: 70%;
    flex-shrink: 0;
    flex-grow: 1;
  }

  .w50 {
    flex-basis: 45%;
    flex-shrink: 0;
    flex-grow: 1;
  }

  .w25 {
    flex-basis: 20%;
    flex-shrink: 0;
    flex-grow: 1;
  }
`;

const RowWrapper2 = styled.div`
  display: flex;
  gap: 1rem;

  .full-width{
    width: 100%;
  }

  .form-control:disabled{
    background-color: #D7D7D8;
  }

  ${mobileMediaQuery} {
    flex-direction: column;
    align-items: flex-start;
    
  }
`;

const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 2rem;
  .button {
    font-size: 1rem !important;
    font-weight: 500 !important;
  }

  ${mobileMediaQuery} { 
    flex-direction: column-reverse;
    padding-top: 1rem;
  }
`;

const CreateButtonContainer = styled.div`
  display: flex;
  gap: 1rem;


  ${mobileMediaQuery} { 
    flex-direction: column-reverse;
    width: 100%;
    padding-bottom: 1.5rem;
  }
`

const ConfirmDeleteContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 2rem;
  justify-content: space-around;
`;

const VS = styled.div`
  color: #000;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 1rem;
  min-width: 40px;
  text-align: center;

  ${mobileMediaQuery} {
    text-align: center;
    padding: .5rem 0;
    width: 100%;
  }

`;

const ButtonGroup = styled.div`
  display: flex;
  width: 11.4375rem;
  height: 2.875rem;
  align-items: flex-start;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  padding-bottom: 1rem;

  ${mobileMediaQuery} {
    padding: 1rem 0;
    align-self: center;
  }
`;
const StyledButton = styled.div<{ $home?: boolean, $selected?:boolean, $isDisabled?:boolean }>`
  display: flex;
  width: 5.5625rem;
  height: 2.875rem;
  padding: 0.5rem 0.75rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  cursor: pointer;
  color: #36383d;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: ${(props: any) => (props.$home ? "0.3125rem 0 0 0.3125rem" : "0 0.3125rem 0.3125rem 0")};
  background-color: ${(props: any) => (props.$selected ? "#FEC307" : "#fff")};

  ${(props: any) =>
    props.$isDisabled &&
    `
    background-color: #d7d7d7;
    color: #666;
    cursor: default;
  `}

  // This is the logic needed for handling a border - leaving this here because I misunderstood the SOW.
  /* border: ${(props: any) => (props.$home ? "1px solid #FEC307" : "1px solid #FEC307")};
    border-left: ${(props: any) => (props.$home ? "1px solid #FEC307" : "none")};
    border-right: ${(props: any) => (props.$home ? "none" : "1px solid #FEC307")}; */
`;

const InfoText = styled.div`
  color: #36383d;
  font-family: Rubik;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 1rem;
`;

export type ScheduleGameFormState = {
  gameId: FormFieldType<number>;
  seasonId: FormFieldType<number>;
  associationId: FormFieldType<number>;
  leagueId: FormFieldType<number>;
  divisionId: FormFieldType<number>;
  teamId: FormFieldType<number>;
  homeFlag: FormFieldType<boolean>;
  opposingDivision: FormFieldType<number>;
  opposingTeamId: FormFieldType<number>;
  dateTime: FormFieldType<Date>;
  endTime: FormFieldType<string>;
  timezone: FormFieldType<string>;
  gameNumber: FormFieldType<string>;
  gameType: FormFieldType<string>;
  location: FormFieldType<string>;
  scorekeeperName: FormFieldType<string>;
  scorekeeperPhone: FormFieldType<string>;
  broadcastProvider: FormFieldType<string>;
};

const gameTypes = [
  {
    value: "exhibition",
    label: "Exhibition",
  },
  {
    value: "playoff",
    label: "Playoff",
  },
  {
    value: "regular_season",
    label: "Regular Season",
  },
  {
    value: "tournament",
    label: "Tournament",
  },
];

type ScheduleGameFormProps = {
  onSubmit: (gameForm: ScheduleGameFormState, method: string, another?: boolean, resetForm?: () => void, opposingTitle?: string) => void;
  gameDetails: ScheduleGameFormData;
  broadcastProviders: string[];
  resetScheduledGameData: Resetter;
};

export const ScheduleGameForm = forwardRef(({ onSubmit, gameDetails, broadcastProviders, resetScheduledGameData }: ScheduleGameFormProps, ref) => {  

  const app = useAppState();
  const seasonsData = useSeasonsData();
  const user = useUserState();
  const seasons = seasonsData.seasons.filter((season) => season.archived !== true);
  const [ availableSeasons, setAvailableSeasons ] = useState<{id: number, title: string}[]>();
  const [ initialSeason, setInitialSeason ] = useState<any>(undefined);
  const [ locationVal, setLocationVal ] = useState(gameDetails?.location || "")
  const [ availableDivisions, setAvailableDivisions ] = useState<{id: number, title: string}[]>();
  const [ availableTeams, setAvailableTeams ] = useState<any>();
  const [ confirmDelete, setConfirmDelete ] = useState<boolean>(false); //TODO: Confirm delete will likely need to be reset on navigating away from the page in a useEffect.
  const [ loaded, setLoaded ] = useState(0);
  const [ formValid, setFormValid ] = useState(false);
  const [ singleSeason, setSingleSeason ] = useState(false);
  const [timezoneOptions, setTimeZoneOptions] = useState<TimeZoneOptions[]>(getTimezoneOptions().map((tz) => {
    if (tz === gameDetails?.timeZoneName && !!gameDetails?.dateTime) {
      return {
        label: `${tz} (GMT${formatOffset(getOffset(tz, gameDetails?.dateTime))})`,
        value: tz,
        offset: getOffset(tz, gameDetails?.dateTime),
      }
    } else if (tz === getUserTimeZone() && !!gameDetails?.dateTime) {
      return {
        label: `${tz} (GMT${formatOffset(getUserOffsetNow())})`,
        value: tz,
        offset: getUserOffsetNow(),
      }
    } else {
      return {
        label: tz,
        value: tz,
        offset: null,
      }
    }
  }));

  const userRoles = user.GetClaims();
  const schedulerRoles = userRoles.filter((role: any) => role.title == "scheduler")

  const editGame = (gameDetails.gameId != 0)
  var initialLockState = {season: false, all: true, opposingTeam: true}
  if (editGame) {initialLockState = {season: true, all: false, opposingTeam: false}}
  var initialHomeFlag = true
  if (gameDetails.gameId != 0) initialHomeFlag = gameDetails.homeFlag
  const [ disabledFields, setDisabledFields ] = useState(initialLockState)

  const defaultDateTime = new Date();
  const defaultEndTime = dayjs(defaultDateTime).add(1, 'hour').add(15, 'minute').format("HH:mm");
  
  // Create form TODO: fix form validation and error messages
  const { state: formData, onChange, setFormsState, resetForm } = useCreateForm("scheduleGame", {
    seasonId: NewFormField(gameDetails?.seasonId || initialSeason, /.{1,}/, "Season must be selected"),
    associationId: NewFormField(gameDetails?.associationId || 0, /.{0},}/, ""), // This is set automatically
    leagueId: NewFormField(gameDetails?.leagueId || 0, /.{0,}/, ""), // This is set automatically
    divisionId: NewFormField(gameDetails?.divisionId || 0, /.{0,}/, ""), // This is set automatically
    teamId: NewFormField(gameDetails?.teamId || 0, /.{0,}/, ""), // This is set automatically
    homeFlag: NewFormField(initialHomeFlag, /.*/, ""),
    opposingDivision: NewFormField(gameDetails?.opposingDivision || 0, /.{0,}/, ""),
    opposingTeamId: NewFormField(gameDetails?.opposingTeamId || 0, /.{0,}/, ""),
    dateTime: NewFormField(gameDetails?.dateTime || defaultDateTime, /.{1,}/, "Date and time must be chosen"),
    endTime: NewFormField(gameDetails?.endTime || defaultEndTime, /.{1,}/, "End time must be entered"),
    timezone: NewFormField(gameDetails?.timeZoneName || getUserTimeZone(), /.{1,}/, "Timezone must be selected"),
    gameNumber: NewFormField( gameDetails?.gameNumber || "", /.{1,}/, "Game number must be entered"),
    gameType: NewFormField(gameDetails?.gameType || 'regular_season', /.{1,}/, "Game type must be selected"),
    location: NewFormField(gameDetails?.location || "", /.{0,}/, ""),
    scorekeeperName: NewFormField(gameDetails?.scorekeeperName || "", /.{0,}/, ""),
    scorekeeperPhone: NewFormField(gameDetails?.scorekeeperPhone || "", /.{0,}/, ""),
    broadcastProvider: NewFormField(gameDetails?.broadcastProvider || "", /.{0,}/, ""),
  });

   // Enable/disable buttons
   useEffect(() => {
    if ((formData?.seasonId?.value > 0) && (formData?.gameNumber?.value != "")) {
      setFormValid(true)
    } else {
      setFormValid(false)
    }
  }, [JSON.stringify(formData)])

  // Get all season teams in a specific season
  const selectedSeasonId = formData?.seasonId?.value;
  const { seasonTeams } = useSeasonTeamsInSeasonData(Number(parseInt(selectedSeasonId)));
  // Set Date and Time, also update end time
  const handleDateTimeChange = (newDate: Date) => {
    updateTimeZoneOptions(formData?.timezone?.value, newDate);
    if (setFormsState) {
      setFormsState((formData: any) => ({ ...formData, scheduleGame: { initialState: { ...formData?.scheduleGame.initialState }, currentState: {
        ...formData?.scheduleGame.currentState,
        dateTime: { ...formData?.scheduleGame.currentState.dateTime, value: newDate },
        endTime: { ...formData?.scheduleGame.currentState.endTime, value: dayjs(newDate).add(1, 'hour').add(15, 'minute').format("HH:mm") },
      }, }, }));
    }
  }

  // Set Location
  useEffect(() => {
    if (setFormsState) {
      setFormsState((formData: any) => ({ ...formData, scheduleGame: { initialState: { ...formData?.scheduleGame.initialState }, currentState: {
        ...formData?.scheduleGame.currentState,
        location: { ...formData?.scheduleGame.currentState.location, value: locationVal },
      }, }, }));
    }
  }, [ locationVal ])

  // Set homeFlag
  const handleHomeAwayClick = (home: boolean) => {
    if (setFormsState) {
      setFormsState((formData: any) => ({ ...formData, scheduleGame: { initialState: { ...formData?.scheduleGame.initialState }, currentState: {
        ...formData?.scheduleGame.currentState,
        homeFlag: { ...formData?.scheduleGame.currentState.homeFlag, value: home },
      }, }, }));
  }}

  const updateTimeZoneOptions = (currentValue: string, dateTime: Date | null) => {
    if (!dateTime) return;

    const offset = getOffset(currentValue, dateTime);
    if (!offset) return;

    setTimeZoneOptions((prev) => {
      return prev.map((option) => {
        if (option.value === currentValue) {
          if (option.offset !== offset) {
            return {
              ...option,
              label: `${currentValue} (GMT${formatOffset(offset)})`,
              offset: offset,
            };
          }
        }
        return option;
      });
    });
  }

  const updateTimeZone = (e: ChangeEvent<HTMLSelectElement>) => {
    updateTimeZoneOptions(e.target.value, formData?.dateTime?.value);
    return onChange(e);
  };

  const setEndTimeFromTimePicker = (newTime: string) => {
    onChange({ target: { name: "endTime", value: newTime } } as any);
  }

  // Get possible seasons to select from
  useEffect(() => {
    const filteredSeasons = seasons.filter((season) => {
      const hasMatchingRole = schedulerRoles.some((role: any) => {
        const teamCheck = season.team.id == role.level.id && role.level.type == "teams";
        const divisionCheck = season.division.id == role.level.id && role.level.type == "divisions";
        const seasonCheck = season.id == role.level.id && role.level.type === "seasons";
        const leagueCheck = season.team.league.id == role.level.id && role.level.type == "leagues";
        const associationCheck = season.team.association.id == role.level.id && role.level.type == "associations";

        return teamCheck || divisionCheck || seasonCheck || leagueCheck || associationCheck;
      });
      
      return hasMatchingRole;
    });
    if (filteredSeasons.length > 1) { // The prototeam has teams in multiple seasons
      const _seasons = filteredSeasons?.map((season: any) => ({ id: parseInt(season.id), title: season.title, }));
      _seasons.unshift({ id: 0, title: "" });
      _seasons?.sort((a, b) => a.title.localeCompare(b.title));
      setAvailableSeasons(_seasons);
      if (editGame) {
        setDisabledFields({...disabledFields, all: true, season: false})
      }
    } else {  // The prototeam only has one season team
      const _seasons = ({ id: filteredSeasons[0]?.id, title: filteredSeasons[0]?.title });
      setAvailableSeasons([_seasons]);
    }
  }, [ JSON.stringify(seasons), JSON.stringify(schedulerRoles) ]);

  // Set the season if there is only one available season
  useEffect(() => {
    if (availableSeasons?.length == 1) {
      setSingleSeason(true);
      if (setFormsState) {
        setFormsState((formData: any) => ({ ...formData, scheduleGame: { initialState: { ...formData?.scheduleGame.initialState }, currentState: {
          ...formData?.scheduleGame.currentState,
          seasonId: { ...formData?.scheduleGame.currentState.seasonId, value: availableSeasons[0]?.id },
        }, }, }));
      }
    }
  }, [ JSON.stringify(availableSeasons) ])

  // Set required id fields when a season is selected. These can all be derived from the season the user selects
  const setSeasonFields = useCallback(() => {
    const selectedSeasonTeam: any = seasonsData.teams.filter((team) => team.season.id === parseInt(selectedSeasonId))
    if (setFormsState && (loaded > 0)) {
      setFormsState((formData: any) => ({ ...formData, scheduleGame: { initialState: { ...formData?.scheduleGame.initialState }, currentState: {
        ...formData?.scheduleGame.currentState,
        associationId: { ...formData?.scheduleGame.currentState.associationId, value: selectedSeasonTeam[0]?.association.id },
        leagueId: { ...formData?.scheduleGame.currentState.leagueId, value: selectedSeasonTeam[0]?.league.id },
        divisionId: { ...formData?.scheduleGame.currentState.divisionId, value: selectedSeasonTeam[0]?.division.id },
        seasonId: { ...formData?.scheduleGame.currentState.seasonId, value: Number(selectedSeasonTeam[0]?.season.id) },
        teamId: { ...formData?.scheduleGame.currentState.teamId, value: selectedSeasonTeam[0]?.id },
      }, }, }));
    }
    if (loaded < 1) setLoaded(loaded + 1)
  }, [JSON.stringify(seasonsData), selectedSeasonId ])

  // Get possible divisions to select from
  useEffect(() => {
    if (!editGame) {
      if (formData?.seasonId?.value) {
        setDisabledFields({ all: false, season: false, opposingTeam: true });
        setSeasonFields();
      } else {
        setDisabledFields({ ...disabledFields, all: true, season: false });
      }
    }

    if ((singleSeason && ((availableDivisions?.length || 0) < 2)) || !singleSeason) {
      const uniqueDivisions: any[] = Array.from(new Set(seasonTeams?.map((team: any) => Number(team.division.id))));
      const divisions = uniqueDivisions.map((id) => ({
        id: Number(id), // Ensure that the ID is treated as a number
        title: seasonTeams?.find((team: any) => Number(team.division.id) === Number(id))?.division.title || "",
      }));

      divisions?.sort((a, b) => a.title.localeCompare(b.title));

      divisions.unshift({ id: 0, title: "" });
      setAvailableDivisions(divisions);
    }
  }, [JSON.stringify(seasonTeams), selectedSeasonId, formData?.seasonId?.value]);

  // Get possible teams to select from
  useEffect(() => {

    if (!seasonTeams || seasonTeams?.length < 1) {
      // Season teams data is not available yet
      return;
    }

    const divisionId: any = formData?.opposingDivision?.value
    const filteredTeams = seasonTeams?.filter((team: any) => parseInt(team.division.id) === parseInt(divisionId));

    const teamsToExclude = seasonsData.teams?.map((team: any) => team.id) || [];
    const updatedFilteredTeams = filteredTeams?.filter((team: any) => !teamsToExclude.includes(team.id));

    updatedFilteredTeams?.sort((a:any, b: any) => a.title.localeCompare(b.title));

    updatedFilteredTeams?.unshift({ id: 0, title: "" })

    const uniqueTeams = updatedFilteredTeams?.filter(
      (team: any, index: any, self: any) =>
        index === self.findIndex((t: any) => t.id === team.id)
    );

    setAvailableTeams(uniqueTeams);
    if (formData?.opposingDivision?.value == 0) {
      setDisabledFields({...disabledFields, opposingTeam: true})
    } else {
      setDisabledFields({...disabledFields, opposingTeam: false})
    }
  }, [ JSON.stringify(seasonTeams), formData?.opposingDivision?.value ]);

  // Set disabled fields if we have loaded a game in
  useEffect(() => {
    if (editGame) {
      setDisabledFields({season: true, all: false, opposingTeam: true})
      if (gameDetails.opposingDivision > 0) { setDisabledFields({ ...disabledFields, opposingTeam: false}) }
    }
  }, [JSON.stringify(gameDetails)])

  // Create/Edit buttons
  const widthStyle = app.layout.showMobile ? "block" : "auto";
  const commonOnClickParams = { value: editGame ? gameDetails.gameId : 0, valid: true, test: /.{0,}/, error: "" };

  const handleClick = (action: string, reset: boolean) => {
      let chosenTeam = availableTeams.find((team: any) => team.id == formData?.opposingTeamId?.value)
      onSubmit({ ...formData, gameId: commonOnClickParams }, action, reset, resetForm, chosenTeam?.title);
      resetScheduledGameData();
  };

  if(!formData) {
    return <PageLoading />;
  }

  return (
    <FormWrapper>
      <fieldset>
        <Title bartype="divider" text="Schedule Game" size="h2" />

        <div className="">
          <div className="form-floating mb-2 mb-md-3">
            <SelectInput state={formData?.seasonId} name="seasonId" label="Season*" options={availableSeasons?.map((season: {id: number, title: string}) => ({ value: season.id, label: season.title })) || []} onChange={onChange} isDisabled={disabledFields.season}/>
          </div>
        </div>

        <RowWrapper>
          <ButtonGroup>
            <StyledButton onClick={() => handleHomeAwayClick(true)} $selected={formData?.homeFlag?.value} $home $isDisabled={disabledFields.all}>Home</StyledButton>
            <StyledButton onClick={() => handleHomeAwayClick(false)} $selected={!formData?.homeFlag?.value} $home={false} $isDisabled={disabledFields.all}>Visitor</StyledButton>
          </ButtonGroup>
          { formData?.homeFlag?.value && <VS>VS</VS>}
          { !formData?.homeFlag?.value && <VS>@</VS>}
          <div className={`col ${app.layout.showMobile && "full-width"}`}>
            <div className="form-floating mb-2 mb-md-3">
              <SelectInput
                state={formData?.opposingDivision}
                name="opposingDivision"
                label="Opposing Division"
                options={availableDivisions?.map((division: {id: number, title: string}) => ({ value: division.id, label: division.title })) || []}
                onChange={onChange}
                isDisabled={disabledFields.all}
              />
            </div>
          </div>

          <div className={`col ${app.layout.showMobile && "full-width"}`}>
            <div className="form-floating mb-2 mb-md-3">
              <SelectInput
                state={formData?.opposingTeamId}
                name="opposingTeamId"
                label="Opposing Team"
                options={availableTeams?.map((team: any) => ({ value: team.id, label: team.title })) || []}
                onChange={onChange}
                isDisabled={disabledFields.opposingTeam || disabledFields.all}
              />
            </div>
          </div>
        </RowWrapper>

        <WrappingRow>
          <div className={(app.layout.showMobile ? "w75" : "w25")}>
            <div className="form-floating mb-2 mb-md-3">
              <DateAndTimePicker value={formData?.dateTime?.value || new Date()} setValue={handleDateTimeChange} label="Game Date & Time*" isDisabled={disabledFields.all} />
            </div>
          </div>
          <div className="w25">
            <div className="form-floating mb-2 mb-md-3">
              <TimePicker
                value={formData?.endTime?.value}
                setValue={setEndTimeFromTimePicker}
                label="End Time"
              />
            </div>
          </div>
          <div className={(app.layout.showMobile ? "w100" : "w25")}>
            <div className="form-floating mb-2 mb-md-3">
            <SelectInput state={formData?.timezone} name="timezone" label="Time Zone*" onChange={updateTimeZone} options={timezoneOptions} />
            </div>
          </div>
          <div className={(app.layout.showMobile ? "w50" : "w25")}>
            <div className="form-floating mb-2 mb-md-3">
              <TextInput state={formData?.gameNumber} name="gameNumber" label="Game Number*" onChange={onChange} isDisabled={disabledFields.all}/>
            </div>
          </div>
          <div className={(app.layout.showMobile ? "w50" : "w25")}>
            <div className="form-floating mb-2 mb-md-3">
              <SelectInput  state={formData?.gameType} name="gameType" label="Game Type*" options={gameTypes.map((gameType) => ({ value: gameType?.value, label: gameType.label }))} onChange={onChange} isDisabled={disabledFields.all} />
            </div>
          </div>
          <div className={(app.layout.showMobile ? "w100" : "w25")}>
            <div className="form-floating mb-2 mb-md-3">
              <ScheduledGameLocationInput
                location={locationVal}
                setLocation={setLocationVal}
                clear={0}
                isDisabled={disabledFields.all}
              />
            </div>
          </div>
          <div className={(app.layout.showMobile ? "w100" : "w25")}>
            <div className="form-floating mb-2 mb-md-3">
              <TextInput state={formData?.scorekeeperName} name="scorekeeperName" label="Scorekeeper Name" onChange={onChange} isDisabled={disabledFields.all}/>
            </div>
          </div>
          <div className={(app.layout.showMobile ? "w100" : "w25")}>
            <div className="form-floating mb-2 mb-md-3">
              <TextInput state={formData?.scorekeeperPhone} name="scorekeeperPhone" label="Scorekeeper Phone #" onChange={onChange} isDisabled={disabledFields.all}/>
            </div>
          </div>
        </WrappingRow>

        <RowWrapper2>
          <div style={{minWidth: "calc(50% - 0.5rem)"}} className={`col ${app.layout.showMobile && "full-width"}`}>
            <div className="form-floating mb-2 mb-md-3">
              <SelectInput
                state={formData?.broadcastProvider}
                name="broadcastProvider"
                label="Broadcast Provider"
                options={broadcastProviders?.map((bcp) => ({ value: bcp, label: bcp })) || []}
                onChange={onChange}
                isDisabled={disabledFields.all}
              />
            </div>
          </div>
          <div className="col">
            <InfoText>
              Let your fans know where they can watch the game by selecting a broadcast provider from the list. If your desired broadcaster is not in the list, shoot us a message and let us know so we
              can add them.
            </InfoText>
          </div>
        </RowWrapper2>
        { editGame && <RowWrapper>
          {confirmDelete ? (
              <ConfirmDeleteContainer>
                <Button label="Keep Game" size="lg" width="block" variant={"success"} textColor="#fff" onClick={() => setConfirmDelete(false)} />
                <Button
                  label="Delete Game"
                  size="lg"
                  width="block"
                  variant="danger"
                  onClick={() => onSubmit({...formData, gameId: {value: gameDetails.gameId, valid: true, test: /.{0,}/, error: ""}}, 'delete')}
                />
              </ConfirmDeleteContainer>
            ) : (
              <Button
                label="Delete Scheduled Game"
                size="lg"
                width="block"
                variant="danger"
                onClick={() => {
                  setConfirmDelete(true);
                }}
              />
            )}
        </RowWrapper> }
        <FooterContainer>
          <Button variant="inverted" label="Cancel" onClick={() => {app.navigate('/games'); if (resetForm) resetForm(); resetScheduledGameData();}} width={`${app.layout.showMobile ? "block": "auto"}`}>
            Cancel
          </Button>
          <CreateButtonContainer>
            {editGame ? (
                <Button
                    size="lg"
                    label="Update Scheduled Game"
                    width={widthStyle}
                    onClick={() => handleClick('edit', false)}
                />
            ) : (
                <>
                    <Button
                        size="lg"
                        label="Create And Add Another"
                        width={widthStyle}
                        style={{ backgroundColor: formValid ? '' : '#b5b5b3' }}
                        onClick={() => handleClick('create', true)}
                    />
                    <Button
                        size="lg"
                        label="Create Scheduled Game"
                        width={widthStyle}
                        style={{ backgroundColor: formValid ? '' : '#b5b5b3' }}
                        onClick={() => handleClick('create', false)}
                    />
                </>
            )}
        </CreateButtonContainer>
        </FooterContainer>
      </fieldset>
    </FormWrapper>
  );
});

export default ScheduleGameForm;
