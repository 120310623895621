import { Loading, Logo } from "@gamesheet/ui";
import { PropsWithChildren } from "react";
import styled from 'styled-components';

const StyledLoadingLayout = styled.div`
text-align: center;
background-color: #fec307;

.loading {
    opacity: 1;
    transition: opacity 1s ease-in-out;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    font-style: italic;
    font-variant: small-caps;
    color: black;
}
`

// add the app version to the loading screen

export function LoadingLayout(props:PropsWithChildren<{}>){
    return (
        <StyledLoadingLayout>
        <div className="loading">
            <Logo logo='primary' color='black' size='xxl'/><br/>
            <Loading variant="inverted" />
        </div>
        </StyledLoadingLayout>
    );
}