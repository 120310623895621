import { CoachFormData } from "@/state/data/useCoachData";
import { PlayerFormData } from "@/state/data/usePlayerData";

export type LineupFlowType = {
  path: LineupFlowPath;
};

export enum LineupFlowPath {
  Player = "playerPath",
  StartingGoalie = "startingGoaliePath",
  Coach = "coachPath",
  Preview = "previewPath",
  AddPlayer = "addPlayerPath",
  AddCoach = "addCoachPath",
}

export interface ExtendedPlayerFormData extends PlayerFormData {
  externalId?: string;
  errorMessage?: string;
  rosterDataChanged?: boolean;
  bioDataChanged?: boolean;
  saveToRoster?: boolean;
  isSelected?: boolean;
  new?: boolean;
  starting?: boolean;
  suspension?: {
    number: number;
    length: number;
  };
}

export interface ExtendedCoachFormData extends CoachFormData {
  externalId?: string;
  status: string;
  errorMessage?: string;
  rosterDataChanged?: boolean;
  bioDataChanged?: boolean;
  saveToRoster?: boolean;
  isSelected?: boolean;
  new?: boolean;
  suspension?: {
    number: number;
    length: number;
  };
}

export type Lineup = {
  players: ExtendedPlayerFormData[];
  goalies: ExtendedPlayerFormData[];
  coaches: ExtendedCoachFormData[];
};

export type LineupEvent = {
  record: LineupRecord;
};

export type LineupRecord = {
  id: string;
  lineup: Lineup;
  createdAt: number;
  locked: boolean;
  protoTeamId: string; 
  gameId: string; 
  rosterLocked: boolean;
};
