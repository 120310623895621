import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

// utc
dayjs.extend(utc)

// timezone support
dayjs.extend(timezone)
dayjs.tz.setDefault('UTC')

export default dayjs