import { useRecoilState, useRecoilValue } from "recoil";
import { FormFieldType, FormItems, FormsState, useFormStateResponse } from "./useCreateForm";
import { ChangeEvent, useCallback, useMemo } from "react";

export function useForm<T extends FormItems>(id: string): useFormStateResponse<T> & { setState: (newState: T) => void } {
  const [formsState, setFormsState] = useRecoilState(FormsState);

  const formState = formsState[id];
  const currentFormState = formState?.currentState;

  const setFormState = useCallback(
    (newState: T) => {
      setFormsState({
        ...formsState,
        [id]: {
          ...formsState[id],
          currentState: newState,
        },
      });
    },
    [formsState, setFormsState]
  );

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
      let { name, value }: { name: string; value: string } = e.target as HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;
      name.startsWith(id) && (name = name.replace(`${id}-`, ``));
  
      if (currentFormState[name] === undefined) {
        console.error(`FormState.${name} does not exist`);
        return {
          value: "",
          error: `FormState.${name} does not exist`,
          test: /^$/,
          valid: false,
        };
      }
  
      const updated: FormFieldType<any> = {
        value: value,
        error: currentFormState[name].error,
        test: currentFormState[name].test,
        valid: value.match(currentFormState[name].test) ? true : false,
      };
  
      setFormState({
        ...currentFormState,
        [name]: { ...updated },
      } as T);
  
      return updated;
    },
    [currentFormState, setFormState, id]
  );

  return {
    setState: setFormState,
    state: currentFormState as T,
    onChange,
  };
}
