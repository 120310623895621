import { isMatch } from "date-fns";

export default function validateBirthDate({ birthdate }: { birthdate: any }) {
  if (!birthdate) {
    return [];
  }

  if (!isMatch(birthdate, "yyyy-MM-dd")) {
    return [{ field: "birthdate", error: "invalid" }];
  }

  return [];
}
