import { Button, Title } from "@gamesheet/ui";
import { useEffect, useMemo, useState } from "react";
import { useAppState } from "@/state/app/useAppState";
import { styled } from "styled-components";
import { MobileRoster as RosterView } from "@/components/views/Roster/MobileRoster";
import { LayoutGridContainer } from "@/layouts/GridItems";
import { InsetTextBlock } from "./components/InsetTextBlock";
import { DetailedTeamPreview } from "@/components/pure/DetailedTeamPreview";
import { RosterWrapper } from "./components/RosterWrapper";
import { Roster } from "@/state/data/useTeamRoster/types";
import { FloodTwoTone } from "@mui/icons-material";
import { useSeasonsData } from "@/state/data/useSeasonTeams";
import { MakePrototeamSeasons } from "./lib/MakePrototeamSeasons";
import { useUserState } from "@/state/user/useUserState";

const DesktopTeamPreview = styled.div`

.team-selector {
    
    padding-top: 0;
    margin-bottom: 35px;

    .team-logo {
        padding-left: 8px;
        img {
            width: 96px;
            height: 96px;
        }
    }

    .team-title {
        .title {
            font-size: 1.75rem;
        }
        .subtitle {
            font-size: 1.1rem;
        }
    }

    ul {
        list-style-type: disc; /* Use disc for filled circles, other options: square, circle, none */
        padding-left: 20px; /* Adjust the left padding based on your preference */
      }
    
      li {
        margin-bottom: 8px; /* Adjust the spacing between list items based on your preference */
      }
}`

const MobileTeamPreview = styled.div`

.team-selector {
    
    margin-top: 15px;
    margin-bottom: 35px;

    .team-logo {
        padding-left: 8px;
    }

    ul {
        list-style-type: disc; /* Use disc for filled circles, other options: square, circle, none */
        padding-left: 20px; /* Adjust the left padding based on your preference */
      }
    
      li {
        margin-bottom: 8px; /* Adjust the spacing between list items based on your preference */
      }
}`

type SchedulerPreviewProps = {
    prototeam_id: string;
    inviteRoles: any;
    onAccept: () => void;
    onBack: () => void;
}

export function SchedulerPreview({ prototeam_id, inviteRoles, onBack, onAccept }:SchedulerPreviewProps){

    const app = useAppState()
    const seasons = useSeasonsData();
    const StyledTeamPreview = (app.layout.showTablet || app.layout.showDesktop) ? DesktopTeamPreview : MobileTeamPreview;
    const [ previewSeasons, setPreviewSeasons ] = useState<any>();

    const prototeam_seasons = MakePrototeamSeasons(prototeam_id || "", seasons, inviteRoles);


    useEffect(() => {
        const seasonTeams = app.teams.teamData?.map((prototeam: any) => prototeam?.seasonTeams).flat().filter((team: any) => inviteRoles?.invitation?.roles?.some((role: any) => role.level.id == team.season.id))
        const uniqueSeasons = Array.from(
            seasonTeams?.reduce((map: any, team: any) => map.set(team.season.id, team.season), new Map()).values()
        );
        const seasonsList = uniqueSeasons?.map((season: any) => season.title)

        if (prototeam_seasons.length == 0) {
            setPreviewSeasons(seasonsList)
        } else {
            setPreviewSeasons(prototeam_seasons)
        }
    }, [JSON.stringify(prototeam_seasons), JSON.stringify(app.teams.teamData), JSON.stringify(inviteRoles), prototeam_id])


    const BtnBack = useMemo(() => <Button type="button" size="lg" onClick={onBack}>Back</Button>, [ onBack ])
    useEffect(() => app.ui('leftTray').set(BtnBack, app.layout.showMobile), [ BtnBack, app.layout.showMobile ])

    const BtnNext = useMemo(() => <Button type="button" size="lg" onClick={onAccept}>Continue</Button>, [ onAccept ])
    useEffect(() => app.ui('rightTray').set(BtnNext, app.layout.showMobile), [ BtnNext, app.layout.showMobile ])

    return <div>
        
        <Title bartype="plain" text="" size="h6">
            <InsetTextBlock>You are accepting an invitation for the Scheduler role, which grants you the ability to schedule games for the seasons listed below.</InsetTextBlock>
        </Title>

        <StyledTeamPreview>
            <ul>
                {previewSeasons?.map((season: any, index: any) => (
                <li key={index}>{season}</li>
                ))}
            </ul>
        </StyledTeamPreview>

        <Title bartype="bar" text="Not the right seasons?" size="h6" variant="muted"/>
        <InsetTextBlock style={{fontSize: "0.85rem", fontWeight: "500", lineHeight: ".9rem"}}>
            Click back and double check that you have the correct Invitation Code. 
            If the code is correct but the team is not, contact your league or tournament coordinator to request a new code.
        </InsetTextBlock>
        
        { (app.layout.showTablet || app.layout.showDesktop) && <>
            <hr/>
            <div style={{ display:'flex', justifyContent:'space-between' }}>
                {BtnBack}
                {BtnNext}
            </div>
        </>}

    </div>

}