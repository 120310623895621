import { atom, selector } from 'recoil';

export const rawAppVersion = atom<{remoteVersion: string, localVersion: string}>({
    key: 'rawAppVersion',
    default: {remoteVersion: '', localVersion: ''}
});

export const appVersion = selector<{remoteVersion: string, localVersion: string, updateAvailable: boolean}>({
    key: 'appVersion',
    get: ({get}) => {
        
        const rawAppVersionState = get(rawAppVersion);
        const updateAvailable = rawAppVersionState.remoteVersion !== rawAppVersionState.localVersion;

        return(
            { remoteVersion: rawAppVersionState.remoteVersion, localVersion: rawAppVersionState.localVersion, updateAvailable: updateAvailable });
    }
})