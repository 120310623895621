import { Title, UserNotice } from "@gamesheet/ui";
import React, { useEffect } from "react";
import styled from "styled-components";
import { BreakPoints } from "@/libs/breakpoints";
import { MobileEntityCard } from "@/components/pure/EntityCard/MobileEntityCard";
import CardAction from "@/components/pure/EntityCard/CardAction";
import { useLineupData } from "./state/useLineup";
import { ExtendedPlayerFormData } from "./types";
import { LineupFlowHook } from "./state/useLineupFlow";
import { useAppState } from "@/state/app/useAppState";
import { usePlayerNotParticipating } from "@/windows/lineups/playerNotParticipating/usePlayerNotParticipating";
import { useMatchingJerseyPlayers } from "./state/atom.matchingJerseyPlayerState";

const StyledPlayersStep = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 1rem;

  @media screen and (min-width: ${BreakPoints.lg}) {
    flex-direction: row;
    padding-top: 0;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media screen and (min-width: ${BreakPoints.lg}) {
    width: 50%;
    padding: 0 0.5rem;
  }
`;

const PlaceholderErrorWrapper = styled.div`
  display: flex;
  gap: 1rem;
`;

const MobileEntityCardWrapper = styled.div`
  cursor: pointer;
  flex-grow: 1;
`;

type PlayerStepProps = {
  flow: LineupFlowHook;
};

export const PlayersStep: React.FC<PlayerStepProps> = ({ flow }) => {
  const app = useAppState();
  const { LocalLineupData } = useLineupData();
  const { open: openPlayerNotParticipatingModal } = usePlayerNotParticipating();
  const { matchingPlayers, updateMatchingPlayers } = useMatchingJerseyPlayers();

  const handlePlayerActionClick = (playerId: string, setToPlaying: boolean) => {
    if (setToPlaying) {
      LocalLineupData.setPlayingStatusById(playerId);
    } else {
      LocalLineupData.selectPlayerGoalieOrCoachById(playerId, "players");
      openPlayerNotParticipatingModal();
    }
  };

  const handleGoalieActionClick = (goalieId: string, setToPlaying: boolean) => {
    if (setToPlaying) {
      LocalLineupData.setPlayingStatusById(goalieId);
    } else {
      LocalLineupData.selectPlayerGoalieOrCoachById(goalieId, "goalies");
      openPlayerNotParticipatingModal();
    }
  };

  const handleCardClick = (entityId: string, entityType: string) => {
    const updatedEntities = entityType === "player" ? LocalLineupData.get().lineup.players : LocalLineupData.get().lineup.goalies;
    const updatedEntityKey = entityType === "player" ? "players" : "goalies";

    const updatedEntitiesWithSelection = updatedEntities.map((entity) => {
      if (entity.id === entityId) {
        return { ...entity, isSelected: true };
      }
      return entity;
    });

    LocalLineupData.set({
      ...LocalLineupData.get(),
      lineup: {
        ...LocalLineupData.get().lineup,
        [updatedEntityKey]: updatedEntitiesWithSelection,
      },
    });

    flow.useAddPlayerPath();
  };

  const sanitizeStatus = (player: ExtendedPlayerFormData) => {
    if (!player) return "";
    const { status, suspension } = player;
    switch (status) {
      case "playing":
        return "playing";
      case "not_playing":
        return "Not Playing";
      case "injured":
        return "Injured";
      case "suspended":
        return `Suspended ${suspension?.number || 0} of ${suspension?.length || 0}`;
      default:
        return status;
    }
  };

  useEffect(() => {
    // Call updateMatchingPlayers whenever lineup data changes
    const lineup = LocalLineupData.get().lineup;
    updateMatchingPlayers([...lineup.players, ...lineup.goalies]);
  }, [LocalLineupData.get().lineup]);

  return (
    <StyledPlayersStep>
      <Column>
        <Title bartype="divider" size="h2" text={`Players (${LocalLineupData?.get()?.lineup?.players?.length || 0})`} />
        {LocalLineupData.get().lineup.players.map((player, index) => (
          <UserNotice
            display={(matchingPlayers.includes(player.id) || player.jersey === "") && (player.status === "playing" || player.status === "active")}
            type="error"
            msg={player.jersey === "" ? "Missing Jersey Number" : "More than one player has the same jersey number"}
            key={index}
          >
            <div style={{ display: "flex", gap: "1rem" }}>
              <MobileEntityCardWrapper onClick={() => handleCardClick(player.id, "player")}>
                <MobileEntityCard title={`${player.firstName} ${player.lastName}`} stat={`#${player.jersey}`} subtitle={app.helpers.translatePosition(player.position, true)} reverse />
              </MobileEntityCardWrapper>
              <CardAction
                active={player.status === "playing"}
                onClick={() => {
                  handlePlayerActionClick(player.id, player.status !== "playing");
                }}
                text={player.status !== "playing" ? sanitizeStatus(player) : "playing"}
              />
            </div>
          </UserNotice>
        ))}
      </Column>
      <Column>
        <Title bartype="divider" size="h2" text={`Goalies (${LocalLineupData.get()?.lineup?.goalies?.length})`} />
        {LocalLineupData.get().lineup.goalies.map((goalie, index) => (
          <UserNotice
            display={(matchingPlayers.includes(goalie.id) || goalie.jersey === "") && (goalie.status === "playing" || goalie.status === "active")}
            type="error"
            msg={goalie.jersey === "" ? "Missing Jersey Number" : "More than one player has the same jersey number"}
            key={index}
          >
            <div style={{ display: "flex", gap: "1rem" }}>
              <MobileEntityCardWrapper onClick={() => handleCardClick(goalie.id, "goalie")}>
                <MobileEntityCard title={`${goalie.firstName} ${goalie.lastName}`} stat={`#${goalie.jersey}`} subtitle={app.helpers.translatePosition(goalie.position, true)} reverse />
              </MobileEntityCardWrapper>
              <CardAction
                active={goalie.status === "playing"}
                onClick={() => {
                  handleGoalieActionClick(goalie.id, goalie.status !== "playing");
                }}
                text={goalie.status !== "playing" ? sanitizeStatus(goalie) : "playing"}
              />
            </div>
          </UserNotice>
        ))}
      </Column>
    </StyledPlayersStep>
  );
};
