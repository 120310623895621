// write a reducer for use with useReducer that changes the loading state of the page:
// 
export enum ActionType {
    SET_LOADING = "SET_LOADING"
}

export type PageSessionState = {
    loading: boolean
}

export type Action = {
    type: ActionType,
    state: Partial<PageSessionState>
}

export const defaultPageSessionState:PageSessionState = {
    loading: true
}

export function PageSessionReducer( state:PageSessionState, action:Action ): PageSessionState {
    switch( action.type ){
        case ActionType.SET_LOADING: return {
            ...state,
            loading: action.state.loading as boolean
        }
        default: return state
    }
}
