import styled from "styled-components";
import { Modal, Button } from "@gamesheet/ui";
import { useAppState } from "@/state/app/useAppState";

import { useAlreadySet } from "./useAlreadySet";
import { useLocation } from "react-router-dom";

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgb(0, 0, 0, 0.5);
  z-index: 5200;
`;

const Text = styled.div`
  font-family: Rubik;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  padding-bottom: 0.75rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  padding-top: 0.5rem;
  padding-bottom: 1rem;
`;

const ConfirmButtonContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  padding-top: 0.5rem;
  justify-content: space-between;
`;

const Divider = styled.div`
  border: 1px solid #d7d7d8;
  margin: 0 -1rem;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 1rem;
`;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function AlreadySet() {
  const app = useAppState();
  const { isOpen, close, resetToOriginalRoster } = useAlreadySet();

  const query = useQuery();
  const email = query.get('email'); 

  const handleReset = () => {
    resetToOriginalRoster();
    close();
  };

  if (!isOpen) {
    return <></>;
  }

  return (
    <ModalContainer>
      <Modal title="Lineup already set" show={isOpen} onClose={close}>
        <Text>A lineup has already been set for this game. Would you like to edit the existing lineup?</Text>
        <Divider />
        <ButtonContainer>
          <Button size="lg" label="Edit lineup" onClick={close} />
          <Button size="lg" label="Discard and Start Over" variant="danger" onClick={handleReset} />
        </ButtonContainer>

        <Button
          size="lg"
          label="Back"
          variant="inverted"
          onClick={() => {
            close();
            if(email){
              app.navigate("/")
            } else {
              app.goBack();
            }
          }}
        />
      </Modal>
    </ModalContainer>
  );
}
