import { useAppState } from "@/state/app/useAppState";
import { useUserState } from "@/state/user/useUserState";
import { EventFormState, recurrenceRRuleState } from "@/forms/EventForm";
import { useSelectedTeamState } from "@/state/team/useSelectedTeamState";
import { useRecoilValue } from "recoil";
import { getOffset } from "@/libs/timezones";

export const useEventService = () => {
  const app = useAppState();
  const user = useUserState();
  const selectedTeam = useSelectedTeamState();

  const formatDate = (date: Date | null) => {
    if (!date) {
      return "";
    }
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hour = date.getHours().toString().padStart(2, "0");
    const minute = date.getMinutes().toString().padStart(2, "0");

    const formattedString = `${year}-${month}-${day}T${hour}:${minute}`;

    return formattedString;
  };
  const recurrenceRRuleValue = useRecoilValue(recurrenceRRuleState);

  return {
    GetEventDetails: async (eventId: string) => {
      const response = await fetch(`${app.config.gateways.data}/events/${eventId}?filter[updatedAfter]=${Date.now()}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${user.tokens.access}`,
        },
      });
      const data = response.json();
      return data;
    },
    CreateEvent: async (eventData: EventFormState) => {
      const url = `${app.config.gateways.eventLog}/v5/teams/${selectedTeam?.id}/create-event`;
      const headers = new Headers();
      user.isLoggedIn && headers.set("Authorization", `Bearer ${user.tokens.access}`);

      const requestData = {
        attributes: {
          event: "create-event",
          schema: "event-info",
          version: "v1",
        },
        data: {
          title: eventData.type.value === "practice" ? "practice" : eventData.title.value,
          timezone: eventData.timezone.value,
          timezone_offset: getOffset(eventData.timezone.value, eventData.startDateTime.value) || 0,
          start_date: formatDate(eventData.startDateTime.value),
          end_date: formatDate(eventData.endDateTime.value),
          location: eventData.location.value,
          notes: eventData.notes.value,
          type: eventData.type.value,
          prototeam_id: selectedTeam?.id,
          recurrence: recurrenceRRuleValue,
        },
      };

      try {
        const response = await fetch(url, {
          method: "POST",
          headers,
          body: JSON.stringify(requestData),
        });

        if (response.ok) {
          return 200;
        } else {
          console.error("Error while creating event data:", response);
          return 500;
        }
      } catch (error) {
        console.error("Error while creating event data:", error);
        return 500;
      }
    },
  };
};
