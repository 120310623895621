import { Config } from "./type";

export const config:Config = {
    name: "",
    settings: {
        showDumpStateButton: false,
    },
    features: {

    },
    apps: {
        statsWidget: "https://gamesheetstats.com",
        leagueDashboard: "https://gamesheet.app",
    },
    gateways: {
        data: "/gateway/data",
        events: "/gateway/events",
        auth: "/gateway/auth",
        eventLog: "/gateway/eventlog",
        lookups: "gateway/lookups",
    },
    services: {
        bffApi: "/api", // functions folder
        assetsApi: "/service/assets",
        registryApi: "/service/registrysync",
        scoresheetExportApi: "/service/scoresheets",
        unleashApi: "https://features.gamesheet.io/api/frontend",
    },
    firebase: {},
    unleash: {},
}