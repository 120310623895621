import { useRosterImport } from "../hooks/useRosterImport";
import styled from "styled-components";

const StyledValidationSucceeded = styled.div`
  text-align: center;
  font-size: 18px;
`;

export function ValidationSucceeded() {
  const { players, coaches, team } = useRosterImport();

  return (
    <StyledValidationSucceeded>
      <p>
        {players.length} players and {coaches.length} coaches will be imported
        into the <span className="season">{team && team.title}</span> team.
      </p>
      <p>Would you like to continue?</p>
    </StyledValidationSucceeded>
  );
}
