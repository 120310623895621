// react
import { useState, useMemo, useEffect } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

// type imports
import { CSVRosterImportStatus as ImportStatus } from "../types/importStatus";

// function imports
import readCsvFile from "../parser/read";
import validateCsvRecords from "../parser/validate";
import * as mutators from "../parser/mutators";

// component state
import {
  playerImportResponsesState,
  coachesImportResponsesState,
  componentStatusState,
  recordsState,
  playerImportProgressState,
  playerRecordsState,
  coachImportProgressState,
  coachRecordsState,
} from "../state";
import { asyncCommandQueue } from "../asyncCommandQueue";
import { useSelectedTeamState } from "@/state/team/useSelectedTeamState";
import { useAppState } from "@/state/app/useAppState";
import { useUserState } from "@/state/user/useUserState";
import { selectedTeamSportPositionsOptionsData, selectedTeamSportCoachPositionsOptionsData } from "@/state/data/useSeasonTeams";

const TEAM_ROSTER_IMPORT_CSV_TEMPLATE = [
  "TYPE *,FIRST NAME *,LAST NAME *,EXTERNAL ID,JERSEY NUMBER,POSITION,DESIGNATION,AFFILIATED,BIRTHDATE,WEIGHT,HEIGHT,SHOT HAND,BIRTH COUNTRY,BIRTH STATE,BIRTH CITY,DRAFTED BY,COMMITTED TO,BIO\n",
  ",,,,,,,,,,,,,,,,,",
];

// for updating external state
// import { CurrentTeamLoadingRoutine } from "@/redux/teams/routines";

/**
 * useRosterImport Hook
 *
 * This hook manages the state of the RosterImportWizard.
 *
 */
export function useRosterImport() {

  const selectedTeam = useSelectedTeamState();
  const app = useAppState();
  const user = useUserState();

  const unlockedSeasonTeam = selectedTeam?.seasonTeams?.find((team) => !team.rosterLocked);
  const userToken = user.GetToken();
  const positions = useRecoilValue(selectedTeamSportPositionsOptionsData);
  const coachPositions = useRecoilValue(selectedTeamSportCoachPositionsOptionsData);

  // -------------------------------------------------------------------------------------------------------------------| shared state setters and getters

  const setPlayerImportResponses = useSetRecoilState(playerImportResponsesState);
  const setCoachImportResponses = useSetRecoilState(coachesImportResponsesState);
  const [records, setRecords] = useRecoilState<any[]>(recordsState);
  const [status, setStatus] = useRecoilState(componentStatusState);
  const [playerImportProgress, setPlayerImportProgress] = useRecoilState(playerImportProgressState);
  const [coachImportProgress, setCoachImportProgress] = useRecoilState(coachImportProgressState);

  // -------------------------------------------------------------------------------------------------------------------| selectors (filtered state values)

  const [invalidRecords, setInvalidRecords] = useState<any[]>([]);
  const players = useRecoilValue(playerRecordsState);
  const coaches = useRecoilValue(coachRecordsState);

  // -------------------------------------------------------------------------------------------------------------------| local state values

  const [error, setError] = useState<any>(null);
  const [isPlayersImported, setIsPlayersImported] = useState(false);
  const [isCoachesImported, setIsCoachesImported] = useState(false);

  // -------------------------------------------------------------------------------------------------------------------| triggers

  useEffect(() => {
    if (isPlayersImported && isCoachesImported) {
      setStatus(ImportStatus.COMPLETE);
    }
  }, [isPlayersImported, isCoachesImported]);

  // -------------------------------------------------------------------------------------------------------------------| functions

  async function runImportOn(
    imports: any,
    onQueueProgress: any,
    onDone = (x: any) => { }
  ) {
    return new Promise((resolve, reject) => {
      asyncCommandQueue({
        MAX_CONCURRENT: 6,
        requests: [...imports],
        onProgress: (queue) => {
          onQueueProgress({
            pending: queue.pending.length,
            sent: queue.sent.length,
            resolved: queue.resolved.length,
            rejected: queue.rejected.length,
          });
        },
        onDone: (queue) => {
          onDone(queue);
          resolve(queue);
        },
      });
    });
  }

  const resetImport = () => {
    setStatus(ImportStatus.PENDING);
    setRecords([]);
    setInvalidRecords([]);
    setError(null);
    setIsPlayersImported(false);
    setIsCoachesImported(false);
  };

  // -------------------------------------------------------------------------------------------------------------------| handlers

  async function handleStartImport() {
    setStatus(ImportStatus.IMPORTING);

    // import players
    runImportOn(playerImports, setPlayerImportProgress, (queue) => {
      setPlayerImportResponses(queue.resolved);
      setIsPlayersImported(true);
    });

    // import coaches
    runImportOn(coachImports, setCoachImportProgress, (queue) => {
      setCoachImportResponses(queue.resolved);
      setIsCoachesImported(true);
    });
  }

  async function handleFileSelection(file: File | undefined) {
    if (!file) return;

    // set the status to parsing, which will show the loading screen
    setStatus(ImportStatus.PARSING);
    setRecords([]);
    setInvalidRecords([]);
    setError(null);

    try {
      // read the csv file and parse it
      // THROWS `ReadingError`
      const { records: parsedRecords } = await readCsvFile(file, {
        teamId: selectedTeam!.id,
      }, positions, coachPositions);

      // records are PARSED without error, validate them
      const validatedRecords = await validateCsvRecords(parsedRecords, positions, coachPositions);

      setRecords(validatedRecords);

      const invalidRecords = validatedRecords.filter(
        ({ validationErrors }) => validationErrors.length > 0
      );
      if (invalidRecords.length > 0) {
        setInvalidRecords(invalidRecords);
        setStatus(ImportStatus.VALIDATION_FAILED);
        return;
      } else {
        setStatus(ImportStatus.PARSED);
        return;
      }
    } catch (e) {
      setError(e);
      setStatus(ImportStatus.PARSING_FAILED);
    }
  }

  function handleDownloadTemplate(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault();
    const ROSTER_IMPORT_CSV_TEMPLATE = TEAM_ROSTER_IMPORT_CSV_TEMPLATE;
    const blob = new Blob(ROSTER_IMPORT_CSV_TEMPLATE, {
      type: "text/csv;charset=utf-8",
    });
    saveAs(blob, `gamesheet-team-roster-import-template.csv`);
  }

  // -------------------------------------------------------------------------------------------------------------------| "selectors"

  const playerImports = useMemo(() => {
    if (!players?.length || !unlockedSeasonTeam) return [];
    const teamId = parseInt(unlockedSeasonTeam?.id);
    if (!teamId) return [];

    // players is an array of merged players, so it's expected to have less than the number of rows in the csv if there are more than one record with the same externalId
    return players.map((record) => async () => {
      const player = Object.keys(record).reduce((mutatedRecord, key) => {
        return {
          ...mutatedRecord,
          [key]: (mutators as any)[key]
            ? (mutators as any)[key](record[key])
            : record[key],
        };
      }, {} as any);

      const url = `${app.config.gateways.events}/roster`;
      return fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          event: "prototeam-player-create",
          attributes: {
            schema: "player-event",
          },
          data: {
            teamId,
            player: {
              associationId: unlockedSeasonTeam!.association!.id,
              leagueId: unlockedSeasonTeam!.league!.id,
              seasonId: unlockedSeasonTeam!.season!.id,
              externalId: player.externalId,
              firstName: player.firstName,
              lastName: player.lastName,
              birthdate: player.birthdate,
              photoUrl: "",
              biography: player.bio,
              height: player.height,
              weight: player.weight,
              shotHand: player.shotHand,
              province: player.state,
              hometown: player.hometown,
              country: player.country,
              draftedBy: player.draftedBy,
              committedTo: player.committedTo,
              jersey: player.jerseyNumber,
              position: player.position,
              status: "playing",
              duty: player.designation,
              player: "", // unused??
              starting: false,
              affiliated: player.affiliated,
              addedAtGameTime: false,
            },
          },
        }),
      }).then((res) => res.json());
    });
  }, [JSON.stringify(players), JSON.stringify(positions), JSON.stringify(coachPositions), userToken, unlockedSeasonTeam]);

  const coachImports = useMemo(() => {
    if (!coaches?.length || !unlockedSeasonTeam) return [];
    const teamId = parseInt(unlockedSeasonTeam?.id);
    if (!teamId) return [];

    return coaches.map((record) => () => {
      const coach = Object.keys(record).reduce((mutatedRecord, key) => {
        return {
          ...mutatedRecord,
          [key]: (mutators as any)[key]
            ? (mutators as any)[key](record[key])
            : record[key],
        };
      }, {} as any);

      const url = `${app.config.gateways.events}/roster`;
      return fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          event: "prototeam-coach-create",
          attributes: {
            schema: "coach-event",
          },
          data: {
            teamId,
            coach: {
              associationId: unlockedSeasonTeam.association.id,
              leagueId: unlockedSeasonTeam.league.id,
              seasonId: unlockedSeasonTeam.season.id,
              externalId: coach.externalId,
              firstName: coach.firstName,
              lastName: coach.lastName,
              position: coach.position,
              status: "coaching",
              signature: "",
            },
          },
        }),
      }).then((res) => res.json());
    });
  }, [JSON.stringify(coaches), userToken, unlockedSeasonTeam]);

  // -------------------------------------------------------------------------------------------------------------------| expose

  return {
    // state values
    records,
    status,
    error,
    invalidRecords,
    players,
    coaches,
    playerImportProgress,
    coachImportProgress,

    // data
    team: selectedTeam,
    clearData: resetImport,

    // handlers
    handleFileSelection,
    handleStartImport,
    handleDownloadTemplate,
  };
}

export const saveAs = (blob: Blob, name: string) => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.style.display = "none";
  a.href = url;
  a.download = name;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
};
