import { useCallback, useState } from "react";

export function useAppNavigation() {
  
  const [destination, navigate] = useState<string>("");
  const hasNavigation = destination !== "";

  const handleNavigation = useCallback((path: string) => {
    return () => navigate(path);
  }, [navigate]);

  return {
    navigate,
    destination,
    hasNavigation,
    handleNavigation
  }

}
