import { atom, selector } from "recoil";
import { CoachFormData } from "@/state/data/useCoachData";
import { selectedPersonState } from "./atom.lineupFlowState";
import { LineupDataState } from "./useLineup";

export const coachFormDataState = atom({
  key: "coachFormDataState",
  default: {
    id: "",
    firstName: "",
    lastName: "",
    position: "",
    rosterLocked: false,
    teamId: "",
  } as CoachFormData,
});

export const coachFormValidityState = selector({
  key: "coachFormValidityState",
  get: ({ get }) => {
    const coachFormData = get(coachFormDataState);
    const { firstName, lastName } = coachFormData;
    return firstName.length > 0 && lastName.length > 0;
  },
});

export const coachFormIsDifferentState = selector({
  key: "coachFormIsDifferentState",
  get: ({ get }) => {
    const coachFormData = get(coachFormDataState);
    const selectedPerson = get(selectedPersonState);

    if (!selectedPerson) {
      return true;
    }

    // Iterate over the properties of coachFormData and compare them with selectedPerson
    for (const key in coachFormData) {
      if (coachFormData.hasOwnProperty(key)) {
        if (coachFormData[key as keyof CoachFormData] !== selectedPerson[key as keyof CoachFormData]) {
          return true;
        }
      }
    }

    return false;
  },
});

export const hasHeadCoachSelector = selector({
  key: "hasHeadCoachSelector",
  get: ({ get }) => {
    const lineupData = get(LineupDataState); // Access the current state of LineupDataState
    const coaches = lineupData.localLineupRecord.lineup.coaches; // Access the coaches array

    // Check if any of the coaches have a position of "head_coach"
    const hasActiveHeadCoach = coaches.some((coach) => coach.position === "head_coach" && coach.status === "coaching");

    return hasActiveHeadCoach;
  },
});
