import { useEffect, useState } from "react";
import styled from "styled-components";
import { Modal, Button } from "@gamesheet/ui";
import { LineupFlowHook } from "@/flows/LineupFlow/state/useLineupFlow";
import { useLineupData } from "@/flows/LineupFlow/state/useLineup";

import { usePlayerNotParticipating } from "./usePlayerNotParticipating";

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgb(0, 0, 0, 0.5);
  z-index: 5200;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
`;

const ConfirmButtonContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  padding-top: 0.5rem;
`;

const Divider = styled.div`
  border: 1px solid #d7d7d8;
  margin: 0 -1rem;
`;

type PlayerNotParticipatingProps = {
  flow: LineupFlowHook;
};

export function PlayerNotParticipating({ flow }: PlayerNotParticipatingProps) {
  const { isOpen, close } = usePlayerNotParticipating();
  const { LocalLineupData } = useLineupData();
  const [showSuspension, setShowSuspension] = useState(false);
  const [suspension, setSuspension] = useState({ currentGame: "", totalGames: "" });

  const handleNotParticipating = (status: string) => {
    if (status === "suspended") {
      setShowSuspension(true);
    } else {
      LocalLineupData.setSelectedStatus(status);
      close();
    }
  };

  useEffect(() => {
    if (!isOpen) {
      // Reset suspension-related state when modal is closed
      setShowSuspension(false);
      setSuspension({ currentGame: "", totalGames: "" });
    }
  }, [isOpen]);

  const handleCurrentGameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Extract only digits from the input value
    const value = event.target.value.replace(/\D/g, "");
    setSuspension((prevSuspension) => ({
      ...prevSuspension,
      currentGame: value,
    }));
  };

  const handleTotalGamesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Extract only digits from the input value
    const value = event.target.value.replace(/\D/g, "");
    setSuspension((prevSuspension) => ({
      ...prevSuspension,
      totalGames: value,
    }));
  };

  const handleSubmit = () => {
    const currentGame = suspension.currentGame === "" ? 0 : parseInt(suspension.currentGame);
    const totalGames = suspension.totalGames === "" ? 0 : parseInt(suspension.totalGames);

    LocalLineupData.setSuspensionById(LocalLineupData.getSelectedPlayerGoalieOrCoach()?.data?.id || "", currentGame, totalGames);
    close();
  };

  const isValidSubmission = () => {
    const currentGameInt = parseInt(suspension.currentGame || "0", 10);
    const totalGamesInt = parseInt(suspension.totalGames || "0", 10);

    // Check if both have values and currentGame is less than or equal to totalGames
    return suspension.currentGame.trim() !== "" && suspension.totalGames.trim() !== "" && currentGameInt <= totalGamesInt;
  };

  if (!isOpen) {
    return <></>;
  }

  return (
    <ModalContainer>
      <Modal title={!showSuspension ? "Reason for Not Participating" : "Suspension Details"} show={isOpen} onClose={close}>
        <ButtonContainer>
          {!showSuspension ? (
            <ConfirmButtonContainer>
              {LocalLineupData.getSelectedPlayerGoalieOrCoach()?.type !== "coach" && <Button size="lg" label="Not Playing" onClick={() => handleNotParticipating("not_playing")} />}
              {LocalLineupData.getSelectedPlayerGoalieOrCoach()?.type !== "coach" && <Button size="lg" label="injured" onClick={() => handleNotParticipating("injured")} />}
              {LocalLineupData.getSelectedPlayerGoalieOrCoach()?.type === "coach" && <Button size="lg" label="Away" onClick={() => handleNotParticipating("away")} />}
              <Button size="lg" label="Suspended" onClick={() => handleNotParticipating("suspended")} />
            </ConfirmButtonContainer>
          ) : (
            <>
              <div style={{ textAlign: "center" }}>Serving</div>
              <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
                <div className="form-floating mb-2 mb-md-3">
                  <input type="text" inputMode="numeric" pattern="\d*" name="number" id="number" className="form-control" onChange={handleCurrentGameChange} value={suspension.currentGame} />
                  <label htmlFor="number" className="floating">
                    Current Game
                  </label>
                </div>
                <div>Of</div>
                <div className="form-floating mb-2 mb-md-3">
                  <input type="text" inputMode="numeric" pattern="\d*" name="length" id="length" className="form-control" onChange={handleTotalGamesChange} value={suspension.totalGames} />
                  <label htmlFor="length" className="floating">
                    Total Games
                  </label>
                </div>
              </div>
            </>
          )}
          {showSuspension && <Divider />}

          {!showSuspension ? (
            <Button size="lg" variant="inverted" label="Cancel" onClick={close} />
          ) : (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button size="lg" variant="inverted" label="Cancel" onClick={close} />
              <Button size="lg" label="Save" variant={!isValidSubmission() ? "muted" : ""} onClick={handleSubmit} disabled={!isValidSubmission()} />
            </div>
          )}
        </ButtonContainer>
      </Modal>
    </ModalContainer>
  );
}
