import { MouseEvent, useCallback, useState } from "react";
import { atom, useRecoilCallback, useRecoilState } from "recoil";

type EventCallback = (...args:any[]) => any;
const eventsMap = new Map<string, Set<EventCallback>>()

export function useEventsState(){

    const add = (key:string, callback:EventCallback) => {
        
        const set = eventsMap.get(key) || new Set()
        set.add(callback)
        eventsMap.set(key, set)
        return () => remove(key, callback)
    }

    const remove = (key:string, callback:EventCallback) => {
        
        eventsMap.get(key)?.delete(callback)
    }

    const trigger = useCallback((key:string, ...args:any[]) => {
        
        eventsMap.get(key)?.forEach(callback => {
            
            callback(...args)
        })
    }, [JSON.stringify(eventsMap)])

    const handler = useCallback((key:string) => {
        return (event:MouseEvent<HTMLElement>) => {
            
            event.preventDefault()
            trigger(key)
        }
    }, [])

    return {
        add,
        remove,
        trigger,
        handler,
    }

}