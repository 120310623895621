import { useUserState } from '@/state/user/useUserState';
import config from '../../config';

export const useScoresheetService = () => {

    const user = useUserState();

    return {
        fetchScoresheet: (gameId: string) => {
            const url = `${config.services.scoresheetExportApi}/service.scoresheets/v4/get-game/${gameId}`;

            return fetch(url, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${user.tokens.access}`,
                },
            })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to fetch scoresheet');
                }
                return response.blob();
            })
            .then((blob) => {
                // Create blob link to download
                const downloadLink = document.createElement('a');
                downloadLink.setAttribute('target', 'gamesheet-scoresheet');
                downloadLink.href = URL.createObjectURL(blob);
                downloadLink.download = `GameSheet-Game-${gameId}.pdf`;
                downloadLink.click();

                // Cleanup
                URL.revokeObjectURL(downloadLink.href);
            })
            .catch((error) => {
                throw new Error(error.message);
            });
        }
    }

}