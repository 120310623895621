import { Title } from "@gamesheet/ui";
import styled from "styled-components";

const ErrorMessageContainer = styled.div`

margin:0 auto;
margin-top:105px;
max-width:1280px;

`

export function ErrorMessage({message}: {message: string}) {

    return (
        <ErrorMessageContainer>
            <Title bartype="plain" size="h1">Offside!</Title>
            We regret to inform you that something went horribly wrong.<br/>
            The person responsible has been benched.
        </ErrorMessageContainer>
    )

}