import countries from 'i18n-iso-countries';
const en = require("i18n-iso-countries/langs/en.json");
en.countries.US.push("United States");
countries.registerLocale(en);

export function country( value: string ){

    // 2 and 3 character country codes are only valid server-side when uppercase
    if(value && value.length <= 3){
        value = value.toUpperCase();
    }
    
    switch(true){
        case value && value.length === 2: return countries.isValid(value) ? value : "";
        case value && value.length > 2: return countries.getAlpha2Code(value, "en");
        default: return "";
    }

}