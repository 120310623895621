export function mergeByExternalId( records: any[] ){

    records = records.reverse();
    
    // create a mapping of the last occurance of a player by externalId, ignoring blank externalIds
    const externalIdIndex = new Map();
    records.forEach(( record, i ) => {
        if(record.externalId){
            externalIdIndex.set(record.externalId, i);
        }
    });

    externalIdIndex.forEach(( index, externalId ) => {
        records[ index ] = {
            ...records[ index ],
            teamIds: [ records[ index ].teamId ]
        }
    });

    // find entries of players with duplicate externalId's, and merge their truthy values into the top entry from the externalIdIndex
    // reverse, values go bottom to top
    let duplicates = records.filter(( record, i ) => record.externalId && externalIdIndex.get(record.externalId) !== i );
    
    // mutaute the player to only keep truthy values
    // strip out keys that are falsey
    // append the [key]:value of a player if it's truthy
    duplicates = duplicates.map( record => {
        return Object.keys(record).reduce(( partialRecord, key ) => {    
            return !!record[key] && { ...partialRecord, [key]: record[key] } || partialRecord;
        }, {});
    });

    // merge the truthy values of subsequent player records into the last one
    duplicates.forEach( partialRecord => {
        const index = externalIdIndex.get(partialRecord.externalId);
        records[ index ] = { ...records[ index ], ...partialRecord, teamIds: [ ...records[ index ].teamIds, partialRecord.teamId ] }
    });
      
    return records.filter(( record, i ) => !record.externalId || externalIdIndex.get(record.externalId) === i);

}