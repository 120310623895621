import { collection, where, onSnapshot, query } from "@firebase/firestore";
import { useEffect, useCallback, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useAppState } from "@/state/app/useAppState"
import { rawRosterState, rosterState } from "./atom.rosterstate";
import { RosterCoach, RosterPlayer } from "./types";
import { useRosterService } from "@/services/useRosterService";
import { StoredRoster } from "@/services/useRosterService/types";
import { ActiveRosterState } from "./selector.activeroster";

export function useReadActiveRosterData(){

    const app = useAppState()
    const RosterService = useRosterService()
    const setRoster = useSetRecoilState(rawRosterState)
    const roster = useRecoilValue(ActiveRosterState)
    
    const onRosterChange = useCallback((partial:Partial<StoredRoster>) => {
        setRoster(r => ({ ...r, ...partial, }))
    }, [])

    useEffect(() => {
        return RosterService.listenToActiveRoster(app.connections.firestore, onRosterChange, app.selectedTeam)
    }, [ app.selectedTeam ])

    return { 
        roster
    }

}