import { firestore } from "@/libs/firebase";
import { DocumentData, QuerySnapshot, addDoc, collection, limit, onSnapshot, or, query } from "firebase/firestore";
import { orderBy } from "firebase/firestore";
import { Lineup } from "../types";
import config from "@/config";
import { useUserState } from "@/state/user/useUserState";

export function useLineupService(){

    const User = useUserState()
    const lineups = (gameId:number) => collection(firestore, `games/${gameId}/lineups`)
    
    // snapshot of the lineup data in firestore
    const listenToGameLineup = (gameId:number, onSnapshotHandler: (snapshot: QuerySnapshot<DocumentData>)=>void) => {
        const latestLineup = query(lineups(gameId), orderBy("createdAt","desc"), limit(1))
        return onSnapshot(latestLineup, onSnapshotHandler)
    }
    
    // publish lineup
    const publishLineup = async (gameId:number, lineup: Lineup) => {
        addDoc(lineups(gameId), {
            lineup,
            createdAt: Date.now(),
            locked: false
        })
    }
    
    // send roster changes
    const setRoster = async (gameId:number, lineup: Lineup) => {
        
        fetch(`${config.services.bffApi}/set-roster`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${User.tokens.access}`
            },
            body: JSON.stringify({
                gameId,
                lineup
            })
        
        })

    }

    return {
        listenToGameLineup,
        publishLineup,
        setRoster
    }

}