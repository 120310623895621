import { Button, ButtonGroup, DatePicker } from "@gamesheet/ui";
import React from "react";
import styled from "styled-components";

const StyledRepeatWidget = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  gap: 1rem;
  max-width: 314px;

  .button {
    width: 44.8px;
    text-align: end;
  }
`;

const IntervalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
`;

type RepeatWidgetProps = {
  date: Date;
  setDate: (date: Date) => void;
  interval: number;
  setInterval: (interval: number) => void;
  days: RepeatDay[];
  setDays: (days: RepeatDay[]) => void;
  readableText?: string;
};

export type RepeatDay = {
  active: boolean;
  value: RRuleWeekDayType;
  label: "S" | "M" | "T" | "W" | "F";
};

export type RRuleWeekDayType = "MO" | "TU" | "WE" | "TH" | "FR" | "SA" | "SU";

const RepeatWidget: React.FC<RepeatWidgetProps> = ({ days, setDays, date, setDate, interval, setInterval, readableText }) => {

  const [displayedInterval, setDisplayedInterval] = React.useState<string | number >(interval ? interval : "");

  const toggleDay = (dayValue: RepeatDay["value"]) => {
    const updatedDays = days.map((day) => {
      if (day.value === dayValue) {
        return { ...day, active: !day.active };
      }
      return day;
    });
    setDays(updatedDays);
  };

  const handleIntervalChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Ensure the entered value is a number and limit it to two digits
    const newValue = e.target.value.replace(/\D/g, "").slice(0, 2);

    setDisplayedInterval(newValue);
    setInterval(Number(newValue));

  };

  const handleIntervalBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    // If the user clicks away without entering a new value, set the interval to 1
    if (!e.target.value || e.target.value === "0") {
      setInterval(1);
      setDisplayedInterval(1);
    }
  };

  return (
    <StyledRepeatWidget>
      <IntervalContainer>
        Repeat every{" "}
        <div className="form-outline">
          <input style={{ width: "60px" }} type="number" id="typeNumber" className="form-control" onBlur={handleIntervalBlur} onChange={handleIntervalChange} defaultValue={interval} value={displayedInterval}/>
        </div>
        week
      </IntervalContainer>
      <ButtonGroup size="xl" style={{ border: "1px solid #86888B" }}>
        {days.map((day) => (
          <Button
            textColor={day.active ? "#fff" : "#86888B"}
            backgroundColor={day.active ? "##36383D" : "#fff"}
            variant={day.active ? "inverted" : "muted"}
            key={day.value}
            label={day.label}
            onClick={() => toggleDay(day.value)}
          />
        ))}
      </ButtonGroup>
      <DatePicker label="End Date" value={date} setValue={setDate} dateFormatOptions={{
        weekday: "long" as const,
        month: "short" as const,
        day: "numeric" as const,
        year: "numeric" as const,
        }} 
        hideValidation
      />
      <div style={{ color: "#36383D" }}>{readableText}</div>
    </StyledRepeatWidget>
  );
};

export default RepeatWidget;
