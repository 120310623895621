import { Firestore, collection, onSnapshot, query, where, and } from "firebase/firestore";
import { StoredRoster } from "./types";
import { listenToRoster } from "./func.listenToRoster";

export function listenToActiveRoster( 
    firestore: Firestore,
    onRosterChange: (roster:Partial<StoredRoster>) => void,
    prototeamId: string
){
    
    // guard
    if(!prototeamId) return () => {}
    
    // declarations
    //const ignoreLockedArchivedCondition = and(where("rosterLocked", "==", false), where("season.archived", "!=", true));
    const playersQuery = query(collection(firestore, `teams/${prototeamId}/players`))
    const coachesQuery = query(collection(firestore, `teams/${prototeamId}/coaches`))
    return listenToRoster(playersQuery, coachesQuery, onRosterChange)

}