import { atom, selector } from 'recoil';

export interface EventData {
  id: number;
  title: string;
  timezone: string;
  startDateTime: Date | null;
  endDateTime: Date | null;
  location: string;
  notes: string;
  type: string;
  recurrence: string;
  eventGroup: string;
}

export const recurrenceData = atom<string>({
  key: 'recurrenceData',
  default: '',
});

export const rawEventData = atom<{ status: string; data: EventData }>({
  key: 'rawEventData',
  default: {
    status: 'loading',
    data: {
      id: 0,
      title: '',
      timezone: '',
      startDateTime: null,
      endDateTime: null,
      location: '',
      notes: '',
      type: '',
      recurrence: '',
      eventGroup: '',
    },
  },
});

export const eventData = selector<{ status: string; data: EventData }>({
  key: 'eventDataSelector',
  get: ({ get }) => {
    const rawEventDataState = get(rawEventData);

    return {
      status: rawEventDataState.status,
      data: {
        id: rawEventDataState.data.id,
        title: rawEventDataState.data.title,
        timezone: rawEventDataState.data.timezone,
        startDateTime: rawEventDataState.data.startDateTime,
        endDateTime: rawEventDataState.data.endDateTime ,
        location: rawEventDataState.data.location,
        notes: rawEventDataState.data.notes,
        type: rawEventDataState.data.type,
        recurrence: rawEventDataState.data.recurrence,
        eventGroup: rawEventDataState.data.eventGroup,
      },
    };
  },
});
