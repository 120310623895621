import { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { locationQueryState, locationInputFocusState } from '../QueryInput/state/atoms';

import { hideLocationResultsState } from './state/hideResults';

type Venue = {
  name: string;
  city: string;
  province: string;
  postalCode: string;
  surfaces: Surface[];
};

type Surface = {
    name: string;
    id: string;
    compositeKey: string;
}

type UseSearchResultsProps = {
  hits: any[]; // Replace 'any' with the actual type of 'hits' if available
};

type UseSearchResultsResult = {
  venues: Venue[];
  setLocation: (compositeKey: string) => void;
};

const useSearchResults = ({ hits }: UseSearchResultsProps): UseSearchResultsResult => {
  const setLocationQuery = useSetRecoilState(locationQueryState);
  const setlocationInputFocus = useSetRecoilState(locationInputFocusState);
  const hideLocationResults = useRecoilValue(hideLocationResultsState);
  const [venues, setVenues] = useState<Venue[]>([]);

  const setLocation = (compositeKey: string) => {
    setLocationQuery(compositeKey);
    setlocationInputFocus(false);
  };

  useEffect(() => {
    if (!hideLocationResults && hits) {
      const newVenues: Venue[] = hits.map((hit) => ({
        name: hit.name,
        city: hit.city,
        province: hit.province.name,
        postalCode: hit.postalCode,
        surfaces: hit.surfaces.map((surface: Surface) => ({
          name: surface.name,
          id: surface.id,
          compositeKey: surface.compositeKey,
        })),
      }));
      setVenues(newVenues);
    } else {
      if (venues.length > 0) {
        setVenues([]);
      }
    }
  }, [hits, hideLocationResults]);

  return {
    venues,
    setLocation,
  };
};

export default useSearchResults;
