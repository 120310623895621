import { atom, useRecoilState } from "recoil";
import { ExtendedPlayerFormData } from "../types";

// Define a new atom state to manage the list of player IDs with matching jerseys
export const MatchingJerseyPlayersState = atom<string[]>({
  key: "MatchingJerseyPlayersState",
  default: [],
});

// Use this hook to manage the list of player IDs with matching jerseys
export function useMatchingJerseyPlayers() {
  const [matchingPlayers, setMatchingPlayers] = useRecoilState(MatchingJerseyPlayersState);

  const updateMatchingPlayers = (lineup: ExtendedPlayerFormData[]) => {
    const jerseyCounts: { [jersey: string]: number } = {};

    // Filter players with "playing" status and count the occurrences of each jersey number
    lineup.forEach((player) => {
      if (player.status === "playing" || player.status === "starting") {
        jerseyCounts[player.jersey] = (jerseyCounts[player.jersey] || 0) + 1;
      }
    });

    // Filter the players with matching jerseys who have "playing" status
    const matchingPlayerIds: string[] = [];
    lineup.forEach((player) => {
      if ((player.status === "playing" || player.status === "starting") && jerseyCounts[player.jersey] > 1) {
        matchingPlayerIds.push(player.id);
      }
    });

    // Update the atom state with the list of player IDs with matching jerseys
    setMatchingPlayers(matchingPlayerIds);
  };

  return {
    matchingPlayers,
    updateMatchingPlayers,
  };
}
