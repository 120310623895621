
import styled from "styled-components";
import { Modal, Button } from "@gamesheet/ui";
import { useState } from "react";
import { BreakPoints } from "@/libs/breakpoints";
import { repeatingEventModalState, useRepeatingEvent } from "./useRepeatingEvent";
import { useRecoilValue } from "recoil";
import { recurrenceRRuleState } from "@/forms/EventForm";
import { recurrenceData } from "@/services/useEventService/atom.eventData";
import { useAppState } from "@/state/app/useAppState";
import { PageLoading } from "@/components/pure/PageLoading";

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgb(0, 0, 0, 0.5);
  z-index: 5200;

  .gs-modal {
    margin: 0 1rem;
    width: 30rem;
  }

  .gs-modal-content {
    padding: 0 !important;
  }

  @media only screen and (max-width: ${BreakPoints.md}) {
    .gs-modal .gs-modal-content {
      padding-bottom: 0;
    }
  }
`;

const Divider = styled.div`
  border: 1px solid #d7d7d8;
  margin-bottom: 1rem;
`;
const InfoText = styled.div`
 color:  #404247;
font-family: Rubik;
font-size: 1rem;
font-style: italic;
font-weight: 400;
line-height: normal;
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem 1rem 1rem;
  gap: 0.5rem;
  

  .button {
    font-size: 1rem !important;
    font-weight: 500 !important;
    width: 100%;
  }

  @media only screen and (max-width: ${BreakPoints.md}) {
    flex-wrap: wrap-reverse;
  }
`;

const Header = styled.div`
font-size: 1rem;
font-style: normal;
font-weight: 500;
line-height: normal;
`;

const ContentContainer = styled.div`
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: column;
  gap: .5rem;
`;

export function RepeatingEventModal() {
  const { isOpen, close, onChangeOccurrence } = useRepeatingEvent();
  const app = useAppState();
  const showLoading = !app.hasSelectedTeam || !app.loading.isComplete('events')

  //TODO: If event.event_group != "" AND recurrence rule has changed, than the user cannot select edit only this event. 
  // TODO: I might have this done, but I would like eyes on it.
  const recurrenceFromLoad = useRecoilValue(recurrenceData)
  const recurrenceFromForm = useRecoilValue(recurrenceRRuleState)


  const eventData = useRecoilValue(repeatingEventModalState).eventData;

  const isEventGroupNotEmpty = eventData?.eventGroup.value !== "";
  const isRecurrenceChanged = recurrenceFromForm !== recurrenceFromLoad;

  if (showLoading) {
    return <PageLoading />
  }

  if (!isOpen) {
    return <></>;
  }


  return (
    <ModalContainer>
      <Modal title="Repeating Event Modification" show={isOpen} onClose={close}>
        <ContentContainer>
            <Header>You are editing a repeating event. </Header>
          <InfoText>Do you want to change only this occurrence of the event, this and all future occurrences, or all occurrences?</InfoText>
        </ContentContainer>

        <Divider />

        <FooterContainer>
         {isEventGroupNotEmpty && !isRecurrenceChanged && <Button label="Edit only this event" onClick={() => onChangeOccurrence("single")} />}
          <Button label="Edit this and all future events" onClick={() => onChangeOccurrence("future")} />
          <Button label="Edit all instances" onClick={() => onChangeOccurrence("all")} />
          <Button style={{marginTop: "10px"}} variant="inverted" label="Cancel" onClick={close} />
        </FooterContainer>
      </Modal>
    </ModalContainer>
  );
}
