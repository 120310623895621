import { atom, selector } from 'recoil';
import { ScheduleGameFormData, RawScheduledGameData } from './types';
import { seasonsDataState } from '@/state/data/useSeasonTeams';

// this used to be used to get the default value for date_time, now left here for reference
const getDate = () => {
    const currentDate = new Date();

    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(currentDate.getDate()).padStart(2, '0');
    const hours = String(currentDate.getHours()).padStart(2, '0');
    const minutes = String(currentDate.getMinutes()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}`;

    return formattedDate;
}

export const rawScheduledGameData = atom<{status: string, data: RawScheduledGameData}>({
    key: 'rawScheduledGameData',
    default: { status: 'loading', data: {game_id: 0, season_id: 0, association_id: 0, league_id: 0, home_division_id: 0, home_id: 0, visitor_division_id: 0, visitor_id: 0, date_time: "", end_time: "", date_time_gmt: "", time_zone_name: "", game_number: "", game_type: "", location: "", scorekeeper_name: "", scorekeeper_phone: "", broadcast_provider: ""}}
});

export const scheduledGameData = selector<{status: string, data: ScheduleGameFormData}>({
    key: 'scheduledGameData',
    get: ({get}) => {
        
        const rawScheduledGameDataState = get(rawScheduledGameData);
        const seasons = get(seasonsDataState);

        var home = true
        var uTeamId
        var uDivId
        var oTeamId
        var oDivId
        var found = false
        
        seasons.teams?.forEach((team: any) => {
            // Check if the team's ID matches the home or away ids
            if (team.id == rawScheduledGameDataState.data.visitor_id) {
                if (!found) home = false;
            } 
            if (team.id == rawScheduledGameDataState.data.home_id) {
                if (!found) home = true;
            }
        });

        if (home == true) {
            uTeamId = rawScheduledGameDataState.data.home_id
            uDivId = rawScheduledGameDataState.data.home_division_id
            oTeamId = rawScheduledGameDataState.data.visitor_id
            oDivId = rawScheduledGameDataState.data.visitor_division_id
        } else {
            uTeamId = rawScheduledGameDataState.data.visitor_id
            uDivId = rawScheduledGameDataState.data.visitor_division_id
            oTeamId = rawScheduledGameDataState.data.home_id
            oDivId = rawScheduledGameDataState.data.home_division_id
        }

        const endTime = !rawScheduledGameDataState.data?.end_time ? "" : rawScheduledGameDataState.data?.end_time.split('T')[1];

        return(
            {
                status: rawScheduledGameDataState.status ,data:{
                gameId: rawScheduledGameDataState.data.game_id,
                seasonId: rawScheduledGameDataState.data.season_id,
                associationId: rawScheduledGameDataState.data.association_id,
                leagueId: rawScheduledGameDataState.data.league_id,
                homeFlag: home,
                teamId: uTeamId,
                divisionId: uDivId,
                opposingTeamId: oTeamId,
                opposingDivision: oDivId,
                endTime,
                timeZoneName: rawScheduledGameDataState.data.time_zone_name,
                dateTime: rawScheduledGameDataState.data.date_time ? new Date(rawScheduledGameDataState.data.date_time) : null,
                gameNumber: rawScheduledGameDataState.data.game_number,
                gameType: rawScheduledGameDataState.data.game_type,
                location: rawScheduledGameDataState.data.location,
                scorekeeperName: rawScheduledGameDataState.data.scorekeeper_name,
                scorekeeperPhone: rawScheduledGameDataState.data.scorekeeper_phone,
                broadcastProvider: rawScheduledGameDataState.data.broadcast_provider,
            }
        });
    }
})