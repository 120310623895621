import { FormFieldType } from '@/forms/hooks/useCreateForm';
import { Button } from '@gamesheet/ui';
import Visible from '@mui/icons-material/Visibility';
import Hidden from '@mui/icons-material/VisibilityOff';
import { useState } from 'react';
import { styled } from 'styled-components';

type InputProps = {
    className?: string[]
    state: FormFieldType,
    name: string
    label: string
    autocomplete?:boolean
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const Input = styled.input`
~.floating{
    width: 100%;
}
`

const Control = styled.a.attrs({
    className: 'control'
})`
display: block;
position: absolute;
right: 32px;
top: 15px;
z-index: 1000;
color: #D7D7D8;

&:hover{
    cursor: pointer;
    color: #36383D
}
`

export function PasswordInput({ className=[], state, name, label, onChange, autocomplete=true }:InputProps){

    const [isDirty, setIsDirty] = useState(false)    
    const testResult = isDirty ? state?.valid ? "valid" : "invalid" : "";
    const inputClass = `is-${testResult}`
    const labelClass = `${testResult}-feedback`

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange(e)
    }

    const handleFirstValidation = (e: React.ChangeEvent<HTMLInputElement>) => {
        handleChange(e)
        setIsDirty(true)
    }
    
    // password privacy
    const [ privacy, setPrivacy ] = useState('on')
    const togglePrivacy = () => setPrivacy(privacy === 'on' ? 'off' : 'on')
    const Icon = privacy === 'on' ? Visible : Hidden
    const inputType = privacy === 'on' ? 'password' : 'text'

    // Warning: A component is changing an uncontrolled input to be controlled. 
    // This is likely caused by the value changing from undefined to a defined value, which should not happen. 
    // Decide between using a controlled or uncontrolled input element for the lifetime of the component. 
    // More info: https://reactjs.org/link/controlled-components
    if(!state){
        return <></>
    }

    return <>
        <div className={`form-floating ${className.join(" ")}`} style={{
            position: 'relative'
        }}>
            
            <Input
                required
                type={inputType}
                name={name}
                id={name}
                className={`form-control ${inputClass}`}
                placeholder={label}
                onChange={handleChange}
                onBlur={handleFirstValidation}
                value={state?.value}
                role={autocomplete ? "textbox" : "presentation"}
                autoComplete={autocomplete ? "on" : "off"}
            />
            <label htmlFor={name} className="floating">
                <div>{label}</div>
            </label>
            <Control onClick={togglePrivacy}>
                <Icon fontSize='medium' />
            </Control>
            
            { !state?.valid && testResult && <span className={labelClass}>{state?.error}</span> }

        </div>
        
    </>
}