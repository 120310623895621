import styled from "styled-components";

export const StyledFloatingMenu = styled.div`
display:flex;
flex-direction:column;
row-gap: 5px;
align-items: flex-end;
z-index: 4150;
position: relative;

.button {
    display: ilnine-block;
}
`
type MenuProps = {
    $dropup?: boolean | number
}

export const Menu = styled.div<MenuProps>`
display: flex;
flex-direction:column;
row-gap: 5px;
align-items: flex-end;
position: absolute;
width: 100vw;
bottom: ${p => p.$dropup ? "42px" : "auto" };
top: ${p => p.$dropup ? "auto" : "42px"};
`