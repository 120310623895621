import styled from "styled-components";
import { useUserState } from "@/state/user/useUserState";
import { Modal, Card } from "@gamesheet/ui";
import appleicon from "@/assets/images/calendar_icon_apple.png";
import googleicon from "@/assets/images/calendar_icon_google.png";
import copyicon from "@/assets/images/calendar_icon_copy.svg";
import { useSelectedTeamState } from "@/state/team/useSelectedTeamState";
import { useCallback } from "react";
import { pushToDataLayer } from "@/libs/analytics";

export type CalendarSyncModalProps = {
  onClose: () => void;
};

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgb(0, 0, 0, 0.5);
  z-index: 5200;
  font-family: "Rubik", Arial, sans-serif;

  background-size: cover;
  background-position: right;
  background-attachment: fixed;

  // move card content text further away from card icon
  .card-content {
    padding-left: 0.75rem;
  }

  // all space around the card icon will come from other places
  .card-content-container img {
    margin: 0;
    padding: 0;
    width: 50px; // otherwise the svg is too small
  }

  .card {
    cursor: pointer;
  }
`;

export const TextBefore = styled.p`
  margin-bottom: 0.5rem;
`;

export const TextAfter = styled.p`
  text-align: center;
  margin-top: 0.4rem;
  margin-bottom: 0.5rem;
`;

export const ButtonGroup = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const CalendarSyncModal = ({ onClose }: CalendarSyncModalProps) => {
  const selectedTeam = useSelectedTeamState()
  const user = useUserState();

  const urls = getCalendarUrls({
    userEmail: user.email || "",
    prototeamId: selectedTeam?.id || "",
  });

  const trackSubscriptionType = useCallback((selection: string) => {
    pushToDataLayer({
      event: 'calendar_subscription_choice',
      subscription_type: selection
  });

}, []);

  const fakeClick = ({ url, newTab, selection }: { url: string, newTab: boolean, selection: string }) => {
    trackSubscriptionType(selection);

    const a = document.createElement("a");
    document.body.appendChild(a);

    a.href = url;
    if (newTab) a.target = "_blank";
    a.click();

    document.body.removeChild(a);
  }

  return (
    <ModalContainer>
      <Modal title="Subscribe To Calendar" show={true} onClose={onClose}>
        <Card.Card handleClick={() => fakeClick({ url: urls.apple, newTab: false, selection: 'Apple Calendar'  })}>
          <div>
            {" "}
            <Card.CardContentContainer
              header="Apple Calendar"
              child={`Subscribe to ${selectedTeam?.title}'s schedule in Apple Calendar`}
              imgUrl={appleicon}
            />
          </div>
          <Card.CardIconContainer></Card.CardIconContainer>
        </Card.Card>
        <Card.Card handleClick={() => fakeClick({ url: urls.google, newTab: true, selection: 'Google Calendar' })}>
          <div>
            {" "}
            <Card.CardContentContainer
              header="Google Calendar"
              child={`Subscribe to ${selectedTeam?.title}'s schedule in Google Calendar`}
              imgUrl={googleicon}
            />
          </div>
          <Card.CardIconContainer></Card.CardIconContainer>
        </Card.Card>
        <Card.Card handleClick={() => {trackSubscriptionType('Calendar URL'); navigator.clipboard.writeText(urls.raw)}}>
          <div>
            {" "}
            <Card.CardContentContainer
              header="Calendar URL"
              child={`Copy ${selectedTeam?.title}'s calendar URL to your clipboard`}
              imgUrl={copyicon}
            />
          </div>
          <Card.CardIconContainer></Card.CardIconContainer>
        </Card.Card>
        <p style={{ marginBottom: 0 }}>
          Get the full picture by syncing your team's game scheduled with your
          everyday calendar.
        </p>
      </Modal>
    </ModalContainer>
  );
};

export const getCalendarUrls = ({
  userEmail,
  prototeamId,
}: {
  userEmail: string;
  prototeamId: string;
}) => {
  const regularData = {
    tz: -1 * new Date().getTimezoneOffset(),
    email: userEmail,
  };

  const googleData = {
    ...regularData,
    app: "google",
  };

  const regularEncodedData = encodeURIComponent(btoa(JSON.stringify(regularData)));
  const googleEncodedData = encodeURIComponent(btoa(JSON.stringify(googleData)));

  const regularPath = `/get-calendar/${prototeamId}.ics?data=${regularEncodedData}`;
  const googlePath = `/get-calendar/${prototeamId}.ics?data=${googleEncodedData}`;

  return {
    raw: `${window.location.origin}${regularPath}`,
    apple: `webcal://${window.location.host}${regularPath}`,
    google: `https://calendar.google.com/calendar/render?cid=webcal://${window.location.host}${googlePath}`,
  };
};
