import React from "react";
import styled from "styled-components";
import { Title, UserNotice } from "@gamesheet/ui";
import { useLineupData } from "./state/useLineup";
import { BreakPoints } from "@/libs/breakpoints";
import { ExtendedCoachFormData, ExtendedPlayerFormData } from "./types";
import { useAppState } from "@/state/app/useAppState";
import SignaturePad from "./components/SignaturePad";

const StyledPreviewPage = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  padding-top: 1rem;

  @media screen and (min-width: ${BreakPoints.lg}) {
    padding-top: 0;
  }
`;

const StyledPreviewStep = styled.div`
  display: flex;
  flex-direction: column-reverse;
  min-height: 100%;

  .lineup-table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #000;
  }

  .lineup-table td,
  .lineup-table tr {
    border: 1px solid #000;
    padding: 0.5rem 1rem;
    text-align: center;
    color: #000;
    font-family: Rubik;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }

  .jersey-row {
    width: 2rem;
  }

  .not-participating {
    text-decoration-line: line-through;
    text-decoration-color: red;
    -webkit-text-decoration-line: line-through;
    -webkit-text-decoration-color: red;
  }

  .reason {
    text-decoration: none !important;
  }

  .name-position-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    gap: 0.5rem;
  }

  .position {
    min-width: 3rem;
    text-align: right;
  }

  @media screen and (min-width: ${BreakPoints.lg}) {
    flex-direction: row;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media screen and (min-width: ${BreakPoints.lg}) {
    width: 50%;
    padding: 0 0.5rem;
  }
`;

const SignaturePadContainer = styled.div`
  position: sticky;
  bottom: 0px;
  right: 0px;
  display: flex;
  justify-content: center;
  padding: 0 0.5rem;

  @media screen and (min-width: ${BreakPoints.md}) {
    justify-content: flex-end;
  }
`;

const MobileSpacer = styled.div`
  @media screen and (max-width: ${BreakPoints.lg}) {
    padding-bottom: 5rem;
  }
`;

export const PreviewStep: React.FC = () => {
  const app = useAppState();
  const { LocalLineupData } = useLineupData();

  const sanitizeStatus = (player: ExtendedPlayerFormData | ExtendedCoachFormData) => {
    if (!player) return "";
    const { status } = player;
    switch (status) {
      case "playing":
        return "playing";
      case "not_playing":
        return "Not Playing";
      case "injured":
        return "Injured";
      case "away":
        return "Away";
      default:
        return status;
    }
  };

  const { players, goalies, coaches } = LocalLineupData.get().lineup;

  const playingPlayers = players.filter((player) => player.status === "playing");
  const playingGoalies = goalies.filter((goalie) => goalie.status === "playing");
  const playingCoaches = coaches.filter((coach) => coach.status === "coaching");

  const notParticipatingPlayers = players.filter((player) => player.status !== "playing");
  const notParticipatingGoalies = goalies.filter((goalie) => goalie.status !== "playing");

  const totalPlayers = playingPlayers?.length + playingGoalies?.length;
  const totalNotParticipating = notParticipatingPlayers?.length + notParticipatingGoalies?.length;

  const renderPlayerRow = (player: ExtendedPlayerFormData, isGoalie = false) => {
    if (player.status !== "playing") {
      return null;
    }

    return (
      <tr key={player.id}>
        <td className="jersey-row">{player.jersey}</td>
        <td>
          <div className="name-position-row">
            {player.firstName} {player.lastName}
            <div className="position">
              {isGoalie && "G"} {player.duty === "captain" && "C"} {player.duty === "alternate_captain" && "AC"} {player.affiliated === true && "AP"}
            </div>
          </div>
        </td>
      </tr>
    );
  };

  const renderNotParticipatingRow = (person: ExtendedPlayerFormData | ExtendedCoachFormData) => {
    if (person.status === "playing" || person.status === "coaching" || person.status === "suspended") {
      return null;
    }
    return (
      <tr key={person.id}>
        <td className="jersey-row not-participating">{"jersey" in person ? person.jersey : "C"}</td>
        <td>
          <div className="name-position-row ">
            <div className="not-participating">
              {person.firstName} {person.lastName}
            </div>
            <div className="reason">({sanitizeStatus(person)})</div>
          </div>
        </td>
      </tr>
    );
  };

  const renderSuspendedPlayers = (players: (ExtendedPlayerFormData | ExtendedCoachFormData)[]) => {
    const suspendedPlayers = players.filter((person) => person.status === "suspended");

    if (suspendedPlayers.length === 0) {
      return null;
    }

    return (
      <>
        <tr>
          <td colSpan={2}>Suspended Players/Officials No. of Games:</td>
        </tr>
        {suspendedPlayers.map((person) => (
          <tr key={person.id}>
            <td colSpan={2} style={{ paddingLeft: "3.75rem" }}>
              <div className="name-position-row">
                {person.firstName} {person.lastName}
                <div className="position">
                  {person.suspension?.number} of {person.suspension?.length}
                </div>
              </div>
            </td>
          </tr>
        ))}
      </>
    );
  };

  const renderCoaches = (coaches: ExtendedCoachFormData[]) => {
    const positionPriority: { [key: string]: number } = {
      head_coach: 1,
      assistant_coach: 2,
      manager: 3,
      trainer: 4,
    };

    const defaultPriority = 999;

    const sortedCoaches = coaches.slice().sort((a, b) => {
      const priorityA = positionPriority[a.position] || defaultPriority;
      const priorityB = positionPriority[b.position] || defaultPriority;

      if (priorityA !== priorityB) {
        return priorityA - priorityB;
      }

      return a.firstName.localeCompare(b.firstName);
    });

    return sortedCoaches.map((coach) => {
      if (coach.status !== "coaching") {
        return null;
      }
      return (
        <tr key={coach.id}>
          <td>
            <div className="name-position-row">
              {coach.firstName} {coach.lastName}
              <div className="position">{app.helpers.translatePosition(coach.position, true)}</div>
            </div>
          </td>
        </tr>
      );
    });
  };

  return (
    <StyledPreviewPage>
      <StyledPreviewStep>
        <Column>
          <Title bartype="divider" size="h2" text={`Game Lineup (${totalPlayers})`} />

          <table className="lineup-table">
            <tbody>
              {goalies.map((goalie, index) => renderPlayerRow(goalie, true))}
              <tr style={{ padding: 0 }}>
                <td colSpan={2} style={{ padding: 0 }}></td>
              </tr>

              {players.map((player) => renderPlayerRow(player, false))}
            </tbody>
          </table>

          <Title bartype="divider" size="h2" text={`Coaches (${playingCoaches?.length})`} />
          <table className="lineup-table">
            <tbody>{renderCoaches(coaches)}</tbody>
          </table>
          <MobileSpacer />
        </Column>
        <Column>
          <Title bartype="divider" size="h2" text={`Not Participating (${totalNotParticipating})`} />
          <table className="lineup-table">
            <tbody>
              {goalies.map((goalie) => renderNotParticipatingRow(goalie))}
              {players.map((player) => renderNotParticipatingRow(player))}
              {renderSuspendedPlayers([...players, ...goalies, ...coaches]) !== null && (
                <tr style={{ padding: 0 }}>
                  <td colSpan={2} style={{ padding: 0 }}></td>
                </tr>
              )}
              {renderSuspendedPlayers([...players, ...goalies, ...coaches])}
            </tbody>
          </table>
        </Column>
      </StyledPreviewStep>
      <SignaturePadContainer>
        <SignaturePad />
      </SignaturePadContainer>
    </StyledPreviewPage>
  );
};
