import { useEventsState } from "@/state/events/useEventsStats";
import { LineupEvent } from "../types";

export function useLineupEvents(){

    const Events = useEventsState()
    
    const notifyLocked = (event: LineupEvent) => {
        Events.trigger("lineup:locked", event)
    }

    const notifyUnlocked = (event: LineupEvent) => {
        Events.trigger("lineup:unlocked", event)
    }

    const notifyNewVersion = (event: LineupEvent) => {
        Events.trigger("lineup:newVersion", event)
    }

    return {
        notifyLocked,
        notifyUnlocked,
        notifyNewVersion
    }

}