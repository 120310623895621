import { useEffect } from "react";
import { useRecoilState, useResetRecoilState } from 'recoil';
import { rawEventData, recurrenceData } from './atom.eventData';
import { useEventService } from "./useEventService";
import { formatOffset, getOffset, getUserOffsetNow } from "@/libs/timezones";

export const useReadEventData = () => {
    const [eventData, setEventData] = useRecoilState(rawEventData);
    const resetEventData = useResetRecoilState(rawEventData);
    const eventService = useEventService();

    const [recurrence, setRecurrence] = useRecoilState(recurrenceData);

    return {
        ReadEventData: async (eventId: string) => {
            resetEventData();
            try {
                if (eventId !== 'new') {
                    const data = await eventService.GetEventDetails(eventId);
                    if (data.status === 'success') {
                        // Convert date strings to Date objects
                        // We need to interpret the times as if they were in the user's timezone
                        // Unless we're dealing with a legacy event, in which case we need to interpret as being in an arbitrary timezone

                        let offsetStr = formatOffset(getUserOffsetNow());
                        
                        if (!data.data?.start_date_gmt) {
                            const eventOffset = getOffset(data.data.timezone, new Date(data.data.start_date))
                            const myOffset = getUserOffsetNow();
                            offsetStr = formatOffset(myOffset - (eventOffset || 0));                            
                        }

                        const startDate = new Date(data.data.start_date.split("Z")[0] + offsetStr);
                        const endDate = new Date(data.data.end_date.split("Z")[0] + offsetStr);
                        
                        setEventData({
                            status: data.status,
                            data: {
                                ...data.data,
                                id: parseInt(eventId),
                                startDateTime: startDate,
                                endDateTime: endDate,
                                eventGroup: data.data.event_group,
                            }
                        });
                    }

                    setRecurrence(data.data.recurrence);
                    return data.status;
                }
            } catch (error) {
                console.error("Error while updating event data:", error);
                return error;
            }
        }
    }
}
