import { useCallback } from "react";
import { atom, useRecoilState } from "recoil";

type FrozenLineupState = {
  loading: boolean;
  isOpen: boolean;
};

const frozenLineupAtom = atom<FrozenLineupState>({
  key: "frozenLineupAtom",
  default: {
    loading: false,
    isOpen: false,
  },
});

export function useFrozenLineup() {
  const [{ loading, isOpen }, setState] = useRecoilState(frozenLineupAtom);

  const open = useCallback(() => {
    setState((prev) => ({
      ...prev,
      isOpen: true,
    }));

    document.body.style.overflow = "hidden";
  }, [setState]);

  const close = useCallback(() => {
    setState((prev) => ({
      ...prev,
      isOpen: false,
    }));

    document.body.style.overflow = "auto";
  }, [setState]);

  return {
    loading,
    open,
    close,
    isOpen,
  };
}
