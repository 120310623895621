import { selector } from "recoil";
import { AcceptInvitationFlowState } from "./atom.AcceptInvitationFlowState";
import { ConvertInvitationPlayerToRosterPlayer } from "../lib/ConvertInvitationPlayerToRosterPlayer";
import { SampleTeamState } from "./selector.SampleTeam";

export const MergePreviewDataState = selector({
    key: 'MergePreviewDataState',
    get: ({ get }) => {
        const state = get(AcceptInvitationFlowState);
        const rawMergedData = state.rawMergedData;
        const sampleTeam = get(SampleTeamState);
        const prototeam = state.prototeamOptions.linkedTeam;

        if (!sampleTeam || !rawMergedData || !prototeam) {
            return null;
        }

        return {
            ...sampleTeam,
            logo: rawMergedData.logo,
            roster: rawMergedData.players.map((player) => ConvertInvitationPlayerToRosterPlayer(sampleTeam.id, player)),
            prototeam, 
        }
    }
})