import { Config } from "./type";
import { config as defaultConfig } from "./config.default";

const qa:Config = {
    ...defaultConfig,
    name: "QA",
    apps: {
        statsWidget: "https://qa.gamesheetstats.com",
        leagueDashboard: "https://qa.gamesheet.app",
    },
    firebase: {
        apiKey: "AIzaSyBiCavmlIowAvuyxIlJ6DeEHoFvGOuZkuE",
        authDomain: "gamesheet-v4-testing-stack.firebaseapp.com",
        projectId: "gamesheet-v4-testing-stack",
        storageBucket: "gamesheet-v4-testing-stack.appspot.com",
        messagingSenderId: "358351777959",
        appId: "1:358351777959:web:384f890d4dfb6d59036b82",
        measurementId: "G-2B8GJ5V3EC"
    },
    unleash: {
        environment: "development",
        clientKey: "default:development.da9a93bdc34d892b340e9c311d0246724af0a8ebccabef0142515427",
    }
}

const qaLocal:Config = {
    ...qa,
    name: "QA Local",
    settings:{
        ...qa.settings,
        showDumpStateButton: true,
    },
    gateways: {
        data: "https://gateway-data-5weo5hsb7q-nn.a.run.app",
        events: "https://gateway-events-5weo5hsb7q-nn.a.run.app",
        auth: "https://gateway-authserver-5weo5hsb7q-nn.a.run.app",
        eventLog: "https://gateway-eventlog-5weo5hsb7q-nn.a.run.app",
        lookups: "https://lookups.gamesheet.io",
    },
    services: {
        bffApi: "/api", // functions folder
        assetsApi: "https://assetsapi-5weo5hsb7q-nn.a.run.app",
        registryApi: "https://registrysync-v4-5weo5hsb7q-nn.a.run.app",
        scoresheetExportApi: "https://scoresheet-service-5weo5hsb7q-nn.a.run.app",
        unleashApi: "https://features.gamesheet.io/api/frontend",
    },
    apps: {
        ...qa.apps
    },
}

export const config = (process.env.REACT_APP_LOCAL === "true") ? qaLocal : qa;