import { atom } from "recoil";
import { CSVRosterImportStatus as ImportStatus } from "../types/importStatus";

export const componentStatusState = atom<string>({
  key: 'componentStatus',
  default: ImportStatus.PENDING
});

export const recordsState = atom<any[]>({
  key: 'records',
  default: []
});

export const teamIdState = atom<string>({
  key: 'teamId',
  default: ""
});

export const playerImportProgressState = atom<any>({
  key: 'playerImportProgress',
  default: { pending: 0, sent: 0, resolved:0, rejected:0 }
});

export const coachImportProgressState = atom<any>({
  key: 'coachImportProgress',
  default: { pending: 0, sent: 0, resolved:0, rejected:0 }
});

export const playerImportResponsesState = atom<any>({
  key: 'playerImportResponses',
  default: []
});

export const coachesImportResponsesState = atom<any>({
  key: 'coachesImportResponses',
  default: []
});
