import { Alert, FormWrapper } from "@gamesheet/ui";
import { useEffect, useReducer } from "react";
import { MobileRoster } from "@/components/views/Roster/MobileRoster";
import { TabletRoster } from "@/components/views/Roster/TabletRoster";
import { DesktopRoster } from "@/components/views/Roster/DesktopRoster";
import { useAppState } from "@/state/app/useAppState";
import { useSeasonsData } from "@/state/data/useSeasonTeams";
import { Navigate } from "react-router-dom";
import { ActionType } from "./types";
import { RosterButton, TeamDisplay } from "./styles";
import { TeamRosterReducer } from "./reducer";
import { RosterCoach, RosterPlayer } from "@/state/data/useTeamRoster/types";
import { useRosterSelect } from "./useRosterSelect";
import { TeamSelector } from "./TeamSelector";
import { useReadLockedRosterData } from "@/state/data/useTeamRoster/useReadLockedRosters";
import LockImg from "@/assets/images/padlock.png";
import { usePageState } from "@/state/page/usePageState";

export function TeamRosterPage() {

    useReadLockedRosterData()
    
    const app = useAppState()
    const seasonTeams = useSeasonsData()
     // import navigation items. Use navigate('path') to navigate to a specific path.

    const { 
        selected: roster, 
        options: rosterOptions, 
        isLocked: viewingLockedRoster,
        lockedRosters: lockedRosters,
        setSelectedRoster 
    } = useRosterSelect()
    
    const Page = usePageState('team-roster', {
        showActionsMenu: !viewingLockedRoster
    })

    useEffect(() => {
        
        Page.clearButtons()

        if(viewingLockedRoster){
            return;
        }

        Page.addNavButton('/roster/import/registry/usah', 'Import from USA Hockey')
        Page.addNavButton('/roster/import/registry/hcr', 'Import from Hockey Canada')
        Page.addNavButton('/roster/import/csv', 'Import from CSV')
        Page.addNavButton('/roster/player/new', 'Add New Player')
        Page.addNavButton('/roster/coach/new', 'Add New Coach')

    }, [ viewingLockedRoster ])

    useEffect(() => {
        // If there is only one season team and it is locked, select that roster
        if (!seasonTeams.hasUnlockedRoster) {
            seasonTeams.teams.length === 1 && lockedRosters[0] && setSelectedRoster(lockedRosters[0]?.id)
        }
    }, [ JSON.stringify(seasonTeams), JSON.stringify(lockedRosters), setSelectedRoster ])

    const [state, dispatch] = useReducer(TeamRosterReducer, {
        rosterSelectOpen: false
    });

    const rosterSelectOpen = state.rosterSelectOpen
    const toggleRosterSelectMenu = () => dispatch({ type: ActionType.TOGGLE_ROSTER_SELECT, state:{ rosterSelectOpen } })

    const onClickCoach = (coach: RosterCoach) => {
        if (viewingLockedRoster || !coach.id) {
            return;
        }

        app.navigate(`/roster/coach/${coach.id}`)
    }

    const onClickPlayer = (player: RosterPlayer) => {
        if (!player.id) {
            return;
        }

        app.navigate(`/roster/player/${player.id}`)
    }

    // we need this at the end of a component to return a redirect when required
    if (Page.navigation.hasNavigation) {
        return <Navigate to={Page.navigation.destination} />
    }

    const RosterComponent = app.layout.showMobile ? MobileRoster : app.layout.showTablet ? TabletRoster : DesktopRoster;

    return (
        <>
            { state.rosterSelectOpen && <TeamSelector activeSeasons={seasonTeams} lockedRosters={lockedRosters} toggleRosterSelectMenu={toggleRosterSelectMenu} selectedRoster={roster} setSelectedRoster={setSelectedRoster}/> }
            <>
                { seasonTeams.hasLockedRoster  && <FormWrapper>
                    { roster.isLocked && <TeamDisplay>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontWeight: 'bold', fontSize: '18px', textAlign: 'left', backgroundColor: '#2e2e2e', color: '#FFF', borderRadius: '6px', height: '30px', paddingLeft: '20px', paddingRight: '10px' }}>
                            TEAM ROSTER
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingRight: '10px' }}>
                            <span style={{ width: '80%', fontStyle: 'italic', paddingLeft: '8px', paddingTop: '6px', fontSize: '14px' }}>
                                <div style={{ fontWeight: 'bold', fontStyle: 'normal', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <span>Team Roster Plays in:</span>
                                </div>
                                <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 1, WebkitBoxOrient: 'vertical' }}>{roster.seasonTitle}</span>
                            </span>
                            <div style={{ textAlign: 'left' }}>
                                <img src={LockImg} alt="Padlock" style={{ width: '25px', height: '25px' }} />
                            </div>
                        </div>
                    </TeamDisplay>}
                   {seasonTeams.seasons.length > 1 && <RosterButton width="block" size="lg" onClick={ toggleRosterSelectMenu }>VIEW ALL ROSTERS</RosterButton>}
                </FormWrapper> }

                {(!roster.players[0] && !roster.coaches[0]) ? (
                    <>
                        <Alert message={!seasonTeams.hasUnlockedRoster ? `Your active roster is locked` : `Roster is empty`} style="warning" />
                    </>
                ) : (
                    <RosterComponent roster={roster} onClickCoach={onClickCoach} onClickPlayer={onClickPlayer} />
                )}
            </>
        </>
    );

}