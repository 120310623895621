import { Button, FormWrapper, Loading, Title } from "@gamesheet/ui";
import * as Grid from "@/layouts/GridItems";
import { TextInput } from "./components/TextInput";
import { FormEvent, FormEventHandler, forwardRef, useCallback, useEffect, useImperativeHandle, useState, MouseEvent } from "react";
import { FormFieldType, NewFormField, useCreateForm } from "@/forms/hooks/useCreateForm";
import { PasswordInput } from "./components/PasswordInput";
import { styled } from "styled-components";
import { PageLoading } from "@/components/pure/PageLoading";
import { useUserState } from "@/state/user/useUserState";
import { AppStateInterface } from "@/state/app/app.interface";
import { UserUpdate } from "@/services/useUserService";

const StyledEditPasswordForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: 16px;
`

type EditPasswordFormProps = {
    onSubmit: (userForm:EditPasswordFormState, userInfo: UserUpdate) => void;
    userDetails: UserUpdate;
    app: AppStateInterface;
}

export type EditPasswordFormState = {
    currentPassword: FormFieldType<string>;
    newPassword: FormFieldType<string>;
}

export const EditPasswordForm = forwardRef(({ onSubmit, userDetails, app }:EditPasswordFormProps, ref ) => {
    
    const user = useUserState()
    const [ showSave, setShowSave ] = useState(false);

    const { state: formState, onChange } = useCreateForm('editPassword', {
        currentPassword: NewFormField('', /.{1,}/, 'Password field is empty'),
        newPassword: NewFormField('', /.{8,}/, 'Password must be at least 8 characters'),
    })

    useEffect(() => {
        if(formState){
            setShowSave((formState.currentPassword?.value.length >= 1) && (formState.newPassword?.value.length >= 8))
        }
    },[ formState ])

    const handleSubmit = useCallback((e:MouseEvent<HTMLButtonElement> | FormEvent<HTMLFormElement>) => {
        e && e.preventDefault();
        
        for(const field of Object.values(formState)){
            if(!field.value.match(field.test)){
                return;
            }
        }
        
        onSubmit(formState, userDetails);
    }, [ onSubmit, formState ])

    useEffect(() => {
        if (showSave) {
            app.ui('rightTray').set(<Button size="lg" gs-style="solid" onClick={handleSubmit}>Save</Button>)
        } else {
            app.ui('rightTray').set(<Button size="lg" gs-style="solid" style={{ backgroundColor:'#b5b5b3' }}>Save</Button>)
        }
    }, [ onSubmit, formState, showSave ])

    if(!formState){
        return <PageLoading />
    }

    return <FormWrapper style={{ marginTop:'15px' }}>
        <Title bartype="divider" text="Edit Profile" size="h2"/>
        <StyledEditPasswordForm onSubmit={handleSubmit} style={{ marginTop:'15px' }}>
            <PasswordInput state={formState.currentPassword} name="currentPassword" label="Current Password" onChange={onChange} autocomplete={false} />
            <PasswordInput state={formState.newPassword} name="newPassword" label="New Password" onChange={onChange} autocomplete={false} />
        </StyledEditPasswordForm>
    </FormWrapper>
})