import { useEffect } from "react";
import { atom, useRecoilState, useRecoilValue } from "recoil";

type DeviceState = {
    isMobile: boolean;
    isTablet: boolean;
    isDesktop: boolean;
    isVertical: boolean;
    isMac: boolean;
    isIos: boolean;
}

export const deviceState = atom<DeviceState>({
    key: "deviceState",
    default: {
        isMobile: false,
        isTablet: false,
        isDesktop: true,
        isVertical: true,
        isMac: false,
        isIos: false,
    }
})

export function useDeviceState(): DeviceState {
    return useRecoilValue(deviceState)
}

export function useSetDeviceState(){

    const [ deviceStateValue, setDeviceState ] = useRecoilState(deviceState)

    useEffect(() => {
        
        const handleResize = () => {
            const width = window.innerWidth
            const height = window.innerHeight
            const isMac = navigator.platform.indexOf('Mac') >= 0 || /Mac/.test(navigator.userAgent);
            const isIos = navigator.platform.indexOf('iP') >= 0 || /iP/.test(navigator.userAgent);

            setDeviceState({
                ...deviceStateValue,
                isMobile: width <= 768,
                isTablet: width > 768 && width <= 1024,
                isDesktop: width > 1024,
                isVertical: height > width,
                isMac,
                isIos,
            })
        }

        handleResize()
        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }

    }, [])

}