import styled from "styled-components"
import React from "react";
import { EntityCardLoader } from "./EntityCardLoader";

const StyledTabletEntityCard = styled.div.attrs<{ $isLoading?:boolean }>({
    className: "entityCard"
})`
display: flex;
flex-direction: row;
background-color: #fff;
border-radius: 5px;
padding: 5px;
font-family: 'Rubik';
min-height: 60px;

img {
    height: 50px;
    width: 50px;
    object-fit: contain;
    border-radius: 5px;
}
`

const Text = styled.div`
flex-grow: 1;
display: grid;
grid-template-columns: 80px auto 120px 120px;
align-items: center;
`

const TitleOnlyText = styled.div`
flex-grow: 1;
display: grid;
grid-template-columns: 50% 50%;
align-items: center;
margin-left: 15px;
`

const CombinedTitle = styled.div`
display: flex;
flex-direction: row;
align-items: center;
`

const Title = styled.div`
font-size: 1.3125rem;
line-height: 89%;
font-weight: 500;
`

const Subtitle = styled.div`
font-size: 1.3125rem;
line-height: normal;
margin-left: 5px;
`

const Stat = styled.div`
font-size: 1.3125rem;
line-height: 89%;
justify-self: center;
font-weight: 500;
`

const SubStat = styled.div`
font-size: 1.3125rem;
line-height: normal;
justify-self: center;
text-align: center;
`

type TabletEntityCardProps = {
    isLoading?: boolean
    stat?: string
    title: string
    subtitle?: string | React.ReactNode
    substatOne?: string
    substatTwo?: string
    image?: string
}

export function TabletEntityCard({ stat, title, subtitle, substatOne, substatTwo, image, isLoading }: TabletEntityCardProps) {
    return (<>
        <StyledTabletEntityCard $isLoading={isLoading}>
            {isLoading && <EntityCardLoader />}
            {(!isLoading && image) && <img src={image} />}
            <Text>
                <Stat>{stat}</Stat>
                <CombinedTitle>
                    <Title style={{ textTransform: 'uppercase' }}>{title}</Title>
                    <Subtitle>{subtitle}</Subtitle>
                </CombinedTitle>
                <SubStat>{substatOne}</SubStat>
                <SubStat>{substatTwo}</SubStat>
            </Text>
        </StyledTabletEntityCard>
    </>)
}

export function TitleOnlyTabletEntityCard({ title, subtitle, isLoading }: Omit<TabletEntityCardProps, "stat"|"image"|"substatOne"|"substatTwo">) {
    return (<>
        <StyledTabletEntityCard $isLoading={isLoading}>
            <TitleOnlyText>
                <Title style={{ textTransform: 'uppercase' }}>{title}</Title>
                {isLoading && <EntityCardLoader />}
                {!isLoading && <Subtitle>{subtitle}</Subtitle>}
            </TitleOnlyText>
        </StyledTabletEntityCard>
    </>)
}
