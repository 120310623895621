import { Button } from "@gamesheet/ui";
import * as Grid from "@/layouts/GridItems";
import { useAppState } from "@/state/app/useAppState";
import { useEffect, useCallback, useState } from "react";
import { usePlayerData } from "@/state/data/usePlayerData";
import { useParams } from "react-router";
import { WarningCard } from "@/components/pure/EntityCard/MobileEntityCard";
import { useSeasonsData } from "@/state/data/useSeasonTeams";
import { useTeamRoster } from "@/state/data/useTeamRoster/useTeamRoster";


export function TeamRosterPlayerDelete(){

    const app = useAppState()
    const params = useParams()
    const playerId = params.id
    const roster = useTeamRoster()
    const { formData, remove } = usePlayerData(playerId)
    const seasons = useSeasonsData()
    const showActions = seasons.hasUnlockedRoster

    const onDeletePlayer = useCallback( () => {
        remove()
        playerId && roster.removePlayer(playerId)
        app.navigate(`/roster/team`)
    }, [remove])
    
    useEffect(() => {
        
        app.ui('leftTray').set(<Button gs-style="solid" size="lg" onClick={() => app.navigate(`/roster/player/${playerId}`)}>Cancel</Button>)
        if (showActions) {
            app.ui('rightTray').set(<Button gs-style="solid" size="lg" onClick={onDeletePlayer} variant="danger">Confirm Remove</Button>)
        }

        return () => {
            app.ui('leftTray').clear()
            app.ui('rightTray').clear()
        }
    }, [onDeletePlayer])

    return (<>
        <Grid.Blank $column={1} $row={1}>
            <WarningCard title={`Remove ${formData.firstName} ${formData.lastName}`}>
                This will remove the player {formData.firstName} {formData.lastName} from your roster. Are you sure?
            </WarningCard>  
        </Grid.Blank>
    </>)
}