import React from 'react';
import styled from 'styled-components';

interface ButtonSpaceProps {
  width?: string;
}

const BlankSpace = styled.div`
  height: 15px;
  width: 10px;
`;

const ButtonSpace: React.FC<ButtonSpaceProps> = ({ width }) => {
  const gapWidth = width || '10px';

  return <BlankSpace style={{ width: gapWidth }} />;
};

export default ButtonSpace;
