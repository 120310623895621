import { MobileEntityCard } from "@/components/pure/EntityCard/MobileEntityCard";
import { Title, UserNotice } from "@gamesheet/ui";
import React from "react";
import styled from "styled-components";
import { useLineupData } from "./state/useLineup";
import { useAppState } from "@/state/app/useAppState";
import { BreakPoints } from "@/libs/breakpoints";

const StyledGoalieStep = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: 100%;
  padding-top: 1rem;

  @media screen and (min-width: ${BreakPoints.lg}) {
    padding-top: 0;
  }
`;

const MobileEntityCardWrapper = styled.div`
  cursor: pointer;
  width: 100%;
  border-radius: 0.3125rem;
  &.starting {
    border: 2px solid #36383d !important;
  }
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  max-width: 35rem;
`;

const HeaderText = styled.div`
  color: #36383d;
  text-align: center;
  font-family: Rubik;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const StartingGoalieStep: React.FC = () => {
  const app = useAppState();

  const { LocalLineupData } = useLineupData();

  const handleCardClick = (id: string) => {
    if (!id) return;
    LocalLineupData.setGoalieStartingById(id);
  };

  return (
    <StyledGoalieStep>
      <Column>
        <Title bartype="divider" size="h2" text={`Starting Goalie`} />
        <HeaderText>Which Goaltender will be starting the game?</HeaderText>
        {LocalLineupData.get().lineup.goalies.map((goalie, index) => {
          if (goalie.status !== "playing") {
            return null;
          } else {
            return (
              <UserNotice display={false} type="error" msg="" key={index}>
                <div style={{ display: "flex", gap: "1rem" }}>
                  <MobileEntityCardWrapper className={goalie.starting ? "starting" : ""} onClick={() => handleCardClick(goalie.id)}>
                    <MobileEntityCard title={`${goalie.firstName} ${goalie.lastName}`} stat={`#${goalie.jersey}`} subtitle={app.helpers.translatePosition(goalie.position, true)} reverse />
                  </MobileEntityCardWrapper>
                </div>
              </UserNotice>
            );
          }
        })}
      </Column>
    </StyledGoalieStep>
  );
};
