interface DataLayerEvent {
    event: string;
    [key: string]: any;
  }
  
  // Utility function to safely push events to dataLayer
  export const pushToDataLayer = (event: DataLayerEvent): void => {
    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || [];
      try {
        window.dataLayer.push(event);
      } catch (error) {
        console.error('Failed to push to dataLayer:', error);
      }
    }
  };