import { useCallback, useReducer, useState } from "react";
import { useRecoilState } from "recoil";
import { useAppState } from "../app/useAppState";
import { PageOptions, defaultOptions } from "./type.options.";
import { PageStates } from "./atom.pagestates";
import { ActionType, PageSessionReducer, PageSessionState, defaultPageSessionState } from "./reducer.pagesession";
import { usePageScrollState } from "./usePageScrollState";
import { usePageButtons } from "./usePageButtons";

export function usePageState( pageId:string, _options?:Partial<PageOptions> ){

    const { selectedTeam } = useAppState()
    
    // page options
    const options:PageOptions = {
        ...defaultOptions,
        ..._options
    };

    // page global state (persisted between page loads)
    const [ page, setPageState ] = useRecoilState(PageStates(`${selectedTeam}-${pageId}`));

    // page session (intentionally not persisted)
    const [ pageSession, dispatchSessionEvent ] = useReducer(PageSessionReducer, defaultPageSessionState)
    const setPageSession = useCallback((action: ActionType, state: Partial<typeof pageSession>) => dispatchSessionEvent({ type: action, state }), [ pageSession, dispatchSessionEvent ])

    // scroll position
    usePageScrollState(options, [pageSession, setPageSession], [ page, setPageState ])
    
    const pageButtons = usePageButtons(options)

    const out = {
        ...page,
        ...pageSession,
        ...pageButtons,
    };

    // return the page state
    return out;

}