import { PropsWithChildren } from "react";
import styled from "styled-components";
import bg2 from '@/assets/images/bg2.jpg'
import { useAppState } from "@/state/app/useAppState";
import { UiTrays } from "./Trays";
import { Button } from "@gamesheet/ui";
import { useUserState } from "@/state/user/useUserState";

const StyledIsolatedLayout = styled.div`

> div {
    background-size: cover;
    background-position: right;
    background-attachment: fixed;
    background-image: url(${bg2});
    min-height: 100vh;
    
    opacity: 1;
    transition: opacity 1s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 48px;

}
`

const MobileIsolateContainer = styled.div`
position: fixed;    
left: 16px;
right: 16px;
bottom: 16px;
top: 16px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;

.signup-step {
    height: calc(100% - 32px);
    overflow-y: scroll;
}
`

const DesktopIsolatedContainer = styled.div`
height: 100%;
.signup-step {
    width: 400px;
    max-height: calc(100% - 32px);
}
`

const ButtonsContainer = styled.div`
display: flex;
flex-direction: column;
margin-top: 4px;
width: 100%;

gap: 4px;
position: relative;

.mobile-tray, .desktop-tray {
    position: relative;
    background: transparent;
    height: inherit;
    top: inherit;
    left: inherit;
    right: inherit;
    bottom: inherit;
    min-height: 0;
    padding: 0;

    display: flex;
    flex-direction: column;

    .inner {

        width: 100%;
        display: flex;
        gap: 4px;
        flex-direction: column-reverse;
        padding: 0;

        > div {
            width: 100%;
            float: none;
            padding: 0;
        }
    }

    .button {
        width: 100%;
        margin: 0;
    }
}

.button {
    width: 100%;
}
`

export function IsolatedLayout(props: PropsWithChildren<{}>) {

    const app = useAppState()
    const user = useUserState()
    const Container = app.layout.showMobile ? MobileIsolateContainer : DesktopIsolatedContainer

    return <StyledIsolatedLayout>
        <div>
            <Container>
                {props.children}
                <ButtonsContainer>
                    <UiTrays />
                    <Button 
                        onClick={user.SignOut}
                        type="button" 
                        gs-style="solid" 
                        variant="inverted"
                    >
                        Logout
                    </Button>
                </ButtonsContainer>
            </Container>
        </div>
    </StyledIsolatedLayout>
}