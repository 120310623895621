import { selector } from "recoil";
import { recordsState } from "./atoms";
import { mergeByExternalId } from "../functions/mergeByExternalId";

export const coachRecordsState = selector({
  key: 'coachRecords',
  get: ({ get }) => {
    const coaches = get(recordsState).filter(record => record.type === "coach").map(coach => ({ ...coach, team_ids:[ coach.teamId ] }));
    return mergeByExternalId( coaches );
  }
});
