import { atomFamily } from "recoil";

export type GlobalPageOptions = {
    scrollY: number
}

export const defaultGlobalPageOptions:GlobalPageOptions = {
    scrollY: 0
}

export const PageStates = atomFamily({
    key: 'PageStates',
    default: defaultGlobalPageOptions
});