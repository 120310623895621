import styled from "styled-components";

export const RosterWrapper = styled.div`
    max-height: 30vh;
    overflow-y: scroll;

    > * {
    padding-top: 0.5rem;
}
`;


