// PlayerForm.tsx
import { FormWrapper, Title } from "@gamesheet/ui";
import React, { useMemo, useState } from "react";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import { BreakPoints } from "@/libs/breakpoints";
import "@gamesheet/ui/build/index.css";
import { createYearRange, getDaysInMonth, months } from "@/libs/constants";
import { getData } from "country-list";
import { RegionDropdown } from "react-country-region-selector";
import { PlayerFormData } from "@/state/data/usePlayerData";
import { ImageUploader } from "@/components/pure/ImageUploader";
import { useUserState } from "@/state/user/useUserState";
import { addImgSizeToUrl } from "@/libs/addImgSizeToUrl";
import Avatar from "@/assets/images/blank_avatar.svg"
import redTrashcan from "../assets/images/red_trash_can.svg";
import { useAppState } from "@/state/app/useAppState";
import { selectedTeamSportPositionsOptionsData } from "@/state/data/useSeasonTeams";

const Photo = styled.div`
width: min(100%, 250px);

.photo {
  width: 100%;
  height: auto;
}

.supported {
  font-size: 12px;
}
`

const DisabledStylingOverride = styled.div`
  .form-floating > .form-control:not(:placeholder-shown):disabled ~ label::after {
    background-color: inherit;
  }

  select:disabled {
    border-color: #ced4da !important;
  }
`

const PhotoError = styled.p`
  font-size: 12px;
  background: #ffafaf;
  padding: 2px;
  border-radius: 4px;
  text-align: center;
  margin-bottom: 0.6rem;
`

const Trash = styled.div`
  margin: 0 10px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

const PhotoButton = styled.div`
  text-transform: uppercase;
  flex-grow: 1;
  display: flex;
  align-items: center;

  > div {
    flex-grow: 1;

    label {
      width: 100%;
      padding: 6px 0;
      font-size: 11px;

      @media only screen and (min-width: ${BreakPoints.md}) {
        font-size: 16px;
      }
    }
  }
`

type PlayerFormProps = {
  formData: PlayerFormData;
  setFormData: React.Dispatch<React.SetStateAction<PlayerFormData>>;
  isLocked: boolean;
  useLineupConfig?: boolean;
  isLineupRosterLocked?: boolean;
};

const PlayerForm = ({ formData, setFormData, isLocked, useLineupConfig, isLineupRosterLocked = false }: PlayerFormProps) => {


  const app = useAppState();
  const positionOptions = useRecoilValue(selectedTeamSportPositionsOptionsData);

  const [fNameTestResult, setFNameTestResult] = useState("")
  const [lNameTestResult, setLNameTestResult] = useState("")
  const user = useUserState();
  const [isSubmittingPhoto, setIsSubmittingPhoto] = useState<boolean>(false);
  const [photoError, setPhotoError] = useState<string | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;

    if (name === "firstName" || name === "lastName") {
      if (!value.match(/.{2,}/)) {
        name === "firstName" ? setFNameTestResult("invalid") : setLNameTestResult("invalid")
      } else {
        name === "firstName" ? setFNameTestResult("valid") : setLNameTestResult("valid")
      }
      setFormData({ ...formData, [name]: value });
    } else if (name === "jersey") {
      const sanitizedValue = Math.abs(parseInt(value.replace(/-/g, "")))
      const stringValue = isNaN(sanitizedValue) ? "" : sanitizedValue.toString().slice(0, 2)
      setFormData({ ...formData, [name]: stringValue });
    } else if (name === "affiliated") {
      setFormData({ ...formData, [name]: value === "true" });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  function handleRegionChange(value: string, event: React.ChangeEvent<Element>): void {
    setFormData({ ...formData, province: value });
  }

  const daysForBirth = useMemo(() => {
    return formData.birthMonth ? getDaysInMonth(formData.birthMonth) : [];
  }, [formData.birthMonth]);

  const countryList = useMemo(getData, []);

  const handleUploadSuccess = (imageUrl: string) => {
    setFormData({ ...formData, photoURL: imageUrl });
  };

  const handleClearPhoto = () => {
    setPhotoError("");
    setFormData({ ...formData, photoURL: "" });
  }

  return (
    <FormWrapper>
      <DisabledStylingOverride>
        <fieldset>

          <Title bartype="divider" text="Player Details" size="h2" />

          <div className="row gx-2 gx-md-3">
            <div className="col-5 col-md-4 col-xl-3 d-flex justify-content-center">
              <Photo>
                <img className="photo mb-2 mb-md-3" src={!formData.photoURL ? Avatar : `${addImgSizeToUrl(formData.photoURL, 256)}`} />

                {!isLocked && !isLineupRosterLocked && (<div>
                  {photoError && <PhotoError className="error">{photoError}</PhotoError>}
                  <div className="d-flex align-items-center mb-1 mb-md-2">
                    {formData.photoURL && (<Trash onClick={handleClearPhoto}><img src={redTrashcan} alt="trashcan-icon" /></Trash>)}
                    <PhotoButton>
                      <ImageUploader label={!formData.photoURL ? "Select Photo" : "Change Photo"} onUploadSuccess={handleUploadSuccess} getToken={user.GetToken} setIsSubmitting={setIsSubmittingPhoto} setError={setPhotoError} isSubmitting={isSubmittingPhoto} disabled={isLocked || isLineupRosterLocked} />
                    </PhotoButton>
                  </div>
                  <p className="supported">Supported formats are PNG, JPEG, WebP, and SVG. Max file size is 10 MB</p>
                </div>)}
              </Photo>
            </div>

            <div className="col-7 col-md-8 col-xl-9">
              <div className="row gx-2 gx-md-3">
                <div className="col-12 col-md-6">
                  <div className="form-floating mb-2 mb-md-3">
                    <input type="text" name="firstName" id="firstName" className={"form-control " + "is-" + fNameTestResult} placeholder="First Name" onChange={handleChange} value={formData.firstName} disabled={isLocked || useLineupConfig} />
                    <label htmlFor="firstName" className="floating">
                      {fNameTestResult === "invalid" ? "First name must be at least 2 characters" : "First Name*"}
                    </label>
                  </div>
                </div>

                <div className="col-12 col-md-6">
                  <div className="form-floating mb-2 mb-md-3">
                    <input type="text" name="lastName" id="lastName" className={"form-control " + "is-" + lNameTestResult} placeholder="Last Name" onChange={handleChange} value={formData.lastName} disabled={isLocked || useLineupConfig} />
                    <label htmlFor="lastName" className="floating">
                      {lNameTestResult === "invalid" ? "First name must be at least 2 characters" : "Last Name*"}
                    </label>
                  </div>
                </div>
              </div>

              <div className="row gx-2 gx-md-3">
                <div className={app.layout.showMobile ? "col-4" : "col-6"}>
                  <div className="form-floating mb-2 mb-md-3">
                    <input type="number" name="jersey" id="jersey" className="form-control" placeholder="0" onChange={handleChange} value={formData.jersey} disabled={isLocked} />
                    <label htmlFor="jersey" className="floating">
                      Jersey
                    </label>
                  </div>
                </div>

                <div className={app.layout.showMobile ? "col" : "col-6"}>
                  <div className="form-floating mb-2 mb-md-3">
                    <select name="position" id="position" className="form-select" onChange={handleChange} value={formData.position} disabled={isLocked}>
                      <option value="">None</option>
                      {positionOptions.map(option => (
                        <option key={option.key} value={option.key}>
                          {option.title}
                        </option>
                      ))}
                    </select>

                    <label htmlFor="position" className="floating">
                      Position
                    </label>
                  </div>
                </div>
              </div>

              <div className="row gx-2 gx-md-3">
                <div className="col-12 col-md-6">
                  <div className="form-floating mb-2 mb-md-3">
                    <select name="affiliated" id="affiliated" className="form-select" onChange={handleChange} value={formData.affiliated ? "true" : "false"} disabled={isLocked || isLineupRosterLocked}>
                      <option value={"false"}>Regular</option>
                      <option value={"true"}>Affiliated</option>
                    </select>
                    <label htmlFor="affiliated" className="floating">
                      Status
                    </label>
                  </div>
                </div>

                <div className="col-12 col-md-6">
                  <div className="form-floating mb-2 mb-md-3">
                    <select name="duty" id="duty" className="form-select" onChange={handleChange} value={formData.duty} disabled={isLocked}>
                      <option value="">No designation</option>
                      <option value="captain">Captain</option>
                      <option value="alternate_captain">Alternate Captain</option>
                    </select>
                    <label htmlFor="duty" className="floating">
                      Designation
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Title bartype="divider" text="Player Profile" size="h2" />

          <div className="row gx-2 gx-md-3">
            <div className="col">
              <div className="form-floating mb-2 mb-md-3">
                <select name="birthMonth" id="birthMonth" className="form-select" onChange={handleChange} value={formData.birthMonth} disabled={isLocked || isLineupRosterLocked}>
                  <option disabled value="">
                    Month
                  </option>
                  {months.map((month: string, index: number) => {
                    return (
                      <option key={index} value={month}>
                        {month}
                      </option>
                    );
                  })}
                </select>
                <label htmlFor="birthMonth" className="floating">
                  Month
                </label>
              </div>
            </div>
            <div className="col">
              <div className="form-floating mb-2 mb-md-3">
                <select name="birthDay" id="birthDay" className="form-select" onChange={handleChange} value={formData.birthDay} disabled={isLocked || isLineupRosterLocked}>
                  <option disabled value="">
                    Day
                  </option>
                  {daysForBirth.map((day: string, index: number) => {
                    return (
                      <option key={index} value={day}>
                        {day}
                      </option>
                    );
                  })}
                </select>
                <label htmlFor="birthDay" className="floating">
                  Day
                </label>
              </div>
            </div>
            <div className="col">
              <div className="form-floating mb-2 mb-md-3">
                <select name="birthYear" id="birthYear" className="form-select" onChange={handleChange} value={formData.birthYear} disabled={isLocked || isLineupRosterLocked}>
                  <option disabled value="">
                    Year
                  </option>
                  {daysForBirth.length > 0
                    ? createYearRange().map((year: string, index: number) => {
                      return (
                        <option key={index} value={year}>
                          {year}
                        </option>
                      );
                    })
                    : null}
                </select>
                <label htmlFor="birthYear" className="floating">
                  Year
                </label>
              </div>
            </div>
          </div>

          <div className="row gx-2 gx-md-3">
            <div className="col-12 col-md-4">
              <div className="form-floating mb-2 mb-md-3">
                <input type="text" name="height" id="height" className="form-control" placeholder="Height" onChange={handleChange} value={formData.height} disabled={isLocked || isLineupRosterLocked} />
                <label htmlFor="externalId" className="floating">
                  Height
                </label>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-floating mb-2 mb-md-3">
                <input type="text" name="weight" id="weight" className="form-control" placeholder="Weight" onChange={handleChange} value={formData.weight} disabled={isLocked || isLineupRosterLocked} />
                <label htmlFor="weight" className="floating">
                  Weight
                </label>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-floating mb-2 mb-md-3">
                <select name="shotHand" id="shotHand" className="form-select" onChange={handleChange} value={formData.shotHand} disabled={isLocked || isLineupRosterLocked}>
                  <option value="" />
                  <option value="right">Right</option>
                  <option value="left">Left</option>
                </select>

                <label htmlFor="shotHand" className="floating">
                  Shot-hand / Catch Hand
                </label>
              </div>
            </div>
          </div>

          <div className="row gx-2 gx-md-3">
            <div className="col-12 col-md-4">
              <div className="form-floating mb-2 mb-md-3">
                <select name="country" id="country" className="form-select" onChange={handleChange} value={formData.country} disabled={isLocked || isLineupRosterLocked}>
                  <option disabled value=""/>                    
                  <option value={"CA"}>Canada</option>
                  <option value={"US"}>United States of America</option>
                  {countryList.map((country, index) => {
                    return (
                      <option key={index} value={country.code}>
                        {country.name}
                      </option>
                    );
                  })}
                </select>
                <label htmlFor="country" className="floating">
                  Country
                </label>
              </div>
            </div>

            <div className="col-12 col-md-4">
              {formData.country === "US" || formData.country === "CA" ? (
                <div className="form-floating mb-2 mb-md-3">
                  <RegionDropdown
                    classes="form-control mb-2 mb-md-3"
                    countryValueType="short"
                    id="playerProvince"
                    name="province"
                    value={formData?.province || ""}
                    country={formData.country}
                    defaultOptionLabel=""
                    onChange={handleRegionChange}
                    disabled={isLocked || isLineupRosterLocked}
                  />
                  <label htmlFor="externalId" className="floating">
                    Province
                  </label>
                </div>
              ) : (
                <div className="form-floating mb-2 mb-md-3">
                  <input type="text" name="province" id="province" className="form-control" placeholder="province" onChange={handleChange} value={formData.province} disabled={isLocked || isLineupRosterLocked} />
                  <label htmlFor="externalId" className="floating">
                    State / Province
                  </label>
                </div>
              )}
            </div>

            <div className="col-12 col-md-4">
              <div className="form-floating mb-2 mb-md-3">
                <input name="hometown" id="hometown" className="form-control" placeholder="City / Town" onChange={handleChange} value={formData.hometown} disabled={isLocked || isLineupRosterLocked} />
                <label htmlFor="hometown" className="floating">
                  City / Town
                </label>
              </div>
            </div>
          </div>

          <div className="form-floating mb-2 mb-md-3">
            <textarea name="biography" id="biography" className="form-control" placeholder="Bio" onChange={handleChange} value={formData.biography} style={{ height: '200px', width: '100%' }} disabled={isLocked || isLineupRosterLocked} />
            <label htmlFor="biography" className="floating">
              Bio
            </label>
          </div>

          <div className="row gx-2 gx-md-3">
            <div className="col-12 col-md-6">
              <div className="form-floating mb-2 mb-md-3">
                <input type="text" name="draftedBy" id="draftedBy" className="form-control" placeholder="Drafted By" onChange={handleChange} value={formData.draftedBy} disabled={isLocked || isLineupRosterLocked} />
                <label htmlFor="draftedBy" className="floating">
                  Drafted By
                </label>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="form-floating mb-2 mb-md-3">
                <input type="text" name="committedTo" id="committedTo" className="form-control" placeholder="Committed to" onChange={handleChange} value={formData.committedTo} disabled={isLocked || isLineupRosterLocked} />
                <label htmlFor="committedTo" className="floating">
                  Commited to
                </label>
              </div>
            </div>
          </div>
        </fieldset>
      </DisabledStylingOverride>
    </FormWrapper>
  );
};

export default PlayerForm;
