import { ManagedFlow } from "@/hooks/useManagedFlow";
import { useInvitationCodeService } from "./services/useInvitationCodeService";
import { TeamData } from "@/state/data/useTeams";

export type InsertInvitationCodeProps = {
    onSubmit: (code:string) => void;
    error?: string;
}

export type AcceptInvitationFlowType = ManagedFlow<AcceptInvitationFlowStep, AcceptInvitationFlowPath> & {
    loading: boolean,
    error: string,
    mergeError: string,
    invitationCode: string,
    invitation: Invitation | undefined,
    rawMergedData: RawMergedData | undefined,
    prototeamOptions: PrototeamOptions,
}

export enum AcceptInvitationFlowPath {
    NewUser = 'newUserPath',
    ExistingUser = 'existingUserPath',
}

export enum AcceptInvitationFlowStep {
    InsertInvitationCode = 0,
    PreviewTeam,
    SchedulerPreview,
    RegisterNewAccount,
    PrototeamOptions,
    SelectedOptionSummary,
    Success,
}

export type InvitationTeam = {
    id: number;
    title: string;
    prototeam_id: string;
    deleted_at: string;
    logo: string;
    season: {
        id: number;
        title: string;
    },
    division: {
        id: number;
        title: string;
    },
    players: Array<InvitationPlayer>
}

export type InvitationPlayer = {
    id: number;
    firstName: string;
    lastName: string;
    number: string;
    photo: string;
    duty: string;
}

export type Invitation = {
    invitation?: {
        code: string;
        description: string;
        id: string;
        roles: Array<{
            title: string;
            level: {
                id: string;
                type: string;
            }
        }>
    },
    teams?: InvitationTeam[],
    userAlreadyHasTeamRoles: {
        [key:number]: boolean
    },
}

export type useAcceptInvitationFlowType = AcceptInvitationFlowType & {
    code: ReturnType<typeof useInvitationCodeService>;
} & {
    showCodeInput: boolean,
    showTeamPreview: boolean,
    showSchedulerPreview: boolean,
    showAccountRegistration: boolean,
    showPrototeamOptions: boolean,
    showSelectedOptionSummary: boolean,
    showSuccess: boolean,
};

export type RegistrationData = {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
}
export type PrototeamOptions = {
    strategy: Strategy,
    linkedTeam: TeamData | undefined,
}

export type Strategy = "default" | "link" | "add";

export type RawMergedData = {
    alreadyInPrototeam: boolean,
    players: Array<InvitationPlayer>,
    logo: string,
    title: string,
}
