import styled from "styled-components";
import { Modal, Button, UserNotice } from "@gamesheet/ui";
import { LineupFlowHook } from "@/flows/LineupFlow/state/useLineupFlow";
import { useRecoilValue } from "recoil";
import { playingGoalieIdsSelector } from "@/flows/LineupFlow/state/atom.playerFormDataState";
import { useWarningDisplay } from "./useValidationErrors";
import { hasHeadCoachSelector } from "@/flows/LineupFlow/state/atom.coachFormDataState";

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgb(0, 0, 0, 0.5);
  z-index: 5200;
`;

const Text = styled.div`
  font-family: Rubik;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  padding-bottom: 0.75rem;
`;

const ConfirmButtonContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: row;
  padding-top: 0.5rem;
  justify-content: space-between;
`;

const Divider = styled.div`
  border: 1px solid #d7d7d8;
  margin: 0 -1rem;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 1rem;
`;

type SavePlayerProps = {
  flow: LineupFlowHook;
};

export function WarningDisplay({ flow }: SavePlayerProps) {
  const { isOpen, close } = useWarningDisplay();

  const playingGoalieIds = useRecoilValue(playingGoalieIdsSelector);
  const hasActiveHeadCoach = useRecoilValue(hasHeadCoachSelector);

  const handleProceed = () => {
    close();
    if (flow.path === "coachPath") {
      flow.usePreviewPath();
    } else {
      flow.useCoachPath();
    }
  };

  if (!isOpen) {
    return <></>;
  }

  return (
    <ModalContainer>
      <Modal title="Warning" show={isOpen} onClose={close}>
        <Text>Your game lineup has the following warnings: </Text>
        {playingGoalieIds.length === 0 && (
          <Content>
            {" "}
            <UserNotice display={true} type="warning" msg="Lineup has no active Goaltender" />
          </Content>
        )}
        {!hasActiveHeadCoach && flow.path === "coachPath" && (
          <Content>
            <UserNotice display={true} type="warning" msg="Lineup has no active Head Coach" />
          </Content>
        )}
        <Text>Would you like to proceed anyway?</Text>
        <Divider />
        <ConfirmButtonContainer>
          <Button size="lg" variant="inverted" label="Cancel" onClick={close} />
          <Button size="lg" label="Proceed" onClick={handleProceed} />
        </ConfirmButtonContainer>
      </Modal>
    </ModalContainer>
  );
}
