export function shotHand( value: string ){

    value = value.toLowerCase();

    switch( value ){
        case "l": return "left";
        case "r": return "right";
    }

    return value;

}