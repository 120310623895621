import { atom, selector } from "recoil";
import { Roster } from "./types";
import { StoredRoster } from "@/services/useRosterService/types";

export const rawRosterState = atom<StoredRoster>({
    key: 'rawRosterState',
    default: {
        coaches: [],
        players: []
    }
})

export const rosterState = selector<Roster>({
    key: 'rosterState',
    get: ({get}) => {
        const rawRoster = get(rawRosterState)
        return {
            ...rawRoster,
            skaters: rawRoster.players.filter((player) => player.position !== 'goalie'),
            goalies: rawRoster.players.filter((player) => player.position === 'goalie')
        }
    }
})