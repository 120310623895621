import styled from "styled-components";
import { Modal, Card, Alert, Button } from "@gamesheet/ui";
import { useInviteUser } from "./useInviteUser";
import { useSelectedTeamState } from "@/state/team/useSelectedTeamState";
import { InviteUserForm, InviteUserFormState } from "@/forms/InviteUserForm";
import { useState } from "react";

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgb(0, 0, 0, 0.5);
  z-index: 5200;
`;

const Header = styled.div`
  color: #404247;
  font-size: 1rem;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
`;

const Divider = styled.div`
  border: 1px solid #d7d7d8;
  margin-bottom: 1rem;
  margin-left: -1rem;
  margin-right: -1rem;
`;
const InfoText = styled.div`
  color: #000;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 1rem 0;
`;

const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .button {
    font-size: 1rem !important;
    font-weight: 500 !important;
  }
`;

const ContentContainer = styled.div`

`;

export function InviteUser() {
  const selectedTeam = useSelectedTeamState();

  const { isOpen, close, loading, onSubmit } = useInviteUser();

  const [isValid, setIsValid] = useState(false);
  const [formData, setFormData] = useState<InviteUserFormState>();

  if (!isOpen) {
    return <></>;
  }

  return (
    <ModalContainer>
      <Modal title="Invite To Team" show={isOpen} onClose={close}>
        <ContentContainer>
          <Header>{`Invite your team staff to view and manage ${selectedTeam?.title} by entering their email addresses below.`}</Header>

          <InviteUserForm onSubmit={onSubmit} setIsValid={setIsValid} setFormData={setFormData} />
          <InfoText>To send multiple invitations, separate additional emails by commas.</InfoText>
        </ContentContainer>

        <Divider />

        <FooterContainer>
          <Button variant="inverted" label="Cancel" onClick={close}>
            Cancel
          </Button>
          {isValid && formData ? <Button onClick={() => onSubmit(formData)}>Send Invitation</Button> : <Button state="disabled" variant="inverted" label="Send Invitation" />}
        </FooterContainer>
      </Modal>
    </ModalContainer>
  );
}
