import { Config } from "./type";
import { config as producationConfig } from "./config.prod";

export const config:Config = {
    ...producationConfig,
    name: "Beta",
    apps: {
        statsWidget: "https://gamesheetstats.com",
        leagueDashboard: "https://gs-dashboard-beta-v3.firebaseapp.com/",
    }
}