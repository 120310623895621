import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import config from "@/config";

import { signInWithCustomToken } from "firebase/auth";
import { auth } from "@/libs/firebase";

export const useLoginWithOtp = () => {

  const { pathname } = useLocation();
  const searchParams = new URLSearchParams(window.location.search);
  const query = searchParams.get("otp");

  useEffect(() => {

    if (!query) {
      return;
    }

    // send the otp to the auth gateway
    fetch(
      `${config.gateways.auth}/auth/v4/otp/loginWithFirebase`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
        },
        body: query
    })
    .then(response => response.json())
    
    // get the firebase token
    // sign in with the firebase token
    .then(data => {
      signInWithCustomToken(auth, data?.firebase || "");
    })

    // remove otp query param
    // Build new URL with remaining query parameters
    // Replace current URL with new URL
    searchParams.delete("otp");
    const remainingQueryParams = searchParams.toString();
    const newUrl = remainingQueryParams ? `${pathname}?${remainingQueryParams}` : pathname;
    window.history.replaceState(null, "", newUrl);

  }, [pathname, query]);

};
