import React, { HTMLProps, useEffect, useState } from "react";
import { useAppState } from "@/state/app/useAppState";
import { useUserState } from "@/state/user/useUserState";
import { Iframe, useIframeLoadingState } from "@/state/app/useIframeLoading";
import { getOtp } from "@/state/user/getOtp";

type RequireIframeProps = {
  src: string;
  id: string;
  innerRef?: React.RefObject<HTMLIFrameElement>;
};

export default function IframeWithOtp({ src, id, innerRef }: HTMLProps<HTMLIFrameElement> & RequireIframeProps ) {
  
  const user = useUserState();
  const { loading } = useIframeLoadingState("user-profile");
  const [otp, setOtp] = useState("");
  const char = src.includes("?") ? "&" : "?";

  useEffect(() => {
    
    let mounted = true;

    async function fetchOtp() {
      
      if (!mounted) return;
      const otpValue = await getOtp(user.tokens.access);
      setOtp(otpValue);

    }

    !!user.tokens.access && !otp && fetchOtp();

    return () => {
      mounted = false;
    };

  }, [ user.tokens.access ]);

  return <>{otp && src && <Iframe $isLoading={loading} id={id} src={`${src}${char}otp=${otp}`} ref={innerRef} />}</>;
}
