import { Button, ButtonGroup, Title } from "@gamesheet/ui";
import { useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import styled from "styled-components";
import { ActionBar } from "@/components/pure/ActionBar";
import * as Grid from "@/layouts/GridItems";
import { useAppState } from "@/state/app/useAppState";

const StyledRosterButtonGroup = styled.div`
.buttonGroup {
    display: flex;
    margin-right: 10px;
    
    .button {
        flex: 1
    };
}
`

type SelectedRoster = 'team' | 'seasons'

export function RosterPage(){

    const { pathname } = useLocation()
    const [ _, route ] = (pathname.match(/^\/roster\/([a-z]*)/) || []) as SelectedRoster[];

    if (!route) {
        return <Navigate to="/roster/team" />
    }

    return (<>
        <Outlet />
    </>)
}