import default_validations from "./validations";
import { PositionValidator, makePositionValidator } from "./validations/validatePosition";

function validateRecord(record: any, positionValidator: PositionValidator) {
  const validations = [ ...default_validations ];
  validations.push(positionValidator as any);

  return validations.reduce((allErrors, validation) => {
    const errors = validation(record);
    return errors.length > 0 ? [...allErrors, ...errors] : allErrors;
  }, [] as any[]);
}

export default function validate(records: { [col: string]: any }[], playerPositions: { key: string, title: string }[], coachPositions: { key: string, title: string }[]) {
  const positionValidator = makePositionValidator(playerPositions, coachPositions);

  return records.map((record) => ({
    ...record,
    validationErrors: validateRecord(record, positionValidator),
  }));
}
