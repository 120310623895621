import { atom, selector, selectorFamily } from "recoil";
import { LockedRoster } from "./types";

export const rawLockedRostersState = atom<{ [key:string]:LockedRoster }>({
    key: 'rawLockedRostersState',
    default: {}
})

export const LockedRostersState = selector<{ [key:string]:LockedRoster }>({
    key: 'lockedRostersState',
    get: ({get}) => {
        
        const rawRosters = get(rawLockedRostersState)
        return Object.keys(rawRosters).reduce((lockedRoster, seasonTeamId) => {
            
            const rawRoster = rawRosters[seasonTeamId]
            return {
                ...lockedRoster,
                [seasonTeamId]: {
                    ...rawRoster,
                    coaches: [...(rawRoster?.coaches || [])].sort((a, b) => a.firstName.localeCompare(b.firstName)),
                    skaters: rawRoster.players.filter((player) => player.position !== 'goalie').sort((a, b) => a.firstName.localeCompare(b.firstName)),
                    goalies: rawRoster.players.filter((player) => player.position === 'goalie').sort((a, b) => a.firstName.localeCompare(b.firstName))            
                }
            }

        }, {})
    }
})

export const LockedRosterState = selectorFamily<LockedRoster, string>({
    key: 'lockedRosterState',
    get: (seasonTeamId) => ({get}) => {
        const lockedRosters = get(LockedRostersState)
        return lockedRosters[seasonTeamId]
    }
})