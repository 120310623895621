import { Button } from "@gamesheet/ui";
import { ErrorCard } from "@/components/pure/EntityCard/MobileEntityCard";
import { useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { EditUserForm, EditUserFormState } from "@/forms/EditUserForm";
import { EditPasswordForm, EditPasswordFormState } from "@/forms/EditPasswordForm";
import { PageLoading } from "@/components/pure/PageLoading";
import { useAppState } from "@/state/app/useAppState";
import { Navigate } from "react-router-dom";
import { useAppNavigation } from "@/hooks/useAppNavigation";
import { usePageState } from "@/state/page/usePageState";
import { useUserState } from "@/state/user/useUserState";
import { UserUpdate } from "@/services/useUserService";

const PasswordStyle = styled.div`
  margin: 0;
  margin-top: 20px;
  font-size: 14px;
  color: blue;
  cursor: pointer;

  button {
    width: 100%;
  }
`;

export function UserEdit(){
    
    const app = useAppState()
    const user = useUserState()
    const [ loading, setLoading ] = useState(false)
    const { navigate, destination, hasNavigation, handleNavigation } = useAppNavigation();
    const [error, setError] = useState("");

    const [ userDetails, setUserDetails ] = useState<UserUpdate>({id: user.id || "", firstName: "", lastName: "", email: user.email || "", password: ""})
    const [ loadingUser, setLoadingUser ] = useState(true);
    const [ editUser, setEditUser ] = useState(true);

    useEffect(() => {
        async function fetchData() {
          try {
            const data = await user.GetExtraDetails();
            setUserDetails({...userDetails, firstName: data.firstName, lastName: data.lastName});
            setLoadingUser(false);
          } catch (error) {
            setLoadingUser(false);
          }
        }
    
        fetchData();
    }, []);

    const onSaveUser = useCallback (async (userForm: EditUserFormState, revalidateUser: boolean) => {
        try {
            setLoading(true)
            if (userForm.email?.value !== userDetails.email) {
                if (userForm.confirmPassword?.value === "") {
                    setError("Please enter your password to change your email")
                    setLoading(false)
                    return;
                }
            }
            const response = await user.UpdateUser({id: user.id || "", firstName: userForm.firstName.value, lastName: userForm.lastName.value, email: userForm.email.value.toLowerCase(), password: ""}, userForm.confirmPassword.value, revalidateUser);

            if (response == "User details updated successfully") {
                setError("")
                setLoading(false)
                navigate(`/`)
            }
        } catch (error: any) {
            console.error("Error while updating user data:", error);
            setError(error)
            setLoading(false)
        }
    }, [])

    const onSavePassword = useCallback(async (passwordForm: EditPasswordFormState, userInfo: UserUpdate) => {
        try {
            setLoading(true)
            const response = await user.UpdatePassword(passwordForm.currentPassword.value, {id: userInfo.id, firstName: userInfo.firstName, lastName: userInfo.lastName, email: userInfo.email.toLowerCase(), password: passwordForm.newPassword.value});

            if (response == "Password updated successfully") {
                setError("")
                setLoading(false)
                navigate(`/`)
            }
        } catch (error: any) {
            console.error("Error while updating password:", error);
            setError(error)
            setLoading(false)
        }
    }, [])

    const handleChangeForm = () => {
        setError("");
        setEditUser(!editUser);
    }

    useEffect(() => {
        
        if (editUser) {
            app.ui('leftTray').set(<Button gs-style="solid" size="lg" onClick={handleNavigation(`/`)}>Back</Button>)
        } else {
            app.ui('leftTray').set(<Button gs-style="solid" size="lg" onClick={handleChangeForm}>Back</Button>)
        }

        return () => {
            app.ui('leftTray').clear()
            app.ui('rightTray').clear()
        }
    }, [ editUser ])

    if (hasNavigation) {
        return <Navigate to={destination} />
    }
    
    if(loading || loadingUser){
        return <PageLoading />
    }

    return (<>
            { editUser && <div style={{ width:'92%', margin:'0 auto' }}>
                <EditUserForm onSubmit={onSaveUser} userDetails={userDetails} app={app}/>
                <PasswordStyle>
                    <Button type="button" gs-style="solid" variant="inverted" size="lg" onClick={handleChangeForm}>Change Password</Button>
                </PasswordStyle>
            </div> }
            { !editUser && <div style={{ width:'92%', margin:'0 auto' }}>
                <EditPasswordForm onSubmit={onSavePassword} userDetails={userDetails} app={app}/>
            </div>
            }
            { error !== "" &&  <div style={{ width:'100%', marginTop:'20px' }}><ErrorCard title="" message={error} /></div>}
        
    </>)
}