import { Title } from "@gamesheet/ui";
import { MobileEntityCard } from "@/components/pure/EntityCard/MobileEntityCard";
import * as Grid from "@/layouts/GridItems";
import { useAppState } from "@/state/app/useAppState";

export function SeasonsRostersPage(){

    const app = useAppState()

    return (<>
        <Grid.Blank $column={1} $row={1}>
            <Title bartype="divider" text="Active" size="h2"/>
        </Grid.Blank>

        <Grid.Blank $column={1} $row={2} onClick={()=>app.navigate("/roster/seasons/1")}>
            <MobileEntityCard title="USA Nationals March 24-27" subtitle="USA Hockey" image="https://cdn1.sportngin.com/attachments/logo_graphic/7238-153469602/NC_Logo_generic_C_No_Year_small.png" />
        </Grid.Blank>

        <Grid.Blank $column={1} $row={3}>
            <Title bartype="divider" text="Inactive" size="h2"/>
        </Grid.Blank>

        <Grid.Blank $column={1} $row={4}>
            <MobileEntityCard title="State Championships" subtitle="New York State Amateur Hockey Association" image="https://cdn1.sportngin.com/attachments/photo/2682/7249/NYSlogoHRsmall_large.jpg" />
        </Grid.Blank>

        <Grid.Blank $column={1} $row={5}>
            <MobileEntityCard title="2023 March Meltdown Tournament" subtitle="New York State Amateur Hockey Association" image="https://cdn1.sportngin.com/attachments/photo/2682/7249/NYSlogoHRsmall_large.jpg" />
        </Grid.Blank>

        <Grid.Blank $column={1} $row={6}>
            <Title bartype="divider" text="Archived" size="h2"/>
        </Grid.Blank>
        <Grid.Info $column={1} $row={7}>
            <div style={{ padding: 15 }}>Archived seasons are read-only and cannot be edited.</div>
        </Grid.Info>

        <Grid.Blank $column={1} $row={8}>
            <MobileEntityCard title="2022 March Meltdown Tournament" subtitle="New York State Amateur Hockey Association" image="https://cdn1.sportngin.com/attachments/photo/2682/7249/NYSlogoHRsmall_large.jpg" />
        </Grid.Blank>
        
        <Grid.Blank $column={1} $row={9}>
            <MobileEntityCard title="2021 March Meltdown Tournament" subtitle="New York State Amateur Hockey Association" image="https://cdn1.sportngin.com/attachments/photo/2682/7249/NYSlogoHRsmall_large.jpg" />
        </Grid.Blank>

        <Grid.Blank $column={1} $row={10}>
            <MobileEntityCard title="2019 March Meltdown Tournament" subtitle="New York State Amateur Hockey Association" image="https://cdn1.sportngin.com/attachments/photo/2682/7249/NYSlogoHRsmall_large.jpg" />
        </Grid.Blank>
    </>)
}