import { useEffect, useState } from 'react';

interface QueryParams {
  [key: string]: string;
}

function useQueryParams() {
  const [queryParams, setQueryParams] = useState<QueryParams>({});

  useEffect(() => {
    function getQueryParams() {
      const searchParams = new URLSearchParams(window.location.search);
      const params: QueryParams = {};
      for (const [key, value] of searchParams.entries()) {
        params[key] = value;
      }
      return params;
    }

    const initialQueryParams = getQueryParams();
    setQueryParams(initialQueryParams);

    function handleUrlChange() {
      const updatedQueryParams = getQueryParams();
      setQueryParams(updatedQueryParams);
    }

    window.addEventListener('popstate', handleUrlChange);

    return () => {
      window.removeEventListener('popstate', handleUrlChange);
    };
  }, []);


  return queryParams;
}

export default useQueryParams;
