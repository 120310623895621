import React, { useState } from "react";
import { FormFieldType } from "@/forms/hooks/useCreateForm";

type SelectOption = {
  value: number | string;
  label: string;
};

type SelectProps = {
  className?: string[];
  state: FormFieldType;
  name: string;
  label: string;
  options: SelectOption[];
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => FormFieldType<any>;
  isDisabled?: boolean;
};

export function SelectInput({ className = [], state, name, label, options, onChange, isDisabled }: SelectProps) {
  const [testResult, setTestResult] = useState("");
  const inputClass = `is-${testResult}`;
  const labelClass = `${testResult}-feedback`;

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { valid } = onChange(e);
    setTestResult(valid ? "valid" : "invalid");
  };

  const handleFirstValidation = (e: React.ChangeEvent<HTMLSelectElement>) => {
    handleChange(e);
  };

  if (!state) {
    return <></>;
  }
  

  return (
    <div className={`form-floating ${className.join(" ")}`}>
      <select disabled={isDisabled} name={name} id={name} className={`form-control ${inputClass} border`} onChange={handleChange} onBlur={handleFirstValidation} value={state?.value} style={{ fontFamily: 'sans-serif' }} >
        {options?.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>

      <label htmlFor={name} className={`floating`}>
        {label}
      </label>
      {!state?.valid && testResult && <span className={labelClass}>{state?.error}</span>}
    </div>
  );
}
