import { useCallback } from "react";
import { atom, useRecoilState } from "recoil";
import { useAppState } from "@/state/app/useAppState";
import { useUserState } from "@/state/user/useUserState";
import { InviteUserFormState } from "@/forms/InviteUserForm";
import { useSelectedTeamState } from "@/state/team/useSelectedTeamState";

type InviteUserModal = {
  loading: boolean;
  isOpen: boolean;
};

type UpdateUserEmailData = {
  attributes: {
    event: "invite-users";
    schema: "invitation-emails";
    version: "v1";
  };
  data: {
    emails: string;
    prototeam_id: string;
    invited_by: string;
  };
};

export const inviteUserModalState = atom<InviteUserModal>({
  key: "inviteUserModalState",
  default: {
    loading: false,
    isOpen: false,
  },
});

export function useInviteUser() {
  const app = useAppState();
  const user = useUserState();
  const selectedTeam = useSelectedTeamState();

  const [{ loading, isOpen }, setState] = useRecoilState(inviteUserModalState);

  const open = useCallback(() => {
    setState((prev) => ({
      ...prev,
      isOpen: true,
    }));

    document.body.style.overflow = "hidden";
  }, [setState]);

  const close = useCallback(() => {
    setState((prev) => ({
      ...prev,
      isOpen: false,
    }));

    document.body.style.overflow = "auto";
  }, [setState]);

  const onSubmit = useCallback(async ({ emails }: InviteUserFormState) => {
    if (!selectedTeam?.id || !user.email) {
      return;
    }

    const emailList = emails.value
      .split(",")
      .map((email) => email.trim())
      .join(",");

    const requestData: UpdateUserEmailData = {
      attributes: {
        event: "invite-users",
        schema: "invitation-emails",
        version: "v1",
      },
      data: {
        emails: emailList,
        prototeam_id: selectedTeam?.id,
        invited_by: user.email
      },
    };

    const url = `${app.config.gateways.eventLog}/v5/teams/${selectedTeam.id}/invite-users`;
    const headers = new Headers();
    user.isLoggedIn && headers.set("Authorization", `Bearer ${user.tokens.access}`);

    try {
      const response = await fetch(url, {
        method: "POST",
        headers,
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        setState((prev) => ({
          ...prev,
          isOpen: false,
        }));
      } else {
        console.error("Error while sending invitation emails", response);
      }
    } catch (error) {
      console.error("Error while sending invitation emails", error);
    }
  }, [user.tokens.access, selectedTeam?.id]);

  return {
    loading,
    open,
    close,
    isOpen,
    onSubmit,
  };
}
