import { useSeasonsData } from "@/state/data/useSeasonTeams";
import { Roster } from "@/state/data/useTeamRoster/types";

export type RosterOption = Roster & {
    teamId: string,
    title: string,
    seasonTitle: string,
    seasonId: string,
    divisionTitle: string,
    divisionId: string,
    displayTeamId: string,
    isLocked: boolean
}

export type TeamRosterLocalState = {
    rosterSelectOpen: boolean,
}

export type ReducerAction = {
    type: ActionType,
    state?: Partial<TeamRosterLocalState>
}

export enum ActionType {
    SET_ROSTER = "SET_ROSTER",
    SET_SELECTED_ROSTER = "SET_SELECTED_ROSTER",
    TOGGLE_MENU_STATE = "TOGGLE_MENU_STATE",
    SET_ACTIVE_MENU = "SET_ACTIVE_MENU",
    TOGGLE_ROSTER_SELECT = "TOGGLE_ROSTER_SELECT"
}

type SeasonInput = ReturnType<typeof useSeasonsData>

export interface TeamSelectorProps {
    activeSeasons: SeasonInput;
    lockedRosters: any;
    toggleRosterSelectMenu: () => void;
    selectedRoster: RosterOption;
    setSelectedRoster: (teamId: string) => void;
}