import { useEffect } from "react"
import { useAppState } from "@/state/app/useAppState"

import RosterIcon from '@mui/icons-material/Groups';
import StatsIcon from '@mui/icons-material/Scoreboard';
import SeasonsIcon from '@mui/icons-material/CalendarMonth';
import GamesIcon from '@mui/icons-material/Stadium';
import DivisionsIcon from '@mui/icons-material/Category';
import TeamsIcon from '@mui/icons-material/Ballot';
import RefereesIcon from '@mui/icons-material/Sports';
import { Outlet, useParams } from "react-router-dom";
import { useMessageListener } from "@/state/app/useWindowState";

export function LeaguesApp(){
    
    const app = useAppState()
    const messages = useMessageListener()
    const { seasonId } = useParams<{seasonId: string}>()

    const onMessageEvent = (event:MessageEvent) => {
        if(event.data.type === 'location' && event.data.source == 'dashboard-v3'){
            app.navigate(event.data.location)
        }
    }

    useEffect(()=>{
        
        window.scrollTo(0, 0)
        messages.add(onMessageEvent)
        return () => messages.remove(onMessageEvent)

    }, [])

    useEffect(() => {

        if(seasonId){
            app.navigation.set([
                { to: `/seasons/${seasonId}`, icon: <SeasonsIcon />, label: "Season"},
                { to: `/seasons/${seasonId}/games`, icon: <GamesIcon />, label: "Games"},
                { to: `/seasons/${seasonId}/stats`, icon: <StatsIcon />, label: "Stats"},
                { to: `/seasons/${seasonId}/divisions`, icon: <DivisionsIcon />, label: "Divisions"},
                { to: `/seasons/${seasonId}/teams`, icon: <TeamsIcon />, label: "Teams"},
                { to: `/seasons/${seasonId}/roster`, icon: <RosterIcon />, label: "Players"},
                { to: `/seasons/${seasonId}/referees`, icon: <RefereesIcon />, label: "Referees"},
            ])
        }

    }, [ seasonId ])
    
    
    return <Outlet />
}