import { useAppState } from "@/state/app/useAppState";
import { useUserState } from "@/state/user/useUserState";
import { ScheduleGameFormState } from "@/forms/ScheduleGameForm"
import config from "@/config";
import { getOffset } from "@/libs/timezones";

export const useScheduledGameService = () => {

  const app = useAppState()
  const user = useUserState()

    const formatDate = (date: Date) => {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const hour = date.getHours().toString().padStart(2, '0');
      const minute = date.getMinutes().toString().padStart(2, '0');

      const formattedString = `${year}-${month}-${day}T${hour}:${minute}`;

      return formattedString;

    };

    return {

      GetBroadcastProviders: async () => {
          const response = await fetch(`https://lookups.gamesheet.io/api/lookups/broadcasters`, { 
            method: "GET"
        })
        const data = await response.json();

        const transformedData = data.map((item: any) => item.key);
        transformedData.unshift("");

        return transformedData;
      },

      GetGameDetails: async (gameId: string) => {
          const response = await fetch(`${config.gateways.data}/games/${gameId}/scheduled/v4?filter[updatedAfter]=${Date.now()}}`, { 
              method: "GET", 
              headers: { 
                  Authorization: `Bearer ${user.tokens.access}` 
              } 
          })
          const data = response.json();
          return data;
      },

      CreateScheduledGame : async ( gameData : ScheduleGameFormState) => {
          const teamId = gameData.teamId.value.toString()
          const seasonId = gameData.seasonId.value.toString()

          const url = `${app.config.gateways.eventLog}/v5/seasons/${seasonId}/teams/${teamId}/create-scheduled-game`;
          const headers = new Headers();
          user.isLoggedIn && headers.set("Authorization", `Bearer ${user.tokens.access}`);
      
          const requestData = {
            attributes: {
              event: "create-scheduled-game",
              schema: "scheduled-game",
              version: "v1",
            },
            data: {
              scheduled_game_data: {
                  season_id: gameData.seasonId.value,
                  association_id: gameData.associationId.value,
                  league_id: gameData.leagueId.value,
                  division_id: gameData.divisionId.value,
                  team_id: gameData.teamId.value,
                  home_flag: gameData.homeFlag.value,
                  opposing_division: Number(gameData.opposingDivision.value),
                  opposing_team_id: Number(gameData.opposingTeamId.value),
                  date_time: formatDate(gameData.dateTime.value),
                  end_time: gameData.endTime.value,
                  time_zone_name: gameData.timezone.value,
                  time_zone_offset: getOffset(gameData.timezone.value, gameData.dateTime.value) || 0,
                  game_number: gameData.gameNumber.value,
                  game_type: gameData.gameType.value,
                  location: gameData.location.value,
                  scorekeeper_name: gameData.scorekeeperName.value,
                  scorekeeper_phone: gameData.scorekeeperPhone.value,
                  broadcast_provider: gameData.broadcastProvider.value
              }
            }
          };
      
          try {
            const response = await fetch(url, {
              method: "POST",
              headers,
              body: JSON.stringify(requestData),
            });
      
            if (response.ok) {
              return 200
            } else {
              console.error("Error while updating team data:", response);
              return 500
            }
          } catch (error) {
            console.error("Error while updating team data:", error);
            return 500
          }
      },

      EditScheduledGame : async ( gameData : ScheduleGameFormState) => {
          const gameId = gameData.gameId.value.toString()

          const url = `${app.config.gateways.eventLog}/v5/games/${gameId}/edit-scheduled-game`;
          const headers = new Headers();
          user.isLoggedIn && headers.set("Authorization", `Bearer ${user.tokens.access}`);
      
          const requestData = {
            attributes: {
              event: "edit-scheduled-game",
              schema: "scheduled-game",
              version: "v1",
            },
            data: {
              scheduled_game_data: {
                  id: Number(gameData.gameId.value),
                  season_id: Number(gameData.seasonId.value),
                  association_id: Number(gameData.associationId.value),
                  league_id: Number(gameData.leagueId.value),
                  division_id: Number(gameData.divisionId.value),
                  team_id: Number(gameData.teamId.value),
                  home_flag: gameData.homeFlag.value,
                  opposing_division: Number(gameData.opposingDivision.value),
                  opposing_team_id: Number(gameData.opposingTeamId.value),
                  date_time: formatDate(gameData.dateTime.value),
                  end_time: gameData.endTime.value,
                  time_zone_name: gameData.timezone.value,
                  time_zone_offset: getOffset(gameData.timezone.value, gameData.dateTime.value) || 0,
                  game_number: gameData.gameNumber.value,
                  game_type: gameData.gameType.value,
                  location: gameData.location.value,
                  scorekeeper_name: gameData.scorekeeperName.value,
                  scorekeeper_phone: gameData.scorekeeperPhone.value,
                  broadcast_provider: gameData.broadcastProvider.value
              }
            }
          };
      
          try {
            const response = await fetch(url, {
              method: "POST",
              headers,
              body: JSON.stringify(requestData),
            });
      
            if (response.ok) {
              return 200
            } else {
              console.error("Error while updating team data:", response);
              return 500
            }
          } catch (error) {
            console.error("Error while updating team data:", error);
            return 500
          }
      },

      DeleteScheduledGame : async ( gameData : ScheduleGameFormState) => {
          const gameId = gameData.gameId.value.toString()

          const url = `${app.config.gateways.eventLog}/v5/games/${gameId}/delete-scheduled-game`;
          const headers = new Headers();
          user.isLoggedIn && headers.set("Authorization", `Bearer ${user.tokens.access}`);
      
          const requestData = {
            attributes: {
              event: "delete-scheduled-game",
              schema: "game-id",
              version: "v1",
            },
            data: {
              id: gameData.gameId.value
            }
          };
      
          try {
            const response = await fetch(url, {
              method: "POST",
              headers,
              body: JSON.stringify(requestData),
            });
      
            if (response.ok) {
              return 200
            } else {
              console.error("Error while updating team data:", response);
              return 500
            }
          } catch (error) {
            console.error("Error while updating team data:", error);
            return 500
          }
      }
    }
}