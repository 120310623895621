type ScoreboardPeriod = {
    [key:string]:number
}

export function EventToFinalScore(event:any, teamIds:string[]) {

    const { home, visitor } = event
    if( !home || !visitor ) return {
        result: "N/A",
        visitorTeamScore: 0,
        homeTeamScore: 0
    }

    let team = home;
    let opponent = visitor;

    // need to use season team ids, as prototeams can change over time 
    if (teamIds.includes(visitor.id)) {
        team = visitor;
        opponent = home;
    }

    function getScore(team:any){
        return team.scoreboard.find((period:ScoreboardPeriod) => !!period['final'])?.final || 0
    }

    const teamScore = getScore(team);
    const opponentScore = getScore(opponent);

    let result = teamScore > opponentScore ? "W" : teamScore < opponentScore ? "L" : "T"

    if (event.hasOvertime && !event.hasShootout) {
        result = `OT${result}`
    }

    if (event.hasShootout) {
        result = `SO${result}`
    }

    return {
        result,
        visitorTeamScore: getScore(visitor),
        homeTeamScore: getScore(home)
    }

}